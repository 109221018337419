import React, { ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import type { SxProps } from '@mui/system';
import _ from 'lodash';

import BlueCheck from '@carbonmaps/media/icons/bluecheckok.svg?react';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import { siteColors } from '../../lib/colors';

const styles = {
	overTitle: css({
		color: siteColors.grey700,
  leadingTrim: 'both',
  textEdge: 'cap',
  fontVariantNumeric: 'lining-nums tabular-nums',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '150%',
	}),
};

export type ProductSheetHeaderProps = {
	icon: ReactNode;
	rightElement?: ReactNode;
	sx?: SxProps;
} & (
	| {
			variant: 'fixed';
			title: React.ReactNode;
			subtitle?: React.ReactNode;
			isBlueCheck?: string | boolean;
			overTitle?: string;
	  }
	| {
			variant: 'flexible';
			leftElement: ReactNode;
	  }
);

const ProductSheetHeader = ({ icon, rightElement, sx = {}, ...props }: ProductSheetHeaderProps) => {
	const theme = useTheme();

	return (
		<Box
			sx={_.merge(
				{
					background: siteColors.primary10,
					borderRadius: '16px',
					mb: pxToRem(16),
				},
				sx,
			)}
		>
			<Box
				className="flexRow alignCenter spaceBetween"
				paddingRight="24px"
				flexWrap="nowrap"
			>
				{/* on the left */}
				<Box className="flexRow alignCenter" height="120px" flexWrap="nowrap">
					{/* Box Icon */}
					<Box
						className="flexRow alignCenter justifyCenter"
						border={`0.475px solid ${theme.palette.grey[700]}`}
						borderRadius="8px"
						bgcolor="#FFF"
						width="72px"
						height="72px"
						marginLeft="-8px"
						css={{
							flexShrink: 0,
						}}
					>
						{icon}
					</Box>

					{props.variant === 'fixed'
? (
						<Box ml="24px">
							{props.overTitle && (
								<div className="flexRow alignCenter">
									<div className={cx(styles.overTitle, 'flexRow alignCenter')}>
										{props.overTitle}
									</div>
								</div>
							)}

							<Box className="flexRow alignCenter" css={{ marginTop: 8, marginBottom: 8 }}>
								{(props.isBlueCheck === 'yes' || props.isBlueCheck === true) && (
									<BlueCheck css={{ marginRight: '8px' }} />
								)}
								<Typography variant="h2" fontWeight={700}>
									{props.title}
								</Typography>
							</Box>

							{props.subtitle && (
								<div className="flexRow alignCenter">
									<div>
										{props.subtitle}
									</div>
								</div>
							)}
						</Box>
					)
: (
						props.leftElement
					)}
				</Box>

				{/* on the right */}
				{rightElement && rightElement}
			</Box>
		</Box>
	);
};

export default ProductSheetHeader;
