import { useMemo } from 'react';

import { useTheme } from '@mui/material';

import { CARBON_INDICATOR, FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';

import TableHeaderCell from '../../../../components/table/TableHeaderCell';
import { useCompareProduct } from '../../../../hooks/useCompareProduct';
import { useTranslation } from '../../../../hooks/useTranslation';
import { sorting } from '../../../../utils/utils';
import { useProductComparePeriod } from '../hooks';

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		'.bordered tr th:not(:nth-of-type(1),:nth-of-type(1)) > div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) div ,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) button':
		{
			justifyContent: 'flex-end!important',
			paddingRight: '0!important',
		},
	};
};


const ComparePerPeriodTable = () => {
	const { t } = useTranslation();

	const { sortDirection, setSortDirection, products, compareType, indicator } = useCompareProduct();

		const { data, isLoading } = useProductComparePeriod();
	// theme
	const theme = useTheme();
	const stylesTable = useMemo(() => {
			return classes(theme);
		}, [theme]);

		// ------------------------------------------------- //
		// ------------------- columns --------------------- //
		// ------------------------------------------------- //
	const tableColumns = useMemo(() => {
	return [
		{
			Header: (props: any) => {
				return <TableHeaderCell valueType="string" column={props.column} label={t('product')} />;
			},
			accessor: 'label',
			editable: true,
			sortDescFirst: true,
			component: 'TagLabel',
			type: 'string',
			disableSortBy: true,
			props: {
				gap: 0.001,
				isProductCompared: 1,
			},

		},

		...data?.years
?
// eslint-disable-next-line no-unsafe-optional-chaining
	(sorting(data?.years || []))?.map((year: number) => {
				return {
					Header: (props: any) => {
						return (
							<TableHeaderCell
								column={props.column}
								label={t('for_year', { year })}
								variant="measure"
								measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'}
							/>
						);
					},
					accessor: `impact_${year}`,
					editable: true,
					sortDescFirst: true,
					component: 'NumberField',
					type: 'number',
				};
		})
	:
	[],
	];
	}, [t, indicator, data]);

	// ----------------------------------------------------- //
	// --------------------- data table -------------------- //
	// ----------------------------------------------------- //
	const dataTable = useMemo(() => {

		if (!data) return [];

		const productsMap = new Map();
		products.forEach((product: any) => {
			productsMap.set(product.uid, product);
		});

		const results = data.products.map((product: any) => {

			return {
				label: productsMap.get(product.uid)?.label,
				uid: productsMap.get(product.uid)?.uid,
				origin: productsMap.get(product.uid)?.uid,
				link: `${FRONT_PATH_NAMES.products.details}/${productsMap.get(product.uid)?.objectId}/synthesis`,
				objectId: productsMap.get(product.uid)?.objectId,
				color: productsMap.get(product.uid)?.color,
				...product.data.reduce((acc: any, item: any) => {
					return {
						...acc,
						[`impact_${item.year}`]: item[`${indicator}Impact`],
						tonnage: item?.tonnage,
						volume: item?.tonnage,
					};
				}, {}),
			};
		});

		return results;

	}, [data, products, indicator]);


	return (
		<TableComponent
			skeleton={isLoading}
			loading={isLoading}
			fetchData={() => { console.log('data');}}
			data={dataTable}
			pageCount={1}
			columns={tableColumns}
			pagination={false}
			addStyles={stylesTable}
			isSelectable={false}
		/>
	);
};


export default ComparePerPeriodTable;
