
import { type DialogProps } from '@mui/material';
import _ from 'lodash';

import SearchProduct from '../../../containers/products/general/tabs/products/SearchProduct';
import SearchSupplier from '../../../containers/supplier/general/tabs/comparison/SearchSupplier';
import { product } from '../../../hooks/useCompareProduct';
import { useTranslation } from '../../../hooks/useTranslation';
import SupplierTableSelect from '../supplier/SupplierTableSelect';

import ModalSelect from './ModalSelect';

type SupplierModalSelectProps= {
	open: DialogProps['open'];
	onClose?: DialogProps['onClose'];
	onAddProduct: (product: product) => void;
	onRemoveProduct: (product: product) => void;
	container?: any;
}


const SupplierModalSelect = ({ open, onClose, onAddProduct, onRemoveProduct , container }: SupplierModalSelectProps) => {
		const { t }= useTranslation();
		return (
			<ModalSelect
				title={t('add a supplier')}
				open={open} onClose={onClose}
				search={<SearchSupplier withMore={false} />}
				container={container}
			>
				<SupplierTableSelect onAddProduct={onAddProduct} onRemoveProduct={onRemoveProduct} />
			</ModalSelect>
		);

};

export default SupplierModalSelect;
