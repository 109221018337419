import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ROLES_HIERARCHY } from '@carbonmaps/shared/utils/constants';
import {
	UserAccountFormSuperAdminInput,
	userAccountFormSuperAdminValidationSchema,
} from '@carbonmaps/shared/validations/user.validations';
import { createUserRowAction } from '@carbonmaps/ui/actions/userRow';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import FormSelect from '../../../components/form/fields/FormSelect';
import TeamSelectOptions from '../../../components/team/TeamSelectOptions';
import AlertStatic from '../../../components/Toast/AlertStatic';
import Typography from '../../../components/Typography';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useTeamsOptions } from '../../team/team.hooks';


const styles = {
	formSelectContainer: css({
		'& .MuiFormControl-root': {
			width: '100%',
		},
		'& fieldset': {
			borderWidth: '1px',
			borderColor: `${siteColors.grey5} !important`,
		},
	}),
	title: css({
		color: `${siteColors.cmText} !important`,
	}),
	label: css({
		color: `${siteColors.grey9} !important`,
		fontWeight: '500 !important',
	}),
	roleLabel: css({
		textTransform: 'capitalize',
	}),
};

const UserForm = ({ toggle, user, company }: any) => {
	const [message, setMessage] = useState('');
	const queryParams = useQueryParams();
	const queryClient = useQueryClient();

	const { t, i18n } = useTranslation();
	const languageCode = getIsoCountryCode(i18n.language);

		const { data: teamsOptions } = useTeamsOptions({ companyId: company?.objectId || queryParams?.companyId[0]  });

	const optionsRoles = useMemo(() => {
		return ROLES_HIERARCHY.map((role: any, index: any) => {
			return { value: role.suffixName, label: t(role.label) };
		});
	}, [t]);

	const {
		register,
		handleSubmit,
		control,
		setValue,
		formState: { errors, isDirty, isValid },
		// watch,
		reset,
	} = useForm<UserAccountFormSuperAdminInput>({
		resolver: zodResolver(userAccountFormSuperAdminValidationSchema),
		defaultValues: {
			companyId: queryParams?.companyId ? queryParams?.companyId[0] : company?.objectId ,
			firstName: user?.firstName,
			lastName: user?.lastName,
			email: user?.email,
			roleCode: user?.role?.name?.split('_')[1],
			teams: [],

		},
		mode: 'onChange',
	});

	const {
		mutate: createUserFromSuperAdmin,
		isError,
		isLoading,
		isSuccess,
	} = useMutation({
		mutationKey: ['createUserFromSuperAdmin'],
		mutationFn: createUserRowAction,
		onSuccess: async () => {
			setMessage(t('add-user-success-message'));
			queryClient.invalidateQueries({ queryKey: ['findUsers'] });
			toggle();
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
				// setTimeout(() => {
				// 	setMessage('');
				// }, 3000);
			}
		},
	});

	const [userTeams, setUserTeams] = useState<Array<string>>([]);

	const onSubmit: SubmitHandler<UserAccountFormSuperAdminInput> = async (values) => {
		if (!userTeams?.length) return;

		createUserFromSuperAdmin({
			...values,
			languageCode,
			teams: userTeams,
			id: user?.objectId,
		} as any);
	};

	// create user set all options to default selected
	useEffect(() => {
		if(!teamsOptions?.length) return;

		if (user) {
			setUserTeams((user?.teamsOptions || [])?.map((team: Parse.Object) => {return team.id;}));
			return;
		}

		setUserTeams([teamsOptions[0].objectId]);
	}, [teamsOptions, user]);

	return (
		<>
			<div css={{ marginBottom: 24 }}>
				<FormContainer
					loading={isLoading}
					title=""
					onSubmit={handleSubmit(onSubmit)}
					isSuccess={isSuccess}
					isError={isError}
					withSave
					withCancel
					onCancel={() => {
						toggle();
					}}
					buttonTitle={ user ? t('save-user-button') : t('create-user-button')}
					alertSuccess={
						<AlertStatic text={message} type={'success'} icon={<CheckIcon css={{ color: siteColors.green500 }} />} />
					}
					alertError={<AlertStatic text={message} type={'error'} icon={<ErrorIcon css={{ color: '#C74600' }} />} />}
					addStyles={{
						maxWidth: 528,
						// gap: 24,
						margin: 'auto',
					}}
					t={t}
				>
					<div className="flexColumn width100 gap32">
					{ !user ?
					<Typography variant='h2' className={styles.title}>{t('form-user-title')}</Typography>
					:
						<div className="flexColumn gap8">
							<Typography className="grey9">{t('Modifier un utilisateur')}</Typography>
							<div className="flexColumn gap4">
								<Typography variant='h2'>{user?.firstName} {user?.lastName}</Typography>
								<Typography className="grey8">{user?.email}</Typography>
							</div>
						</div>
					}
					<div className="flexColumn width100 gap16">
						<div className="flexRow alignCenter noWrap width100 gap16">
							<div
								className="flexColumn flex1"
								css={{
									gap: 24,
									'.input-container': {
										width: '100%',
									},
								}}
							>
								<BInput
									required
									id="lastName"
									label={t('last-name-label')}
									fullWidth
									margin="dense"
									{...register('lastName')}
									name="lastName"
									error={errors.lastName ? true : false}
									errorMessage={errors?.lastName?.message}
									// onChange={handleNameChange}
									autoComplete="off"
								/>
							</div>
							<div
								className="flexColumn flex1"
								css={{
									gap: 24,
									'.input-container': {
										width: '100%',
									},
								}}
							>
								<BInput
									required
									id="firstName"
									label={t('first-name-label')}
									fullWidth
									margin="dense"
									{...register('firstName')}
									name="firstName"
									// disabled={true}
									error={errors.firstName ? true : false}
									errorMessage={errors?.firstName?.message}
									autoComplete="off"
								/>
							</div>
						</div>
						<div
								className="flexColumn width100"
								css={{
									gap: 24,
									'.input-container': {
										width: '100%',
									},
								}}
							>
								<BInput
									required
									id="email"
									label={t('input-email-label')}
									fullWidth
									margin="dense"
									{...register('email')}
									name="email"
									error={errors.email ? true : false}
									errorMessage={errors?.email?.message}
									autoComplete="off"
								/>
							</div>

							<div className="flexColumn width100 gap8">
								<Typography className={styles.label}>{t('teams')}</Typography>
								<TeamSelectOptions userTeams={userTeams} onConfirm={setUserTeams} companyId={company?.objectId || queryParams?.companyId[0] }/>
							</div>
							{/* <FormSelect
								control={control}
								{...register('position')}
								options={[
										{ value: 'Qualité', label: t('position-quality') },
										{ value: 'R&D', label: t('rd-label') },
										{ value: 'Achat', label: t('purchase-position') },
										{ value: 'Direction', label: t('direction-position') },
										{ value: 'Responsable RSE', label: t('rse-position') },
										{ value: 'Conseiller technique', label: t('ct-position') },
										{ value: 'Autre', label: t('other-text') },
									]}

									label={t('user-position-label')}
									className={cx('width100', styles.formSelectContainer)}
									renderValue={(v: string) => {return <Typography>{v}</Typography>;}}
									isOptions
							/> */}

							<FormSelect
								control={control}
								{...register('roleCode')}
								options={optionsRoles}

									label={t('role-label')}
									className={cx('width100', styles.formSelectContainer)}
									renderValue={(v: string) => {return <Typography className={styles.roleLabel}>{v}</Typography>;}}
									isOptions
							/>
						</div>
					</div>
				</FormContainer>
			</div>
		</>
	);
};

export default UserForm;
