import { useCallback, useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { UnfoldHorizontal, X } from 'lucide-react';
import { useToggle } from 'react-use';

import ExportIcon from '@carbonmaps/media/icons/export.svg?react';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useRowTableSelection } from '@carbonmaps/ui/hooks/useRowTableSelection';

import { useCompareProduct } from '../../../hooks/useCompareProduct';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import ExportDialog from '../../dialogs/ExportDialog';
import JobSuccessModal from '../../dialogs/JobSuccessModal';
import Typography from '../../Typography';
import Button from '../../v3/Button';

const styles = {
	container: css({
		padding: '8px 16px',
		boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
		borderRadius: 16,
		background: siteColors.grey8,
		position: 'fixed',
		right: 'calc(38%  - 266px)', //- 258px
		bottom: 16,
		zIndex: 1200,
	}),
	whiteCard: css({
		minWidth: 40,
		padding: '2px 8px',
		background: siteColors.common.white,
		borderRadius: 4,
	}),
	compareButton: css({
		minWidth: '245px !important',
	}),
	button: css({
		background: 'rgba(255, 255, 255, 0.10) !important',
		color: `${siteColors.common.white} !important`,
		':hover': {
			background: 'rgba(255, 255, 255, 0.20) !important',
		},
	}),

	closeButton: css({
		background: 'transparent !important',
		width: '100px !important',
		minWidth: '0 !important',
		padding: '0!important',
		':hover': {
			background: 'transparent !important',
			opacity: 0.8,
		},
	}),
};

type FloatingMenuProps = {
	element?: React.ElementType;
	onExport: (format: string, selectedRows: any) => void;
	tag: string;
};

const CompareFloatingMenu = ({ element, tag, onExport }: FloatingMenuProps) => {
	const { t } = useTranslation();

	const Component = element || 'div';

	const { indicator } = useApp();

	// ---- products selected ---- //
	const { selectedRows, removeAllSelectedRows } = useRowTableSelection();

	// state to open compare modal
	const [isCompareOpen, setIsCompareOpen] = useState(false);

	// store for compare product
	const { setProducts, setIndicator, setProductToCompare, setReset } = useCompareProduct();

	// state to open floating menu

	const [isOpenMenu, setIsOpenMenu] = useState(false);

	const [searchQueryParams, setSearchParams] = useSearchQueryParams();

	const analytics = useJuneTrack();

	// ------------------------------------------------------ //
	// ----------- handle compare button click -------------- //
	// ------------------------------------------------------ //
	const handleCompareClick = useCallback(() => {
		setIsCompareOpen(true);
		// set products to compare
		const productIds: Array<string> = [];
		setProducts(
			selectedRows.map((item: any, index: number) => {
				productIds.push(item.original.objectId);
				return {
					...item.original,
					color: (siteColors.compareProductColors as any)[`color${index % 5}`],
				};
			}),
		);
		setSearchParams({ compareProduct: 'true', productToCompare: productIds.join(',') });
		setIndicator(indicator);

		// set current product to compare
		setProductToCompare(selectedRows[0].original);
		// open dialog compare

		setReset(0);

		// add event Jun
		analytics.track(
			'Compare products',
			{},
			{
				context: { category: 'Products' },
			},
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setProducts, selectedRows, setIndicator, indicator, setProductToCompare]);

	// --------------------------------------------------- //
	// ----------------- on close compare ---------------- //
	// --------------------------------------------------- //
	const handleCloseCompareModale = useCallback(() => {
		removeAllSelectedRows();
		setIsCompareOpen(false);
		setIsOpenMenu(false);
		setSearchParams({
			productToCompare: '',
			compareProduct: searchQueryParams?.compareProduct === 'true' ? 'false' : 'true',
		}); // force to re-draw table
	}, [removeAllSelectedRows, searchQueryParams?.compareProduct, setSearchParams]);

	// ------------------------------------------- //
	// ---------------- export actions ----------- //
	// ------------------------------------------- //
	const [isExportDialogOpen, toggleExportDialog] = useToggle(false);

	const [isJobRunning, setIsJobRunning] = useToggle(false);

	// ------------------------------------------- //
	// ------------ export ----------------------- //
	// ------------------------------------------- //
	const [format, setFormat] = useState('excel');

	const handleExport = useCallback(
		async ({ exportFormat }: { exportFormat: string }) => {
			setFormat(exportFormat);
			setIsJobRunning();
		},
		[setIsJobRunning],
	);

	// ----------------------------------------------------------------- //
	// -------- remove scroll bar body when compare modal is open ------ //
	// ----------------------------------------------------------------- //
	useEffect(() => {
		const bodyElement = document.getElementsByTagName('body')[0];

		if (isCompareOpen) {
			bodyElement.style.overflowY = 'hidden';
		} else {
			bodyElement.style.overflowY = 'scroll';
		}
	}, [isCompareOpen]);

	// -------------------------------------------------------------- //
	// --------------- force to open compare products --------------- //
	// -------------------------------------------------------------- //
	useEffect(() => {
		// force to close floating menu
		if (!selectedRows?.length && searchQueryParams?.productToCompare?.trim() === '' && !isCompareOpen) {
			setIsOpenMenu(false);
		}

		// force to close compare modal
		if (searchQueryParams?.compareProduct !== 'true' && isCompareOpen) {
			setIsCompareOpen(false);
			setIsOpenMenu(false);
			return;
		}

		if (selectedRows?.length || searchQueryParams?.productToCompare?.trim() !== '') {
			setIsOpenMenu(true);
		}

		if (searchQueryParams?.productToCompare?.trim() !== '') {
			setIsCompareOpen(true);
		}
	}, [
		selectedRows,
		searchQueryParams?.productToCompare,
		setProducts,
		searchQueryParams?.compareProduct,
		isCompareOpen,
	]);

	// ------------------------------------------------------------- //
	// ----------------- handle close compare modal  --------------- //
	// ------------------------------------------------------------- //
	const handleCloseModal = () => {
		setIsCompareOpen(false);
		setSearchParams({ productToCompare: '', compareProduct: 'false' });
		removeAllSelectedRows();
		setIsOpenMenu(false);
	};

	return isOpenMenu
? (
		<>
			<div className={cx('flexRow gap16 justifyCenter alignCenter nowrap', styles.container)}>
				<div className="flexRow gap8 alignCenter nowrap">
					<div className={cx('flexRow alignCenter justifyCenter', styles.whiteCard)}>{selectedRows.length}</div>
					<Typography className="white fontWeight500">{t('sélectionnés')}</Typography>
				</div>
				<Button
					label={t('Comparer ces éléments')}
					handleClick={handleCompareClick}
					className={cx(styles.compareButton, styles.button)}
					iconLeft={<UnfoldHorizontal />}
				/>
				<Button
					label={t('Exporter')}
					handleClick={() => {
						return toggleExportDialog();
					}}
					iconLeft={<ExportIcon />}
					className={styles.button}
				/>
				<Button
					label={''}
					handleClick={() => {
						return handleCloseCompareModale();
					}}
					iconLeft={<X />}
					className={cx(styles.button, styles.closeButton)}
					isFullWidth={false}
				/>
			</div>

			{isCompareOpen && <Component onClose={handleCloseModal} />}

			<ExportDialog
				title={`${t('export-message-title')} ${selectedRows?.length ? selectedRows?.length : 0}  ${tag}(s)`}
				open={isExportDialogOpen}
				toggle={toggleExportDialog}
				isLoading={false}
				handleExport={handleExport}
				trackName="Export products"
			/>
			{isJobRunning && (
				<JobSuccessModal
					open={isJobRunning}
					toggle={setIsJobRunning}
					onConfirm={() => {
						return onExport(format, selectedRows);
					}}
				/>
			)}
		</>
	)
: null;
};

export default CompareFloatingMenu;
