import { useCallback, useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { CircularProgress, Popper } from '@mui/material';
import type { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { ChevronDown } from 'lucide-react';

import SupplierIcon from '@carbonmaps/media/icons/supplier.svg?react';
import { roleLabels } from '@carbonmaps/shared/utils/constants';

import MultiSelectCheckboxField from '../../../../../apps/front/src/components/form/fields/MultiSelectCheckboxField';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import BButton from '../BButton';
import BPopover from '../BPopover';

type Option = {
	value: string | number;
	label: React.ReactNode;
	count?: number;
	companyId: string;
};
type Props = {
	value: any;
	row: any;
	column: any;
	companyId: any;
	props: any;
}

const styles = {
	content: css({
		gap: 8,
		color: siteColors.text,
		fontSize: 14,
		fontFamily: 'Inter',

	}),
	innerContent: css({
		width: 16,
		height: 16,
		borderRadius: 16,
		background: 'var(--background-round)',
	}),
	popoverContainer: css({
		padding: 24,
	}),
	popoverContent: css({
		'& p': {
			color: siteColors.text,
			fontSize: 14,
			fontFamily: 'Inter',
			lineHeight: '25px',
			margin: 0,
		},
	}),
	iconContainer: css({
		gap: 8,
		// display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		width: 40,
	}),
	width10: css({
		width: 10,
	}),
	width20: css({
		width: 20,
	}),
	nowrap: css({
		whiteSpace: 'nowrap',
	}),
	label: css({
		color: cn(siteColors.grey9, '!important'),
		fontSize: 14,
		fontWeight: 500,
		gap: 8,
		alignItems: 'center!important',
	}),

	paper: css({
		'& .MuiAutocomplete-paper': {
			maxHeight: 600, // Fixer la longueur ici
			width: '350px',
		},

	}),

	skeletonContainer: css({
		height: '600px !important',
		width: '500px !important',
		borderRadius: 16,
	}),

	actionContainer: css({
		zIndex: 3,
		marginTop: '0px !important',
		justifyContent: 'space-between',
		paddingBottom: '8px !important',
	}),

};

const customPopper = (props: any) => {
	return <Popper {...props}
		style={{ width: 400, borderRadius: 0, border: 'none' }} placement="bottom-start"
		className={cx(styles.paper)}
	/>;
};

const EditSupplierUser = ({
	value,
	row,
	column,
}: Props) => {
	const theme = useTheme();
	const [selectedValues, setSelectedValues] = useState<any>([]);
	// eslint-disable-next-line no-unsafe-optional-chaining
	const { t, isLoading, companyId, supplierOptions } = column?.props;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const dataSupplier = row.original?.supplierUids || [];

	useEffect(() => {
		if (dataSupplier) {
			setSelectedValues((supplierOptions?.suppliers as any)?.filter((supplier: any) => {
				return dataSupplier.includes(_.get(supplier, 'uid'));

			}).map((supplier: any, index: number) => {
				return {
					label: _.get(supplier, 'name'),
					value: _.get(supplier, 'uid'),
					code: _.get(supplier, 'uid'),
					count: 0,
				};
			}));
		}
	}, [dataSupplier, supplierOptions]);


	const handleChange = useCallback(
		(event: any, value: Option[], reason: any, _option: { option: Option } /*  ...args */) => {
			const { option } = _option;
			const _reason = reason as AutocompleteChangeReason;

			setSelectedValues((oldValues: any) => {
				const newValues: Option[] = [];

				if (_reason === 'removeOption') {
					for (const oldV of oldValues) {
						if (oldV.value === option.value && oldV.label === option.label) {
							continue;
						}

						newValues.push(oldV);
					}

					return newValues;
				}

				let isNew = true;

				for (const oldV of oldValues) {
					if (oldV.value === option.value && oldV.label === option.label) {
						isNew = false;
						continue;
						// break;
					}

					newValues.push(oldV);
				}

				if (isNew) {
					newValues.push(option);
				}

				return newValues;
			});
		},
		[setSelectedValues],
	);

	if (row.original?.role?.label !== roleLabels.CONTRIBUTOR) {
		return;
	}

	return (
		<div className={cx('flexRow width100 justifyCenter', styles.content)}>
			<BPopover
				anchorOriginVertical={'bottom'}
				anchorOriginHorizontal={'left'}
				transformOriginVertical={'top'}
				transformOriginHorizontal={'left'}
				content={(onClose) => {
					return (
						<div>
							<div css={{ marginBottom: 24, height: 410, width: 230 }}>
								<div
									className="flexColumn width100"
									css={{
										maxWidth: 450,
										gap: 24,
										'.input-container': {
											// width: '100%',
											width: 450,
										},
										marginBottom: 24, height: 420, width: 350,
									}}
								>
									{supplierOptions &&
										<MultiSelectCheckboxField
											customPopper={customPopper}
											showOptionListDefault={true}
											name={'WTF'}
											selectedValues={selectedValues}
											handleFilterClick={(e: any) => {
												/* idk */
											}}
											handleChange={handleChange}
											options={(supplierOptions?.suppliers as any)?.map((supplier: any) => {
												return {
													label: _.get(supplier, 'name'),
													value: _.get(supplier, 'uid'),
													code: _.get(supplier, 'uid'),
													count: 0,
												};
											}) as any}
											showCount={false}
											customSx={{
												width: '100%',
												'& .MuiInputBase-root': {
													borderRadius: '0px',
													// background: 'red',
													border: 'none!important',
													'&:hover': { background: 'none' },
													'&:focus': { background: 'red' },
												},
												'& .MuiOutlinedInput-root': {
													borderRadius: '0px',
													border: 'none!important',
													'&:hover': { background: 'none' },
												},
												'& .MuiInputBase-colorPrimary': {
													borderRadius: '8px 8px 0px 0px',
												},
											}}
										/>
									}
								</div>

							</div>
							<div className={cx('flexRow', styles.actionContainer)}>
								<BButton
									onClick={() => {
										column?.props?.updateMyData({
											suppliersUid: selectedValues?.map((v: any) => { return v.code; }) || [],
											companyId,
											userId: row.original.objectId,
										});
										if (!isLoading) onClose();

									}}
									addStyles={{ borderRadius: '8px', padding: '8px 12px', marginLeft: '5px' }}
									label={isLoading ? <CircularProgress sx={{ color: siteColors.common.white }} size={20} /> : t('button-save-company')}
									variant="primary"
								/>
							</div>
						</div>
					);
				}}
			>
				<BButton
					iconRight={<ChevronDown color={theme.palette.grey[700]} />}
					variant="tertiary"
					label={
						<div className={cx('flexRow justifyCenter center ', styles.label)}>	<SupplierIcon css={{ '& path': { fill: theme.palette.grey[700] } }} width={20} height={20} />
							<div>{t('Lié à')} {selectedValues?.length || 0} {t('supplier')}{selectedValues?.length > 1 ? 's' : ''}</div></div>}
					addStyles={{
						display: 'flex',
						justifyContent: 'space-between',
						background: 'none',
						padding: '8px 12px',
						height: '32px',
						flexShrink: 0,
					}}
				/>
			</BPopover>
		</div>
	);
};

export default EditSupplierUser;

