import { useCallback, useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { ArrowLeftRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import CardTitle from '../../../../../../components/dashboard/v2/CardTitle';
import Typography from '../../../../../../components/Typography';
import Card from '../../../../../../components/v3/Card';
import CategoryTable from '../../../../../../components/v3/CategoryTable';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../../lib/colors';
import { useSupplierSetup } from '../../../hooks/supplier.hooks';

const styles = {
	title: css({
		fontSize: '16px !important',
		lineHeight: '1.2 !important',
	}),
	subtitle: css({
		lineHeight: '1.2 !important',
		color: `${siteColors.grey7} !important`,
	}),
	button: css({
		width: 'auto !important',
		minWidth: '50px !important',
		color: `${siteColors.blackButton} !important`,
		padding: '8px 16px !important',
		fontWeight: '500 !important',
	}),
	approTable: css({
			'& table tbody tr td:first-of-type a > .flexRow.nowrap.alignCenter > div': {
				'& p': {
					maxWidth: '500px !important',
				},
				flexGrow: 1,
		},
	}),
};

const ApprosBlock = () => {
	const {
		productsSupplier,
		isLoading,
	} = useSupplierSetup();

	const { indicator } = useApp();

	const dataTable = useMemo(() => {
		if (!productsSupplier?.length) return [];

		const data = productsSupplier;

		const _d = data.map((item: any) => {
			return {
				...item,
				value: indicator === CARBON_INDICATOR ? item.carbonImpactPercent : item.waterImpactPercent,
			};
		});

		return _d.sort((a: any, b: any) => {return b.value - a.value;}).slice(0, 6);
	}, [indicator, productsSupplier]);

	const { t } = useTranslation();
	const navigate = useNavigate();

	const goToAnalyzeList = useCallback(() => {
		window.scrollTo(0, 0);
		navigate('approvisionnements');
	}, []);

	return (
		<div css={{ marginBottom: '40px' }}>
			<div css={{ marginBottom: '16px' }}>
			<CardTitle
							title={`${t('approvisionnements-card-title')} (${productsSupplier.length})`}
							icon={<ArrowLeftRight color={siteColors.grey700} size={20} />}
						/>

			</div>
			<Card
				title={
					<div className="flexColumn gap6">
						<Typography className={styles.subtitle}>{t('Par impact total')}</Typography>
					</div>
				}
				footer={
					<BButton
						variant="secondary"
						label={t('see-all-button')}
						onClick={goToAnalyzeList}
						className={cx(styles.button)}
					/>
				}
				loading={isLoading}
				innerClassName={styles.approTable}
			>
				<CategoryTable data={dataTable} />
			</Card>
		</div>
	);
};

export default ApprosBlock;
