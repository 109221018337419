import { type QueryFunctionContext } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';

import { getFacetToShow } from '../../../apps/front/src/utils/utils';

export type GetParams = {
	size: number;
	page: number;
	direction?: number;
	id?: string;
	input?: string;
	facetFilters?: { values: string[]; path: string }[] | [];
	facetFiltersSelected?: { values: string[]; path: string }[] | [];
	sortingBy?: any;
};

//----------------------------------------------------------------------------------------------------//
//                           Data to display in general view of supplier list for a company                            //
//----------------------------------------------------------------------------------------------------//
export const getSupplierCompany = async (context: QueryFunctionContext) => {
	try {
		const {
			queryKey: {
				1: { size, page, ...rest },
			},
		} = context as any;
		const { data, count } = await Parse.Cloud.run('findSupplier', {
			limit: size,
			skip: (page - 1) * size,
			pageIndex: page,
			...rest,
		});

		return {
			data,
			meta: {
				last_page: count ? Math.ceil(count / size) : 1,
				count,
			},
		};
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                              count supplier for company                               //
//--------------------------------------------------------------------------------------//
export const supplierCompanyCount = async (context: QueryFunctionContext<readonly ['getSupplierCompanyCount', Record<string, any>]>) => {
	try {
		const params = context.queryKey[1];
		const count = await Parse.Cloud.run('getSupplierCount', { selectedYear: params.selectedYear, team: params.team  });
		return count;
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                              facet of supplier for company                               //
//--------------------------------------------------------------------------------------//
export async function getSupplierFacets(params: any) {
	try {
		const {  facetFilters } = params;

		const data = await Parse.Cloud.run('getFacetsSupplier', params);

		// For a facet with a value already selected, I may be able to select another value
		// and see the products (OR ingredient) of one OR the other of the facet values appear
		if (facetFilters.length && data) {
			const allData = await Parse.Cloud.run('getFacetsSupplier', {
				...params,
				facetFilters: [],
			});

			return getFacetToShow(facetFilters, data/* .facet */, allData/* .facet */);
		}

		return data;
	} catch (error) {
		return Promise.reject(error);
	}
}

//--------------------------------------------------------------------------------------//
//                                    supplier infos                                    //
//--------------------------------------------------------------------------------------//
export const getSupplier = async (context: any) => {
	try {
		const {
			queryKey: {
				1: { supplierId },
			},
		} = context;
		const result = await Parse.Cloud.run('getSupplier', {
			supplierId,
		});

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

//----------------------------------------------------------------------------------------------------//
//                           Data to display in general view at the donut chart                            //
//----------------------------------------------------------------------------------------------------//
export const getDonutInfoSupplier = (supplier: any, sumCarbonImpact: number, sumWaterImpact: number) => {
	return {
		carbonImpactPercent: (supplier.totalCarbonImpact * 100) / sumCarbonImpact || 0,
		carbonImpact: supplier.totalCarbonImpact,
		volume: supplier.totalTonnage,
		waterImpact: supplier.totalWaterImpact,
		waterImpactPercent: (supplier.totalWaterImpact * 100) / sumWaterImpact || 0,
	};
};

export const getSuppliersIndicatorsChart = async (context: any) => {
	try {
		const {
			queryKey: {
				1: { input, facetFilters, supplierIds, selectedYear },
			},
		} = context as any;

		// const results = await Parse.Cloud.run('findSupplier', { input, facetFilters, supplierIds, unity: 1, isChart: true });
		// return results || [];
		return await Parse.Cloud.run(functionName.temporality.findSupplier, {
			view: 'general-suppliers-onboarding',
			input, facetFilters, supplierIds, selectedYear,
		});
	} catch (error) {
		return Promise.reject(error);
	}
};

//----------------------------------------------------------------------------------------------------//
//                           Data to display in tradeoff                           //
//----------------------------------------------------------------------------------------------------//

export const getSupplierTradeOff = async (context: QueryFunctionContext) => {
	try {
		const {
			queryKey: {
				1: { input, facetFilters, ...rest },
			},
		} = context as any;

		const result = await Parse.Cloud.run('findSupplier', { input, facetFilters, unity: 1, isGraph: true, ...rest });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//              Get the list of suppliers for the current user's company               //
//--------------------------------------------------------------------------------------//
export const getCompanySuppliers = async (
	context: QueryFunctionContext<readonly ['getAllSuppliersForCompany', { itemType?: string; isN2?: boolean, isFilter?: boolean }]>,
): Promise<Parse.Object[] | any[]> => {
	try {
		const {
			queryKey: {
				1: { itemType, isFilter, isN2 },
			},
		} = context;

		const suppliers = await Parse.Cloud.run('getAllSuppliersForCompany', { itemType, isN2, isFilter });

		return suppliers;
	} catch (error) {
		return Promise.reject(error);
	}
};



//--------------------------------------------------------------------------------------//
//                         get data for one Supplier with time period                   //
//--------------------------------------------------------------------------------------//
export const getSupplierSheetTimePeriodAction = async (
	context: QueryFunctionContext<
		readonly ['getSupplierSheetTimePeriod', { objectId: string; period: string | number; fromPath?: string; team?: string }]
	>,
): Promise<any> => {
	try {
		const {
			queryKey: [, { objectId, fromPath, period, team }],
		} = context;
		const res = (await Parse.Cloud.run(functionName.temporality.findOneSupplier, {
			supplierId: objectId,
			period,
			fromPath,
			team,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getSupplierSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};



export const getOneSupplierAction = async (
	context: QueryFunctionContext<
		readonly ['getOneSupplierData', { objectId: string; }]
	>,
): Promise<any> => {
	try {
		const {
			queryKey: [, { objectId }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.suppliers.findOneSupplierData, {
			supplierId: objectId,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getOneSupplierData error: ', error);
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                         get data for one Supplier for radar chart                    //
//--------------------------------------------------------------------------------------//
export const getOneSupplierRSERadarChartDataAction = async (
	context: QueryFunctionContext<
		readonly ['getOneSupplierRSERadarChartData', { objectId: string; period: string | number; questionFormId: string }]
	>,
): Promise<any> => {
	try {
		const {
			queryKey: [, { objectId, period, questionFormId }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.temporality.findOneSupplierRsePolarChart, {
			supplierId: objectId,
			period,
			questionFormId,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getOneSupplierRSERadarChartData error: ', error);
		return Promise.reject(error);
	}
};


type GetSupplierToCompareParams = {
	productIds: Array<string>;
	period: number;
	products: Array<string>;
}

export const getSuppliersToCompareAction = async (
	context: QueryFunctionContext<readonly ['getSuppliersToCompare', GetSupplierToCompareParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { productIds, products,  period },
			},
		} = context;

		if (!productIds.filter((p) => {return p;})?.length) {
			return [];
		}

		const hasValue = productIds.some((id) => {return products.includes(id);});

		if (hasValue) {
			return [];
		}


		const data = await Parse.Cloud.run(functionName.suppliers.getSuppliersToCompare, { productIds, period });
		return data;
	} catch(error){
		return Promise.reject(error);
	}
};


type GetResultComparePerPeriod = {
	productUids: Array<string>;
	period?: number;

}

export const getCompareResultSupplierByPeriodAction = async (
	context: QueryFunctionContext<readonly ['getCompareResultSupplierByPeriod', GetResultComparePerPeriod]>,
) => {
	try {
		const {
			queryKey: {
				1: { productUids },
			},
		} = context;

		if (!productUids.filter((p) => {return p;})?.length) {
			return [];
		}


		const data = await Parse.Cloud.run(functionName.suppliers.getCompareResultByPeriod, { productUids });
		return data;
	} catch(error){
		return Promise.reject(error);
	}
};
