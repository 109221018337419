import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { forEach } from 'lodash';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';

import BlockTitle from '../../../../../components/comparison/BlockTitle';
import CompareProductBarChart from '../../../../../components/comparison/CompareProductBarChart';
import ImpactSelected from '../../../../../components/comparison/ImpactSelected';
import TrajectoryGraph from '../../../../../components/comparison/TrajectoryGraph';
import Unit from '../../../../../components/units/Unit';
import Card from '../../../../../components/v3/Card';
import { useCompareProduct } from '../../../../../hooks/useCompareProduct';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { orderBy } from '../../../../../utils/array.utils';
import { compareProductConfig } from '../../../../../utils/compareProduct';
import { sorting } from '../../../../../utils/utils';
import { useSupplierComparePeriod } from '../../../hooks';

import SupplierPerPeriodTable from './SupplierPerPeriodTable';




const styles = {
	container: css({
			padding: '16px 8px',
	}),
	content: css({
		overflow: 'auto',
		maxHeight: 'calc(100vh - 170px)',
		gap: 4,
	}),
	card: css({
		padding: '0px !important',
		gap:'0 !important',
		minHeight: '200px',
	}),


};

const options = [
	{
		label: 'Tri du moins impactant au plus impactant',
		value: 'asc',
	},
	{
		label: 'Tri du plus impactant au mois impactant',
		value: 'desc',
	},
];

const SupplierPerPeriodContent = () => {

	const { t } = useTranslation();

	const { sortDirection, setSortDirection, products, compareType, indicator } = useCompareProduct();

	// config for compare value
	const config = compareProductConfig.get(compareType)[indicator] as any;

	const { data, isLoading } = useSupplierComparePeriod();

	// -------------------------------------------------------- //
	// -------------------- data series ----------------------- //
	// -------------------------------------------------------- //
	const series = useMemo(() => {

		if (!data) return [];

		const productsMap = new Map();
		products.forEach((product: any) => {
			productsMap.set(product.uid, product);
		});

		const allYears = sorting(data?.years);

		const dataSeries = data.products.map((product: any) => {

			const dataMapYears = new Map();
			forEach(product.data, (item: any) => {
				dataMapYears.set(item.year, item);
			});

			return {
				name: productsMap.get(product.uid)?.label,
				data: allYears.map((year: any) => {
					const item = dataMapYears.get(year);
					return item ? item[`${indicator}Impact`] : 0;
				}),

				color: productsMap.get(product.uid)?.color,
				marker: {
					enabled: true,
					symbol: 'circle', // Points en forme de petit rond
					radius: 4, // Taille du cercle
					lineWidth: 2, // Épaisseur du contour du cercle
					fillColor: 'transparent', // Cercle vide
					lineColor: productsMap.get(product.uid)?.color, // Couleur du contour rouge
				},
			};
		});

		return dataSeries;


	}, [data, products, indicator]);

	const xAxis = useMemo(() => {
		if(!data) return [];

		return sorting(data?.years);
	}, [data]);


	return (
		<div className={cx('flexColumn flex1 gap24', styles.container)}>
			<BlockTitle title={t('Comparaison de l’impact par période')} subtitle={t('Visualiser les évolutions des émissions carbone pour identifier les grandes tendances')}/>
			{  !isLoading && data?.years?.length === 1 && <ImpactSelected options={options} value={sortDirection} onChange={(v: any) => {setSortDirection(v);}}/> }

			<div className={cx('flexColumn width100 gap8', styles.content)} >
				{ (isLoading || !isLoading && data?.years?.length > 1) &&
				<>
					<Card loading={isLoading} noBorder={true} containerClassName={styles.card} >
						<TrajectoryGraph series={series}  xAxis={xAxis}  unit={<Unit measure={ indicator===CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'} />}/>
					</Card>

				<Card loading={isLoading} noBorder={true } containerClassName={styles.card} >
					<SupplierPerPeriodTable />
				</Card>

				</> }

				{ !isLoading && data?.years?.length === 1 && <CompareProductBarChart products={orderBy(products, config.field, sortDirection as any) as any}/> }
			</div>
		</div>
	);


};

export default SupplierPerPeriodContent;
