import { useCallback } from 'react';

import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { AlertCircle, ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { getVolumeErrors } from '@carbonmaps/ui/actions/dashboard.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import Skeleton from '../../../../components/Skeleton';
import Typography from '../../../../components/Typography';
import CardContent from '../../../../components/dashboard/v2/CardContent';
import CardTitle from '../../../../components/dashboard/v2/CardTitle';
import DashboardButton from '../../../../components/dashboard/v2/DashboardButton';
import DashboardCard from '../../../../components/dashboard/v2/DashboardCard';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';

const styles = {
	content: css({ minHeight: 224, gap: '34px !important', cursor: 'pointer' }),
	unit: css({
		fontSize: '14px !important',
	}),
	iconContainer: css({
		width: 78,
		height: 78,
		background: siteColors.grey300,
		borderRadius: 8,
	}),
	button: css({
		minWidth: '0 !important',
		padding: '0!important',
	}),
};
type ErrorVolumeCardProps = {
	isCenter?: boolean;
	isBottomButton?: boolean;
	className?: string;
	gap?: number;
};

const ErrorVolumeCard = ({ className, isCenter = true, isBottomButton = true, gap }: ErrorVolumeCardProps) => {
	// ---- translation ---- //
	const { t } = useTranslation();
	const navigate = useNavigate();
	// ---- fetch global percent value ---- //
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	// ---- fetch eco design global information ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getVolumeErrors',
			{
				facetFilters: searchQueryParams?.facetFilters || [],
				period: selectedYear,
			},
		],
		queryFn: getVolumeErrors,
	});

	const goToError = useCallback(() => {
		const filters = [{
			path: 'type_error', type: 'string', values: ['Erreur%20volume'], global: false, folderLabel: 'Type%20d%27erreur',
		}];
		const stringFilters = JSON.stringify(filters);
		navigate(`${FRONT_PATH_NAMES.dataDiagnostics}/product?facetFilters=${stringFilters || '[]'}`);
	}, []);

	const content = (
		<CardContent
				className={cx(' justifyCenter gap34', isCenter ? 'flexCenter' : 'flexColumn', styles.content, className)}
				button={
					<DashboardButton
						label={t('error-button')}
						path={`${FRONT_PATH_NAMES.audit}/product`}
						eventName="Dashboard Correct errors"
					/>
				}
				// loading={isLoading}
				withButton={isBottomButton ? data?.error : false}
				onClick={goToError}
			>
				<div className="flexRow alignCenter gap24 nowrap">
					<div className={cx('flexCenter', styles.iconContainer)}>
						<AlertCircle size={30} color={siteColors.grey700} />
					</div>

					<div className="flexColumn flex1 ">
						<div className="flexRow gap8 alignCenter">
							<Typography variant="subtitle2">{_.isArray(data?.error) ? data.error.length : data}</Typography>
							<BButton
								variant="tertiary"
								label={<ArrowRight color={siteColors.grey700} />}
								onClick={goToError}
								className={styles.button}
							/>
						</div>
						<Typography>{t('dashboard-error-volume-content')}</Typography>
					</div>
				</div>
			</CardContent>
	);

	return (
		<DashboardCard title={<CardTitle title={t('Erreurs volume')} />} className={cx('flex1 ', `gap${gap}`)}>
			{isLoading ? <Skeleton sx={{ width: '100%!important', maxWidth: 'unset' }} >{content}</Skeleton>: content}
		</DashboardCard>
	);
};

export default ErrorVolumeCard;
