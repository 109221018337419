import { ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import Box, { type BoxProps } from '@mui/material/Box';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { InfoIcon } from 'lucide-react';

import { pxToRem } from '@carbonmaps/ui/utils/styles';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import { useTranslation } from '../hooks/useTranslation';
import { siteColors } from '../lib/colors';

import LightTooltip from './LightTooltipGlossary';

type Props = TypographyProps & {
	icon?: ReactNode;
	className?: string;
};


const styles = {
	container: css({
		padding: '12px',
		backgroundColor: siteColors.grey2,
		border: `solid 1px ${siteColors.grey500}`,
		borderRadius: '4px',
		maxWidth: '400px',
	}),
};

const BlockInfo = ({ children, icon, className, ...props }: Props) => {
	const { t } = useTranslation();
	//todo generic
	return (
		<LightTooltip title={t('product-modelling-reference-info', { product_lowercase: toLowerCase(t('product')), recipe: toLowerCase(t('recipe')) })}>
			<div className={cx('flexRow alignCenter gap8', styles.container)} >
				{icon ?? <InfoIcon size={12} color={siteColors.grey8} />
				}
				<Typography
					color={siteColors.grey8}
					fontSize={'12px'}
					fontWeight={400}
					ml={icon ? '8px' : 0}
					{...props}
				>
					{children}
				</Typography>
			</div>
		</LightTooltip>
	);
};

export default BlockInfo;
