import { css } from '@emotion/css';
import Typography from '@mui/material/Typography';

import { useYearSelection } from '../../../hooks/useImpactYear';
import { useTranslation } from '../../../hooks/useTranslation';

type PopoverItemProps = {
	label: React.ReactNode;
	value: React.ReactNode;
	other?: React.ReactNode;
};

const styles = {
	container: css({
		marginBottom: '12px !important',
	}),
	mt: css({
		marginTop: '5px !important',
	}),
};

const PopoverItem = ({ value, label, other }: PopoverItemProps) => {
	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();
	return (
		<div className={styles.container}>
			<div className='flexColumn gap16'>
			<div>
			<Typography fontWeight="600">{t(label , { period: selectedYear || t('period') })}</Typography>
			<Typography className="number">{value}</Typography>
			</div>
			<div className={styles.mt}>{other}</div>
			</div>
		</div>

	);
};

export default PopoverItem;
