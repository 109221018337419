import { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import FormControl from '@mui/material/FormControl';
import type { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system';
import { lighten } from '@mui/system/colorManipulator';
import _ from 'lodash';
import { ChevronDown } from 'lucide-react';

import { important, pxToRem } from '@carbonmaps/ui/utils/styles';

// import BlockTitle from '../../BlockTitle';
import { siteColors } from '../../../lib/colors';
import { ItemField } from '../../../types/field.type';

type DropdownFieldProps = {
	id?: string;
	disabled?: boolean;
	value: string | number;
	onChange: (value: any) => void;
	ExpandMoreRoundedIcon?: React.ElementType;
	iconSize?: number;
	items: Array<ItemField>;
	renderItem?: (item: ItemField) => React.ReactNode;
	renderValue?: (selectedItem?: ItemField) => React.ReactNode;
	sx?: SxProps;
	// addStyles?: {
	// 	select?: any;
	// 	icon?: any;
	// 	formControl?: any;
	// };
	className?: string;
	iconComponent?: any;
	paddingRight?: number;
	iconColor?: string;
	MenuProps?: any;
	displayEmpty?: boolean;
	style?:any;
};

const classes = () => {
	return {
		paper: {
			borderRadius: 12,
			marginTop: 8,
			minWidth: '212px !important',
			'& .MuiButtonBase-root': {
				'&:hover': {
					backgroundColor: `${siteColors.grey300} !important`,
				},
			},
		},
		list: {
			paddingTop: 0,
			paddingBottom: 0,
			'& li': {
				padding: '4px 16px',
				alignItems: 'center',
				gap: '8px',
				fontSize: '14px',
				fontStyle: 'normal',
				fontWeight: '400',
				color: siteColors.text,
				'& .MuiRadio-root': {
					padding: 4,
				},
			},
		},
	};
};

const SelectField = ({
	id,
	disabled,
	value,
	items,
	ExpandMoreRoundedIcon,
	iconSize = 16,
	onChange,
	renderItem,
	renderValue,
	paddingRight = 34,
	// addStyles,
	sx = {},
	className,
	iconColor = '#7900D9',
	MenuProps,
	displayEmpty,
	style,
}: DropdownFieldProps) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const styles = classes();

	const [selectedValue, setSelectValue] = useState(value);

	const handleChange = (event: SelectChangeEvent<string>) => {

		setSelectValue(event.target.value);
		onChange(event.target.value);

	};

	const menuProps = {
		disableScrollLock: true,
		classes: {
			list: css(styles.list),
			paper: css(styles.paper),
		},
		PopoverClasses: 'test',
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'center',
		},
		transformOrigin: {
			vertical: 'center',
			horizontal: 'center',
		},
		getContentAnchorEl: null,
	} as Partial<MenuProps>;

	useEffect(() => {
		setSelectValue(value);
	}, [value]);

	return (
		<FormControl
		>
			<Select
				id={id}
				disabled={disabled}
				variant="standard"
				value={value as any}
				onChange={handleChange}
				disableUnderline
				IconComponent={(props) => {
					const Icon = ExpandMoreRoundedIcon || ChevronDown;
					return <Icon {...props} size={iconSize} />;
				}}
				MenuProps={{
					...menuProps,
					...MenuProps,
				}}
				displayEmpty={displayEmpty}
				renderValue={(value: string) => {
					const selectedItem = items.find((item) => {
						return item.value === value;
					});

					if (renderValue) {
						return renderValue(selectedItem);
					}

					return (
						<Typography
							variant="h1"
							color="#7900D9"
							textTransform="lowercase"
						>
							{selectedItem?.label}
						</Typography>
					);
				}}
				sx={_.merge(
					{
						bgcolor: lighten('#7900D9', 0.8),
						borderRadius: pxToRem(8),
						'& .MuiSelect-select': {
							paddingLeft: pxToRem(12),
							paddingRight: important(pxToRem(32)),
							display: 'flex',
							alignItems: 'center',
						},
						'& .MuiSelect-icon': {
							color: iconColor,
							right: pxToRem(10),
							top: 'calc(50% - 8px)',
							pointerEvents: 'none',
						},
						'&.Mui-focused>.MuiSelect-select': { bgcolor: 'transparent' },

					},
					sx,
				)}
				className={className}
				style={style}
			>
				{items.map((item, index: number) => {
					return (
						<MenuItem key={index} value={item.value}>
							{renderItem
								? (
									renderItem(item)
								)
								: (
									<>
										<Radio checked={item.value === selectedValue} />
										{item.label}
									</>
								)}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

export default SelectField;
