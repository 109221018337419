import { useEffect } from 'react';

import CompareContent from '../../../components/comparison/layout/CompareContent';
import CompareLayout from '../../../components/comparison/layout/CompareLayout';
import CompareLeftMenu from '../../../components/comparison/layout/CompareLeftMenu';
import CompareTypeMenu from '../../../components/comparison/layout/CompareTypeMenu';
import MenuTitle from '../../../components/comparison/layout/MenuTitle';
import ProductModalSelect from '../../../components/comparison/modal/ProductModalSelect';
import { useCompareProduct } from '../../../hooks/useCompareProduct';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { compareContentMap, compareTypeOptions } from '../../../utils/compareProduct';

import { useProductsToCompare } from './hooks';

type CompareProductProps = {
	onClose: () => void;
}

const CompareProduct = ({ onClose }: CompareProductProps ) => {
	const { products, compareType, setProducts, setProductToCompare } = useCompareProduct();
	const { t } = useTranslation();

	const ComponentContent = compareContentMap.get(compareType)?.element;

	const { data, isLoading } = useProductsToCompare();
	// --------------------------------------- //
	// --------- set compare to products ----- //
	// --------------------------------------- //
	useEffect(() => {
	if (!data?.length) return;

	setProductToCompare(data[0]);
	setProducts(data.map((p: any, index: number) => {
		return {
			...p,
			color: (siteColors.compareProductColors as any)[`color${index%5}`],
		};
	}));
	}, [data, setProducts, setProductToCompare]);

	return (
		<CompareLayout onClose={onClose}
			leftMenu={<CompareLeftMenu title={<MenuTitle title={t('Comparaison de produits')} subtile={t('product-selected-count', { count: products.length } )} componentModal={ProductModalSelect} />} isLoading={isLoading}/>}
		>
			<CompareContent compareTypeMenu={<CompareTypeMenu options={compareTypeOptions} />} >
				<ComponentContent />
			</CompareContent>
		</CompareLayout>
	);
};

export default CompareProduct;
