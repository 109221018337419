import React, { ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import { type PopperPlacementType } from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { ChevronDown } from 'lucide-react';

import BRadioGroup from '@carbonmaps/ui/components/saas/BRadioGroup';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import { useTranslation } from '../hooks/useTranslation';

import Popper from './Popper';

interface IValue {
	value: string;
	label: string;
	unity: string | React.ReactNode;
}
interface TradeOffHeaderProps {
	indicators: any[];
	setUseValue: (e: any) => void;
	disabledValue: IValue;
	useValue: IValue;
	popperPlacement?: PopperPlacementType;
	toggleImpact?: boolean;
	legend?: ReactNode;
	showAxisLabels?: boolean;
}

const styles = {
	triggerContainer: css({
		display: 'flex',
		flexDirection: 'row',
		gap: '4px',
	}),
	triggerTitle: css({
		display: 'inline-block',
		lineHeight: '1.2 !important',
	}),

	triggerSubtitle: css({
		lineHeight: '1.2 !important',
	}),
};

const TradeOffHeader = ({
	indicators,
	setUseValue,
	useValue,
	disabledValue,
	popperPlacement,
	toggleImpact = true,
	legend,
	showAxisLabels = true,
}: TradeOffHeaderProps) => {
	// const [isOpen, toggleOpen] = useToggle(false);
	const theme = useTheme();
	const { t } = useTranslation();

	const onChange = (value: any) => {
		const selected: any = indicators.find((indicator) => {
			return indicator.value == value; // ! WTF is this ?
		});
		setUseValue(selected);
	};

	return (
		<>
			<div className="flexRow alignCenter">
				{showAxisLabels ? (
					<Popper
						enableTrigger={toggleImpact ?? false}
						placement={popperPlacement}
						boxContentContainerProps={{
							sx: {
								padding: pxToRem(8),
							},
						}}
						trigger={
							<div className="flexRow alignCenter cursorPointer">
								{toggleImpact === true && <ChevronDown color={theme.palette.grey[700]} size={20} />}
								<div className={styles.triggerContainer}>
									<Typography variant="caption" className={cx(styles.triggerTitle, 'grey700')}>
										{t(useValue?.label)}
									</Typography>
									<Typography variant="caption" lineHeight={1.2} className={cx(styles.triggerSubtitle, 'grey700')}>
										({useValue?.unity})
									</Typography>
								</div>
							</div>
						}
						renderContent={({ close }) => {
							if (toggleImpact !== true) return null;
							return (
								<BRadioGroup
									disabledValue={disabledValue.value}
									options={indicators}
									value={useValue?.value}
									handleChange={onChange}
									onClickAway={() => {
										// setIsExpand(false);
									}}
								/>
							);
						}}
					/>
				) : null}
				{legend ? legend : null}
			</div>
		</>
	);
};

export default TradeOffHeader;
