import { useEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { useMutation } from '@tanstack/react-query';
import { AlertCircle } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import z from 'zod';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { resetPasswordUserRowAction } from '@carbonmaps/ui/actions/userRow';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import AlertStatic from '../../components/Toast/AlertStatic';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useLogOutMutation } from '../../lib/react-query/features/auth/auth.hooks';

type GetUserParams = {
	username: string;
	token: string;
};

type ResetPasswordInput = z.infer<typeof resetPasswordSchema>;

const resetPasswordSchema = z
	.object({
		password: z
			.string({ required_error: 'Veuillez entrer un nouveau mot de passe' })
			.min(12, 'Le mot de passe doit contenir au moins 12 caractères')
			.regex(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, 'Le mot de passe doit contenir au moins un caractère spécial.'),
		confirmPassword: z
			.string({ required_error: 'Vous devez confirmer votre mot de passe' })
			.min(1, 'Vous devez confirmer votre mot de passe'),
	})
	.refine(
		(data) => {
			return data.password === data.confirmPassword;
		},
		{
			// eslint-disable-next-line quotes
			message: "Le mot de passe saisi n'est pas identique",
			path: ['confirm'],
		},
	);

const ResetPasswordNewAccount = () => {
	const { t, i18n } = useTranslation();

	const {
		result: { mutate: logOut },
	} = useLogOutMutation({
		redirect: false,
	});

	const navigate = useNavigate();
	const theme = useTheme();

	const [searchParams] = useSearchParams();
	const username = searchParams.get('username');
	const token = searchParams.get('token');
	const [message, setMessage] = useState('');
	const [queryParams, setQueryParams] = useState<GetUserParams>({ username: '', token: '' });

	useEffect(() => {
		let lng = searchParams.get('lang');

		if (lng && lng !== 'fr') {
			lng = 'en';
		}

		i18n.changeLanguage(lng || 'fr');
	}, [i18n, searchParams]);

	useEffect(() => {
		logOut();

		if (username && token) {
			setQueryParams({ username, token });
		}
	}, [logOut, token, username]);

	const {
		mutate: resetPasswordUserRow,
		isLoading,
		isError,
		isSuccess,
	} = useMutation({
		mutationKey: ['resetPasswordUserRow'],
		mutationFn: resetPasswordUserRowAction,
		onError: (error: any) => {
			if (error.message) {
				if(error.message === 'error-reset-pwd-user-not-found'){
					setMessage(t('error-reset-pwd-user-not-found'));
				}

				else setMessage(t(error.message));
			}
		},
	});

	const resetPassword = async (values: any) => {
		const { username, token } = queryParams;

		if (username && token) {
			await resetPasswordUserRow({
				username,
				token,
				password: values.password,
				confirmPassword: values.confirmPassword,
				languageCode: getIsoCountryCode(i18n.language),
			});
		}
	};

	const onSubmit = async (values: any) => {
		resetPassword(values);
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ResetPasswordInput>({
		resolver: zodResolver(resetPasswordSchema),
	});
	return (
		<>
			{queryParams.token && queryParams.username && !isSuccess && (
				<Box px={0} py={2} maxWidth={'386px'}>
					<Typography
						variant="inherit"
						color={siteColors.grey900}
						css={{
							fontSize: 24,
							marginBottom: 20,
						}}
					>
						{t('Créer votre mot de passe')}
					</Typography>

					{errors.password && errors?.password?.message && (
						<AlertStatic
							text={t(errors.password ? errors?.password?.message : '')}
							type="error"
							icon={<AlertCircle css={{ color: '#C74600' }} />}
						/>
					)}
					{errors.confirmPassword && errors?.confirmPassword?.message && (
						<AlertStatic
							text={t(errors.confirmPassword ? errors?.confirmPassword?.message : '')}
							type="error"
							icon={<AlertCircle css={{ color: '#C74600' }} />}
						/>
					)}

					{isError && <AlertStatic text={message} type="error" icon={<AlertCircle css={{ color: '#C74600' }} />} />}

					<Box mb="32px">
						<Typography mb={1.5}>{t('Bienvenue sur Carbon Maps !')}</Typography>
						<Typography mb={1.5}>{t('alert-create-password-before-login')}.</Typography>
					</Box>

					<div css={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
						<BInput
							required
							id="password"
							label={t('Mot de passe')}
							type="password"
							fullWidth
							margin="dense"
							{...register('password')}
							name="password"
							error={errors.password ? true : false}
						/>
						<BInput
							required
							id="confirmPassword"
							label={t('Répéter le mot de passe')}
							type="password"
							fullWidth
							margin="dense"
							{...register('confirmPassword')}
							name="confirmPassword"
							error={errors.confirmPassword ? true : false}
						/>
					</div>

					<Box mt={3} css={{ marginBottom: 48 }}>
						<BButton
							variant="primary"
							label={
								isLoading ? <CircularProgress size={20} css={{ color: theme.palette.common.white }} /> : 'Confirmer'
							}
							onClick={handleSubmit(onSubmit)}
						/>
					</Box>
				</Box>
			)}

			{isSuccess && (
				<Box mb="32px">
					<Typography
						variant="inherit"
						color={siteColors.green500}
						css={{
							fontWeight: 600,
							fontSize: 24,
							marginBottom: 20,
						}}
					>
						{t('Créer votre mot de passe')}
					</Typography>
					<Box mb="32px">
						<AlertStatic text={t('Mot de passe créé')} type="success" icon={<CheckIcon css={{ color: '#95ab76' }} />} />
					</Box>
					<Box mb="32px">
						<Typography mb={1.5}>{t('Vous pouvez désormais vous connecter à votre compte Carbon Maps')}.</Typography>
					</Box>

					<BButton
						variant="primary"
						label={t('Se connecter')}
						onClick={() => {
							navigate(FRONT_PATH_NAMES.logIn);
						}}
					/>
				</Box>
			)}
		</>
	);
};

export default ResetPasswordNewAccount;
