import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { nanoid } from 'nanoid';

import AB from '@carbonmaps/media/icons/label-ingredients/AB.svg';
import bleublancoeur from '@carbonmaps/media/icons/label-ingredients/bleublancoeur.svg';
import labelrouge from '@carbonmaps/media/icons/label-ingredients/label-rouge.svg';
import rainforestalliance from '@carbonmaps/media/icons/label-ingredients/rainforestalliance.svg';
import RSPO from '@carbonmaps/media/icons/label-ingredients/RSPO.svg';

import { useTranslation } from '../hooks/useTranslation';

import LightTooltip from './LightTooltip';

const config = new Map([
	['bio', { icon: AB, translationKey: 'Bio' }],
	['bleublancoeur', { icon: bleublancoeur, translationKey: 'Bleu Blanc Coeur' }],
	['labelrouge', { icon: labelrouge, translationKey: 'Label Rouge' }],
	['rainforestalliance', { icon: rainforestalliance, translationKey: 'RainForest Alliance' }],
	['rspo', { icon: RSPO, translationKey: 'RSPO' }],
]);

type Props = {
	certifications?: string[];
};

const styles = {
	wrapper: css({
		gap: '6px',
	}),
	icon: css({
		height: '20px',
	}),
};

const requiredOrder = ['bio', 'labelrouge', 'bleublancoeur', 'rspo', 'rainforestalliance'];

const LabelsIngredients = ({ certifications }: Props) => {
	const { t } = useTranslation();

	const sortedCertifications = _.sortBy(
		_.map(certifications, (i) => {
			return _.toLower(i);
		}),
		(cert) => {
			return requiredOrder.indexOf(cert);
		},
	);

	const icons = _.map(
		_.filter(sortedCertifications, (i) => {
			return config.has(i);
		}),
		(cert) => {
			return (
				<LightTooltip title={t(config.get(cert)?.translationKey)} key={nanoid()} placement="top">
					<img src={config.get(cert)?.icon} alt={cert} className={cx(styles.icon)} />
				</LightTooltip>
		);
		},
	);
	return <div className={cx('flexRow', styles.wrapper)} >{icons}</div>;
};

export default LabelsIngredients;
