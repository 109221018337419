import * as React from 'react';

import { css, cx } from '@emotion/css';
import { type Theme } from '@emotion/react';
import { type Interpolation } from '@emotion/serialize';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
interface BPopoverProps {
	children: React.ReactNode;
	content: (onClose: () => void) => React.ReactNode;
	anchorOriginVertical?: number | 'center' | 'bottom' | 'top';
	anchorOriginHorizontal?: number | 'center' | 'left' | 'right';
	transformOriginVertical?: number | 'center' | 'bottom' | 'top';
	transformOriginHorizontal?: number | 'center' | 'left' | 'right';
	addStyles?: Interpolation<Theme>;
	modeHover?: boolean;
	className?: string;
	style?: any;
	containerClass?: string;
}

const styles = {
	button: css({ cursor: 'pointer', '&:hover': { background: 'unset !important' } }),
};

/**
 * Use the new Popover in front instead
 */
const BPopover = ({
	children,
	content,
	anchorOriginVertical,
	anchorOriginHorizontal,
	transformOriginVertical,
	transformOriginHorizontal,
	addStyles,
	modeHover,
	className,
	containerClass,
	...otherProps
}: BPopoverProps) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		return;
	};

	const handleClose = () => {
		setAnchorEl(null);
		return;
	};

	return (
		<div className={cx('relative', containerClass)} {...otherProps}>
			<Button
				className={cx(styles.button, className)}
				css={addStyles}
				variant="text"
				{...(!modeHover
					? {
							onClick: handleOpen,
					  }
					: {
							onMouseEnter: handleOpen,
							onMouseLeave: handleClose,
					  })}
				disableRipple
			>
				{children}
			</Button>
			{anchorEl && (
				<>
					<Popover
						disableScrollLock /* ={false} */
						open={true}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: anchorOriginVertical ? anchorOriginVertical : 'top',
							horizontal: anchorOriginHorizontal ? anchorOriginHorizontal : 'left',
						}}
						transformOrigin={{
							vertical: transformOriginVertical ? transformOriginVertical : 'bottom',
							horizontal: transformOriginHorizontal ? transformOriginHorizontal : 'left',
						}}
						disableRestoreFocus={!!modeHover}
						className={cx('fixed', modeHover ? 'pointerEventsNone' : 'pointerEventsAuto')}
					>
						{content(handleClose)}
					</Popover>
				</>
			)}
		</div>
	);
};

export default BPopover;
