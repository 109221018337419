import { useRef } from 'react';

import { css, cx } from '@emotion/css';
import { AppBar } from '@mui/material';
import { X } from 'lucide-react';

import { ALL_OPTION_VALUE, ALL_YEAR_OPTION_VALUE } from '@carbonmaps/shared/utils/constants';

import { product, useCompareProduct } from '../../../hooks/useCompareProduct';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import ShareButton from '../../layout/ShareButton';
import SwitchButton from '../../layout/SwitchButton';
import Typography from '../../Typography';
import Button from '../../v3/Button';


const Z_INDEX = 1150 ;
const styles = {
	container: css({
		height: 64,
		padding: '0px 32px',
	}),
	selectedYearButton: css({
		borderRadius: 8,
		fontSize: '16px !important',
		padding: '8px 16px',
		background: 'transparent',
		fontWeight: 500,
		color: `${siteColors.blackButton} !important`,
		fontVariantNumeric: 'lining-nums tabular-nums',
	}),
	rightContainer: css({
		alignItems: 'stretch !important',
		gap: '8px',
	}),
	appBar:css({
    zIndex: `${Z_INDEX + 1} !important`,
		background:`${siteColors.backgroundDrawer} !important`,
		boxShadow: 'none !important',
  }),
	btnClose: css({
		background: 'transparent!important',
		paddingLeft: 'unset!important',
	}),
};

type CompareProductHeaderProps = {
	onClick: () => void;
}

const CompareHeader = ({ onClick }: CompareProductHeaderProps) => {
	const topBarRef = useRef<any>(null);

	const { selectedYear } = useYearSelection();

	const { t } = useTranslation();

	const { indicator, setIndicator } = useCompareProduct();

	const { products } = useCompareProduct();

		return (
			<AppBar position="fixed" className={styles.appBar}>
				<div className={cx('flexRow width100 spaceBetween alignCenter nowrap gap16', styles.container)}>
					<div>
						<Button  label={t('close')} handleClick={onClick} iconLeft={<X  color={siteColors.grey700}/>} disableRipple className={cx('', styles.btnClose)} />
					</div>
					<div className={cx('flexRow center alignCenter', styles.rightContainer)}>

						<Typography className={cx('flexCenter', styles.selectedYearButton)}>
							{selectedYear !== ALL_YEAR_OPTION_VALUE ? selectedYear : t('toutes les années')}
						</Typography>
						<SwitchButton
							value={indicator as any}
							onChange={(value) => {
								return setIndicator(value);
							}}

						/>
						<ShareButton anchorEl={topBarRef.current} products={products.map((p: product) => {return p.objectId;})} />
					</div>
				</div>
				</AppBar>
		);

};

export default CompareHeader;
