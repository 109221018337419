import { useCallback, useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { Box, Building2, ChefHat, LineChart, MoreHorizontal, PackageOpen, PieChart } from 'lucide-react';

import SupplierIcon from '@carbonmaps/media/icons/supplier-small.svg?react';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';

const styles = {
	title: css({
		fontSize: '24px',
		fontWeight: 600,
		marginBottom: '8px',
	}),
	description: css({
		marginBottom: '16px',
	}),
};

const Promote = () => {
	const { setBreadcrumbs } = useApp();
	const { t, i18n } = useTranslation();

	const languageCode = getIsoCountryCode(i18n.language);

	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(name: string) => {
			if (!analytics) return;
			analytics.track(name, {
				context: { category: 'Contributor' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		trackEvent('Unlock all features Land');
	}, [trackEvent]);

	const bgImage = {
		en: '/promotion-banner-en.svg',
		fr: '/promotion-banner-fr.svg',
	};

	useEffect(() => {
		setBreadcrumbs([{ text: t('other-features') }]);
	}, [setBreadcrumbs, t]);

	const otherFeatures = [
		{
			icon: <Box color={siteColors.grey700} />,
			name: t('features.lca'),
		},
		{
			icon: <Building2 color={siteColors.grey700} />,
			name: t('features.carbon-footprint'),
		},
		{
			icon: <PackageOpen color={siteColors.grey700} />,
			name: t('features.packaging'),
		},
		{
			icon: <SupplierIcon width="25" height="25" css={{ '& path': { fill: siteColors.grey700 } }} />,
			name: t('features.suppliers'),
		},
		{
			icon: <LineChart color={siteColors.grey700} />,
			name: t('features.scenarios'),
		},
		{
			icon: <ChefHat color={siteColors.grey700} />,
			name: t('features.eco-design'),
		},
		{
			icon: <PieChart color={siteColors.grey700} />,
			name: t('features.sbti'),
		},
		{
			icon: <MoreHorizontal color={siteColors.grey700} />,
			name: (
				<a
					target="_blank"
					href="https://carbonmaps.io"
					rel="noreferrer"
					className="grey900"
					onClick={() => {
						trackEvent('See all features click');
					}}
				>
					{t('voir-tout')}
				</a>
			),
		},
	];

	return (
		<div>
			<div
				css={{
					height: '390px',
					backgroundSize: 'contain',
					background: `url(${(bgImage as never)[languageCode] || bgImage.fr}) no-repeat top center`,
					backgroundColor: siteColors.grey300,
				}}
			></div>

			<div className="flexCenter">
				<div className="grey900 textCenter" css={{ paddingTop: '42px', maxWidth: '782px' }}>
					<div className={cx('', styles.title)}>{t('promote-title')}</div>
					<div className={cx('', styles.description)}>
						<div>{t('promote-description')}</div>
						<div>{t('promote-description2')}</div>
					</div>
				</div>
				<div className="width100" css={{ maxWidth: '782px' }}>
					<iframe
						title="meetings-iframe-container"
						className={cx('width100')}
						css={{ height: '700px' }}
						src="https://meetings.hubspot.com/matteo-greco/carbon-maps-intro-team?embed=true"
						frameBorder="0"
					></iframe>
				</div>
				<div className={cx('flexRow alignCenter justifyCenter')}>
					<div className={cx('flexRow alignCenter justifyCenter')} css={{ gap: '14px', paddingBottom: '60px' }}>
						{otherFeatures.map((feature, index) => {
							return (
								<div key={index}>
									<div
										className={cx('flexCenter')}
										css={{ width: '84px', height: '48px', background: siteColors.grey200, marginBottom: '14px' }}
									>
										{feature.icon}
									</div>
									<div className={cx('flexCenter')}>{feature.name}</div>
								</div>
							);
						})}
					</div>
				</div>
				<div css={{ paddingBottom: '64px' }}>
					<img src="/carbonmaps-logo-pdf.png" alt="logo" css={{ width: '182px', height: '37px' }} />
				</div>
			</div>
		</div>
	);
};

export default Promote;
