import { useCallback, useEffect, useMemo } from 'react';

import { css } from '@emotion/css';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Building2, ChevronDown, Globe, Users } from 'lucide-react';

import { ALL_OPTION_VALUE, roleLabels } from '@carbonmaps/shared/utils/constants';
import { getTeamOptionsAction, getTeamsAction, saveTeamUserAction } from '@carbonmaps/ui/actions/team.actions';

import useHasRoles from '../../hooks/useHasRoles';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTeam } from '../../hooks/useTeam';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import SelectField from '../form/fields/SelectField';
import Typography from '../Typography';

const styles = {
	container: css({
		background: 'transparent !important',
		border: 'none !important',
		minHeight: '42px',

		'&: .MuiInputBase-root': {
			width: '150px',
			minHeight: '42px',
			background: 'transparent !important',

		},
		'&:hover': {
			backgroundColor: '#E2DDEB!important',
		},
	}),
	text: css({
		fontSize: '16px !important',
		fontWeight: '500 !important',
		paddingRight: 16,
	}),

	paper: css({
		borderRadius: 12,
		marginTop: 8,
		minWidth: '212px !important',
		'& .MuiButtonBase-root': {
			'&:hover': {
				backgroundColor: `${siteColors.grey300} !important`,
			},
		},
	}),
	list: css({
		paddingTop: 0,
		paddingBottom: 0,
		'& li': {
			padding: '8px 16px',
			alignItems: 'center',
			gap: '8px',
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: '400',
			color: siteColors.text,
			'& .MuiRadio-root': {
				padding: 4,
			},
		},
	}),
};



const TeamSelect = () => {

	// translation
	const { t } = useTranslation();
	const { team, setTeam } = useTeam();

	// get user data
	const {
		result: { data: dataUser },
	} = useGetClientAuthQuery();

	const hasRoles = useHasRoles();
	const isAdmin = hasRoles([roleLabels.ADMIN]);


	// react query mutation to save team
		const { mutate: saveTeamUser } = useMutation({
			mutationKey: ['saveTeamUser'], // This mutation is used to save a team by sending a message
			mutationFn: saveTeamUserAction,
			onSuccess: async () => {
				// Marks the operation as successful upon completion

			},
			onError: (error: any) => {
				/* Handle error here */
			},
	});

 // -------------------------------- //
 // --------- team options --------- //
 // -------------------------------- //
	const options = useMemo(() => {

		if (!dataUser?.teams) return [];

		const dataOptions: Array<{
			label: string;
			value: string | number;
			icon: React.ReactNode;
			objectId: string;
		}> = [];


		dataUser?.teams?.forEach((item: {name: string, objectId: string}) => {
			dataOptions.push({
				label: t(item.name),
				value: item.name === ALL_OPTION_VALUE ? ALL_OPTION_VALUE : item.objectId,
				objectId: item.objectId,
				icon: <Users  size={20} color={siteColors.grey700}/>,
			});
		});

		console.log('dataUser?.teams', dataUser?.teams);

		// filters teams by user teams options
		const userOptionsTeams =  dataUser?.user?.teamsOptions;

		if (userOptionsTeams) {
			const dataFilters = dataOptions.filter((item) => {return userOptionsTeams.map((team: any) => {return team.objectId;}).includes(item.objectId);});

			// check if user options is in teams values
			if (dataFilters.length) {
				return dataFilters;
			}
		}

		// don't show all option if user is not admin
		if (!isAdmin) {
			return dataOptions.filter((item) => {return item.value === ALL_OPTION_VALUE;});
		}

		return dataOptions;
	}, [dataUser, isAdmin,  t]);

	useEffect(() => {
		if (!dataUser || !options?.length) return;
		// find current team user in all teams ( because user can have multiple teams for different companies)
		const { user } = dataUser;
		const teamUser = (user?.teams || []).find((item: any) => {return item.company.objectId === dataUser?.session?.company?.objectId;});
		// check if current user team is in the list of options
		const isExist = options.some((item) => {return item.value === teamUser?.team;});
		// set current team
		setTeam(isExist ? teamUser?.team : options[0]?.value);

	}, [options, dataUser, setTeam]);

	// init event June
	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(evenName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(evenName, options, {
				context: { category: 'Manager User' },
			});
		},
		[analytics],
	);

	// handle change team
	const handleTeamChange = useCallback((team: string | number) => {
		setTeam(team);
		// save team in user
		saveTeamUser({ team });
		// track event
		trackEvent('Switched to another team', { team: options.find((item) => {return item.value === team;})?.label });
	}, [setTeam, saveTeamUser, options, trackEvent]);


	return (
		<SelectField
				id={'select-team'}
				value={team}
				items={options}
				renderValue={(item: any) => {
					return (
						<div className="flexRow nowrap alignCenter gap4">
							{item?.icon}
							<Typography className="">{item?.label}</Typography>
						</div>
					);
				}}
				onChange={handleTeamChange}

				renderItem={(item: any) => {
					return(
						<div className="flexRow nowrap alignCenter gap8">
							{item?.icon}
							<Typography className="">{item?.label}</Typography>
						</div>
					);
				}}
				className={styles.container}
				paddingRight={35}
				ExpandMoreRoundedIcon={ChevronDown}
				iconColor={siteColors.grey700}
				MenuProps={{
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					classes: {
						paper: styles.paper,
						list: styles.list,
					},
				}}
			/>
	);

};

export default TeamSelect;
