import { css, cx } from '@emotion/css';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ReactDOMServer from 'react-dom/server';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { siteColors } from '../../lib/colors';


const styles = {
	tooltipContainer: css({
		padding: 16,
		minWidth: 300,
		gap: 8,
		zIndex:10000000,
	}),
	tooltipValue: css({
		fontSize: '14px !important',
		fontWeight: 400,
	}),
	label: css({
		maxWidth: '125px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		lineHeight: '1.2 !important',
		fontWeight: 400,
		fontSize: 14,
	}),
};

export interface SeriesProps {
  name: string;
  type?: 'line'; // Optional, inherits from chart.type
  data: number[] | Highcharts.PointOptionsObject[]; // Can be numbers or objects
  color?: string; // Optional color for the series
	marker?: any
}

type ImpactTonnageGraphProps = {
	series: Array<SeriesProps>;
	xAxis: Array<number>;
	height?: number;
	gap?: number;
	isFilter?: boolean;
	graphWidth?: string,
	unit?: React.ReactNode;


};

const TrajectoryGraph = ({ graphWidth,  xAxis, series,  unit }: ImpactTonnageGraphProps) => {
		const options = {
			chart: {
				type: 'line',
			},
			title: {
				text: '',
			},
			xAxis: {
				categories: xAxis,
				labels: {
					style: {
					},
				},
				lineColor: siteColors.grey500,
			},
			plotOptions: {
				series: {
					states: {
						inactive: {
							opacity: 0.2,
						},
						hover: {
							lineWidth: 4,
						},
					},
					events: {
						mouseOver: function(this: any) {
							const chart = this.chart;
							chart.series.forEach((s: any) => {
								if (s !== this) {
									s.setState('inactive');
								}
							});
						},
						mouseOut: function(this: any) {
							const chart = this.chart;
							chart.series.forEach((s: any) => {
								s.setState('');
							});
						},
					},
				},
			},
			yAxis: {
				title: {
					text: '',
				},
			},
			series: series,

			tooltip: {
				useHTML: true,
				formatter: function (this: any) {
					return ReactDOMServer.renderToString(
						<div className={cx('flexColumn gap12', styles.tooltipContainer)}>
							<div className="flexColumn">
								<span style={{ fontSize: '16px', fontWeight: 600, color: siteColors.grey900 }}>
									{this.series.name}
								</span>
							</div>
							<div className="flexColumn gap4 width100">
								{xAxis.map((year, index) => {
									const value = this.series.data[index].y;
									return (
										<div key={year} className="flexRow alignCenter spaceBetween width100 gap16 nowrap">
											<div className="flexRow alignCenter gap4">
												<span className={styles.label}>{year}</span>
											</div>
											<div className={cx(styles.tooltipValue, 'grey7')}>
												{value === 0 ? '-' : formatNumber(value, undefined, 0)} {unit}
											</div>
										</div>
									);
								})}
							</div>
						</div>,
					);
				},
				shared: false,
			},
			credits: {
				enabled: false,
			},
			responsive: {
				rules: [{
					condition: {
						maxWidth: 200,
					},
					chartOptions: {
						legend: {
							align: 'center',
							verticalAlign: 'bottom',
							layout: 'horizontal',
						},
					},
				}],
			},
			legend: {
				align: 'right',
				verticalAlign: 'top',
				layout: 'vertical',
				x: 0,
				y: 0,
				floating: true,
				enabled: false, // Désactive la légende

			},
		};

 return (
		<>

				<HighchartsReact
					highcharts={Highcharts}
					options={options}
					containerProps={{
						style: {
							width: graphWidth ?? '100%',
							height:'450px',
						},
					}}
				/>

		</>
 );
};

export default TrajectoryGraph;
