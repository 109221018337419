import { useEffect, useMemo } from 'react';

import { css, cx } from '@emotion/css';
import MUILink from '@mui/material/Link';
import { Lock, Plus, Presentation } from 'lucide-react';
import { Link } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { MOD_SBTI_FLAG } from '@carbonmaps/shared/utils/module';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { isLockedModule } from '@carbonmaps/ui/utils/utils';

import Popover from '../../components/Popover';
import IconContainer from '../../components/layout/list/IconContainer';
import ListHeader from '../../components/layout/list/ListHeader';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

import RapportCard from './RapportCard';

const CONTENT_WIDTH = 'calc(100% - 4rem)';
const CONTENT_MAX_WIDTH = '1200px';

const styles = {
	triggerContainer: css({
		marginLeft: 10,
		marginRight: 10,
		marginTop: 10,
		marginBottom: 4,
		cursor: 'pointer',
		borderRadius: 6,
		padding: '8px',
		'&:hover': {
			background: siteColors.grey300,
		},
	}),
	mb3: css({
		marginBottom: '2.5rem!important',
		maxWidth: CONTENT_MAX_WIDTH,
	}),
	badge: css({
		display: 'flex',
		padding: '6px 8px',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '8px',
		borderRadius: '4px',
		background: siteColors.grey200,
		color: siteColors.grey700,
		textAlign: 'center',
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '140%',
	}),
	cardPlus: css({
		display: 'flex',
		padding: '24px',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '16px',
		// flex: '1 0 0',
		alignSelf: 'stretch',
		borderRadius: '16px',
		border: `1px dashed ${siteColors.grey500}`,
		background: '#FFF',
		width: '346px',
		height: '190px',
		'&:hover': {
			outline: '2px solid #00000015',
		},
	}),
};

const Rapports = () => {
	const { setBreadcrumbs } = useApp();
	const { t } = useTranslation();

	useEffect(() => {
		setBreadcrumbs([{ text: t('Rapports') }]);
	}, [setBreadcrumbs]);

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const plugins = useMemo(() => {
		return authData?.session.company?.plugins || [];
	}, [authData?.session.company]);

	const isLockedSBTi = isLockedModule(plugins, MOD_SBTI_FLAG);

	const renderSBTiCard = (
		<div>
			<RapportCard
				t1={
					<div className="flexRow">
						SBTi Flag{' '}
						{isLockedSBTi
						? (
							<Lock size={16} css={{ position: 'relative', top: 2, left: 4 }} />
						) :
						null}
					</div>
				}
				t2={<>{t('Pilotez vos objectifs climatiques liés aux terres, forêts et agriculture')}</>}
				to={isLockedSBTi ? '#' : FRONT_PATH_NAMES.dashboardSbtiFlag}
				imgUrl="/img-report-3.png"
			/>
		</div>
	);

	return (
		<div>
			<ListHeader
				title={t('Rapports')}
				subtitle={t('rapportsSubtitle')}
				icon={
					<IconContainer element={<Presentation color={siteColors.white} size={32} />} color={siteColors.green500} />
				}
				className={styles.mb3}
			/>

			<div
				style={{
					width: CONTENT_WIDTH,
					margin: 'auto',
					maxWidth: CONTENT_MAX_WIDTH,
				}}
				css={{
					display: 'flex',
					flexWrap: 'wrap',
					gap: 32,
				}}
			>
				{isLockedSBTi
? (
					<Popover
						anchorOrigin={{
							horizontal: 'center',
							vertical: 'center',
						}}
						transformOrigin={{
							horizontal: 'center',
							vertical: 'center',
						}}
						trigger={renderSBTiCard}
						renderContent={() => {
							return (
								<MUILink
									component={Link}
									to="mailto:support@carbonmaps.io"
									display="block"
									className={styles.triggerContainer}
								>
									{t('active-module-text')}
								</MUILink>
							);
						}}
					/>
				)
: (
					renderSBTiCard
				)}
				<RapportCard
					t1={t('audit-breadcrumbs')}
					t2={t('Précisez votre impact grâce aux alertes de votre jeu de données')}
					to={FRONT_PATH_NAMES.dataDiagnostics}
					imgUrl="/img-report-1.png"
				/>
				<RapportCard
					t1={
						<div className="flex alignCenter" css={{ gap: 8, color: siteColors.grey600 }}>
							{t('features.carbon-footprint')} <Lock size={16} /> <div className={styles.badge}>{t('Bientôt disponible')}</div>
						</div>
					}
					t2={t('Explorez votre bilan carbone pour prioriser vos actions de réduction')}
					to=""
					imgUrl="/img-report-2.png"
				/>
				<div className={cx(styles.cardPlus, 'flexCenter')}>
					<Plus size={20} />
					<div>{t('Rapport personnalisé')}</div>
					<div className={styles.badge}>{t('Bientôt disponible')}</div>
				</div>
			</div>
		</div>
	);
};

export default Rapports;
