
import { useCallback, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { MoreVertical, Plus, Trash, X } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';
import BDropdown from '@carbonmaps/ui/components/saas/BDropdown';

import { useCompareProduct } from '../../../hooks/useCompareProduct';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import Typography from '../../Typography';
import Button from '../../v3/Button';

const styles = {
	button: css({
		marginTop: '12px!important',
		minWidth: '0px !important',
		padding: '0px!important',
	}),
	dropDowButton: css({
		borderRadius: '8px',
		background: 'none',
		color: siteColors.text,
		transform: 'rotate(90deg)',
		minWidth: '0px !important',
		height: '0px !important',
		padding: '0px !important',
	}),
	dropDownItem: css({
		padding: '8px 16px 8px 16px',
	}),
	dropItemButton: css({
		gap: 8,
		fontSize: '14px',
		fontWeight: 400,
		cursor: 'pointer',
		padding: '8px 16px 8px 16px',
		background: 'transparent!important',
		color: siteColors.text,
		paddingInline: 0,
	}),
};

type MenuTitleProps = {
	title: string;
	subtile: string;
	componentModal: React.ElementType;
}

const MenuTitle = ({ title, subtile, componentModal }: MenuTitleProps) => {

	const Component = componentModal || 'div';

	const { t } = useTranslation();
	const theme = useTheme();

	const [openDialog, setOpenDialog] = useState(false);

	// the compared products
	const { addProduct, removeProduct, removeAll } = useCompareProduct();
	const [searchQueryParams, setSearchParams] = useSearchQueryParams();

	// handle add product click
	const handleAddProduct = useCallback(() => {
		setOpenDialog(true);
	}, []);

	const handleRemoveAll = useCallback(() => {
		setSearchParams({ productToCompare: '' });
		// remove in store
		removeAll();
	}, [removeAll, setSearchParams]);

	const onAddItem = useCallback((product: any) => {
		const prevProducts = searchQueryParams.productToCompare.split(',');
		prevProducts.push(product.objectId);
		setSearchParams({ productToCompare: prevProducts.join(',') });

		addProduct(product);
	}, [addProduct, searchQueryParams, setSearchParams]);

	return (
		<div className={cx('width100')}  >
			<div className="flexRow spaceBetween width100">
				<div className="flexColumn">
					<Typography variant="h3" className='grey9'>{title}</Typography>
					<Typography variant="caption" className='grey7'>{subtile}</Typography>
				</div>
				<BDropdown
					menus={[
						{
							title: t('Tout retirer'),
							value: '',
							icon: <X size={20} color={siteColors.grey700} />,
							customComponent: 'Filtrer',
						},
					]}
					renderItem={(menu) => {
						return (
							<BButton
								onClick={() => {return handleRemoveAll();}}
								iconLeft={menu.icon}
								label={menu.title}
								variant="tertiary"
								className={styles.dropItemButton}
							/>
						);
					}}
				>
				<BButton label={<MoreVertical />} variant="tertiary" className={styles.dropDowButton} />
			</BDropdown>
			</div>
			<Button
				label={t('Ajouter à la comparaison')}
				iconLeft={<Plus size={20} color={siteColors.grey7} />}
				className={styles.button}
				handleClick={handleAddProduct}
				disableRipple
			/>
			<Component
				open={openDialog}
				title={'test'}
				onClose={() => {return setOpenDialog(false);}}
				onAddProduct={(product: any) => {onAddItem(product);}}
				onRemoveProduct={(product: any) => {removeProduct(product);}}
			/>
		</div>
	);

};

export default MenuTitle;
