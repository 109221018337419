import { z } from 'zod';

export const teamFormValidationSchema = z.object({
	name: z
		.string({ required_error: 'champ obligatoire' })
		.min(1, 'champ obligatoire'),
	description: z.string().optional(),
});

export type TeamFormInput = z.infer<typeof teamFormValidationSchema>;
