import { useQuery } from '@tanstack/react-query';

import { getTeamOptionsAction } from '@carbonmaps/ui/actions/team.actions';

export const useTeamsOptions = ({ companyId }: {companyId: string}) => {
// fetch All options
	const result = useQuery({
		queryKey: [
			'getTeamOptions',
		{ companyId },
		],
		queryFn: getTeamOptionsAction,
	});
	return result;
};
