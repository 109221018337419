import { type QueryFunctionContext } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';
import { parseUrl } from '@carbonmaps/shared/utils/utils';

import { getFacetToShow } from '../../../apps/front/src/utils/utils';


export async function getAnalyseData(params: any) {
	const { size = 100, page, input = '', facetFilters, sortingBy, category, viewMode, ...rest } = params;

	try {
		const results = await Parse.Cloud.run('getAnalyseData', {
			category,
			viewMode,
			limit: size,
			skip: (page - 1) * size,
			pageIndex: page,
			input,
			facetFilters,
			sortingBy,
			...rest,
		});

		return {
			data: results?.results?.data,
			meta: {
				last_page: results?.segment ? Math.ceil(results?.segment / size) : 1,
				count: results?.results?.categories?.length || 0,
				carboneImpactTotal: results?.globalIndicator?.ges,
				sumWaterTotal: results?.globalIndicator?.water,
				countSegment: results?.results?.metadata?.length ? results?.results?.metadata[0].categories.length : 0,
				countPage: results?.results?.metadata?.length
					? Math.ceil(results?.results?.metadata[0].categories.length / size)
					: 1,
			},
			totalSegment: results?.segment,
		};
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getTradeOffAnalyseData(params: any) {
	const { /* size = 100,  page,*/ input = '', facetFilters, /* sortingBy, */ category /* ...rest  */ } = params;

	try {
		const { data } = await Parse.Cloud.run('getAnalyseData', {
			category,
			input,
			facetFilters,
			isGraph: true,
			unity: 1, //for Tonnes, 1000 for kg
		});

		return data;
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getFacetsSegment(params: any) {
	try {
		const { size = 100, page, input = '', categoryValue, facetFilters, sortingBy, category, ...rest } = params;

		const data = await Parse.Cloud.run('getFacets', { ...params, categoryValue: parseUrl(categoryValue) });

		// For a facet with a value already selected, I may be able to select another value
		// and see the products (OR ingredient) of one OR the other of the facet values appear
		if (facetFilters.length && data) {
			const allData = await Parse.Cloud.run('getFacets', {
				...params,
				facetFilters: [],
				categoryValue: parseUrl(categoryValue),
			});

			return getFacetToShow(facetFilters, data, allData);
		}

		return data;
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getDataSegment(params: any) {
	try {
		const result = await Parse.Cloud.run('getDataSegment', {
			...params,
		});

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getOneSegmentTemporality(params: any) {
	try {
		const result = await Parse.Cloud.run(functionName.temporality.findOneSegment, {
			...params,
		});

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
}

export type UseGetLifeCycleDataProps = {
	gesAgriculture: number;
	carbonImpact: number;
	waterImpact: number;
};

export const getLifeCycleDataAction = async (
	context: QueryFunctionContext<
		readonly [
			'getLifeCycleAnalyze',
			{ category: string; value: string; period?: string | number; viewMode?: string; facetFilters?: any },
		]
	>,
): Promise<UseGetLifeCycleDataProps> => {
	try {
		const {
			queryKey: [, { category, value, viewMode, period, facetFilters }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.analyze.getAnalyzeLifeChart, {
			category,
			value: parseUrl(value),
			period,
			viewMode,
			facetFilters,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export const getBreakDownCategoriesDataAction = async (
	context: QueryFunctionContext<
		readonly [
			'getBreakDownCategoriesData',
			{ category: string; value: string; period?: string | number; viewMode?: string; facetFilters?: any },
		]
	>,
): Promise<UseGetLifeCycleDataProps> => {
	try {
		const {
			queryKey: [, { category, value, viewMode, period, facetFilters }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.analyze.getBreakDownCategoriesData, {
			category,
			value: parseUrl(value),
			period,
			viewMode,
			facetFilters,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export const getGlobalIndicatorCategoryAction = async (
	context: QueryFunctionContext<
		readonly [
			'getGlobalIndicatorCategoryAnalyze',
			{ category: string; value: string; period?: string | number; viewMode?: string; facetFilters?: any, team?: string },
		]
	>,
): Promise<UseGetLifeCycleDataProps> => {
	try {
		const {
			queryKey: [, { category, value, viewMode, period, facetFilters, team }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.analyze.getGlobalIndicatorCategory, {
			category,
			value: parseUrl(value),
			period,
			viewMode,
			facetFilters,
			team,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export type productsDataType = {
	products: Array<any>;
	ingredients: Array<any>;
	suppliers: Array<any>;
	count: number;
};

export const getDataProductAnalyzeAction = async (
	context: QueryFunctionContext<
		readonly [
			'getDataProductAnalyze',
			{ category: string; value: string; period?: string | number; viewMode?: string; facetFilters?: any },
		]
	>,
): Promise<productsDataType> => {
	try {
		const {
			queryKey: [, { category, value, viewMode, period, facetFilters }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.analyze.getProductsByCategory, {
			category,
			value,
			period,
			viewMode,
			facetFilters,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export const getDataBreakDownProductAction = async (
	context: QueryFunctionContext<
		readonly [
			'getDataBreakDownProduct',
			{ category: string; value: string; period?: string | number; viewMode?: string; facetFilters?: any, team?: string },
		]
	>,
): Promise<productsDataType> => {
	try {
		const {
			queryKey: [, { category, value, viewMode, period, facetFilters, team }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.analyze.getProductDonut, {
			category,
			value: parseUrl(value),
			period,
			viewMode,
			facetFilters,
			team,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export const getIngredientDonutAction = async (
	context: QueryFunctionContext<
		readonly [
			'getIngredientDonut',
			{ category: string; value: string; period?: string | number; viewMode?: string; facetFilters?: any },
		]
	>,
): Promise<productsDataType> => {
	try {
		const {
			queryKey: [, { category, value, viewMode, period, facetFilters }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.analyze.getIngredientDonut, {
			category,
			value: parseUrl(value),
			period,
			viewMode,
			facetFilters,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export const getSupplierDonutAction = async (
	context: QueryFunctionContext<
		readonly [
			'getSupplierDonut',
			{ category: string; value: string; period?: string | number; viewMode?: string; facetFilters?: any },
		]
	>,
): Promise<productsDataType> => {
	try {
		const {
			queryKey: [, { category, value, viewMode, period, facetFilters }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.analyze.getSupplierDonut, {
			category,
			value: parseUrl(value),
			period,
			viewMode,
			facetFilters,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export const getSupplierFirstElementsAction = async (
	context: QueryFunctionContext<
		readonly [
			'getSupplierFirstElements',
			{ category: string; value: string; period?: string | number; viewMode?: string; facetFilters?: any },
		]
	>,
): Promise<productsDataType> => {
	try {
		const {
			queryKey: [, { category, value, viewMode, period, facetFilters }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.analyze.getFirstSuppliers, {
			category,
			value: parseUrl(value),
			period,
			viewMode,
			facetFilters,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export const getDataAnalyzeProductTableAction = async (
	context: QueryFunctionContext<readonly ['getDataAnalyzeProductTable', Record<string, any>]>,
) => {
	try {
		const {
			queryKey: {
				1: { size, page, viewMode, value, ...rest },
			},
		} = context;

		const result: {
			products: any[];
			meta: {
				last_page: number;
				count: number;
			};
		} = await Parse.Cloud.run(functionName.analyze.getDataAnalyzeProductTable, {
			limit: size,
			skip: (page - 1) * size,
			pageIndex: page,
			viewMode,
			value: parseUrl(value),

			...rest,
		});

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getDataAnalyzeIngredientTableAction = async (
	context: QueryFunctionContext<readonly ['getDataAnalyzeIngredientTable', Record<string, any>]>,
) => {
	try {
		const {
			queryKey: {
				1: { size, page, viewMode, value, ...rest },
			},
		} = context;

		const result: {
			ingredients: any[];
			meta: {
				last_page: number;
				count: number;
			};
		} = await Parse.Cloud.run(functionName.analyze.getDataAnalyzeIngredientTable, {
			limit: size,
			skip: (page - 1) * size,
			pageIndex: page,
			viewMode,
			value: parseUrl(value),
			...rest,
		});

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getDataAnalyzeSupplierTableAction = async (
	context: QueryFunctionContext<readonly ['getDataAnalyzeSupplierTable', Record<string, any>]>,
) => {
	try {
		const {
			queryKey: {
				1: { size, page, viewMode, value, ...rest },
			},
		} = context;

		const result: {
			ingredients: any[];
			meta: {
				last_page: number;
				count: number;
			};
		} = await Parse.Cloud.run(functionName.analyze.getDataAnalyzeSupplierTable, {
			limit: size,
			skip: (page - 1) * size,
			pageIndex: page,
			viewMode,
			value: parseUrl(value),
			...rest,
		});

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type OtherIndicatorType = {
	volume: number;
	ozone1Total: number;
	rayonnementsTotal: number;
	ozone2Total: number;
	particulesTotal: number;
	toxicite1Total: number;
	toxicite2Total: number;
	acidificationTotal: number;
	eutrophisation1Total: number;
	eutrophisation2Total: number;
	eutrophisation3Total: number;
	ecotoxTotal: number;
	fossilUseTotal: number;
	mineralUseTotal: number;
};

export const getOtherIndicatorAnalyzeAction = async (
	context: QueryFunctionContext<
		readonly [
			'getOtherIndicatorAnalyze',
			{ category: string; value: string; period?: string | number; viewMode?: string; facetFilters?: any },
		]
	>,
): Promise<OtherIndicatorType> => {
	try {
		const {
			queryKey: [, { category, value, viewMode, period, facetFilters }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.analyze.getOtherIndicatorAnalyze, {
			category,
			value: parseUrl(value),
			period,
			facetFilters,
			viewMode,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getProductSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};
