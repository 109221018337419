import { ReactNode, useMemo } from 'react';

import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { CARBON_INDICATOR, END_OF_LIFE_COLORS } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';


import Typography from '../../../../../components/Typography';
import Unit from '../../../../../components/units/Unit';
import Card from '../../../../../components/v3/Card';
import { cn, siteColors } from '../../../../../lib/colors';
import CarbonFootprintTooltip from '../../../../dashboard/v3/carbon_footprint/CarbonFootprintTooltip';
import DefaultLegend from '../../../components/DefaultLegend';
import DonutChart from '../../../components/DonutChart';
import DonutChartLegend from '../../../components/DonutChartLegend';
import { useEndOfLifeMaterialPercent } from '../../../packagings.hooks';

const styles = {
	skeleton: css({
		height: '100% !important',
		width: '100% !important',
		minHeight: 160,
		flex: 1,
	} as any),
	textNoWrap: css({
		whiteSpace: 'nowrap',
	}),
	fontBold: css({
		fontWeight: 'bold',
	}),
};

const EndOfLifeCard = () => {
	const { indicator } = useApp();
	const { t } = useTranslation();

	const { data, isLoading } = useEndOfLifeMaterialPercent({ viewMode: indicator });

	const items = useMemo(() => {
		if (!data || !data?.length)
			return [
				{
					color: siteColors.grey3,
					value: 99.9,
					tonnage: 0,
				},
			];

		return data?.map((item: any) => {
			let value = item[`${indicator}ImpactPercent`]*100;

			if (value < 0) value = 0;
			if (value >= 100) value = 99.9;

			return {
				...item,
				label: item?.uid,
				color: (END_OF_LIFE_COLORS as any)[item?.uid] || siteColors.grey7,
				value,
				tonnage: item?.tonnage,
				unit: <Unit measure={'massTon'} />,
			};
		});
	}, [data, indicator]);

	const orderedItems = _.orderBy(items, 'order');

	return (
		<Card
			title={
				<Typography variant="h3" className="grey8 fontWeight600">
					{t('Impact de la fin de vie')}
				</Typography>
			}
			loading={isLoading}
			containerClassName={'flex1'}
		>
			<DonutChart
				width={200}
				height={171}
				// items={_.filter(orderedItems, (item) => {return item?.value > 0;})}
				items={orderedItems}
				innerRadius={83}
				outerRadius={66}
				legend={!data || !data?.length ? <DefaultLegend /> : <DonutChartLegend items={orderedItems} withTonnage={false}/>}
				withTooltip
				renderTooltip={(item) => {
					return (
						<CarbonFootprintTooltip
							label={t(item?.label)}
							value={item?.tonnage}
							items={[
								{
									label: 'Impact',
									value: item && item[`${indicator}Impact`],
									unit: <Unit measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'} className="grey7" />,
								},
								{
									label: 'Tonnage',
									value: item?.tonnage,
									unit: 'T',
								},
							]}
						/>
					);
				}}
				data={orderedItems}
			/>
		</Card>
		// )}
		// </Card>
	);
};

export default EndOfLifeCard;

const TooltipCard = ({ children }: { children: ReactNode }) => {
	return (
		<div
			className="flexColumn"
			css={{
				borderRadius: '8px',
				border: cn('1px solid', siteColors.grey500),
				background: siteColors.common.white,
				boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
				padding: '24px',
				gap: '24px',
				minWidth: '150	px',
			}}
		>
			{/* OOOOOOOOOOOOOOOOO */}
			{children}
		</div>
	);
};
