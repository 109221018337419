export type GetParamsDelete = {
	id: string;
	key: string;
};

export type GetParamsSelected = {
	ids?: any[];
	roleCode?: string;
	newEmail?: string;
	email?: string;
	type?: string;
	companyId?: string;
	languageCode?: string;
	roleLabel?: string
};

export type GetParamsCreateUser = {
	firstName: string;
	lastName: string;
	email: string;
	roleCode: string;
	companyId?: string | null;
	position?: string | null;
	password?: string | null;
	languageCode?: string;
	teams?: Array<string>;
	id?:string;
};

export type GetParamsResetPasswordUserRow = {
	password: string;
	token: string;
	username: string;
	confirmPassword: string;
	languageCode?: string;
};

export async function deleteRow(params: GetParamsDelete) {
	const { id, key } = params;

	try {
		await Parse.Cloud.run('deleteData', { id, key });
	} catch (error) {
		throw (error as Error).message;
	}
}

export async function deleteSelectedRowAction(params: GetParamsSelected) {
	const { ids, companyId } = params;

	try {
		await Parse.Cloud.run('deleteUsers', { ids, companyId });
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function updateRoleSelectedRowAction(params: GetParamsSelected) {
	const { ids, roleCode, companyId } = params;

	try {
		await Parse.Cloud.run('updateRoleForUser', { ids, roleCode, companyId });
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function updateEmailSelectedRowAction(params: GetParamsSelected) {
	const { email, newEmail, languageCode } = params;

	try {
		await Parse.Cloud.run('updateUserNewEmail', { languageCode, email, newEmail });
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function sendEmailSelectedRowAction(params: GetParamsSelected) {
	const { email, type, newEmail, languageCode, companyId, roleLabel } = params;

	try {
		await Parse.Cloud.run('resendEmailSelectedRow', { email, type, newEmail, languageCode, companyId, roleLabel });
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function createUserRowAction(params: GetParamsCreateUser) {
	const { email, firstName, lastName, roleCode, companyId, position, password, languageCode, teams, id } = params;

	try {
		await Parse.Cloud.run('createUserRow', { email, firstName, lastName, roleCode, companyId, position, password, languageCode, teams, id });
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function resetPasswordUserRowAction(params: GetParamsResetPasswordUserRow) {
	const { username, token, password, confirmPassword, languageCode } = params;

	try {
		await Parse.Cloud.run('resetPasswordUserRow', { username, token, password, confirmPassword, languageCode });
	} catch (error) {
		return Promise.reject(error);
	}
}
