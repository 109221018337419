import { useMemo, useRef } from 'react';

import type { Theme } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { Cloud, Cloudy, Droplet, Droplets, ShoppingCart } from 'lucide-react';
import ReactDOMServer from 'react-dom/server';

import { ALL_YEAR_OPTION_VALUE, classNames, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { supplierCompanyCount } from '@carbonmaps/ui/actions/supplier.actions';
import { Indicators, StaticContent, StaticContentLoading } from '@carbonmaps/ui/components/saas/charts/ScatterChart';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import TradeOffChart from '../../../../../components/TradeOffChart';
import Unit from '../../../../../components/units/Unit';
import { useYearSelection } from '../../../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../../../hooks/useSearchQueryParams';
import { useTeam } from '../../../../../hooks/useTeam';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../lib/colors';
import { useGetClientAuthQuery } from '../../../../../lib/react-query/features/auth/auth.hooks';
import { useFindSuppliersTradeoff } from '../../../../../lib/react-query/features/supplier/supplier.hooks';
import SupplierModel from '../../../../../models/Supplier.model';
import { CARBON_INDICATOR } from '../../../../../utils/constants';

const classes = {
	text: {
		color: siteColors.primary,
		fontWeight: 600,
	},
};

const getColor = (score: number) => {
	if (score > 89) return '#2FBA90';
	if (score > 69) return '#49B654';
	if (score > 49) return '#95BF3C';
	if (score > 29) return '#F0BF41';
	if (score > 9 && score < 0) return '#ED9D38';
	return siteColors.grey700;
};

const formatDataGraph = (data: any, useValue: any, switchValue: any, theme: any, t: any) => {
	const finalArray = (data || [])?.map((d: any, index: number) => {
		const supplierObject = new SupplierModel(d);
		return {
			...d,
			//	uid: null, // don't show uid for popover
			y: d[useValue.y.value] || 0,
			x: d[useValue.x.value] || 0,
			value: d[useValue.x.value],
			[useValue.x.value]: d[useValue.x.value] || 0,
			[useValue.y.value]: d[useValue.y.value] || 0,
			key: `details/${d.objectId}/synthesis`,
			color: getColor(d.scoring),
			isChecked: supplierObject.isBlueCheck(),
			path: 'suppliers',
		};
	});

	return finalArray;
};

const getIndicators = (theme: Theme, t: any, period: number): Indicators[] => {
	return [
		{
			label: `${t(WORDING_TERMS.INTENSITY)}`,
			value: 'carbonIntensity',
			unity: <Unit measure="carbonIntensityKg" color={theme.palette.grey[700]} fontWeight={500} />,
			precision: 2,
			popoverKeys: [
				{
					key: 'carbonImpact',
					label:
						period === ALL_YEAR_OPTION_VALUE ? t(WORDING_TERMS.IMPACT_ALL_YEAR) : t(WORDING_TERMS.IMPACT, { period }),
					icon: <Cloudy color={theme.palette.primary.main} />,
					unit: <Unit measure="carbonImpactTon" color={theme.palette.grey[700]} fontWeight={500} />,
					style: classes.text,
					precision: 0,
				},
				{
					key: 'carbonIntensity',
					label: t(WORDING_TERMS.INTENSITY),
					icon: <Cloud color={theme.palette.primary.main} />,
					unit: (
						<Unit measure="carbonIntensityKg" textTransform={'none'} color={theme.palette.grey[700]} fontWeight={500} />
					),
					style: classes.text,
					precision: 2,
				},
			],
		},
		{
			label: t('water'),
			value: 'waterIntensity',
			unity: <Unit measure="waterIntensity" color={theme.palette.grey[700]} fontWeight={500} />,
			precision: 2,
			popoverKeys: [
				{
					key: 'waterImpact',
					label:
						period === ALL_YEAR_OPTION_VALUE ? t(WORDING_TERMS.IMPACT_ALL_YEAR) : t(WORDING_TERMS.IMPACT, { period }),
					icon: <Droplets color={siteColors.water500} />,
					unit: <Unit measure="waterImpact" color={theme.palette.grey[700]} fontWeight={500} />,
					style: { ...classes.text, color: siteColors.water500 },
					precision: 0,
				},
				{
					key: 'waterIntensity',
					label: t(WORDING_TERMS.INTENSITY),
					icon: <Droplet color={siteColors.water500} />,
					unit: <Unit measure="waterIntensity" color={theme.palette.grey[700]} fontWeight={500} />,
					style: { ...classes.text, color: siteColors.water500 },
					precision: 2,
				},
			],
		},
		{
			label: t('Volume'),
			value: 'tonnage',
			precision: 2,
			unity: <Unit measure="massTon" color={theme.palette.grey[700]} fontWeight={500} />,
			popoverKeys: [
				{
					key: 'tonnage',
					label: t('Volume'),
					icon: <ShoppingCart />,
					unit: <Unit measure="massTon" color={theme.palette.grey[700]} fontWeight={500} />,
					precision: 0,
				},
			],
		},
	];
};

const SupplierTradeoff = () => {
	const theme = useTheme();
	const { t, i18n } = useTranslation();
	const [searchQueryParams] = useSearchQueryParams();

	// ---- switch mode value ---- //
	const { indicator: switchValue } = useApp();
	const { selectedYear } = useYearSelection();
	const { team } = useTeam();
	// ---- current indicator graph ---- //
	const indicators = useMemo(() => {
		return getIndicators(theme, t, selectedYear);
	}, [theme, t, selectedYear]);

	// ---- value graph by indicator ---- //
	const useValue = useMemo(() => {
		if (switchValue === CARBON_INDICATOR) {
			return {
				x: indicators[2],
				y: indicators[0],
			};
		} else {
			return {
				x: indicators[2],
				y: indicators[1],
			};
		}
	}, [indicators, switchValue]);

	//--------------------------------------------------------------------------------------//
	//                                  USE QUERY FUNCTION                                  //
	//--------------------------------------------------------------------------------------//

	const {
		result: { data, isLoading },
	} = useFindSuppliersTradeoff({
		params: {
			facetFilters: searchQueryParams.facetFilters,
			input: searchQueryParams.input,
			supplierIds: searchQueryParams.supplierIds ?? [],
			viewMode: switchValue,
		},
	});

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// ---- fetch all count supplier for a company without filter ---- //
	// use this count will appear in graph legend
	const { data: supplierCount } = useQuery({
		queryKey: ['getSupplierCompanyCount', { selectedYear, team }],
		queryFn: supplierCompanyCount,
	});

	const countRef = useRef(0);

	const tooltipFormatter: any = _.debounce(function (this: any, anotherTooltip: any) {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const tooltip = this;
		// eslint-disable-next-line prefer-const, no-var
		var ret = ReactDOMServer.renderToString(
			<StaticContentLoading
				t={t}
				/* tooltip={tooltip} */ xAxis={useValue.x}
				yAxis={useValue.y}
				withTypeModelization={false}
			/>,
		);
		countRef.current = countRef.current + 1;

		findOneScatterPointData({
			uid: tooltip.point.uid,
			period: selectedYear,
			count: countRef.current,
			update: authData?.currentUpdate,
			company: authData?.session?.company,
		}).then((joinedSupplier: Record<string, any> | undefined) => {
			if (countRef.current === joinedSupplier?.count) {
				tooltip.point.name = joinedSupplier?.name;
				tooltip.point.carbonImpact = joinedSupplier?.carbonImpact;
				tooltip.point.waterImpact = joinedSupplier?.waterImpact;
				tooltip.point.isChecked = joinedSupplier?.isChecked;
				anotherTooltip.label?.attr({
					text: ReactDOMServer.renderToString(
						<StaticContent t={t} tooltip={tooltip} xAxis={useValue.x} yAxis={useValue.y} />,
					),
				});
			}
		});

		return ret;
	}, 500);

	return (
		<TradeOffChart
			title={t('Performance des fournisseurs', {
				suppliers: i18n.language === 'fr' ? toLowerCase(t('suppliers')) : t('suppliers'),
			})}
			subtitle={switchValue === CARBON_INDICATOR
				? t('Émission carbone au kilo vs. Volume de tonnage')
				: t('Consommation deau au kilo vs. Volume de tonnage')
			}
			datas={(data ? { data: formatDataGraph(data, useValue, switchValue, theme, t) } : {}) as any}
			displayLine={`${data?.length || 0} ${t('sur')} ${supplierCount || 0} ${t('suppliers')}`}
			indicators={indicators}
			useValue={useValue}
			loading={isLoading}
			skeleton={isLoading}
			toggleImpact={false}
			hcOptions={{
				tooltip: {
					formatter: tooltipFormatter,
				},
			}}
		/>
	);
};

export default SupplierTradeoff;

// =============================

const findOneScatterPointData = async ({
	uid,
	period,
	update,
	count,
	company,
}: {
	uid?: string;
	period?: number;
	update?: Parse.Object;
	count?: number;
	company?: any;
}) => {
	const supplierQuery = new Parse.Query(classNames.SUPPLIER)
		.equalTo('uid', uid)
		.containedIn('impactYear', [period])
		.equalTo('update', update)
		.exclude(['objectId', 'createdAt', 'updatedAt'] as never)
		.select(['name', 'onboarding']);

	const impactQuery = new Parse.Query(classNames.CACHE_SUPPLIER_YEAR)
		.equalTo('uid', uid)
		.equalTo('year', period)
		.equalTo('update', update)
		.exclude(['objectId', 'createdAt', 'updatedAt'] as never)
		.select(['carbonImpact', 'waterImpact', 'carbonIntensity', 'waterIntensity', 'isBlueCheck']);

	// const cacheSupplierQuery = new Parse.Query(classNames.CACHE_SUPPLIER_YEAR)
	// 	.equalTo('update', update)
	// 	.select(['isBlueCheck'])
	// 	.equalTo('uid', uid);

	const promise1 = supplierQuery.first({ json: true });
	const promise2 = impactQuery.first({ json: true });
	// const promise3 = cacheSupplierQuery.first({ json: true });

	const [supplier, impact]: [any, any] = await Promise.all([promise1, promise2]);

	// const supplierObject = new SupplierModel(supplier);
	// const isChecked = supplierObject.isBlueCheck();
	const isChecked = _.get(impact, 'isBlueCheck') === true ? true : false;

	return {
		...impact,
		...supplier,
		isChecked,
		count,
	};
};
