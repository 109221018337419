import { useCallback, useState } from 'react';

import { css, cx } from '@emotion/css';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import _ from 'lodash';
import { AlertTriangle, Building, Edit, Info, MoreHorizontal, Send, Trash2, UserMinus } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import { classNames, functionName, questionsStatusType } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BPopover from '@carbonmaps/ui/components/saas/BPopover';
import DeleteConfirm from '@carbonmaps/ui/components/saas/DeleteConfirm';

import FlashMessage from '../../../../../../../components/bo/FlashMessage';
import Popover from '../../../../../../../components/Popover';
import Skeleton from '../../../../../../../components/Skeleton';
import { useJuneTrack } from '../../../../../../../hooks/useJuneTrack';
import { useTranslation } from '../../../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../../../lib/colors';
import { useGetClientAuthQuery } from '../../../../../../../lib/react-query/features/auth/auth.hooks';
import { removeContactSupplierCampaignAction } from '../../../../../../../lib/react-query/features/question/question.actions';
import StatusChip from '../StatusChip';

import SuperModal from './modal/SuperModal';

const styles = {
	popCount: css({
		padding: '8px',
	}),
};

const ContactsList = ({ objectIds, supplier }: { objectIds: string[]; supplier: Record<string, any> }) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [openAlert, setOpenAlert] = useToggle(false);
	const [messageToast, setMessageToast] = useState<string | null>(null);

	const supplierName = _.get(supplier, 'name');
	const supplierUid = _.get(supplier, '_id');

	const queryKey = ['contacts', { objectIds }] as const;

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const { data, isLoading } = useQuery({
		queryKey,
		queryFn: async (ctx) => {
			try {
				const { objectIds } = ctx.queryKey[1];
				return Parse.Cloud.run(functionName.questions.findContactsListForSupplierTracking, { objectIds });
			} catch (error) {
				console.log('--- fetchContacts action error ----', error);
				return Promise.reject(error);
			}
		},
		enabled: _.isArray(objectIds) && !_.isEmpty(objectIds),
	});

	const params = useParams();
	const qFormId = _.get(params, 'questionFormId');

	const queryClient = useQueryClient();

	const { mutate: reOpenSupplier } = useMutation({
		mutationKey: ['reOpenSupplier'],
		mutationFn: async ({ email, languageCode, qFormId, firstName, lastName, supplierUid, supplierName, objectId }: { languageCode: string; email: string; qFormId: string; supplierUid: string; supplierName: string; firstName: string; lastName: string; objectId: string }) => {
			try {
				await Parse.Cloud.run('reOpenSupplier', { email, languageCode, qFormId, supplierUid, firstName, lastName, supplierName });
				//await Parse.Cloud.run('updateStatusAfterOpenSupplier', { objectId });

			} catch (error) {
				return Promise.reject(error);
			}
		},
		onSuccess: (data, { email, qFormId, supplierUid, objectId }) => {
			queryClient.invalidateQueries({ queryKey: ['relaunchCount', { supplierUid, email, qFormId }] });
			queryClient.invalidateQueries({ queryKey: ['contacts', { objectIds: [objectId] }] });

		},
	});

	// ---- remove contact from question ---- //
	const { mutate: removeContactSupplierCampaign } = useMutation({
		mutationKey: ['removeContactSupplierCampaign'],
		mutationFn: removeContactSupplierCampaignAction,
		onSuccess: async (e) => {
			if (e?.isLast === true) {
				await queryClient.invalidateQueries(['findSuppliersTracking']);
			}

			await queryClient.invalidateQueries(['contacts']);
			setMessageToast(t('toast-contact-removed', { email: e.email }));
			setOpenAlert(true);
		},
		onError: (error: any) => {
			//	setOpen(false);
		},
	});

	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(name: string) => {
			if (!analytics) return;
			analytics.track(name);
		},
		[analytics],
	);

	const renderModal = <SuperModal open={isOpen} setOpen={setIsOpen} supplier={supplier} data={data} />;

	if (isLoading) {
		return (
			<>
				<Skeleton height={50} />
				{renderModal}
			</>
		);
	}

	return (
		<>
			<div>
				<FlashMessage open={openAlert} onClose={setOpenAlert} icon={<AlertTriangle />} message={messageToast} />
			</div>
			<div className="flexRow nowrap" css={{ gap: 12 }}>
				{(objectIds.length === 1 && _.get(data[0], 'statusType') === questionsStatusType.NO_CONTACT) ||
					data.length === 1 && _.get(data[0], 'statusType') === questionsStatusType.NO_CONTACT
					? (
						<BButton
							onClick={() => {
								setIsOpen(true);
							}}
							iconLeft={<AddIcon />}
							variant="tertiary"
							label={
								<Typography color={siteColors.grey9} fontWeight={400} fontSize={14}>
									{t('Ajouter un contact')}
								</Typography>
							}
							addStyles={{
								flexGrow: 1,
								border: cn('1px solid', siteColors.grey400),
								boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
								borderRadius: '4px',
								justifyContent: 'left',
								background: 'white',
								transition: 'all 0.2s',
								'&:hover': {
									background: siteColors.grey400,
								},
								['& > .contact-item' as never]: {
									padding: '6px 6px 6px 12px',
									borderBottom: cn('1px solid', siteColors.grey400),
									'&:last-child': {
										borderBottom: 'none',
									},
								},
							}}
						/>
					)
					: (
						<div
							css={{
								flexGrow: 1,
								border: cn('1px solid', siteColors.grey400),
								boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
								borderRadius: '4px',
								background: 'white',
								['& > .contact-item' as never]: {
									padding: '6px 6px 6px 12px',
									borderBottom: cn('1px solid', siteColors.grey400),
									'&:last-child': {
										borderBottom: 'none',
									},
								},
							}}
						>
							{_.map(data, (item, index) => {
								const email = _.get(item, 'contact.email');
								const firstName = _.get(item, 'contact.firstName');
								const lastName = _.get(item, 'contact.lastName');

								return (
									<div className={cx('contact-item flexRow spaceBetween alignCenter nowrap')} key={index}>
										<div css={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
											{email}
										</div>
										<div className={cx('flexRow alignCenter grey700 nowrap')} css={{ gap:12 }}>
											{_.get(item, 'isError')
												? (
													<div css={{ position: 'relative', top: 4 }}>
														<Info color={siteColors.orange500} size={20} />
													</div>
												)
												: null}
											<RelaunchCountStatus supplierUid={supplierUid}
												email={email}
												qFormId={String(qFormId)} companyId={authData?.session?.company?.objectId || ''}
												statusCurrentType={_.get(item, 'statusType')}
												actionDate={_.get(item, 'actionDate')}
												css={{
														padding: '0 !important',

												}}
											/>

											<div>
												<Popover
													anchorOrigin={{
														horizontal: 'left',
														vertical: 'top',
													}}
													transformOrigin={{
														horizontal: 'right',
														vertical: 'top',
													}}
													trigger={
														<IconButton disableRipple css={{
															'&:hover': {
																background: `${siteColors.grey100}`,
															},
														}}>
															<MoreHorizontal size={20} />
														</IconButton>
													}
													renderContent={({ close }) => {
														return (
															<div
																css={{
																	padding: '8px',
																}}
															>
																<div css={{ paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}>
																	<div css={{ color: siteColors.text, fontSize: 14, fontWeight: 600 }}>{email}</div>
																	<div className="grey700" css={{ fontSize: 14, fontWeight: 500 }}>
																		{supplierName}
																	</div>
																</div>
																<DeleteConfirm
																	title={''}
																	subMessageConfirmDelete={t('Envoyer un mail de demande de réponse au questionnaire à', {
																		email,
																	})}
																	confirmText={t('send')}
																	onClick={(close2?: VoidFunction) => {
																		reOpenSupplier({
																			email,
																			languageCode: _.get(item, 'contact.language', 'fr'),
																			qFormId: String(qFormId),
																			supplierUid,
																			firstName,
																			lastName,
																			supplierName,
																			objectId: _.get(item, 'objectId'),
																		});
																		trackEvent('Individual form sending');
																		close2 && close2();
																		close && close();
																	}}
																	anchorOrigin={{
																		horizontal: 'center',
																		vertical: 'bottom',
																	}}
																	transformOrigin={{
																		horizontal: 'center',
																		vertical: 'top',
																	}}
																>
																	<div>
																		<BButton
																			variant="tertiary"
																			iconLeft={<Send size={20} />}
																			disableRipple
																			label={t('Relancer ce contact')}
																		/>
																	</div>
																</DeleteConfirm>
																<div>
																	<BButton
																		variant="tertiary"
																		iconLeft={<UserMinus size={20} />}
																		disableRipple
																		label={`${t('Retirer ce contact de la campagne')}`}
																		onClick={() => {
																			removeContactSupplierCampaign({
																				email,
																				questionFormId: String(qFormId),
																				supplierUid,
																			});
																			close && close();
																		}}
																	/>
																</div>

															</div>
														);
													}}
												/>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					)}

				<Popover
					css={{ marginRight: 34 }}
					anchorOrigin={{
						horizontal: 'left',
						vertical: 'top',
					}}
					transformOrigin={{
						horizontal: 'right',
						vertical: 'top',
					}}
					trigger={
						<IconButton disableRipple css={{
							position: 'relative',
							top: 6.5,
							'&:hover': {
								background: `${siteColors.grey100}`,
							},
						}}>
							<Edit size={20} />
						</IconButton>
					}
					renderContent={({ close }) => {
						return (
							<div
								css={{
									padding: '8px',
								}}
							>
								<div css={{ color: siteColors.text, fontSize: 16, fontWeight: 600, paddingLeft: 10 }}>
									{supplierName}
								</div>
								<BButton
									variant="tertiary"
									iconLeft={<Edit size={20} />}
									disableRipple
									label={t('Modifier les contacts du fournisseur')}
									onClick={() => {
										close();
										setIsOpen(true);
									}}
								/>
							</div>
						);
					}}
				/>
			</div>

			{renderModal}
		</>
	);
};

export default ContactsList;

const RelaunchCountStatus = ({ supplierUid, email, qFormId, companyId, statusCurrentType, actionDate }: { statusCurrentType: string; supplierUid: string; email: string; qFormId: string, companyId: string, actionDate: string }) => {
	const { data: count, isLoading } = useQuery({
		queryKey: ['relaunchCount', { supplierUid, email, qFormId }] as const,
		queryFn: async (ctx) => {
			try {
				const { supplierUid, email, qFormId } = ctx.queryKey[1];

				const qForm = new Parse.Object(classNames.QUESTION_FORM);
				qForm.id = qFormId;

				const company = new Parse.Object(classNames.COMPANY);
				company.id = companyId;

				const query = new Parse.Query(classNames.QUESTION_FORM_STATUS_UPDATE)
					.equalTo('questionForm', qForm)
					.equalTo('company', company)
					.containedIn('statusType', [questionsStatusType.SUPPLIER_CONTACTED, questionsStatusType.RELAUNCH])
					.equalTo('supplier.uid', supplierUid)
					.containedIn('contact.email', [email, _.toLower(email)]);

				const count = await query.count();


				return count;
			} catch (error) {
				return Promise.reject(error);
			}
		},
	});

	const { t } = useTranslation();

	return (
		<>
					<div><Typography variant="caption" color={siteColors.grey800}>{dayjs(actionDate).format('DD/MM/YYYY')}</Typography></div>

			<div>
				<BPopover
					modeHover
					css={{
							padding: '0 !important',
							minWidth: '0 !important',
					}}
					content={(onClose: () => void) => {
						return (
							<div className={cx(styles.popCount)}>
								<div>{t('relaunch-count', { count })}</div>
							</div>
						);
					}}
				>
					<div className="flexRow alignCenter">
						<div className="grey700" css={{ marginRight: 4 }}>
							<Typography variant="caption" color={siteColors.grey800} >{count}</Typography>
						</div>
						<Send size={16} color={siteColors.grey700} />
					</div>
				</BPopover>
			</div>
			<div>
				<StatusChip
					statusType={
						typeof (count) === 'number' && count > 0 && statusCurrentType === questionsStatusType.SUPPLIER_NOT_CONTACTED
							? questionsStatusType.SUPPLIER_CONTACTED
							: statusCurrentType} />
			</div>

		</>
	);
};
