import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import { CircularProgress } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { AlertCircle } from 'lucide-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { CustomMessageFormInput, MessageFormValidationSchema } from '@carbonmaps/shared/validations/questions.validations';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import AlertStatic from '../../../../../../components/Toast/AlertStatic';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { getClientAuthKey } from '../../../../../../lib/react-query/features/auth/auth.actions';
import { updateCustomMessageAction } from '../../../../../../lib/react-query/features/question/question.actions';
import { DEFAULT_CUSTOM_MESSAGE_OPTIONS } from '../../../../../../utils/constants';
import { useGetQuestionCustomMessage } from '../../../../questionForms.hooks';
import MessageForm from '../custom-messages/MessageForm';
import { stylesPrepareQuestion, topStyles } from '../suppliers-selection/QuestionFormSuppliers';

const languages = _.keys(DEFAULT_CUSTOM_MESSAGE_OPTIONS);

const styles = {
	main: css({
		alignItems: 'center!important',
	}),
};

type Props = {
	setScreen?: (screen: number) => void;
};

const CustomMessageForm = ({ setScreen }: Props) => {
	const [error, setError] = useState<string | null>(null);
	const queryClient = useQueryClient();
	const params = useParams();
	const { t } = useTranslation();

	const languageCodes = _.keys(DEFAULT_CUSTOM_MESSAGE_OPTIONS);

	const initializeVisibleTextAreas = () => {
		return languages.reduce((acc: any, lang: string) => {
			acc[lang] = lang === 'fr' || lang === 'en';
			return acc;
		}, {});
	};


	// ---- invalidateQueries getQuestionCustomMessage   ---- //
	useEffect(() => {
		if (!params?.questionFormId) return;

		return () => {
			queryClient.invalidateQueries({ queryKey: [`getQuestionCustomMessage${params.questionFormId}`] });
		};
	}, [params?.questionFormId]);

	const { data: dataCustomMessage, isLoading: isLoadingCustomMessage } = useGetQuestionCustomMessage({ questionFormId: _.toString(params.questionFormId) });

	const [langCustomEmail, setLangCustomEmail] = useState(initializeVisibleTextAreas);
	const [customMessages, setCustomMessages] = useState();

	const messageForm = useForm<CustomMessageFormInput>({
		resolver: zodResolver(MessageFormValidationSchema),
		defaultValues: async () => {
			const result: any = {};
			languages.forEach((lang) => {
				result[`content_${lang}`] = customMessages?.[lang];
			});
			return result;
		},
		mode: 'onChange',
	});


	const { reset, formState: { errors } } = messageForm;

	const messages = useMemo(() => {
		if (!dataCustomMessage || isLoadingCustomMessage) return {};

		return languageCodes.reduce((acc, lang) => {
			acc[lang] = dataCustomMessage.customEmailContent?.[lang] ?? DEFAULT_CUSTOM_MESSAGE_OPTIONS[lang]?.value ?? '';
			return acc;
		}, {} as any);
	}, [dataCustomMessage, isLoadingCustomMessage, languageCodes]);


	useEffect(() => {
		if (isLoadingCustomMessage) return;

		// Mettre à jour l'état
		setLangCustomEmail(dataCustomMessage.langCustomEmail as never);
		setCustomMessages(messages);

		// Reset les valeurs du formulaire
		reset(
			languages.reduce((acc, lang) => {
				acc[`content_${lang}`] = dataCustomMessage.customEmailContent?.[lang] ?? '';
				return acc;
			}, {} as any),
		);
	}, [dataCustomMessage, reset]);

	const { mutate: updateLangCustomEmail, isLoading: isLoadingUpdateMessage } = useMutation({
		mutationKey: ['updateLangCustomEmail'] as const,
		mutationFn: updateCustomMessageAction,
		onSuccess(data, variables, context) {
			queryClient.invalidateQueries({ queryKey: getClientAuthKey });
			queryClient.invalidateQueries({ queryKey: [`getQuestionCustomMessage${params?.questionFormId}`] });

		},
	});

	const onSubmit: SubmitHandler<CustomMessageFormInput> = async (values: any) => {
		const countChecked = Object.entries(langCustomEmail).reduce((count, [, isChecked]) => {
			return isChecked ? count + 1 : count;
		}, 0);

		if (countChecked < 1) {
			setError('At least one language must be selected');
			return;
		}

		const formattedValues = languages.reduce((acc, lang) => {
			(acc as any)[lang] = values[`content_${lang}`];
			return acc;
		}, {});

		await updateLangCustomEmail({ langCustomEmail, formattedValues, questionFormId: _.toString(params.questionFormId) });
	};

	return (
		<div>
			<div className={cx('flexRow alignCenter', topStyles.main)}>
				<div className={cx('flexRow alignCenter', topStyles.leftGroup)}>
				</div>

				<div className={cx('flexRow alignCenter')}>
					<div className="flexRow gap16">
						<BButton
							iconLeft={isLoadingUpdateMessage
								? (<CircularProgress size={20} css={{ color: '#fff' }} />)
								: null}
							label={t('Enregistrer')}
							variant="primary"
							onClick={messageForm?.handleSubmit(onSubmit)}
							isDisabled={isLoadingCustomMessage}
						/>
					</div>
				</div>
			</div>
			<div className={cx('flexColumn', stylesPrepareQuestion.main, styles.main)}>
				{error && <AlertStatic text={error} type="error" icon={<AlertCircle css={{ color: '#C74600' }} />} />}
				<MessageForm form={messageForm} langCustomEmail={langCustomEmail} setLangCustomEmail={setLangCustomEmail} customMessages={customMessages} />
			</div>
		</div>
	);
};

export default CustomMessageForm;

