import { css, cx } from '@emotion/css';


import { siteColors } from '../../../lib/colors';

import CompareHeader from './CompareHeader';


const styles = {
	container: css({
		position: 'fixed',
		top: 0,
		left: 2,
		right: 0,
		bottom: 0,
		zIndex: 1300,
		width: '95%',
		// height: '100vh',
		background: siteColors.backgroundDrawer,
		boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
	}),
};

type CompareLayoutProps = {
	children?: React.ReactNode;
	onClose: () => void;
	leftMenu: React.ReactNode;
}

const CompareLayout = ({ children, leftMenu,  onClose }: CompareLayoutProps) => {
	return (
		<div className={cx('flexRow width100', styles.container)}>
			<CompareHeader onClick={onClose} />
			{leftMenu}
			{children}
		</div>
	);

};

export default CompareLayout;
