import { useCallback, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useReportClientInfo } from '@carbonmaps/ui/hooks/useGetReportClientSheet';

import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';

import ProductsClientReportsTable from './ProductsClientReportsTable';

const ReportClientSales = () => {
	const params = useParams();
	const [searchQueryParams] = useSearchQueryParams();
	const { indicator } = useApp();

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const { data: reportClientInfo } = useReportClientInfo({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.slugClient!,
		viewMode: indicator,
	});

	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(options: any) => {
			if (!analytics) return;
			analytics.track('Explore Land Report Client', options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	// ---- track event product page ---- //
	useEffect(() => {
		if (!reportClientInfo) return;

		trackEvent({ Client: reportClientInfo?.name });
	}, [reportClientInfo, trackEvent]);

	return (
		<ProductsClientReportsTable filterOptions={searchQueryParams} manualSortBy />
	);
};

export default ReportClientSales;
