import { useState } from 'react';

import { css, cx } from '@emotion/css';

import CheckedIcon from '@carbonmaps/media/icons/check_compare_product.svg?react';

const styles = {
	outerContainer: css({
		height: 16,
		width: 16,
		padding:2,
	}),
	container: css({
		border: '1px solid #9CA3AF',
		borderRadius: '50%',
		cursor: 'pointer',
		height: 12,
		width: 12,
	}),
};

type ButtonRadioProps = {
	isSelected: boolean;
	onSelected: () => void;
}

const ButtonRadio = ({ isSelected, onSelected }: ButtonRadioProps) => {

	// const [isSelected, setIsSelected] = useState(false);

	const handleClick = () => {
		onSelected();
	};

	return (
		<>
		{ isSelected ? <CheckedIcon />: <div className={cx('flexRow alignCenter justifyCenter', styles.outerContainer)}><div className={styles.container} onClick={handleClick}></div></div>}

		</>

	);
};

export default ButtonRadio;
