import { create } from 'zustand';

import { ALL_OPTION_VALUE } from '@carbonmaps/shared/utils/constants';

export const useTeam = create((set: any) => {
	return {
		team: ALL_OPTION_VALUE,
		setTeam: (team: string | number) => {
			return set({ team });
		},
	};
});
