import { ReactElement } from 'react';

import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import type { SxProps } from '@mui/system';
import _ from 'lodash';

import SortDownIcon from '@carbonmaps/media/icons/sort-down.svg?react';
import SortListIcon from '@carbonmaps/media/icons/sort-list.svg?react';
import SortUpIcon from '@carbonmaps/media/icons/sort-up.svg?react';

import Unit, { Measure } from '../units/Unit';

type Props = {
	label: React.ReactNode;
	withPlaceholderUnit?: boolean;

	error?: React.ReactNode;
	sortable?: boolean;
	sx?: SxProps;
	valueType?: 'string';
	column?: any;
	itemCount?: number;
	className?: string;
	isLeft?: boolean;
} & (
	| {
			variant: 'measure';
			measure: Measure;
	  }
	| {
			variant: 'unit';
			unit: ReactElement;
	  }
	| {
			variant?: undefined;
	  }
);

const styles = {
	containerText: css({
		marginRight: '6px',
	}),
	otherContainer: css({
		marginLeft: '6px',
	}),
	text: css({
		margin: '0',
		fontWeight: 600,
		fontStyle: 'normal',
		fontSize: '0.8125rem',
		textTransform: 'uppercase',
		lineHeight: '1',
	}),

	unit: css({
		// textTransform: 'uppercase !important', // don't use uppercase for unit
		lineHeight: '1.5 !important',
	} as any),
};

const TableHeaderCell = ({
	isLeft = false,
	label,
	withPlaceholderUnit = true,
	sortable = true,
	valueType,
	column,
	error,
	className,
	...props
}: Props) => {
	const theme = useTheme();

	const sortIcon = !column?.disableSortBy
? (
		<div className={valueType === 'string' ? styles.containerText : styles.otherContainer}>
			{column?.isSorted
? (
				column?.isSortedDesc
? (
					<SortDownIcon
						css={{
							height: 10,
							width: 10,
						}}
					/>
				)
: (
					<SortUpIcon
						css={{
							height: 10,
							width: 10,
						}}
					/>
				)
			)
: (
				<SortListIcon />
			)}
		</div>
	)
: null;

	return (
		<div
			className={cx(
				isLeft ? 'alignLeft' : 'alignRight',
				css({
					...(sortable
						? {
								'&:hover > *, &:hover .table-header-component-label': {
									color: theme.palette.primary.main,
								},
						  }
						: {}),
					...((props?.sx as any) || {}),
				}),
			)}
		>
			<div className={cx('flexRow alignCenter nowrap', className)}>
				{valueType === 'string' ? sortIcon : null}
				<p className={cx('table-header-component-label grey700', styles.text)}>
					{label}
					{!_.isNil(props.itemCount) ? ` (${props.itemCount})` : ''}
				</p>
				{valueType !== 'string' ? sortIcon : null}
			</div>

			{error}
			{props.variant === 'measure' && (
				<Unit component="p" variant="caption" className={cx('grey700', styles.unit)} measure={props.measure} />
			)}
			{props.variant === 'unit' && props.unit}
		</div>
	);
};

export default TableHeaderCell;
