import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { Mail, Send, UserRoundPlus, Users } from 'lucide-react';
import { useParams } from 'react-router-dom';

import SupplierIcon from '@carbonmaps/media/icons/supplier-small.svg?react';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import { useTranslation } from '../../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../../lib/colors';
import QuestionFormKPICard from '../../../../components/QuestionFormKPICard';
import { useGetContactedSuppliersCountDetails, useGetSentCampaignsCountDetails, useGetStatusPercentage } from '../../../../questionForms.hooks';

const styles = {
	container: css({
		gap: '16px',
	}),
};

const getStatusPercentage = (statusMap: any, totalSuppliersCount: any, statusType: any) => {
	return _.get(statusMap, statusType, 0) as number / (totalSuppliersCount || 1) * 100;
};

const QuestionFormDetailsKPIs = () => {
	const { t } = useTranslation();
	const params = useParams();
	const questionFormId = _.toString(params.questionFormId);

	const { data: sentCampaignsCountData, isLoading: isLoadingCampaignsCount } = useGetSentCampaignsCountDetails({ questionFormId });
	const { data: contactedSuppliersCountData, isLoading: isLoadingSuppliersCount } = useGetContactedSuppliersCountDetails({ questionFormId });
	const { data, isLoading: isLoadingAnswersCount } = useGetStatusPercentage({ questionFormId });

	const totalSuppliersCount = _.get(data, 'totalSuppliersCount', 0);
	const statusMap = _.get(data, 'statusMap', {}) as Record<string, number>;

	const dataReceivedPercent = getStatusPercentage(statusMap, totalSuppliersCount, 'survey_completed');
	const registeredCount = _.get(statusMap, 'supplier_connected', 0);
	const contactedCount = _.get(statusMap, 'supplier_contacted', 0);

	return (
		<div className={cx('flexRow', styles.container)}>
			<QuestionFormKPICard
				icon={<SupplierIcon width="25.5" height="25.5" css={{ '& path': { fill: '#5A719D' } }} />}
				text={t('suppliers-in-the-campaign')}
				count={totalSuppliersCount}
				isLoading={isLoadingAnswersCount}
			/>
			<QuestionFormKPICard
				icon={<Send  size={26} color={siteColors.orange500} />}
				text={t('contacted-suppliers')}
				count={contactedCount}
				isLoading={isLoadingAnswersCount}
			/>
			<QuestionFormKPICard
				icon={<UserRoundPlus size={26} color={siteColors.primary} />}
				text={t('registration-done')}
				count={registeredCount}
				isLoading={isLoadingAnswersCount}
			/>
			<QuestionFormKPICard
				icon={<Mail size={26} color={siteColors.green500} />}
				text={t('received-responses')}
				count={_.get(statusMap, 'survey_completed', 0)}
				isLoading={isLoadingAnswersCount}
			/>
		</div>
	);
};

export default QuestionFormDetailsKPIs;
