import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { classNames, functionName } from '@carbonmaps/shared/utils/constants';

import { type IFormValues } from './QuestionForms';

export const useSaveQuestionForm = ({
	onSuccess,
}: {
	onSuccess?: (data: any) => void;
}) => {
	const result = useMutation({
		mutationKey: ['SaveQuestionForm'] as const,
		mutationFn: async ({ input }: { input: IFormValues }) => {
			try {
				const translation = {
					en: {
						name: input.name_en || input.name_fr,
						typeFormId: input.typeFormId_en || input.typeFormId_fr,
						url: input.url_en || input.url_fr,
					},
					fr: {
						name: input.name_fr,
						typeFormId: input.typeFormId_fr,
						url: input.url_fr,
					},
					it: {
						name: input.name_it,
						typeFormId: input.typeFormId_it,
						url: input.url_it,
					},
					es: {
						name: input.name_es,
						typeFormId: input.typeFormId_es,
						url: input.url_es,
					},
					de: {
						name: input.name_de,
						typeFormId: input.typeFormId_de,
						url: input.url_de,
					},
				};
				const qForm = new Parse.Object(classNames.QUESTION_FORM, {
					...(input?.objectId && { objectId: input?.objectId }),
					translation,
					type: input.type,
					published: input?.published,
					companyCodes: input?.companyCodes,
					year: input?.year,
				});

				// set acls
				const acl = new Parse.ACL();
				acl.setRoleReadAccess('super_admin', true);
				acl.setRoleWriteAccess('super_admin', true);

				if (!_.isEmpty(input?.companyCodes)) {
					input?.companyCodes?.forEach((code) => {
						acl.setRoleReadAccess(`${code}_contributor`, true);
						acl.setRoleWriteAccess(`${code}_editor`, true);
					});
				}

				qForm.setACL(acl);

				const result = await qForm.save();
				return result;
			} catch (error) {
				console.log('useCreateQuestionForm action error: ', error);
				return Promise.reject(error);
			}
		},
		onSuccess: (data, variables, context) => {
			console.log('useCreateQuestionForm action success: ', data);
			if (onSuccess) onSuccess(data);
		},
	});

	return result;
};

export const useFindQuestionForm = ({
	fromBo = false,
	companyCode,
}: { fromBo?: boolean, companyCode?: string; }) => {
	const result = useQuery({
		queryKey: ['FindQuestionForm', { fromBo, companyCode }] as const,
		queryFn: async (ctx) => {
			try {
				const { companyCode, fromBo } = ctx.queryKey[1];

				const query = new Parse.Query(classNames.QUESTION_FORM)
					.descending('createdAt')
					.select(['type', 'published', 'companyCodes', 'translation', 'campaignCounts', 'year']);

				if (!fromBo) {
					query.equalTo('published', true);
					query.equalTo('companyCodes', companyCode);
				}

				const result = await query.find({ json: true });
				return result as Record<string, any>[];
			} catch (error) {
				console.log('useFindQuestionForm-BO action error: ', error);
				return Promise.reject(error);
			}
		},
	});
	return result;
};

export const useFindOneQuestionForm = ({
	fromBo = false,
	companyCode,
	objectId,
	// roles,
}: { fromBo?: boolean, companyCode?: string; objectId?: string /* roles?: IRole[] */ }) => {
	const result = useQuery({
		queryKey: ['FindOneQuestionForm', { fromBo, objectId, companyCode }] as const,
		queryFn: async (ctx) => {
			try {
				const params = ctx.queryKey[1];

				if (!params) {
					return null;
				}

				const { companyCode, fromBo, objectId } = params;

				if (!_.isString(objectId)) {
					return null;
				}

				const query = new Parse.Query(classNames.QUESTION_FORM)
					.equalTo('objectId', objectId)
					.descending('createdAt');

				if (!fromBo) {
					query.equalTo('published', true);
					query.equalTo('companyCodes', companyCode);
				}

				const result = await query.first({ json: true });
				return result; /* || null; */
			} catch (error) {
				console.log('useFindQuestionForm-BO action error: ', error);
				return Promise.reject(error);
			}
		},
	});
	return result;
};



export const useDuplicateQuestionForm = ({
	onSuccess,
}: {
	onSuccess?: (data: any) => void;
}) => {
	const result = useMutation({
		mutationKey: ['DeleteQuestionForm'] as const,
		mutationFn: async ({ objectId }: { objectId: string }) => {
			console.log('objectId', objectId);

			try {
				return Parse.Cloud.run(functionName.questions.duplicateQuestionForm, { questionId: objectId });
			} catch (error) {
				console.log('useDeleteQuestionForm action error: ', error);
				return Promise.reject(error);
			}
		},
		onSuccess: (data, variables, context) => {
			console.log('useDuplicateQuestionForm action success: ', data);
			if (onSuccess) onSuccess(data);
		},
	});

	return result;
};


export const useDeleteQuestionForm = ({
	onSuccess,
}: {
	onSuccess?: (data: any) => void;
}) => {
	const result = useMutation({
		mutationKey: ['DeleteQuestionForm'] as const,
		mutationFn: async ({ objectId }: { objectId: string }) => {
			try {
				const qForm = new Parse.Object(classNames.QUESTION_FORM, { objectId });
				const result = await qForm.destroy();
				return result.toJSON();
			} catch (error) {
				console.log('useDeleteQuestionForm action error: ', error);
				return Promise.reject(error);
			}
		},
		onSuccess: (data, variables, context) => {
			console.log('useDeleteQuestionForm action success: ', data);
			if (onSuccess) onSuccess(data);
		},
	});

	return result;
};

export const useRetrieveFormFromApi = ({ questionFormId }: { questionFormId: string }) => {
	return useQuery({
		queryKey: [functionName.retrieveFormFromApi, { questionFormId }] as const,
		queryFn: async (ctx) => {
			const { questionFormId } = ctx.queryKey[1];
			return Parse.Cloud.run(functionName.retrieveFormFromApi, { questionFormId });
		},
	});
};

export const useSaveQuestionFormSettings = ({ onSuccess }: { onSuccess?: (data: any, variables: any, context: any) => void; } = {}) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['SaveQuestionFormSettings'] as const,
		mutationFn: async ({ questionFormId, localId, settings }: { questionFormId: string, localId: string, settings: any; /* typeFormQuestionId: string; */ }) => {
			try {
				// return Parse.Cloud.run(functionName.saveQuestionFormSettings);
				const query = new Parse.Query(classNames.QUESTION_FORM);
				query.equalTo('objectId', questionFormId).select(['questionSettings']);
				const qForm = await query.first();

				if (!qForm) {
					throw new Error('Question form not found');
				}

				const questionSettings = qForm.get('questionSettings') as any[] | undefined;
				const settingsItemIndex = (questionSettings || [])?.findIndex((item) => { return item.localId === localId; });

				//check if there is already a master question
				if (settings.masterQuestion) {
					const countMasterQuestion = (questionSettings || []).filter((item) => { return item.localId !== localId && item.masterQuestion === true; }).length;

					if (countMasterQuestion === 1) {
						throw new Error('Il y a déjà une master question');
					}
				}

				if (settingsItemIndex !== -1) {
					// console.log((questionSettings!)[settingsItemIndex]);
					// console.log(settings);
					const newItem = _.assign({}, (questionSettings!)[settingsItemIndex], /* _.pick((questionSettings!)[settingsItemIndex], ['localId']), */ settings);
					(questionSettings!)[settingsItemIndex] = newItem;
					qForm.set('questionSettings', questionSettings);
				} else {
					// console.log('****************');
					qForm.add('questionSettings', { localId, ...settings });
				}

				// return;

				qForm.save();
			} catch (error) {
				console.log('--- useSaveQuestionFormSettings error: ', error);
				return Promise.reject(error);
			}
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries(['FinQuestionSettings', { questionFormId: variables.questionFormId }]);
			onSuccess?.(data, variables, context);
		},
	});
};

export const useFindQuestionSettings = ({ questionFormId, enabled }: { questionFormId: string; enabled?: boolean }) => {
	const queryKey = ['FinQuestionSettings', { questionFormId }] as const;
	return useQuery({
		queryKey,
		queryFn: async (ctx) => {
			const { questionFormId } = ctx.queryKey[1];

			try {
				const query = new Parse.Query(classNames.QUESTION_FORM);
				query.equalTo('objectId', questionFormId).select(['questionSettings', 'questionSections', 'defaultPonderation']);
				const result = await query.first();
				return result?.toJSON();
			} catch (error) {
				console.log('useListQuestionSettings error: ', error);
				return Promise.reject(error);
			}
		},
		enabled,
	});
};

export const useSaveQuestionSection = ({
	onSuccess,
}: { onSuccess?: (data: any, variables: any, context: any) => void; } = {}) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['SaveQuestionSection'] as const,
		mutationFn: async ({ questionFormId, section }: { questionFormId: string, section: any }) => {
			try {
				// let _qForm = queryClient.getQueryData(['FinQuestionSettings', { questionFormId }]);

				// if (!_qForm) {
				// 	_qForm = await queryClient.fetchQuery(['FinQuestionSettings', { questionFormId }]);
				// }

				// const qForm = Parse.Object.fromJSON({ className: classNames.QUESTION_FORM, ...(_qForm as any) });
				const qForm = await new Parse.Query(classNames.QUESTION_FORM).select(['questionSections']).get(questionFormId);
				const _qForm = qForm.toJSON();

				const index = _.get(_qForm, 'questionSections', []).findIndex((item: any) => {
					return _.get(item, 'code') === _.get(section, 'code');
				});

				if (index !== -1) {
					const newItem = _.merge({}, _.get(_qForm, `questionSections.${index}`), section);
					(_qForm as any).questionSections[index] = newItem;
					qForm.set('questionSections', (_qForm as any).questionSections);
				} else {
					qForm.add('questionSections', section);
				}

				await qForm.save();
			} catch (error) {
				console.log('SaveQuestionSection error: ', error);
				return Promise.reject(error);
			}
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries(['FinQuestionSettings', { questionFormId: variables.questionFormId }]);
			onSuccess?.(data, variables, context);
		},
	});
};

export const useDeleteQuestionFormQuestion = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['DeleteQuestionFormQuestion'] as const,
		mutationFn: async ({ questionFormId, localId }: { questionFormId: string, localId: string }) => {
			try {
				const qForm = await new Parse.Query(classNames.QUESTION_FORM).select(['questionSettings']).get(questionFormId);
				const questions = qForm.get('questionSettings') as any[] | undefined;

				if (!questions || _.isEmpty(questions)) {
					return;
				}

				const index = questions.findIndex((item) => { return item.localId === localId; });

				if (index === -1) {
					return;
				}

				questions.splice(index, 1);

				qForm.set('questionSettings', questions);
				await qForm.save();
			} catch (error) {
				console.log('DeleteQuestionFormQuestion error: ', error);
				return Promise.reject(error);
			}
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries(['FinQuestionSettings', { questionFormId: variables.questionFormId }]);
		},
	});
};

export const useDeleteQuestionSection = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['DeleteQuestionSection'] as const,
		mutationFn: async ({ questionFormId, sectionCode }: { questionFormId: string, sectionCode: string }) => {
			try {
				const qForm = await new Parse.Query(classNames.QUESTION_FORM).select(['questionSections']).get(questionFormId);
				const sections = qForm.get('questionSections') as any[] | undefined;

				if (!sections || _.isEmpty(sections)) {
					return;
				}

				const index = sections.findIndex((item) => { return item.code === sectionCode; });

				if (index === -1) {
					return;
				}

				sections.splice(index, 1);

				qForm.set('questionSections', sections);
				await qForm.save();
			} catch (error) {
				console.log('DeleteQuestionSection error: ', error);
				return Promise.reject(error);
			}
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries(['FinQuestionSettings', { questionFormId: variables.questionFormId }]);
		},
	});
};

export const useSaveDefaultPonderation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['SaveDefaultPonderation'] as const,
		mutationFn: async ({ questionFormId, ponderation }: { questionFormId: string, ponderation: any }) => {
			try {
				const qForm = await new Parse.Query(classNames.QUESTION_FORM).select([]).get(questionFormId);
				qForm.set('defaultPonderation', ponderation);
				await qForm.save();
			} catch (error) {
				console.log('SaveDefaultPonderation error: ', error);
				return Promise.reject(error);
			}
		},
		onSuccess(data, variables, context) {
			queryClient.invalidateQueries(['FinQuestionSettings', { questionFormId: variables.questionFormId }]);
		},
	});
};

export const useLaunchQuestionFormsScoring = () => {
	return useMutation({
		mutationKey: ['LaunchQuestionFormsScoring'] as const,
		mutationFn: async (params: { companyId?: string | null | undefined } = { companyId: null }) => {
			try {
				const { companyId } = params;
				return Parse.Cloud.run(functionName.questions.launchQuestionFormsScoring, { companyId });
			} catch (error) {
				console.log('LaunchQuestionFormsScoring error: ', error);
				return Promise.reject(error);
			}
		},
	});
};





