import BlueCheckIconNok from '@carbonmaps/media/icons/bluechecknok.svg?react';
import BlueCheckIconOk from '@carbonmaps/media/icons/bluecheckok.svg?react';

import { siteColors } from '../lib/colors';
import SupplierModel from '../models/Supplier.model';

const MODELIZATION_MAP = new Map();

export const NOT_CONTACTED_MODELIZATION_TYPE = 1;
export const WAITING_MODELIZATION_TYPE = 2;
export const PARTIAL_DATA_MODELIZATION_TYPE = 2.5;
export const FULFILLED_MODELIZATION_TYPE = 3;

MODELIZATION_MAP.set(WAITING_MODELIZATION_TYPE, {
	label: 'Données en attente',
	icon: <BlueCheckIconNok />,
	color: siteColors.grey400,
	strokeColor: siteColors.grey400,
});

MODELIZATION_MAP.set(FULFILLED_MODELIZATION_TYPE, {
	label: 'Données renseignées',
	icon: <BlueCheckIconOk />,
	color: '#D4EDFF',
	strokeColor: siteColors.grey400,
});

MODELIZATION_MAP.set(NOT_CONTACTED_MODELIZATION_TYPE, {
	label: 'Non contacté',
	icon: <BlueCheckIconNok />,
	color: siteColors.common.white,
	strokeColor: siteColors.grey400,
});

MODELIZATION_MAP.set(PARTIAL_DATA_MODELIZATION_TYPE, {
	label: 'Données partielles',
	icon: <BlueCheckIconOk />,
	color: '#E0EBF8',
	strokeColor: '#E0EBF8',
});

export const MODELIZATION_CONFIG_ITEMS = MODELIZATION_MAP;

export const supplierCheckedCount = (suppliers: any) => {
	let blueCheckedCount = 0;
	let notFilledCount = 0;
	(suppliers || []).forEach((supplier: any) => {
		const supplierObject = new SupplierModel(supplier);
		const isChecked = supplierObject.isBlueCheck();
		if (isChecked) blueCheckedCount = blueCheckedCount + 1;
		if (!isChecked) notFilledCount = notFilledCount + 1;
	});

	return {
		yes: blueCheckedCount,
		no: notFilledCount,
	};
};
