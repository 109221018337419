import React, { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import MUILink from '@mui/material/Link';
import Tooltip, { tooltipClasses, type TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';

import BlueCheck from '../../../../../apps/front/src/components/BlueCheck';
import LabelsIngredients from '../../../../../apps/front/src/components/LabelsIngredients';
import SupplierScore from '../../../../../apps/front/src/components/dashboard/v2/SupplierScore';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => {
	return <Tooltip {...props} classes={{ popper: className }} />;
})(({ theme }) => {
	return {
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			// color: 'rgba(0, 0, 0, 0.87)',
			color: theme.palette.common.black,
			boxShadow: theme.shadows[2],
			fontSize: 12,
		},
	};
});

const styles = {
	link: css({
		textDecoration: 'none !important',
	}),

	line: css({
		width: 4,
		background: 'var(--background)',
		borderRadius: 8,
		height: 36,
	}),
	container: css({
		paddingTop: 8,
		paddingBottom: 8,
		gap: 8,
	}),
	iconContainer: css({
		borderRadius: '8px',
		padding: '8px',
	}),
	content: css({
		gap: '8px',
		marginBottom: 3,
	}),
	lightText: css({
		whiteSpace: 'normal', // Permet aux mots de passer à la ligne
		wordBreak: 'break-word',
	}),
	ellipsis: css({
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		textWrap: 'nowrap',
	}),
	marginText: css({
		marginLeft: 'var(--margin-text)',
	}),
	subtitle: css({
		maxWidth: '300px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	}),

	'scenario-notOriginal-ingredient-label': css({
		color: cn(siteColors.teal500, '!important'),
	}),
	'scenario-original-ingredient-notIsPresent-label': css({
		color: cn(siteColors.grey700, '!important'),
		textDecoration: 'line-through',
	}),

	'scenario-notOriginal-ingredient-iconContainer': css({
		backgroundColor: '#E2EFF1 !important',
		'& svg': {
			stroke: cn(siteColors.teal500, '!important'),
		},
	}),
	'scenario-original-ingredient-notIsPresent-iconContainer': css({
		backgroundColor: 'transparent !important',
		border: cn('1px dashed', siteColors.grey600, '!important'),
		'& svg': {
			stroke: cn(siteColors.grey600, '!important'),
		},
	}),

	text2: css({
		color: siteColors.grey700,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '12px',
	}),

	labelText: css({
		textTransform: 'capitalize',
		fontWeight: '400 !important',
		maxWidth: '400px',
	}),
};

const today = dayjs();

const TagLabel = (props: any) => {
	const { value, row, column } = props;
	const origin =
		_.isFunction(row?.original?.origin?.includes) && !row?.original?.origin?.includes?.('non prise en compte')
			? row.original.origin
			: false;

	const emptyValue = column?.props?.emptyValue;
	const noLink = column?.props?.noLink;

	const theme = useTheme();

	const ComponentLink = noLink ? 'div' : MUILink;

	const transFunction = () => {
		//
	};

	const t = column?.props?.trans || transFunction;

	const daysUpdateDifference = today.diff(row.original.updatedAt, 'day');

	const iconContainerStyle = useMemo(() => {
		let value = 'bgGrey400';

		if (column?.props?.page === 'simulation-scenario') {
			if (row.original?.isOriginal === false) {
				value = styles['scenario-notOriginal-ingredient-iconContainer'];
			} else if (row.original?.isOriginal === true) {
				if (row.original?.isPresent === false) {
					value = styles['scenario-original-ingredient-notIsPresent-iconContainer'];
				}
			}
		}

		return value;
	}, [column?.props?.page, row.original?.isOriginal, row.original?.isPresent]);

	const labelStyle = useMemo(() => {
		let value = '';

		if (column?.props?.page === 'simulation-scenario') {
			if (row.original?.isOriginal === false) {
				value = styles['scenario-notOriginal-ingredient-label'];
			} else if (row.original?.isOriginal === true) {
				if (row.original?.isPresent === false) {
					value = styles['scenario-original-ingredient-notIsPresent-label'];
				}
			}
		}

		return value;
	}, [column?.props?.page, row.original?.isOriginal, row.original?.isPresent]);

	const labelIngredients = _.get(column, 'props.labelIngredients');
	const ingredientLabelAccessor = _.get(column, 'props.ingredientLabelAccessor');

	return (
		<ComponentLink
			component={RouterLink}
			to={
				row.original.link
					? row.original.link
					: column?.props?.basePath
					? `${column?.props?.basePath}/${row.original.objectId || row.original._id}/synthesis`
					: null
			}
			onClick={(e) => {
				if (column?.callbackLoginAs) {
					e.preventDefault();
					return column?.callbackLoginAs(row?.original);
				}

				if (column?.callBack && column?.props?.page === 'scenarioReplaceIngredient') {
					if (row?.original?.uid !== column?.props?.currentUid) {
						e.preventDefault();
						return column?.callBack(row?.original);
					}
				}

				if (column?.props?.onClick) {
					column?.props?.onClick(row.original);
				}
			}}
			className={cx(styles.link, column?.props?.sx ? css(column?.props?.sx) : '')}
		>
			<div
				className={cx('flexRow nowrap alignCenter', styles.container)}
				{...(column.props?.page === 'simulation-scenario' && row.original.isOriginal && !row.original.isPresent
					? {
							'data-original-disabled-ingredient': 'true',
					  }
					: {})}
			>
				{row?.original?.logoUrl
? (
					<div className={cx(styles.iconContainer)}>
						<img src={row?.original?.logoUrl} width={24} alt="#" />
					</div>
				)
: (
					row.original?.icon ||
					(column?.icon && (
						<div className={cx(styles.iconContainer, iconContainerStyle)}>{row.original?.icon || column?.icon}</div>
					))
				)}

				{column?.props?.isProductCompared
? (
					<div className={styles.line} style={{ ['--background' as any]: row.original.color }} />
				)
: null}

				<div
					className={styles.marginText}
					style={{
						['--margin-text' as any]: column?.props?.gap ? `${column?.props?.gap}px` : '8px',
					}}
				>
					<div className={cx('flexRow nowrap alignCenter', styles.content)}>
						{row.original.isN2ModelizationType || row?.original?.isChecked
? (
							<BlueCheck
								withIcon={
									row.original.isN2ModelizationType || row?.original?.isChecked ? row?.original?.isChecked : false
								}
								iconSize={16}
								className={column?.props?.className}
							/>
						)
: null}

						{row?.original?.note
? (
							<SupplierScore color={row?.original?.color} score={_.get(row?.original, 'note', '-')} />
						)
: null}

						<div className="flexRow alignCenter"  css={{ gap: 6, flexWrap: 'nowrap' }}>
							<LightTooltip title={value}>
								<Typography
									className={cx(
										'item-label', // don't remove because use for test
										column?.props?.className,
										column?.props?.style ? css(column?.props?.style) : '',
										labelStyle,
										styles.labelText,
										column?.props?.noEllipsis ? styles.lightText : styles.ellipsis,
									)}
								>
									{value !== 'no-category' ? value : emptyValue}
								</Typography>
							</LightTooltip>
							{labelIngredients
? (
	<LabelsIngredients
		certifications={Array.from(new Set(_.get(row, ingredientLabelAccessor || 'original.certifications', [])))}
	/>
								)
: null}
						</div>
					</div>

					{origin && (
						<Typography variant="h5" color={theme.palette.grey[700]} className={styles.subtitle}>
							{origin}
						</Typography>
					)}

					{column.props?.page === 'devis-table'
? (
						<div className={styles.text2}>{_.capitalize(t('modify-day', { dayCount: daysUpdateDifference }))}</div>
					)
: null}

					{row.original.reference && (
						<Typography variant="h5" className="grey700">
							{row.original.reference}
						</Typography>
					)}
				</div>
			</div>
		</ComponentLink>
	);
};

export default TagLabel;
