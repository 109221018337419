import { useRef } from 'react';

import { css, cx } from '@emotion/css';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MUILink from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import { PanelLeftClose, PanelRightClose } from 'lucide-react';
import { Link } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import LightTooltip from '../../components/LightTooltip';
import SelectField from '../../components/form/fields/SelectField';
import SelectYear from '../../components/layout/SelectYear';
import ShareButton from '../../components/layout/ShareButton';
import SwitchButton from '../../components/layout/SwitchButton';
import TeamSelect from '../../components/layout/TeamSelect';
import { useQueryParams } from '../../hooks/useSearchParams';
import { cn, siteColors } from '../../lib/colors';
import { SIDEBAR_WIDTH, TOP_BAR_HEIGHT } from '../../utils/constants';

import Beta from './Beta';

const styles = {
	container: css({
		height: TOP_BAR_HEIGHT,
		padding: '2px 32px 2px 32px !important',
		left: SIDEBAR_WIDTH,
		top: 0,
		right: 0,
		zIndex: 101,
		background: '#ffffff',
	}),

	openContainer: css({
		left: '0px !important',
	}),
	breadCrumb: css({
		textDecoration: 'none',
		cursor: 'pointer',
	}),

	toolbar: css({
		minHeight: `${TOP_BAR_HEIGHT} !important`,
	}),
	gap4: css({
		gap: 4,
	}),
	toggleButton: css({
		minHeight: '0px !important',
		minWidth: '0px !important',
		color: cn(siteColors.grey700, '!important'),
		padding: '0px !important',
		height: 'auto !important',
		':hover': {
			color: cn(siteColors.text, '!important'),
		},
	}),
};

type TopBarProps = {
	open: boolean;
	onToggle: () => void;
	disableIndicatorSwitch?: boolean;
	disableYearSelection?: boolean;
	disableAllYear?: boolean;
	withTeamSelect?: boolean;
};

const TopBar = ({ open, onToggle, disableIndicatorSwitch, disableYearSelection, withTeamSelect, disableAllYear }: TopBarProps) => {
	const theme = useTheme();
	const topBarRef = useRef<any>(null);
	const { breadcrumbs, indicator, setIndicator } = useApp();

	const IconComponent = open ? PanelLeftClose : PanelRightClose;

	// ---- check when navigate in dashboard ---- //
	const queryParams = useQueryParams();
	const tab = queryParams?.tab ? queryParams?.tab[0] : '';

	return (
		<>
			<div
				ref={topBarRef}
				className={cx(
					'flexRow  fixed spaceBetween alignCenter nowrap',
					styles.container,
					!open ? styles.openContainer : '',
				)}
			>
				<div className="flexRow alignCenter gap8 nowrap">
					<BButton
						label=""
						variant="tertiary"
						iconLeft={<IconComponent size={20} />}
						className={styles.toggleButton}
						onClick={onToggle}
					/>
					<StyledBreadcrumb
						separator={<NavigateNextIcon fontSize="small" sx={{ '& path': { fill: theme.palette.grey[700] } }} />}
						aria-label="breadcrumb"
					>
						{breadcrumbs.map(({ text, link, isBeta }, index: number) => {
							if (link) {
								return (
									<MUILink
										noWrap
										component={Link}
										key={`${index}${link}`}
										to={link}
										className={cx('mainColor', styles.breadCrumb)}
									>
										{text}
									</MUILink>
								);
							}

							return (
								<div className={cx('flexRow alignCenter', styles.gap4)} key={`${index}${link}`}>
									<LightTooltip title={text}>
										<Typography maxWidth="350px" noWrap width="fit-content">
											{text}
										</Typography>
									</LightTooltip>
									{isBeta && <Beta />}
								</div>
							);
						})}
					</StyledBreadcrumb>
				</div>

				<div className={cx('flexRow center alignCenter')}>
					<div className={cx((!disableYearSelection || withTeamSelect) ?'' : 'display-none' )}>
						<TeamSelect />
					</div>

					{tab !== 'carbon-footprint'
						? (
							<>
								<div className={cx(disableYearSelection ? 'display-none' : '')}>
									<SelectYear disableAllYear={disableAllYear} />
								</div>
								{!disableIndicatorSwitch
									? (
										<SwitchButton
											value={indicator}
											onChange={(value) => {
												return setIndicator(value);
											}}
										/>
									)
									: null}
							</>
						)
						: null}
						<ShareButton anchorEl={topBarRef.current} />
				</div>
			</div>
			<Toolbar variant="dense" className={styles.toolbar} />
		</>
	);
};

export default TopBar;

const StyledBreadcrumb = styled(Breadcrumbs)`
	.MuiBreadcrumbs-ol {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
	}
	.MuiBreadcrumbs-li {
		max-width: 600px;
	}
`;
