import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { Leaf } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';

import IconContainer from '../../components/layout/list/IconContainer';
import ListHeader from '../../components/layout/list/ListHeader';
import { useTranslation } from '../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

const QuestionnaireHeader = ({ qFormName }: { qFormName: string }) => {
	const theme = useTheme();
	const { t } = useTranslation();
		// const {
		// 	result: { data: authData },
		// } = useGetClientAuthQuery();

		// const [searchQueryParams] = useSearchParams();
		// const questionFormIdSearchParams = searchQueryParams.get('questionFromId');

		// const questionnaires = _.get(authData, 'dataForm.questionnaires');

		// const currentQuestionnaire = _.find(questionnaires, (item) => {
		// 	return _.get(item, 'questionForm.objectId') === questionFormIdSearchParams;
		// });
		// const currentQuestionnaireName = _.get(currentQuestionnaire, `questionForm.translation.${'fr'}.name`);

	return (
		<ListHeader
			title={t('questionnaire-performance-rse')}
			subtitle={/* currentQuestionnaireName */qFormName}
			icon={<IconContainer element={<Leaf color={theme.palette.common.white} size={32} />} color={'#95AB76'} />}
		/>
	);
};

export default QuestionnaireHeader;
