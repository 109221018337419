import { useQuery } from '@tanstack/react-query';

import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useTeam } from '../../../../hooks/useTeam';

import { findIngredientFamilyAction, findIngredientFamilyTradeoffAction } from './ingredient.actions';

export const useFindIngredientFamily = ({ params }: { params: any }) => {
	const { selectedYear } = useYearSelection();
	const { team } = useTeam();
	const key = ['findIngredientFamily', { selectedYear, team,  ...params }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: findIngredientFamilyAction,
	});

	return {
		key,
		result,
	};
};

export const useFindIngredientFamilyTradeoff = ({ params }: { params: any }) => {
	const { selectedYear } = useYearSelection();
	const { team } = useTeam();
	const key = ['findIngredientFamily', { selectedYear, team, ...params }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: findIngredientFamilyTradeoffAction,
	});

	return {
		key,
		result,
	};
};
