import { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { eachOfLimit } from 'async';
import useToggle from 'react-use/lib/useToggle';

import BlueCheckOK from '@carbonmaps/media/icons/bluecheckok.svg?react';
import { getFacetConfig, getFacetLabel, getLabelValue } from '@carbonmaps/shared/utils/utils';
import { getCategoriesProduct } from '@carbonmaps/ui/actions/product.actions';
import { useRowTableSelection } from '@carbonmaps/ui/hooks/useRowTableSelection';

// import FilterContainer from '../../components/layout/list/FilterContainer';
import { EXPORT_TYPE } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import { IFilterFolder } from '../../../../components/Filter';
import { Option } from '../../../../components/form/fields/MultiSelectCheckboxField';
import FilterContainer from '../../../../components/layout/list/FilterContainer';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useJuneTrack } from '../../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../../../lib/react-query/features/auth/auth.hooks';
import { exportPackagingJobAction } from '../../../../lib/react-query/features/export/packaging.actions';
import { getPackagingFamiliesAction } from '../../../../lib/react-query/features/packaging/packaging.actions';
import { useFindPackagingsTable } from '../../../../lib/react-query/features/packaging/packaging.hooks';
import { getLabelNodeFacet } from '../../../../utils/utils';


// eslint-disable-next-line quotes

const PackagingsFilter = ({
	withMore = true,
	showFacetFilter = true,
}: {
	withMore?: boolean;
	showFacetFilter?: boolean;
}) => {
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const { selectedYear } = useYearSelection();
	const [isJobRunning, setIsJobRunning] = useToggle(false);
	const [isExportLoading, setExportLoading] = useState(false);
	const { indicator } = useApp();

	const { t, i18n } = useTranslation();
	const [exportKey, setExportKey] = useState<string | undefined>(undefined);
	const suppliersFolderLabel = useMemo(() => {
		return t('family-packaging');
	}, [t]);

	// custom facets suppliers
	const [filterFolders, setFilterFolders] = useState<IFilterFolder[]>([]);

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// filters values
	const [appliedValuesByFolders, setAppliedValuesByFolders] = useState<IFilterFolder[]>([]);
	const [selectedValues, setSelectedValues] = useState<Option[]>([]);

	// -------------------------------------------------------------------------------------- //
	// -------------------------------- Use query ------------------------------------------- //
	// -------------------------------------------------------------------------------------- //
	// ---- fetch suppliers filter ---- //
	const { data: packagingData, isLoading: isSUppliersLoading } = useQuery({
		queryKey: ['getPackagingFamilies'],
		queryFn: getPackagingFamiliesAction,
	});

	// ---- fetch facets ---- //
	const { data: facets, isLoading: isFacetsLoading } = useQuery({
		queryKey: [
			'getCategory',
			{
				input: searchQueryParams.input,
				facetFilters: searchQueryParams?.facetFilters || [],
				path: 'packaging',
				period: selectedYear,
			},
		],
		queryFn: getCategoriesProduct,
	});


// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState(
		/* <GetProductTableQueryParams> */ {
			input: '',
			page: 1,
			size: 100,
			facetFilters: [],
			supplierIds: [], // Suppliers filter
			direction: 0,
			...(searchQueryParams as any),
		},
	);
	const [resetPage, setResetPage] = useState(0);
	useEffect(() => {
		setTableParams((prev: any) => {
			return { ...prev, ...searchQueryParams };
		});
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQueryParams]);

	const {
		key,
		result: { data: tableResultData, isLoading: isTableLoading },
	} = useFindPackagingsTable({
		params: {
			...paramsTable,
			indicator,
			packagingId: null, // to avoid the search params object id // TODO: ?? yes
		},
	});


	// ---- create custom facet for supplier ----
	useEffect(() => {
		if (!packagingData) return;

		const suppliersFolder: IFilterFolder = {
			label: suppliersFolderLabel,
			isFacet: false,
			showCount: false,
			options: packagingData.map((sup: any) => {
				return {
					label: sup.labelCmaps,
					value: sup.codeEmbCmaps,
					folderLabel: suppliersFolderLabel,
				};
			}),
		};

		setFilterFolders((folders) => {
			const supplierFolderIndex = folders.findIndex((e) => {
				return e.label === suppliersFolderLabel;
			});

			if (supplierFolderIndex === -1) {
				const n2FolderIndex = folders.findIndex((e) => {
					if (e.isFacet) {
						return e.path === 'tagAdvancedModelization';
					}

					return false;
				});

				const n2Folder = folders[n2FolderIndex];
				return [...(n2Folder ? [n2Folder] : []), suppliersFolder, ...folders];
			}

			folders[supplierFolderIndex] = suppliersFolder;
			return folders;
		});
	}, [packagingData]);

	useEffect(() => {
		const searchParams = searchQueryParams?.facetFilters ?? [];
		const supplierIdsParams = searchQueryParams?.codeEmbCmapsList ?? [];

		//	if there are filters in the url
		if ((searchParams && searchParams.length) || (supplierIdsParams && supplierIdsParams.length)) {
			const currentSelectedValues = [] as any;
			const currentAppliedValuesFolders = [] as any;

			// for facet
			if (searchParams && searchParams.length) {
				searchParams.forEach((item: any) => {
					const arrayValues = item.values.map((v: any) => {
						const value = {
							facetPath: item.path,
							value: v,
							folderLabel: item?.folderLabel ?? item.path,
							label: getLabelValue(item.path, v),
							isFacetBucket: true,
							type: typeof v,
							global: item.global === true ? true : false,
							itemType: item?.itemType?.toLowerCase() || 'product',
						};

						currentSelectedValues.push(value);
						return value;
					});

					currentAppliedValuesFolders.push({
						isFacet: true,
						label: item?.folderLabel ?? item.path,
						options: arrayValues,
						showCount: true,
					});
				});
			}

			//for supplier
			if (supplierIdsParams && supplierIdsParams.length) {
				if (!packagingData) return;
				const values = packagingData
					.filter((item: any) => {
						return supplierIdsParams.includes(item.codeEmbCmaps);
					})
					.map((sup: any) => {
						return {
							label: sup.labelCmaps,
							value: sup.codeEmbCmaps,
							folderLabel: suppliersFolderLabel,
						};
					});

				const suppliersFolder: IFilterFolder = {
					label: suppliersFolderLabel,
					isFacet: false,
					showCount: false,
					options: values,
				};

				currentAppliedValuesFolders.push(suppliersFolder); // each one filter
				currentSelectedValues.push(...values); // in the base filter
			}

			setSelectedValues(currentSelectedValues);
			setAppliedValuesByFolders(currentAppliedValuesFolders);
		}
	}, [searchQueryParams, packagingData]);

	useEffect(() => {
		const asyncWrapper = async () => {
			// don't change current filter
			if (!facets) return;

			const otherFacetFilterFolders: IFilterFolder[] = [];
			let N2FacetFolder: IFilterFolder;

			for await (const entry of Object.entries(facets.facet)) {
				const [key, value] = entry;
				const { buckets } = value as any;
				const { label, type, global, itemType } = getFacetConfig(key, authData?.session.company) ?? {};

				const facetLabel = label || getFacetLabel(key, authData?.session.company);

				const currentFacetFolder: IFilterFolder = {
					label: facetLabel,
					options: [],
					isFacet: true,
					path: key,
					showCount: false,
					global,
					customIcon:
						key === 'tagAdvancedModelization' ? <BlueCheckOK css={{ 'path:first-of-type': { fill: 'grey' } }} /> : null,
				};

				await eachOfLimit(buckets as any[], 100, async (bucket, k) => {
					if (key !== 'tagAdvancedModelization' && !bucket.count) {
						return;
					}

					currentFacetFolder.options.push({
						value: bucket._id,
						label: getLabelValue(key, bucket._id),
						labelNode: ['tagAdvanced', 'tagAdvancedModelization'].includes(key)
							? getLabelNodeFacet(key, bucket._id, t)
							: undefined,
						count: bucket.count,
						folderLabel: facetLabel,
						isFacetBucket: true,
						facetPath: key,
						type: type ?? 'hardcoded-undefined-facet-type',
						global,
						itemType,
					});

					return;
				});

				if (currentFacetFolder.path === 'tagAdvancedModelization') {
					N2FacetFolder = currentFacetFolder;
				} else {
					otherFacetFilterFolders.push(currentFacetFolder);
				}
			}

			setFilterFolders((folders) => {
				const notFacetFolders = folders.filter((e) => {
					return !e.isFacet;
				});

				return [...(N2FacetFolder ? [N2FacetFolder] : []), ...notFacetFolders, ...otherFacetFilterFolders];
			});
		};

		asyncWrapper();
	}, [facets, authData?.session.company]);

	// ---- select rows selected in store ---- //
	const { selectedRows } = useRowTableSelection();
	const [format, setFormat] = useState('excel');
	const handleExport = useCallback(
		async ({ exportFormat }: { exportFormat: string }) => {
			setFormat(exportFormat);
			setIsJobRunning();
		},
		[
			setIsJobRunning,
		],
	);

			const analytics = useJuneTrack();

			const trackEvent = useCallback(
				(name: string, options?: any) => {
					if (!analytics) return;
					analytics.track(name, options, {
						context: { category: 'Packaging' },
					});
				},
				[analytics],
			);


	const handleConfirmExportJob = useCallback(({ count }: { count?: any }) => {
		trackEvent('Export packaging', { count });
		exportPackagingJobAction(
			{
				ids: selectedRows.map((e: any) => {
					return e.original.objectId;
				}),
				facetFilters: searchQueryParams.facetFilters,
				codeCmapsList: searchQueryParams?.supplierIds ?? [], // todo change to codeCmapsList
				input: searchQueryParams.input ?? '',
				format: format,
				companyCode: authData?.session.company?.name,
				selectedYear: selectedYear as any,
				exportKey,
				language: i18n?.language,
			},
			t,
		);
	}, [trackEvent, selectedRows, searchQueryParams.facetFilters, searchQueryParams?.supplierIds, searchQueryParams.input, format, authData?.session.company?.name, selectedYear, exportKey, i18n?.language, t]);

	return (
		<>
			<FilterContainer
				skeleton={isFacetsLoading}
				isLoading={false}
				appliedValuesByFolders={appliedValuesByFolders}
				setAppliedValuesByFolders={setAppliedValuesByFolders}
				selectedValues={selectedValues}
				setSelectedValues={setSelectedValues}
				folders={filterFolders}
				setSearchQueryParams={setSearchQueryParams}
				suppliersFolderLabel={suppliersFolderLabel}
				selectedRow={selectedRows}
				handleExport={handleExport}
				isTableLoading={false}
				isExportLoading={isExportLoading}
				exportKey={exportKey}
				type={EXPORT_TYPE.packaging}
				enableActionDelete={false}
				searchPlaceholder={t('search-packaging-placeholder')}
				withMore={withMore}
				searchTrackName="Explore Packaging Search"
				filterTrackName="Explore Packaging Filter"
				trackCategory="Explore"
				exportTrackName="Explore Packaging Export"
				isJob
				isJobRunning={isJobRunning}
				setIsJobRunning={setIsJobRunning}
				onConfirmJob={handleConfirmExportJob as never}
				tag={'packaging'}
				count={tableResultData?.meta?.count}
			/></>
	);
};

export default PackagingsFilter;
