import { css, cx } from '@emotion/css';

import SVGSupplierIcon from '@carbonmaps/media/icons/supplier.svg?react';

import { siteColors } from '../../lib/colors';

type Props = {
	// type: 'product' | 'ingredient' | 'supplier';
	children: React.ReactNode;
};

const styles = {
	main: css({
		borderRadius: 8,
		width: 36,
		height: 36,
	}),
};

const ItemIcon = ({ children }: Props) => {
	return <div className={cx('flexCenter bgGrey300', styles.main)}>{children}</div>;
};

export default ItemIcon;

export const SupplierIcon = () => {
	return <SVGSupplierIcon css={{ '& path': { fill: siteColors.grey700 } }} width={20} height={20} />;
};
