import { type QueryFunctionContext } from '@tanstack/react-query';

import { IUser } from '@carbonmaps/shared/types/user.types';

// ---- 1 --------------------------------------------------------------------------------

// ---- 2 --------------------------------------------------------------------------------

export type FindUsersActionParams = {
	companyId: string;
	size?: number;
	page?: number;
	input?: string;
	limit?: number;
	isLoginAs?: boolean;
};

export type FindUsersActionResult = {
	users: IUser;
	meta: {
		last_page: number;
		count: number;
	}
}

export const getUserOptionsAction = async (
	context: QueryFunctionContext<readonly ['getUserOptions', {companyId: string}]>,
) => {
	try {
		const {
			queryKey: {
				1: { companyId },
			},
		} = context;
		console.log('params', companyId);
		const result = Parse.Cloud.run('findUsers', { companyId, isTeamOptions: true });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const findUsersAction = async (
	context: QueryFunctionContext<readonly ['findUsers', FindUsersActionParams]>,
): Promise<FindUsersActionResult> => {
	try {
		const {
			queryKey: {
				1: params,
			},
		} = context;

		const result = Parse.Cloud.run('findUsers', params);

		return result;
	} catch (error) {
		console.log(' ------ findUsersAction error: ', error);
		return Promise.reject(error);
	}
};

// ---- 3 --------------------------------------------------------------------------------


export type SetUserLanguageActionParams = {
	lang?: string;
	email: string;
};

export const setUserLanguageAction = async ({ email, lang }: SetUserLanguageActionParams): Promise<void> => {
	try {
		const res = await Parse.Cloud.run('checkBeforeResetPwd', { email, lang });
		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};


export type CountUsersContributeurParams = {
	companyId?: string;
};

export type CountUsersContributeurActionResult = {
	total: number;
}

export const countUsersContributeurAction = async (
	context: QueryFunctionContext<readonly ['countUsersContributeur', CountUsersContributeurParams]>,
): Promise<CountUsersContributeurActionResult> => {
	try {
		const {
			queryKey: {
				1: params,
			},
		} = context;

		const result = Parse.Cloud.run('getCountUserContributeur', params);

		return result;
	} catch (error) {
		console.log(' ------ findUsersContributeurAction error: ', error);
	return Promise.reject(error);
	}
};


export const getUserByUsernameAction = async (
	context: QueryFunctionContext<readonly ['getUserByUsername', Record<string, any>]>,
): Promise<Parse.Object[]> => {
	try {
		const { username } = context.queryKey[1];
		const email = await Parse.Cloud.run('getEmailByUsername', { username });
		return email;
	} catch (error) {
		return Promise.reject(error);
	}
};
