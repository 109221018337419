import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { Box, HelpCircle } from 'lucide-react';
import { createSearchParams } from 'react-router-dom';

import { ALL_YEAR_OPTION_VALUE, FRONT_PATH_NAMES, ITEM_NATURE_NON_FOOD, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { getProductTableDataAction } from '@carbonmaps/ui/actions/product.actions';
import { TableComponent, getStickyHeaderStyle } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import EmptyValueHelpCircle from '../../../../../components/EmptyValueHelpCircle';
import LightTooltipGlossary from '../../../../../components/LightTooltipGlossary';
import ProductTypePopover from '../../../../../components/product/ProductTypePopover';
import TableHeaderCell from '../../../../../components/table/TableHeaderCell';
import ImpactCarbonePopover from '../../../../../components/table/popover/ImpactCarbonePopover';
import WaterImpactPopover from '../../../../../components/table/popover/WaterImpactPopover';
import { useCompareProduct } from '../../../../../hooks/useCompareProduct';
import { useGlossary } from '../../../../../hooks/useGlossary';
import { useYearSelection } from '../../../../../hooks/useImpactYear';
import { useJuneTrack } from '../../../../../hooks/useJuneTrack';
import { useQueryParams } from '../../../../../hooks/useSearchParams';
import { useTeam } from '../../../../../hooks/useTeam';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../lib/colors';
import { CARBON_INDICATOR } from '../../../../../utils/constants';

const stickyHeaderStyle = getStickyHeaderStyle(1450, 200);

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		'.bordered tr th:not(:nth-of-type(1),:nth-of-type(2)) > div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) div ,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) button':
		{
			justifyContent: 'flex-end!important',
			paddingRight: '0!important',
		},
	};
};

const styles = {
	popoverImpactStyle: css({ paddingRight: 0 }),
	percent: css({
		justifyContent: 'flex-end',
	}),
};

const formatData = (res: any, paramsUrlString: any) => {
	if (!res?.length) return [];
	return res.map((item: any) => {
		return {
			...item,
			link: `${FRONT_PATH_NAMES.products.details}/${item.objectId}/synthesis${paramsUrlString ? `${paramsUrlString}&uid=${item.uid}` : `?uid=${item.uid}`}`,
			typeModelizationIngredient: item?.typeModelizationIngredient?.toLowerCase() || 'n1_inferred_ingredient',
			isChecked: item?.tagAdvancedModelization === 'yes',
			reference: item.uid,
			ingredientCount: !item?.ingredientCount ? null : item.ingredientCount,
		};
	});
};

type ProductsTableProps = {
	filterOptions?: any;
	manualSortBy?: boolean;
	resetPage?: number;
	itemNature?: string;
};

const ProductsTable = ({ filterOptions, manualSortBy, itemNature }: ProductsTableProps) => {
	const analytics = useJuneTrack();
	// ---- hooks ---- //
	// get current switch mode value ( carbon or water )
	const { indicator } = useApp();
	// theme
	const theme = useTheme();
	// styles
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// translation

	const { t } = useTranslation();
	// glossary
	const { g } = useGlossary();
	// query params
	const queryParams = useQueryParams();
	const searchParams = createSearchParams(_.omit(queryParams, ['uid']) as any)?.toString();

	const { selectedYear } = useYearSelection();

	// --- ref for fetch data table
	const fetchIdRef = useRef(0);

	//---- track event page ---- //
	const trackEvent = useCallback(
		(evenName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(evenName, options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	// ---- track next page ---- //
	const trackNextPage = useCallback(() => {
		trackEvent('Explore Products Next Page');
	}, [trackEvent]);

	// ---- columns table definition ---- //
	const tableColumns = useMemo(() => {
		const columns: any = [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('product')} />;
				},
				accessor: 'label',
				editable: true,
				sortDescFirst: true,
				component: 'TagLabel',
				type: 'string',
				props: {
					noEllipsis: true,
					style: {
						color: siteColors.text,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
					},
					onClick: (p: any) => {
						trackEvent('Explore Click Product', {
							ProductLabel: p?.label,
						});
					},
				},
				disableSortBy: true,
				icon: <Box size={20} color={siteColors.grey700} />,
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={
								selectedYear === ALL_YEAR_OPTION_VALUE
									? t(WORDING_TERMS.IMPACT_ALL_YEAR)
									: t(WORDING_TERMS.IMPACT, { period: selectedYear })
							}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonImpactPercent' : 'waterImpactPercent',
				editable: true,
				sortDescFirst: true,
				component: 'PercentField',
				type: 'percent',
				props: {
					...(indicator === CARBON_INDICATOR
						? {
							popoverComponent: ImpactCarbonePopover,
							popoverClassName: styles.popoverImpactStyle,
							className: styles.percent,
							emptyValue: <HelpCircle color={siteColors.carbon500} />,
						}
						: {
							color: {
								primary: siteColors.water500,
								secondary: siteColors.grey500,
							},
							popoverComponent: WaterImpactPopover,
							popoverClassName: styles.popoverImpactStyle,
							className: styles.percent,
							emptyValue: <EmptyValueHelpCircle />,
						}),
					decimal: 2,
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={itemNature !== ITEM_NATURE_NON_FOOD ? t(WORDING_TERMS.INTENSITY) : t(WORDING_TERMS.IMPACT_PORTION)}
							variant="measure"
							measure={itemNature !== ITEM_NATURE_NON_FOOD
								? (indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity') :
								`${indicator}ImpactPortion`
							}
						/>
					);
				},
				accessor: itemNature === ITEM_NATURE_NON_FOOD ? `${indicator}ImpactPortion` : `${indicator}Intensity`,
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				type: 'number',
				props: {
					...(indicator !== CARBON_INDICATOR
						? {
							color: {
								primary: siteColors.water500,
								secondary: siteColors.grey500,
							},
						}
						: null),
					page: 'products',
					emptyValue: <EmptyValueHelpCircle />,
					t,
				},
			},
		];

		if (itemNature !== ITEM_NATURE_NON_FOOD) {
			columns.push(
				{
					Header: (props: any) => {
						return (
							<TableHeaderCell
								sx={{
									'& .table-header-component-label': { whiteSpace: 'nowrap' },
								}}
								column={props.column}
								label={
									<LightTooltipGlossary title={g('vs-gamme')}>
										<span className={'underline'}>{t('vs_reference')}</span>
									</LightTooltipGlossary>
								}
								variant="measure"
								measure={'percent'}
							/>
						);
					},
					accessor: 'VSReferencePercent',
					editable: true,
					sortDescFirst: true,
					component: 'VsMarket',
					type: 'number',
					props: {
						t,
						showPopover: true,
						withPercent: true,
						withEquals: true,
					},
				},
				{
					Header: (props: any) => {
						return <TableHeaderCell column={props.column} label={t('ingr')} sortable={false} />;
					},
					accessor: 'ingredientCount',
					editable: true,
					sortDescFirst: true,
					type: 'number',
					component: 'Ingredient',
				},
			);
		}

		return columns;
	}, [
		indicator,
		siteColors.carbon500,
		siteColors.water500,
		t,
		g,
		filterOptions?.facetFilters,
		filterOptions?.input,
		selectedYear,
		itemNature,
	]);


	// -------------------------------------------------------------------------------------- //
	// --------------------------------- State definition ----------------------------------- //
	// -------------------------------------------------------------------------------------- //

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState({
		input: '',
		page: 1,
		size: 100,
		facetFilters: [],
		supplierIds: [], // Suppliers filter
		direction: 0,
		...(filterOptions?.orderBy && {
			sortingBy: {
				id: filterOptions?.orderBy,
				desc: filterOptions?.direction === 'desc',
			},
		}),
		...filterOptions,
	});

	const { team } = useTeam();

	// ---- fetch data ---- //
	const { data: tableResultData, isLoading: isTableLoading } = useQuery({
		queryKey: [
			'getProductTableData',
			{
				...paramsTable,
				viewMode: indicator,
				selectedYear,
				itemNature,
				team,
			},
		],
		queryFn: getProductTableDataAction,
	});

	// state to force to first page
	const [resetPage, setResetPage] = useState(0);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);

	useEffect(() => {
		setTableParams((prev: any) => {
			return { ...prev, ...filterOptions };
		});
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterOptions]);

	let paramsUrlString = '';
	if (searchParams) paramsUrlString = `?${searchParams}`;
	return (
		<>
			<TableComponent
				skeleton={isTableLoading}
				loading={false}
				fetchData={updateTableParams}
				pageCount={tableResultData?.meta?.last_page || 0}
				noDataMessage={t('product-table-empty-data', { product: toLowerCase(t('product')), of_product: t('of_product') })}
				pageSize={paramsTable.size}
				columns={tableColumns}
				resetPage={resetPage}
				data={formatData(tableResultData?.data || [], paramsUrlString)}
				onSortingColumn={handleSortingTable}
				addStyles={stylesTable}
				manualSortBy={manualSortBy}
				stickyHeader
				stickyHeaderStyles={stickyHeaderStyle}
				transFunc={t}
				onNextClick={trackNextPage}

			/></>
	);
};

export default ProductsTable;
