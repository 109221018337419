import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { Box as BoxIcon } from 'lucide-react';
import { Link } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import BlueCheck from '../../../../../apps/front/src/components/BlueCheck';
import LabelsIngredients from '../../../../../apps/front/src/components/LabelsIngredients';
import ProductModel from '../../../../../apps/front/src/models/Product.model';

import LightTooltip from './LightTooltip';

type Props = {
	value: any;
	row: any;
	column: any;
	updateMyData: (...params: any[]) => void;
};

const styles = {
	link: css({
		textDecoration: 'none',
	}),
	container: css({
		gap: 8,
		paddingTop: 8,
		paddingBottom: 8,
	}),

	IconContent: css({
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 8,
		width: 36,
		height: 36,
	}),

	modelizationContent: css({
		gap: '8px',
		marginBottom: 3,
	}),

	marginText: css({
		marginLeft: 'var(--margin-text)',
	}),

	noCLick: css({
		cursor: 'not-allowed',
	}),

	lighterText: css({
		fontSize: '14px !important',
		fontWeight: 400,
		maxWidth: '300px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	}),
};

const FinalProduct = ({
	value,
	row,
	column,
}:
Props) => {
	const theme = useTheme();

	const fromPath = column?.props?.from;
	const product = new ProductModel(row.original);
	const labelIngredients = _.get(column, 'props.labelIngredients', false);

	return (
		<Link
			to={
				row.original.link
					? row.original.link
					: column?.props?.page === 'view-modelization'
					? '#'
					: `${FRONT_PATH_NAMES.products.details}/${
							row.original.objectId || row.original._id
					  }/synthesis?fromPath=${fromPath}&uid=${row.original.uid}`
			}
			className={cx(
				styles.link,
				column.props?.page === 'quote' && !row.original?.isInCurrentUpdate ? styles.noCLick : '',
			)}
			target={column?.link?.target ? '_blank' : '_self'}
			onClick={(e: any) => {
				if (column.props?.page === 'quote' && !row.original?.isInCurrentUpdate) {
					// do nothing
				} else if (column?.props?.onClick) {
					column?.props?.onClick(row.original, e);
				}
			}}
		>
			<div className={cx('flexRow alignCenter nowrap', styles.container)}>
				<div className={cx('bgGrey400', styles.IconContent)}>
					{column?.icon ? column?.icon : <BoxIcon color={theme.palette.grey[700]} />}
				</div>
				<div
					className={styles.marginText}
					style={{
						['--margin-text' as any]: column?.props?.gap ? `${column?.props?.gap}px` : '8px',
					}}
				>
					<div className="flexRow alignCenter" css={{ gap: 6, flexWrap: 'nowrap' }}>
					<LightTooltip title={value}>
							<div className={cx('flexRow alignCenter', styles.modelizationContent)}>
								<BlueCheck
									iconSize={16}
									title={value}
									fontSize={14}
									fontWeight={400}
									withIcon={product.isN2ModelizationType()}
									className={cx(column?.props?.className, styles.lighterText)}
									variant="body1"
								/>
							</div>
					</LightTooltip>
					{labelIngredients
? (
									<LabelsIngredients certifications={_.get(row, 'original.certifications', [])} />
								)
: null}
					</div>
					{(row.original.reference || row.original.uid) && (
						<Typography variant="h5" className="grey700">
							{row.original.reference ? row.original.reference : row.original.uid}
						</Typography>
					)}
				</div>
			</div>
		</Link>
	);
};

export default FinalProduct;
