import { useCallback, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { LinearProgress } from '@mui/material';
import { createColumnHelper, type SortingState } from '@tanstack/react-table';
import { type TFunction } from 'i18next';
import _ from 'lodash';
import { Box, Minus, Plus } from 'lucide-react';

import { WATER_INDICATOR } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';


import { useSearchProducts } from '../../../containers/products/compareProducts/hooks';
import { product, useCompareProduct } from '../../../hooks/useCompareProduct';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import IntensityCell from '../../_comp/IntensityCell';
import Table from '../../_comp/Table';
import TableHeadCell from '../../_comp/TableHeadCell';
import LabelLogo from '../../question/LabelLogo';
import ProductButton from '../ProductButton';




const tableStyles = {
	main: css({
		width: '100%',
		borderCollapse: 'collapse',
		'& tr': {
			height: '60px',
			alignSelf: 'stretch',
		},
		'& tbody tr': {
			borderTop: cn('1px solid', siteColors.grey500),
		},
		'.labelLogoText': {
			maxWidth: '600px',
		},
		'& tr > th:nth-of-type(1), & tr > td:nth-of-type(1)': {
			width: 300,
		},
		'& tr > th:not(:nth-of-type(1)) .alignRight': {
			display: 'flex!important',
			justifyContent: 'flex-end',
		},
	}),
	paddingStart: css({
		paddingLeft: 54,
	}),
};

export type ProductRowData = {
	id: string;
	uid: string;
	label: string;
	tagAdvanced?: boolean;
	intensity?: number;
	intensityPercent?: number;
	isAdded?: boolean;
};

const columnHelper = createColumnHelper<ProductRowData>();

type GetColumnsProps = {
	productsIdsSelected?: Map<string, product>;
	indicator: string;
	t: TFunction;
	onAddProduct: (product: product) => void;
	onRemoveProduct: (product: product) => void;
	onAddAllProducts: () => void;
	isAllSelected?: boolean;
};

const getColumns = ({ t, indicator,  productsIdsSelected, onAddProduct,  onRemoveProduct, isAllSelected, onAddAllProducts }: GetColumnsProps) => {
	return [
		columnHelper.accessor('label', {
			header: (info) => {
				return <TableHeadCell info={info} label={t('product')} valueType="string" />;
			},
			cell: (info) => {
				return <LabelLogo label={info.row.original.label} uid={info.row.original.uid} icon={<Box size={20} />} />;
			},
		}),
		columnHelper.accessor('intensity', {
			header: (info) => {
				return (
					<TableHeadCell
						info={info}
						label={t('Par kilo')}
						valueType="number"
						variant="measure"
						measure={indicator === WATER_INDICATOR ? 'waterIntensity' : 'carbonIntensityKg'}
					/>
				);
			},
			cell: (info) => {
				return <IntensityCell value={info.getValue()} percentage={info.row.original.intensity} indicator={indicator} />;
			},
		}),
		columnHelper.display({
			id: 'actions',
			header: (info) => {
				return (
					<BButton
						variant="primary"
						onClick={async () => {
							onAddAllProducts();
						}}
						label={isAllSelected ? t('Tout retirer') : t('Tout sélectionner')}
					/>
				);
			},
			cell: (info) => {

				return (
					<div className={cx('flexRow alignCenter justifyCenter')}>
						{productsIdsSelected?.has?.(info.row.original.id)
? (
							<ProductButton  label={t('Retirer')} product={info.row.original as any} variant='primary' icon={<Minus size={20} />} onClick={onRemoveProduct}/>
						)
: (
							<ProductButton label={t('Ajouter')} product={info.row.original as any} variant='tertiary' icon={<Plus size={20} />} onClick={onAddProduct} addStyles={{ background: 'transparent', '&:hover': { background: 'transparent' } }}/>
						)}
					</div>
				);
			},
		}),
	];
};

type ProductTableSelectProps = {

	onAddProduct: (product: product) => void;
	onRemoveProduct: (product: product) => void;
}

const ProductTableSelect = ({  onAddProduct, onRemoveProduct }: ProductTableSelectProps) => {
	const { t } = useTranslation();

	const [sorting, setSorting] = useState<SortingState>([]);

	const [searchQueryParams, setSearchParams] = useSearchQueryParams();

	// the compared products
	const { products, setProducts, setProductToCompare, indicator, sortDirection } = useCompareProduct();

	const productsIdsSelectedMap = useMemo(() => {
		const productSelectedMap = new Map<string, product>();
		products.forEach((p: product) => {
			productSelectedMap.set(p.objectId, p);
		});

		return productSelectedMap;

	}, [products]);


	// ----------------------------------------------------------------- //
	// --------------------- fetch data -------------------------------- //
	// ----------------------------------------------------------------- //
	const { data, fetchNextPage, isFetchingNextPage, isFetching } = useSearchProducts({
		enabled: true,
		sorting,
	});

	//flatten the array of arrays from the useInfiniteQuery hook
	const flatData = useMemo(() => {
		return (
			data?.pages?.flatMap((page) => {
				return page.products;
			}) ?? []
		);
	}, [data]);

	const isAllSelected = useMemo(() => {
		return flatData.length === products.length;
	}, [flatData, products]);

	const onAddAllProducts = useCallback(() => {


		if (!flatData?.length) return;

		if (isAllSelected) {
			setProducts([]);
			setProductToCompare(null as any);
			setSearchParams({ productToCompare: '' });
			return;
		}

			const dataAll = flatData.map((p: any, index: number) => {
				return {
					...p,
					color: (siteColors.compareProductColors as any)[`color${index%5}`],
					objectId: p?.id,
				};
			});
			const maxValue = sortDirection === 'asc' ? _.minBy(dataAll, `${indicator}Intensity`) :  _.maxBy(dataAll, `${indicator}Intensity`);
			setProductToCompare(maxValue);
			setProducts(dataAll);
			setSearchParams({ productToCompare: dataAll.map((p: any) => {return p.objectId;}).join(',') });

	}, [flatData, indicator, isAllSelected, setProductToCompare, setProducts, sortDirection, setSearchParams]);




	// ----------------------------------------------------------------- //
	// -------------------- columns table ------------------------------ //
	// ----------------------------------------------------------------- //
		const columns = useMemo(() => {
			return getColumns({ t: t as never, productsIdsSelected: productsIdsSelectedMap, indicator, onAddProduct, onRemoveProduct, isAllSelected, onAddAllProducts });
		}, [t, productsIdsSelectedMap, indicator, onAddProduct, onRemoveProduct, onAddAllProducts, isAllSelected]);





	const totalDBRowCount = data?.pages?.[0]?.meta?.totalCount ?? 0;
	const totalFetched = flatData.length;

	//called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
	const fetchMoreOnBottomReached = useCallback(
		(containerRefElement?: HTMLDivElement | null) => {
			if (containerRefElement) {
				const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

				//once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
				if (scrollHeight - scrollTop - clientHeight < 500 && !isFetching && totalFetched < totalDBRowCount) {
					fetchNextPage();
				}
			}
		},
		[fetchNextPage, isFetching, totalFetched, totalDBRowCount],
	);

	return (
		<div
			css={{ maxHeight: '600px', overflowY: 'scroll', padding: '0 32px' }}
			className={cx('width100')}
			onScroll={(e) => {
				return fetchMoreOnBottomReached(e.target as HTMLDivElement);
			}}
		>
			<Table
				columns={columns}
				data={flatData}
				tableProps={{ className: cx('', tableStyles.main) }}
				isLoading={isFetching && !isFetchingNextPage}
			 	manualSorting
				 sortingState={sorting}
				 onSortingChange={setSorting}
			/>
			{isFetchingNextPage ? <LinearProgress /> : null}
		</div>
	);

};

export default ProductTableSelect;
