import React, { useCallback } from 'react';

import { css, cx } from '@emotion/css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { emphasize } from '@mui/material/styles';
import { ArrowRight } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { displayAuditValue, formatNumber, truncNumberFormat } from '@carbonmaps/ui/utils/numberFormat';

import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import Skeleton from '../Skeleton';

type AuditCardProps = {
	icon: React.ReactNode;
	title: string;
	data: any;
	value: number;
	itemType: string;
	isValid: boolean;
	isLoading?: boolean;
	content?: string;
	color?: string;
	buttonText?: string;
};

const styles = {
	// outerContainer: css({
	// 	gap: 17,
	// }),
	container: css({
		// padding: '16px',
		padding: '24px',
		// paddingTop: '58px',
		borderRadius: '16px',
		boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.50)',
		gap: 16,
		// width: 284,
		// width: 332,
		minHeight: 420,
		// maxWidth: '100%',
		// [tablet]: {
		// 	width: 224,
		// },
	}),
	body2: css({
		fontSize: '24px',
	}),
	titleContainer: css({
		gap: 8,
	}),
	title: css({
		fontSize: '16px !important',
		fontWeight: '600 !important',
	}),
	donutContainer: css({
		width: 205,
		maxWidth: '100%',
	}),
	legendContainer: css({
		paddingTop: 35,
		gap: 16,
	}),
	button: css({
		padding: '18px 24px !important',
		borderRadius: '8px !important',
		textTransform: 'none !important',
	} as any),

	validButton: css({
		// pointerEvents: 'none !important',
		background: cn(siteColors.grey400, '!important'),
	} as any),
};

const AuditCard = ({
	icon,
	title,
	data,
	value,
	itemType,
	isLoading = false,
	isValid = false,
	content,
	color = siteColors.primary,
	buttonText = '',
}: AuditCardProps) => {
	const { t } = useTranslation();

	const render = (
		<div className={cx('flexColumn spaceBetween', styles.container)}>
			<div className="width100">
				<div className="flexRow alignCenter">
					<div
						className={cx('flexCenter')}
						css={{ width: '52px', height: '52px', borderRadius: '8px', marginRight: '16px' }}
						style={{ background: emphasize(color, 0.9) }}
					>
						{icon}
					</div>
					<div>
						<Typography className={cx(styles.body2, 'number')}>
							{displayAuditValue(value)}
							<span css={{ fontSize: '16px!important', display: 'inline-block', marginLeft: '4px' }}> %</span>
						</Typography>
						<span css={{ fontWeight: 600 }}>{content}</span>
					</div>
				</div>
				<div css={{ margin: '24px 0' }}>
					<div css={{ height: '8px', display: 'flex', borderRadius: '8px', overflow: 'hidden' }}>
						{data.map((dataItem: any, index: any, array: any) => {
							return (
								<div
									key={index}
									style={{
										backgroundColor: dataItem.color,
										width: `${dataItem.valueAsNumber}%`,
									}}
								/>
							);
						})}
					</div>
				</div>

				<div>
					{data.map((dataItem: any, index: any, array: any) => {
						return (
							<div key={index} className="flexRow spaceBetween" css={{ paddingBottom: '8px' }}>
								<div className="flexRow alignCenter">
									{dataItem.icon} <span css={{ marginLeft: '12px' }}>{t(dataItem.label)}</span>
								</div>
								<div>
									<Typography fontWeight={600}>
										{dataItem.value}
										<span css={{ display: 'inline-block', marginLeft: '4px' }}> %</span>
									</Typography>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			<div className="width100">
				{!isValid
				? (
					<Link to={`${FRONT_PATH_NAMES.dataDiagnostics}/${itemType}`}>
						<Button
							className={cx(
								'width100',
								styles.button,
								!isValid ? styles.validButton : '',
								!isValid ? 'bgGrey400 black' : 'bgMain whiteColor',
							)}
							disableRipple
							endIcon={
								<ArrowRight
									color={siteColors.grey700}
								/>
							}
						>
							{buttonText || 'voir le détail'}{' '}
						</Button>
					</Link>
				)
				: null}
			</div>
		</div>
	);

	return isLoading ? <Skeleton sx={{ width: '100%!important', maxWidth: 'unset' }}>{render}</Skeleton> : render;
};

export default AuditCard;
