import { useEffect, useMemo, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Button, ClickAwayListener, Grow, MenuList, Paper, Popper, Typography } from '@mui/material';
import { ChevronDown, Globe, Lock } from 'lucide-react';

import { ALL_OPTION_VALUE } from '@carbonmaps/shared/utils/constants';

import { useTeamsOptions } from '../../containers/team/team.hooks';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';

import TeamOptions from './TeamOptions';

const styles = {
	button: css({
		width: '100%',
		background: 'transparent !important',
		border: '1px solid #E2DDEB !important',
		borderRadius: '8px !important',
		justifyContent: 'space-between !important',
		height: '41px',
		color: '#292430 !important',
		textTransform: 'none !important',
		fontWeight: '400 !important',
	} as any),
	popper: css({
		zIndex:1500,
		width: 290,
	}),
	errorText: css({
		fontSize: '16px !important',
		color: 'red !important',
	}),
};

type TeamSelectOptionsProps = {
	companyId: string;
	userTeams: Array<string>;
	onConfirm: (values: Array<string>) => void;
}

const TeamSelectOptions = ({ companyId, userTeams, onConfirm }: TeamSelectOptionsProps) => {
	const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
	const [teamSelected, setTeamSelected] = useState<Array<string>>(userTeams);
	const { t } = useTranslation();



	const { data: dataOptions } = useTeamsOptions({ companyId });

	const handleClose = () => {
		setOpen(false);
	};

	const handleToggle = () =>{
		setOpen((prevOpen) => {return !prevOpen;});
	};

	const handleSelectOption = (v: string) => {
		const index = teamSelected.indexOf(v);

			if (index > -1) {
				// Si l'élément est présent, on le supprime
				teamSelected.splice(index, 1);
			} else {
				// Sinon, on l'ajoute
				teamSelected.push(v);
			}

			setTeamSelected([...teamSelected]);
	};

	const options = useMemo(() => {
		if (!dataOptions) {
			return [];
		}

		return dataOptions.map((team: any) => {
			return {
				value: team.objectId,
				label: t(team.name),
				icon: team.name === ALL_OPTION_VALUE ? Globe : Lock,
			};
		});
	}, [dataOptions, t]);

	// display selected team in button
	const renderValue = () => {

		if (!userTeams.length)  return <span></span> ;
		const valueLabelMap = new Map();
		options.forEach((option: {value: string, label: string}) => {
			valueLabelMap.set(option.value, option.label);
		});

		const labelArray = userTeams.map((value: string) => {
			return valueLabelMap.get(value);
		});
		return labelArray.join(', ');
	};

	const handleRemoveAll = () => {
		setTeamSelected([]);
	};

	const handleConfirm = () => {
		onConfirm(teamSelected);
		setOpen(false);
	};

	// set current team selected
	useEffect(() => {
		setTeamSelected(userTeams);
	}, [userTeams]);

	return (
		<div className="width100 flexColumn gap4">
			<Button
         ref={anchorRef}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleToggle}
        endIcon={<ChevronDown color={siteColors.grey7} size={20}/>}
				className={styles.button}
      >
				{renderValue()}
      </Button>
			{ !teamSelected ?.length  ?  <Typography className={styles.errorText}>Sélectionnez au moins une équipe</Typography> : null}
			 <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
					className={cx(  styles.popper)}
        >
					{({ TransitionProps, placement }) => {return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
							<Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                  >
                    <TeamOptions options={options} onClose={handleClose} onSelect={handleSelectOption} onConfirm={handleConfirm} teamSelected={teamSelected} onRemoveAll={handleRemoveAll}/>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
						</Grow>

						);}}
				</Popper>
		</div>
	);
};

export default TeamSelectOptions;
