import { useCallback, useEffect, useState } from 'react';

import { cx } from '@emotion/css';
import { Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { Edit, FileDown, FilePen, FileUp, UserRound } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { read, utils } from 'xlsx';

import SupplierIcon from '@carbonmaps/media/icons/supplier.svg?react';
import { InfoMessageContact, UserContact } from '@carbonmaps/shared/types/supplier.types';
import { functionName } from '@carbonmaps/shared/utils/constants';
import { isValidEmail } from '@carbonmaps/shared/utils/utils';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BDropdown from '@carbonmaps/ui/components/saas/BDropdown';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import AlertContactUpload from '../../../../../components/dialogs/AlertContactUpload';
import Dialog from '../../../../../components/dialogs/Dialog';
import ImportDialog from '../../../../../components/dialogs/ImportDialog';
import { useJuneTrack } from '../../../../../hooks/useJuneTrack';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../lib/colors';
import { getFormattedDataUpload, MAX_ROWS_UPLOAD } from '../../../../../utils/question';
import { getParsedDataFromCsvOrExcel, isValidCsvExcelFileExtension } from '../../../../../utils/utils';
import { fetchSuppliersAndContact } from '../../../questionForms.hooks';
import { stylesPrepareQuestion } from '../../_old/screens/suppliers-selection/QuestionFormSuppliers';

import SuppliersDetailsTable from './blocks/SupliersDetailsTable';
import SuppliersFilter from './blocks/SuppliersFilter';
import QuestionFormSuppliersTableSelect from './suppliers-selection/QuestionFormSuppliersTableSelect';


const QuestionFormDetailsSuppliers = () => {
	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const [openInfo, setOpenInfo] = useState(false);
	const { t, i18n } = useTranslation();
	const params = useParams();
	const queryClient = useQueryClient();
	const [openAddSupplier, setOpenAddSupplier] = useState(false);
	const [data, setData] = useState<UserContact[]>([]);
	const [fileName, setFileName] = useState<string | null>(null);
	const languageCode = getIsoCountryCode(i18n.language);
	const analytics = useJuneTrack();
	const [openImport, setOpenImport] = useState(false);
	const trackEvent = useCallback(
		(name: string) => {
			if (!analytics) return;
			analytics.track(name, {
				context: { category: 'Question Form' },
			});
		},
		[analytics],
	);
	const [infoMessage, setInfoMessage] = useState<InfoMessageContact>(
		{
			nbSupplier: 0,
			nbContact: 0,
			nbContactNotImported: 0,
			supplierNotExists: [],
			emailsInvalid: [],
			existingContact: [],
		},
	);
	useEffect(() => {
		trackEvent('Question Form Suppliers Land');
	}, [trackEvent]);


	const saveContactPreCampaign = async (data: any[]) => {
		await Promise.all([
			Parse.Cloud.run(functionName.questions.saveContactsPreCampaignAction, data),
			Parse.Cloud.run(functionName.questions.addSuppliersSelectPreCampaign, {
				supplierUids: data.map((item) => { return item.supplierUid; }),
				questionFormId: params.questionFormId,
			}),
		]);
	};


	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0]; // Retrieve the first file from the input
		if (!file) return; // Exit if no file is selected

		const fileName = file.name; // Extract the file name
		setFileName(fileName); // Save the file name in state
		// Vérifier l'extension du fichier
		const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

		// Ensure the file is a CSV file or xlsx
		if (!isValidCsvExcelFileExtension(fileName)) {
			setError(t('import-contact-error-not-csv')); // Set error if the file is not a CSV
			setData([]); // Clear any existing data
			return;
		}

		// If a valid file is provided, initialize FileReader
		const reader = new FileReader();

		// Handle the file once it's loaded
		reader.onload = (e) => {

			// Convert the sheet data into a JSON array
			const parsedData = getParsedDataFromCsvOrExcel(e, fileExtension);
			if (!parsedData) return; // Exit if no data is found

			// Check if the number of rows (excluding the header) exceeds the maximum allowed
			if (parsedData?.length - 1 > MAX_ROWS_UPLOAD) {
				setError(t('import-error-too-many-rows', { varMaxRow: MAX_ROWS_UPLOAD })); // Set error for too many rows
				setData([]); // Clear data
				return;
			}

			// Format the parsed data, excluding the header row
			const formattedData = getFormattedDataUpload(parsedData);
			// Update state with the formatted data
			setData(formattedData);
		};

		// Read the file as an ArrayBuffer for decoding
		reader.readAsArrayBuffer(file);
	};

	const handleSaveData = async () => {
		if (error) return; // Exit if there is an existing error

		setLoading(true); // Activate the loading state for the UI

		if (!data || data.length === 0) {
			// If no data is available, stop loading and exit
			setLoading(false);
			return;
		}

		const allContactsEmail = _.uniq(data.map((s) => { return s.contactEmail; }).filter(Boolean));
		// Fetch existing suppliers and associated contacts from an API or database
		const { suppliers, existingContact } = await fetchSuppliersAndContact({
			nameSuppliers: data.map((s) => { return s.supplierName; }).filter(Boolean),
			contactsEmail: allContactsEmail,
			isPreCampaign: true,
		}) as never;

		// Create a Map for quick access to suppliers by their names
		const mapSuppliers = new Map<string, any>(
			(suppliers as any).map((result: any) => { return [result.name, result]; }),
		);

		// Initialize structures to store valid results and errors
		const _newData: Record<string, any>[] = []; // Holds valid data for saving
		const supplierNotExists: any[] = []; // Tracks suppliers not found in the database
		const emailsInvalid: any[] = []; // Tracks invalid email addresses

		let countErrorContact = 0; // Counter for invalid contact entries

		// Convertir tous les emails en minuscule
		const normalizedData = data.map(item => {
			return {
				...item,
				contactEmail: item?.contactEmail?.toLowerCase() || '',
			};
		});

		// Regrouper les contacts par email
		const emailToSuppliers = _.mapValues(
			_.groupBy(normalizedData, 'contactEmail'),
			items => { return _.uniq(items.map(e => { return e.supplierName; })); }, // Extraire les supplierName uniques
		);

		// Filtrer les emails ayant plusieurs supplierName
		const identicalEmails = _.pickBy(emailToSuppliers, suppliers => { return suppliers.length > 1; }) || null;

		const filteredData = normalizedData.filter(item => {
			return !_.keys(identicalEmails).includes(item.contactEmail); // On garde seulement si l'email est unique à un seul supplierName
		});

		// Iterate through the data to validate and transform each entry
		filteredData.forEach((s) => {
			if (!s.supplierName && !s.contactEmail) return; // Skip empty rows
			const itemMap = mapSuppliers.get(s.supplierName); // Look up supplier in the Map

			if (!itemMap) {
				// If the supplier doesn't exist in the database
				supplierNotExists.push({
					uid: s.supplierName,
					email: s.contactEmail,
				});
			}

			if (!s.contactEmail || !isValidEmail(s.contactEmail)) {
				// If the email is invalid or missing
				countErrorContact++; // Increment the error counter
				emailsInvalid.push({ email: s.contactEmail }); // Add to invalid emails list
				return; // Stop processing this entry, no further action needed
			}

			if (itemMap) {
				// If the supplier exists and the email is valid, build a valid entry
				_newData.push({
					id: itemMap.id,
					supplierName: s.supplierName || '---', // Default if supplier name is missing
					supplierUid: itemMap.uid || '---', // Default if supplier UID is missing
					contactEmail: s.contactEmail,
					contactFirstName: s.contactFirstName,
					contactLastName: s.contactName,
					contactLanguage: s.contactLanguage ?? languageCode, // Default language if not provided
				});
			}
		});

		// Compute statistics for informational messages
		const uniqueSuppliers = _.uniqBy(_newData, 'supplierUid'); // Unique suppliers

		const uniqueContacts = _.uniqBy(_newData, 'contactEmail'); // Unique contacts

		await saveContactPreCampaign(_newData?.map((item) => {
			return {
				...item,
				questionFormId: _.toString(params.questionFormId),
				supplierId: item.id,
			};
		}));

		// Build the information message for the user
		setInfoMessage({
			supplierNotExists, // List of suppliers not found
			emailsInvalid, // List of invalid email addresses
			existingContact, // Existing contacts from the database
			nbSupplier: uniqueSuppliers.length, // Count of unique suppliers
			nbContact: uniqueContacts.length, // Count of unique contacts
			nbContactNotImported: countErrorContact + (_.keys(identicalEmails).length ?? 0), // Count of contacts not imported
			identicalEmails, // object of emails with multiple suppliers
		} as never);

		setOpenImport(false); // Close the import modal
		setLoading(false); // Deactivate the loading state
		setError(null); // Reset any errors
		setOpenInfo(true); // Open the informational modal for the user
		await queryClient.invalidateQueries({ queryKey: ['findSuppliersTracking'] });
		await queryClient.invalidateQueries({ queryKey: ['findSupplierTable'] });
		await queryClient.invalidateQueries({ queryKey: ['getPreCampaignSuppliersContacts'] });
		trackEvent('Question Form Contact Import');

	};

	return (
		<>
			<AlertContactUpload
				title={t('import-contact-title')}
				openInfo={openInfo} setOpenInfo={setOpenInfo} infoMessage={infoMessage}
			/>

			<div css={{
				'& .filterContainer-wrapper': {
					paddingLeft: 0,
					paddingRight: 0,
				},
			}}>
				<SuppliersFilter pageKey={'supplier_pre_campaign'} withMore={false} rightContent={
					<BDropdown
						menus={[
							{
								title: t('edit-button'),
								value: '',
								customComponent: t('Modifier la liste'),
							},
						]}
						renderItem={(menu) => {
							return (
								<>
									<BButton
										onClick={() => { setOpenAddSupplier(true); }}
										iconLeft={<SupplierIcon css={{ '& path': { fill: siteColors.grey7 } }} width={20} height={20} />}
										label={t('Ajouter des fournisseurs')}
										variant="tertiary"
									/>
									<br />
									<BButton
										onClick={() => { setOpenImport(true); }}
										iconLeft={<UserRound size={20} color={siteColors.grey7} />}
										label={t('Importer des contacts')}
										variant="tertiary"
									/></>
							);
						}}
					>
						<BButton iconLeft={<Edit size={16} color={siteColors.grey700} />}
							label={t('Modifier la liste')} variant="secondary" />
					</BDropdown>
				}
				/>
				<SuppliersDetailsTable />

				<ImportDialog
					title={t('import-contact-title')}
					error={error}
					isLoading={loading}
					open={openImport}
					withCancelButton={false}
					closeButton
					toggle={() => {
						setOpenImport(!openImport);
					}}
					paperProps={{
						maxWidth: 1200,
						width: 482,
						minHeight: 250,
					}}
					description={
						<div>
							<Typography variant="body1" textAlign="left">
								{t('import-contact-description')}
							</Typography>
							<div>
								<a href="/template_contact.csv" download="template_contact.csv" className={stylesPrepareQuestion.link}>
									{t('import-csv-download-model')}
								</a> {' '}
								<span>{t('ou')}</span> {' '}
								<a href="/template_contact.xlsx" download="template_contact.xlsx" className={stylesPrepareQuestion.link}>
									{t('import-xlsx-download')}
								</a>
							</div>
						</div>
					}
					primaryButtonText={t('import-contact-to-validate')}
					handlePrimaryButtonAction={handleSaveData}
				>
					<div className={cx('flexColumn gap16', stylesPrepareQuestion.fileInput)}>
						<input type="file" accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={handleFileUpload} />
						<FileDown color={siteColors.grey700} />
						<Typography color={siteColors.grey700}>{t('import-csv-excel-choose-file')}</Typography>
						{fileName && <div>{fileName}</div>}
					</div>
				</ImportDialog>

				<Dialog
					open={openAddSupplier}
					withCancelButton={false}
					closeButton
					toggle={() => {
						setOpenAddSupplier(!openAddSupplier);
					}}
					paperProps={{
						maxWidth: 1200,
						width: 1200,
						minHeight: 600,
					}}
				>
					<QuestionFormSuppliersTableSelect />
				</Dialog>
			</div>
		</>
	);
};

export default QuestionFormDetailsSuppliers;
