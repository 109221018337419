import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { Droplet, Droplets } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import CategoriesGraph from '../../../../components/charts/CategoriesGraph';
import Unit from '../../../../components/units/Unit';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import { CARBON_INDICATOR } from '../../../../utils/constants';
import * as graphIndicatorUtils from '../../../../utils/graphIndicator';
import { useBreakDownCategories } from '../../analyse.hooks';
import LifeCycleGraph from '../analysePage/synthesisTab/LifeCycleGraph';
import BreakDownIndicator from '../BreakDownIndicator';

import CategoryFilter from './CategoryFilter';
import MainIndicatorBlock from './MainIndicatorBlock';
import OtherIndicatorCard from './OtherIndicatorCard';
import SuppliersCard from './SuppliersCard';

const indicatorItems: any = (theme: any) => {
	return {
		carbonImpact: {
			icon: <Droplets color={siteColors.water500} />,
			key: 'waterImpact',
			label: WORDING_TERMS.IMPACT,
			unity: <Unit measure="waterImpact" color={siteColors.grey700} />,
		},
		intensity: {
			icon: <Droplet color={siteColors.water500} />,
			key: 'waterIntensity',
			label: WORDING_TERMS.INTENSITY,
			unity: <Unit measure="waterIntensity" color={siteColors.grey700} />,
		},
	};
};

const Synthesis = ({ dataSegment, loading, filterValues, facetFilter, onChangeFilter }: any) => {
	const theme = useTheme();
	const currentMainIndicator = indicatorItems(theme);

	const { t } = useTranslation();

	const { selectedYear } = useYearSelection();

	const params = useParams();
	const { viewMode, indicator: indicatorSelected } = useApp();

	const { data, isLoading } = useBreakDownCategories({}) as any;

	return (
		<Box>
			<Box mt={'-20px'}>
				<CategoryFilter segmentName={params.segment} segmentValue={params.value} />
			</Box>

			<Grid container gap="40px" sx={{ position: 'relative', flexWrap: 'nowrap' }}>
				<Grid item xs={8.2}>
					{loading
? (
						<Box
							className="flexCenter"
							css={{
								display: 'flex',
								justifyContent: 'center',
								position: 'absolute',
								top: 200,
								left: '20%',
							}}
						>
							<Box sx={{ p: 2 }}>
								<CircularProgress color="inherit" size={20} />
							</Box>
						</Box>
					)
: (
						<></>
					)}
					<MainIndicatorBlock categoryData={dataSegment?.segmentInfo} suppliers={dataSegment?.suppliers?.data} />
					<BreakDownIndicator />
					<CategoriesGraph
						color={indicatorSelected === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500}
						defaultIndicator={graphIndicatorUtils.getPopoverBySwitchIndicator(indicatorSelected, true, t, selectedYear)}
						showToggle={false}
						data={data}
						isLoading={isLoading}
					/>
					<OtherIndicatorCard viewMode={viewMode} />
				</Grid>

				<Grid item /* bgcolor="blue" */ flexGrow={1}>
					<LifeCycleGraph />
					<SuppliersCard />
				</Grid>
			</Grid>
		</Box>
	);
};

export default Synthesis;
