import { useMemo } from 'react';

import { css } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

// import LineChart from '../../../components/dashboard/temporality/LineChart';
import { classNames } from '@carbonmaps/shared/utils/constants';
import { useProductImpact, useProductInfo } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { displayValue, formatCompactNumber, formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import BlockCard from '../../../components/BlockCard';
import BlockTitle from '../../../components/BlockTitle';
import EmptyValueHelpCircle from '../../../components/EmptyValueHelpCircle';
import Skeleton from '../../../components/Skeleton';
import Unit from '../../../components/units/Unit';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';
import { WATER_INDICATOR } from '../../../utils/constants';
import EmptyImpact from '../../dashboard/EmptyImpact';

import LineChart from './LineChart';
// import EmptyImpact from '../EmptyImpact';

const styles = {
	skeleton: css({
		marginTop: 24,
		height: '175px !important',
		width: '100% !important',
	} as any),
	cardContainer: css({
		// paddingBottom: '0px!important',
	}),
};

const ProductTemporality = ({ graphWidth }: { graphWidth?: string }) => {
	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const { indicator } = useApp();
	const params = useParams();

	const { data: productInfo } = useProductInfo({ objectId: _.toString(params.productId), viewMode: indicator });

	const { data, isLoading } = useQuery({
		queryKey: [
			'getProductSheetTotalImpactPerPeriod',
			{
				updateId: authData?.currentUpdate?.id,
				uid: _.get(productInfo, 'uid'),
			},
		] as const,
		queryFn: async (ctx) => {
			try {
				const { updateId, uid } = ctx.queryKey[1];

				const results = new Parse.Query(classNames.IMPACT_YEAR)
					.equalTo('uid', uid)
					.equalTo('type', 'product')
					.equalTo('update', new Parse.Object(classNames.UPDATE, { objectId: String(updateId) }))
					.select(['year', 'carbonImpact', 'waterImpact'])
					.find({ json: true });

				return results;
			} catch (error) {
				return Promise.reject(error);
			}
		},
	});

	const { data: kpiData /* isLoading, isSuccess */ } = useProductImpact({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.productId!,
		viewMode: indicator,
		uid: searchQueryParams?.uid,
	});


	const isEmpty = useMemo(() => {
		if (kpiData) {
			return (kpiData as any)[`${indicator}Impact`] === 0;
		}

		return null;
	}, [kpiData, indicator]);

	if (_.isEmpty(data)) {
		return null;
	}

	return (
		<BlockCard
			// className="flexCenter"
			height="100%"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				// justifyContent: 'space-between',
				marginBottom: 0,
			}}
		>
			{isLoading
				? (
					<Skeleton width="100%!important" className={styles.skeleton} />
				)
				: data?.length
					?
					(
						<>
							<div>
								<BlockTitle>
									{t('Impact')}{' '}
									{selectedYear !== -1
										? (
											<span css={{ textTransform: 'lowercase' }}>{t('per_period2', { period: selectedYear })}</span>
										)
										: null}
								</BlockTitle>

								<div className="flexRow" css={{ gap: 8 }}>
									<div
										css={{
											color: indicator === WATER_INDICATOR ? siteColors.water500 : siteColors.carbon500,
											fontVariantNumeric: 'lining-nums tabular-nums',
											fontFamily: 'Inter',
											fontSize: '24px',
											fontStyle: 'normal',
											fontWeight: 400,
											lineHeight: '82.4%',
											letterSpacing: '-0.48px',
										}}
									>
										{isEmpty
											? <EmptyValueHelpCircle /> :
											// simplifyNumber(kpiData ? (kpiData[`${indicator}Impact`] as number) : 0)
											formatCompactNumber(kpiData ? (kpiData[`${indicator}Impact`] as number) : 0)
											}

									</div>

									<div
										css={{
											color: siteColors.grey800,
											leadingTrim: 'both',
											textEdge: 'cap',
											fontVariantNumeric: 'lining-nums tabular-nums',
											fontFamily: 'Inter',
											fontSize: '14px',
											fontStyle: 'normal',
											fontWeight: 400,
											lineHeight: '150%',
										}}
									>
										<Unit measure={indicator === WATER_INDICATOR ? 'waterImpact' : 'carbonImpactTon'} />
									</div>
								</div>
							</div>

							<div className="flexCenter" css={{ flexGrow: 1, paddingTop: 24 }}>
								<LineChart
									graphWidth={graphWidth}
									data={_.map(_.sortBy(data, 'year'), (item) => {
										return {
											...item,
											objectId: _.get(item, 'year'),
										};
									})}
									showLegend={false}
								/>
							</div>
						</>
					)
					: (
						<EmptyImpact text={t('temporality-empty-text', { period: selectedYear || t('selectedYear') })} />
					)}
		</BlockCard>
	);
};

export default ProductTemporality;

