import { useState } from 'react';

import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { ChevronDown, Cloudy, Droplets } from 'lucide-react';

import { Indicator } from '@carbonmaps/ui/contexts/AppProvider';
import { CARBON_INDICATOR, WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { capitalizeFirstLetter } from '@carbonmaps/ui/utils/utils';

import { useImpactWaterSwitch } from '../../hooks/useImpactSwitch';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import SelectField from '../form/fields/SelectField';
import Typography from '../Typography';

const options = [
	{
		label: 'carbon',
		defaultColor: '',
		icon: <Cloudy size={20} color={siteColors.carbon500} />,
		value: CARBON_INDICATOR,
	},

	{
		label: 'water',
		defaultColor: '',
		icon: <Droplets size={20} color={siteColors.water500} />,
		value: WATER_INDICATOR,
	},
];

const styles = {
	container: css({
		background: 'transparent !important',
		border: 'none !important',
		minHeight: '42px',
		'& .MuiSelect-icon': {
			color: 'var(--iconColor) !important',
		},
		':hover': css({
			background: 'var(--backgroudhover) !important',
		}),
	} as any),


	paper: css({
		borderRadius: 12,
		marginTop: 8,
		minWidth: '212px !important',
		'& .MuiButtonBase-root': {
			'&:hover': {
				backgroundColor: `${siteColors.grey300} !important`,
			},
		},
	}),
	list: css({
		paddingTop: 0,
		paddingBottom: 0,
		'& li': {
			padding: '8px 16px',
			alignItems: 'center',
			gap: '8px',
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: '400',
			color: siteColors.text,
			'& .MuiRadio-root': {
				padding: 4,
			},
		},
	}),
	value: css({
		color: 'var(--textColor) !important',
	}),
};

type SwitchButtonProps = {
	value: Indicator;
	onChange: (value: Indicator) => void;
};

const SwitchButton = ({ value, onChange }: SwitchButtonProps) => {

	const [selected, setSelected] = useState(value);


	const { t } = useTranslation();
	const analytics = useJuneTrack();

	const handleChange = (value: Indicator) => {
		analytics.track(
			`General Switch ${capitalizeFirstLetter(value)}`,
			{},
			{
				// Add the GROUP_ID here to track this event on behalf of a workspace
				context: { category: 'General' },
			},
		);
		setSelected(value as any);
		onChange(value);
	};

	return (
		<SelectField
			id={'switch-indicator'}
			value={selected}
			items={options}
			renderValue={(item: any) => {
				return (
					<div className="flexRow nowrap alignCenter gap4">
						{item?.icon}
						<Typography className={styles.value} style={{ ['--textColor' as any] :  selected === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500 }}>{t(item?.label)}</Typography>
					</div>
				);
			}}
			onChange={handleChange}

			renderItem={(item: any) => {
				return(
					<div className="flexRow nowrap alignCenter gap8">
						{item?.icon}
						<Typography className={styles.value} style={{ ['--textColor' as any] :  item.value === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500 }} >{t(item?.label)}</Typography>
					</div>
				);
			}}
			className={styles.container}
			style={{ ['--iconColor' as any] : selected === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500 , ['--backgroudhover' as any] : selected === CARBON_INDICATOR ? siteColors.carbon100 : siteColors.water100 }}
			paddingRight={35}
			ExpandMoreRoundedIcon={ChevronDown}
			iconSize={16}
			iconColor={siteColors.grey700}
			MenuProps={{
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'right',
				},
				transformOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
				classes: {
					paper: styles.paper,
					list: styles.list,
				},
			}}
		/>
	);
};

export default SwitchButton;
