import { useEffect, useState } from 'react';

import _ from 'lodash';
import { BarChart2 } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useGetSupplierSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetSupplierSheetTimePeriod';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import IndicatorsBlockLight, { indicatorConfigItems } from '../../../../../../components/synthese/IndicatorsBlockLight';
import Card from '../../../../../../components/widget/Card';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../../lib/colors';
import SupplierModel from '../../../../../../models/Supplier.model';
import { useRseQFormTabQueryParam } from '../../../../general/tabs/rse/hooks/rse.hooks';
import { useFindOneSupplierRSERadarChartData } from '../../../hooks/supplier.hooks';
import { useFindSupplierQuestionFormsTabs } from '../../rse/hooks/rse.hooks';

const SupplierIndicatorsBlock = () => {
	const { indicator } = useApp();
	const { t } = useTranslation();
	const params = useParams();
	const [supplierObject, setSupplierObject] = useState<SupplierModel>();

	const { data } = useGetSupplierSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.supplierId!,
	});

	useEffect(() => {
		if (!data) return;
		const { supplier } = data;
		setSupplierObject(new SupplierModel(supplier));
	}, [data]);

	const [qFormId] = useRseQFormTabQueryParam();

	const { data: tabsData } = useFindSupplierQuestionFormsTabs({
		supplierId: _.toString(_.get(params, 'supplierId')),
	});

	useEffect(() => {
		if (!tabsData) {
			return;
		}

		const entries = _.entries(tabsData);
		const _qFormId = _.get(entries, '0.0');

		if (!_qFormId) {
			return;
		}
	}, [tabsData]);

	const { data: dataPerformanceRse } = useFindOneSupplierRSERadarChartData({
		supplierId: params.supplierId || '',
		questionFormId: _.toString(qFormId),
	});

	return (
		<Card
			title={
				indicatorConfigItems.get(indicator)?.indicatorTitle
					? t(indicatorConfigItems.get(indicator)?.indicatorTitle as any)
					: t('Indicateurs carbone')
			}
			icon={<BarChart2 color={siteColors.grey700} size={20} />}
			sx={{ padding: 0, marginBottom: pxToRem(40), flexWrap: 'nowrap', width: '100%' }}
			withBorder={false}
			center={false}
		>
			<IndicatorsBlockLight
				withPopover={false}
				indicator={indicator}
				indicatorDatas={{
					impact: {
						value: supplierObject?.getImpactByIndicator(indicator),
					},
					intensity: {
						value: supplierObject?.getIntensityByIndicator(indicator),
					},
					...((dataPerformanceRse?.notFound === false && !dataPerformanceRse?.note) || dataPerformanceRse?.note)
? {
						scoring: {
							value: !dataPerformanceRse?.notFound && !dataPerformanceRse?.note
								? 'waiting'
								: dataPerformanceRse?.note,
							withLabel: true,
						},
					}
: {},
				}}
			/>
		</Card>
	);
};

export default SupplierIndicatorsBlock;
