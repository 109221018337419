import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTheme } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { Moon, Trash2 } from 'lucide-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import { FRONT_PATH_NAMES, roleLabels } from '@carbonmaps/shared/utils/constants';
import { updateSupplierUserAction } from '@carbonmaps/ui/actions/user.actions';
import {
	deleteSelectedRowAction,
	sendEmailSelectedRowAction,
	updateEmailSelectedRowAction,
	updateRoleSelectedRowAction,
} from '@carbonmaps/ui/actions/userRow';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import EditableEmailInput from '@carbonmaps/ui/components/saas/Table/EditableEmailInput';
import RoleCellNew from '@carbonmaps/ui/components/saas/Table/RoleCellNew';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import UserRole from '@carbonmaps/ui/components/saas/Table/UserRole';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import FlashMessage from '../../../components/bo/FlashMessage';
import Dialog from '../../../components/dialogs/Dialog';
import LoadingDialog from '../../../components/dialogs/LoadingDialog';
import TableHeaderCell from '../../../components/table/TableHeaderCell';
import AlertStatic from '../../../components/Toast/AlertStatic';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { useTranslation } from '../../../hooks/useTranslation';
import { useLoginAsMutation } from '../../../lib/react-query/features/auth/auth.hooks';
import { useFindSuppliersListOptions } from '../../../lib/react-query/features/supplier/supplier.hooks';
import { FindUsersActionParams } from '../../../lib/react-query/features/user/user.actions';
import { useFindUsers } from '../../../lib/react-query/features/user/user.hooks';

import UserForm from './UserForm';

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
	};
};

type DataCell = { value: Record<string, any>; row: Record<string, any>; callback: (...params: any[]) => void };

type TableProps = {
	filterOptions?: any;
	manualSortBy?: boolean;
	resetPage?: number;
	onSelectedRow?: any; // use to update row selected in export data
	isCompanyArchived?: boolean;
};

const UsersTable = ({ filterOptions, manualSortBy, onSelectedRow, isCompanyArchived }: TableProps) => {
	const queryParams = useQueryParams();
	const companyId = queryParams?.companyId ? queryParams?.companyId[0] : '';

	// translation
	const { t, i18n } = useTranslation();
	const languageCode = getIsoCountryCode(i18n.language);
	const navigate = useNavigate();

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState<FindUsersActionParams>({
		input: '',
		page: 1,
		limit: 100,
		companyId,
		...filterOptions,
	});

	const [message, setMessage] = useState('');

	const queryClient = useQueryClient();
	const [openAlert, setOpenAlert] = useToggle(false);
	const [isDialogOpen, toggleDialog] = useToggle(false);
	// theme
	const theme = useTheme();
	// styles
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// --- ref for fetch data table
	const fetchIdRef = useRef(0);

	// ---- fetch data --- //
	const {
		result: { data: tableResultData, isLoading: isTableLoading },
		key: findUsersQueryKey,
	} = useFindUsers({
		params: { ...paramsTable, companyId },
	});

	// for list supplier
	const {
		result: { data: dataSuppliers },
	} = useFindSuppliersListOptions({
		paramsTable: {
			page: 1,
			size: 10_000,
			companyId,
		},
	});

	// state to force to first page
	const [resetPage, setResetPage] = useState(0);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- mutation login as ---- //
	const {
		result: { mutate: logInAs },
	} = useLoginAsMutation({
		options: {
			onSuccess() {
				navigate(FRONT_PATH_NAMES.home);
			},
		},
	});

	useEffect(() => {
		setTableParams((prev: any) => {
			return { ...prev, ...filterOptions };
		});
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterOptions.input]);

	// mutate action
	const confirmDelete = async (id: any) => {
		const key = '_User';

		if (key && id) {
			await deleteSelectedRow({ ids: [id], companyId } as any);
		}
	};

	const {
		mutate: deleteSelectedRow,
		isLoading: isLoadingDelete,
	} = useMutation({
		mutationKey: ['deleteSelectedRow'],
		mutationFn: deleteSelectedRowAction,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [findUsersQueryKey[0]] });
			setMessage('');
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const { mutate: updateRoleSelectedRow } = useMutation({
		mutationKey: ['updateRoleSelectedRow'],
		mutationFn: updateRoleSelectedRowAction,
		onSuccess: () => {
			return queryClient.invalidateQueries({ queryKey: [findUsersQueryKey[0]] });
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const updateRole = async (ev: any) => {
		const { key, user } = ev.currentTarget.dataset;

		if (key && user) {
			await updateRoleSelectedRow({ ids: [user], roleCode: key, companyId });
		}
	};

	const { mutate: updateEmailSelectedRow, isLoading: isLoadingEmail } = useMutation({
		mutationKey: ['updateEmailSelectedRow'],
		mutationFn: updateEmailSelectedRowAction,
		onSuccess: () => {
			return queryClient.invalidateQueries({ queryKey: [findUsersQueryKey[0]] });
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const {
		mutate: sendEmailSelectedRow,
		isLoading: isLoadingSendEmail,
	} = useMutation({
		mutationKey: ['sendEmailSelectedRow'],
		mutationFn: sendEmailSelectedRowAction,
		onSuccess: () => {
			return queryClient.invalidateQueries({ queryKey: ['users'] });
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const updateEmail = async (values: any) => {
		const { currentEmail, email, type, roleLabel, emailLanguageForContributor } = values;
		const companyId = searchParams.get('companyId') || '';

		let _emailLanguage = languageCode;

		if (_.isEqual(roleLabels.CONTRIBUTOR, roleLabel)) {
			_emailLanguage = emailLanguageForContributor;
		}

		switch (type) {
			case 'sendEmailNewAccount':
				return await sendEmailSelectedRow({ email: currentEmail, type, languageCode: _emailLanguage, roleLabel, companyId });
			case 'sendEmailNewEmail':
				return await sendEmailSelectedRow({
					email: currentEmail,
					newEmail: email,
					type,
					languageCode: _emailLanguage,
					roleLabel,
					companyId,
				});
			case 'changeEmail':
				return await updateEmailSelectedRow({ email: currentEmail, newEmail: email, type, languageCode: _emailLanguage });
		}

		return;
	};

	const [searchParams] = useSearchParams();

	const { mutate: updateSupplierUser, isLoading: isLoadingUpdateSupplier } = useMutation({
		mutationKey: ['updateSupplierUser'],
		mutationFn: updateSupplierUserAction,
		onSuccess: async () => {
			setMessage('Informations mises à jour');
			queryClient.invalidateQueries({ queryKey: ['findUserSuppliers'] });
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const handleClickEdit = useCallback((values: any) => {
		updateSupplierUser(values);
	}, []);

	// user to edit
	const [user, setUser] = useState<any>(null);

	const handleEditUser = (user: any) => {
		setUser(user);
		toggleDialog();
	};

	// ---- columns table definition ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('users')} />;
				},
				accessor: 'email',
				editable: true,
				sortDescFirst: true,
				Cell: ({ value, callback }: DataCell) => {
					return <EditableEmailInput value={value} callback={callback} fromBo={true} />;
				},
				props: {
					fromBo: true,
					onClick: handleEditUser,
				},
				type:'string',

			},

			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('role-label')} />;
				},

				accessor: 'role.label',
				editable: true,
				sortDescFirst: true,
				type: 'string',
				component: 'UserRole',
				props: {
					callback: handleEditUser,
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('teams')} />;
				},

				accessor: 'teamsOptions',
				editable: true,
				sortDescFirst: true,
				disableSortBy: true,
				component: 'Teams',
				props: {
					transFunc: t,
					callback: handleEditUser,
				},
				type:'string',
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('supplier-label')} />;
				},

				accessor: 'id',
				editable: true,
				sortDescFirst: true,
				component: 'EditSupplierUser',
				props: {
					t,
					supplierOptions: dataSuppliers,
					updateMyData: handleClickEdit,
					companyId,
					isLoading: isLoadingUpdateSupplier,
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('actions-column')} />;
				},
				accessor: 'objectId',
				editable: true,
				component: 'Action',
				type: 'action',
				icon: <Trash2 color={theme.palette.grey[700]} />,
				withConfirm: true,
				callback: (value: any) => {
					confirmDelete(value);
				},
				callbackLoginAs: (value: any) => {
					if (isCompanyArchived) {
						setOpenAlert();
						return;
					}

					logInAs({ userId: value || '', companyId: searchParams.get('companyId') || '' });
				},
				callbackEdit: (value: any) => {
					handleEditUser(value);
				},
				disableSortBy: true,
			},
		];
	}, [theme, dataSuppliers]);

	// ---- skeleton ---- //
	const { skeleton } = useSkeleton({ condition: isTableLoading || isLoadingEmail || isLoadingSendEmail });


	return (
		<>
			<div>
				<FlashMessage open={openAlert} onClose={setOpenAlert} icon={<Moon />} message={t('disabled-message-company')} />
			</div>
			<TableComponent
				tableName={'user'}
				skeleton={skeleton}
				loading={false}
				fetchData={updateTableParams}
				pageCount={_.get(tableResultData, 'meta.last_page', 0)}
				noDataMessage={t('empty-data-users-table')}
				pageSize={_.get(paramsTable, 'size', 100)}
				columns={tableColumns}
				resetPage={resetPage}
				data={_.get(tableResultData, 'users', []) as never}
				setSelectedRow={onSelectedRow}
				onSortingColumn={() => {
					return 1;
				}}
				addStyles={stylesTable}
				manualSortBy={manualSortBy}
				updateRole={updateRole}
				updateEmail={updateEmail}
				confirmDelete={confirmDelete}
				onEditRow={handleEditUser}
			/>
			<LoadingDialog open={isLoadingDelete} content={`${t('Suppression en cours')}...`} />
			{message ? <AlertStatic icon={undefined} type={'error'} text={message} /> : null}

			<Dialog
				title={''}
				isLoading={false}
				description={''}
				open={isDialogOpen}
				toggle={toggleDialog}
				closeButton={true}
				withCancelButton={false}
				isDrawer={true}
			>
				<UserForm toggle={toggleDialog} user={user} />
			</Dialog>
		</>
	);
};

export default UsersTable;
