import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

// Define auto-close routes for the sidebar
export const AUTO_CLOSE_SIDEBAR_ROUTES: (string | RegExp)[] = [
  // Page d'édition de simulation (match par pattern)
  // Exemple: /simulation/123456
  new RegExp(`^${FRONT_PATH_NAMES.simulation}/[^/]+$`),
  // Pages de devis carbone (match par pattern)
  // Exemple: /quote/123456
  new RegExp(`^${FRONT_PATH_NAMES.quote}/[^/]+$`),
  // Page de gestion de l'utilisateur (match par pattern)
  // Exemple: /manager-settings/users
  new RegExp(`^${FRONT_PATH_NAMES.userSettings}/[^/]+$`),
  // Page de gestion des paramètres de l'entreprise (match par pattern)
  // Exemple: /manager-settings/config
  new RegExp(`^${FRONT_PATH_NAMES.managerSettings}/[^/]+$`),

];

type SidebarContextType = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  requestCloseSidebar: () => void;
  releaseCloseSidebar: () => void;
  isManualMode: boolean;
  transitionKey: number;
};

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export const SidebarProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isManualMode, setIsManualMode] = useState(false);
  const [transitionKey, setTransitionKey] = useState(0);
  const location = useLocation();
  const closeRequestsRef = useRef(0);

  // Reset tout au changement de page
  useEffect(() => {
    setIsManualMode(false);
    closeRequestsRef.current = 0;
    setIsOpen(true);
  }, [location]);

  // Fonction de base pour changer l'état avec animation
  const updateIsOpen = useCallback((newIsOpen: boolean, manual = false) => {
    if (manual) {
      setIsManualMode(true);
    }

    setTransitionKey(prev => {return prev + 1;});
    setIsOpen(newIsOpen);
  }, []);

  const requestCloseSidebar = useCallback(() => {
    closeRequestsRef.current += 1;

    if (!isManualMode) {
      updateIsOpen(false);
    }
  }, [isManualMode, updateIsOpen]);

  const releaseCloseSidebar = useCallback(() => {
    closeRequestsRef.current = Math.max(0, closeRequestsRef.current - 1);

    if (!isManualMode && closeRequestsRef.current === 0) {
      updateIsOpen(true);
    }
  }, [isManualMode, updateIsOpen]);

  const handleSetIsOpen = useCallback((newIsOpen: boolean) => {
    updateIsOpen(newIsOpen, true);
  }, [updateIsOpen]);

  const value = useMemo(() => {return {
    isOpen,
    setIsOpen: handleSetIsOpen,
    requestCloseSidebar,
    releaseCloseSidebar,
    isManualMode,
    transitionKey,
  };}, [isOpen, handleSetIsOpen, requestCloseSidebar, releaseCloseSidebar, isManualMode, transitionKey]);

  return (
    <SidebarContext.Provider value={value}>
      {children}
    </SidebarContext.Provider>
  );
};

export function useSidebar() {
  const context = useContext(SidebarContext);

  if (context === undefined) {
    throw new Error('useSidebar must be used within a SidebarProvider');
  }

  return context;
}
