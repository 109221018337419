import { ComponentProps, useMemo } from 'react';

import { css } from '@emotion/css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { ShoppingCart } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { useGetProductSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import BlockCard from '../../../components/BlockCard';
import BlockTitle from '../../../components/BlockTitle';
import Skeleton from '../../../components/Skeleton';
import Unit from '../../../components/units/Unit';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';

const styles = {
	skeleton: css({
		width: '100% !important',
		height: '120px !important',
	}),
};

const ProductQuantityBlock = () => {
	const theme = useTheme();
	const params = useParams();
	const { t } = useTranslation();

	const { data, isLoading } = useGetProductSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.productId!,
		//period: 2023,
	});
	const formedData = useMemo(() => {
		return [
			{
				label: 'Quantité vendue',
				value: `${formatNumber(data?.product?.soldItems, undefined, 0)}`,
				isNegativeNumber: data?.product?.soldItems ? data?.product?.soldItems < 0 : true,
				unity: <Unit measure="unity" fontWeight={400} fontSize={'0.875rem'} color={siteColors.text} />,
			},
			{
				label: 'Tonnage',
				value: `${formatNumber(data?.product?.tonnage, undefined, 1)}`,
				isNegativeNumber: data?.product?.tonnage ? data?.product?.tonnage < 0 : true,
				unity: <Unit measure="massTon" fontWeight={400} fontSize={'0.875rem'} color={siteColors.text} />,
			},
			{
				label: 'Poids brut unitaire',
				value: `${formatNumber(data?.product?.grossWeight, undefined, 1)}`,
				isNegativeNumber: data?.product?.grossWeight ? data?.product?.grossWeight < 0 : true,
				unity: <Unit measure="grammes" fontWeight={400} fontSize={'0.875rem'} color={siteColors.text} />,
			},
			{
				label: 'Poids net unitaire',
				value: `${formatNumber(data?.product?.netWeight, undefined, 1)}`,
				isNegativeNumber: data?.product?.netWeight ? data?.product?.netWeight < 0 : true,
				unity: <Unit measure="grammes" fontWeight={400} fontSize={'0.875rem'} color={siteColors.text} />,
			},
		];
	}, [data, t]);

	return (
		<BlockCard>
			{isLoading
? (
				<Skeleton className={styles.skeleton} />
			)
: (
				<div>
					<BlockTitle icon={<ShoppingCart size={20} color={theme.palette.grey[700]} />}>
						{t('product-quantity-card-title')}
					</BlockTitle>
					{formedData.map(({ label, value, unity, isNegativeNumber }, index) => {
						return (
							<Box key={index} sx={{ '&:not(:last-child)': { marginBottom: '8px' } }}>
								<Box display="flex" alignItems="center" justifyContent="space-between">
									<Typography mr="6px" color={theme.palette.grey[800]} fontSize={'14px'} fontWeight={600}>
										{t(label as any)}
									</Typography>
									<Typography mr="6px" className="number">
										{value} {unity}
									</Typography>
								</Box>
							</Box>
						);
					})}
				</div>
			)}
		</BlockCard>
	);
};

export default ProductQuantityBlock;
