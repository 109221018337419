import { useCallback } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { AlertCircle, Moon } from 'lucide-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import LogoutIcon from '@carbonmaps/media/icons/log-out-blue.svg?react';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { LogInInput, logInSchema } from '@carbonmaps/shared/validations/auth.validations';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BCheckbox from '@carbonmaps/ui/components/saas/BCheckbox';
import BInput from '@carbonmaps/ui/components/saas/BInput';

import AlertStatic from '../../components/Toast/AlertStatic';
import useHasRoles from '../../hooks/useHasRoles';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useQueryParams } from '../../hooks/useSearchParams';
import { useTranslation } from '../../hooks/useTranslation';
import { useLogInMutation } from '../../lib/react-query/features/auth/auth.hooks';

import SSOForm from './sso/SSOForm';

const LogInForm = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const hasRoles = useHasRoles();
	// ---- company selected ---- //
	const queryParams = useQueryParams();

	const isLogout = Boolean(queryParams?.isLogout);

	const isFromLogin = _.isArray(queryParams?.isFromLogin)
		? queryParams?.isFromLogin[0]
		: (queryParams?.isFromLogin as any);

	const varEmail = queryParams?.email;

	// translation
	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<LogInInput>({
		resolver: zodResolver(logInSchema),
	});


		const analytics = useJuneTrack();
		const trackEvent = useCallback(
			(name: string, options: any) => {
				if (!analytics) return;
				analytics.track(name, options, {
					context: { category: 'Analyse' },
				});
			},
			[analytics],
		);

	const {
		result: { isError, error, isLoading, mutate: logIn },
	} = useLogInMutation({
		onSuccess(data, variables) {
			trackEvent('Login successful', {
				description: 'Connexion',
				key: variables?.email,
			});

			if (hasRoles(['super_admin'])) {
				navigate(FRONT_PATH_NAMES.chooseCompany);
				return;
			}

			navigate(`${FRONT_PATH_NAMES.superAdminSettings}?isFromLogin=${!!data}`);
		},
		onError(error, variables) {
			trackEvent('Login failed', {
				description: 'Validation du formulaire en erreur',
				key: variables?.email,
			});
		},
	});

	const onSubmit: SubmitHandler<LogInInput> = async (values) => {
		logIn(values);
	};

	const getTranslatedError = (error: any) => {
		if (error?.message === 'nocompany') {
			return t('login-no-orgs');
		}

		if (_.startsWith(error?.message, 'Your account is locked')) {
			return t('account-locked-message');
		}

		if (error?.message === 'Invalid username/password.') {
			return t('login-incorrect-values');
		}

		return t('an-error-occurred');
		// (error as any)?.message === 'nocompany'
		// 						? t('login-no-orgs')
		// 						: t('login-incorrect-values')
	};

	return (
		<Box px={0} py={2} className="flexColumn" css={{ gap: 16 }}>
			{' '}
			<Typography
				variant="inherit"
				css={{
					fontWeight: 400,
					fontSize: 24,
				}}
			>
				{t('login-form-title-long')}
			</Typography>
			<form css={{ display: 'flex', flexDirection: 'column', gap: 20, width: '100%' }}>
				{isError && (
					<AlertStatic
						text={
							getTranslatedError(error)
						}
						// text={error?.message}
						type="error"
						icon={<AlertCircle css={{ color: '#C74600' }} />}
					/>
				)}
				{isLogout && <AlertStatic text={t('login-form-description')} type="info" icon={<LogoutIcon />} />}

				{(isFromLogin === 'true' || isFromLogin === true) && (
					<AlertStatic text={t('disabled-message-company')} type="info" icon={<Moon />} />
				)}

				{varEmail && (
					<Box sx={{ maxWidth: '400px' }}>
						<AlertStatic text={t('varEmail-exists', { varEmail })} type="info" />
					</Box>
				)}

				<BInput
					required
					id="email"
					label={t('input-mail-label')}
					fullWidth
					margin="dense"
					{...register('email')}
					name="email"
					error={errors.email ? true : false}
					errorMessage={t(errors?.email?.message)}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							handleSubmit(onSubmit)();
						}
					}}
				/>
				<BInput
					required
					id="password"
					label={t('input-password-label')}
					type="password"
					fullWidth
					margin="dense"
					{...register('password')}
					name="password"
					error={errors.password ? true : false}
					errorMessage={t(errors?.password?.message)}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							handleSubmit(onSubmit)();
						}
					}}
				/>
				<Box
					css={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					<BCheckbox
						id="remember"
						label={t('remember-button')}

						name="remember"
						control={<Checkbox defaultChecked={true} />}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
								handleSubmit(onSubmit)();
							}
						}}
					/>
					<Link
						// to='#'
						to="/changer-mot-de-passe"
						css={{
							textDecoration: 'none',
							color: theme.palette.primary.main,
							fontWeight: 600,
						}}
					>
						{t('change-password-link')} ?
					</Link>
				</Box>
			</form>
			<Box>
				<BButton
					id="login-btn"
					variant="primary"
					label={
						isLoading ? <CircularProgress size={20} css={{ color: theme.palette.common.white }} /> : t('login-button')
					}
					onClick={handleSubmit(onSubmit)}
					sx={{ minWidth: '400px' }}
				/>
			</Box>
			<SSOForm />
		</Box>
	);
};

export default LogInForm;
