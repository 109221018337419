import LogoutIcon from '@carbonmaps/media/icons/log-out-blue.svg?react';
import { Box, Typography } from "@mui/material";
import { AlertCircle } from 'lucide-react';
import AlertStatic from "../../components/Toast/AlertStatic";
import { useTranslation } from "../../hooks/useTranslation";


const ResendEmailConfirmation = () => {
	// translation
	const { t } = useTranslation();

	return (
		<div css={{ width: '100%' }}>
			<Box px={0} py={2} className="flexColumn" css={{ gap: 16 }}>

				<Typography
					variant="inherit"
					css={{
						fontWeight: 400,
						fontSize: 24,
					}}
				>
					{t('resend-email-notify-invalid-link-title')}
				</Typography>

				<AlertStatic text={t('resend-email-notify-invalid-link-desc')} type="info"
				//  icon={<LogoutIcon />}
				/>
			</Box></div>
	);
};

export default ResendEmailConfirmation;
