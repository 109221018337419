import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';
import { getCompareResultSupplierByPeriodAction, getSuppliersToCompareAction } from '@carbonmaps/ui/actions/supplier.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useCompareProduct } from '../../hooks/useCompareProduct';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';
import { ProductRowData } from '../quoteSheet/_/ProductsTableSelect';

export const useSearchSuppliers = ({ sorting, enabled }: { sorting?: any; enabled: boolean; }) => {
	const { selectedYear } = useYearSelection();
	const { searchParams } = useCompareProduct();
	const { facetFilters, input } = searchParams;
	const { indicator } = useApp();

	const result = useInfiniteQuery({
		queryKey: [
			'findSuppliersInfinite',
			{
				sorting, //refetch when sorting changes
				input,
				facetFilters,
				viewMode: indicator,
				selectedYear,
			},
		] as const,
		queryFn: async ({ pageParam = 0, queryKey }) => {
			const { input, facetFilters, viewMode, selectedYear } = queryKey[1];
			const data = await Parse.Cloud.run(functionName.suppliers.searchSuppliers,
				{ pageParam,
					period: selectedYear,
					input, facetFilters,
					sorting,
					viewMode,
				}) as {
				products: ProductRowData[];
				meta: {
					totalCount: number;
					totalPages: number;
					currentPage: number;
				};
			};
			return data;
		},

		// options
		enabled,

		keepPreviousData: true,
		getNextPageParam: (lastPage, allPages) => {
			const nextPageCursor = lastPage.meta.currentPage + 1;

			if (nextPageCursor < lastPage.meta.totalPages) {
				return nextPageCursor;
			}

			return null;
		},
		getPreviousPageParam: (firstPage, allPages) => {
			const previousPageCursor = firstPage.meta.currentPage - 1;

			if (previousPageCursor < 0) {
				return null;
			}

			return previousPageCursor;
		},
	});

	return result;
};


export const useSuppliersToCompare = () => {
	const [searchQueryParams] = useSearchQueryParams();
	const { selectedYear } = useYearSelection();
	const { products } = useCompareProduct();

	console.log('products', products);


	const queryResult = useQuery({
		queryKey: [
			'getSuppliersToCompare',
			{
				productIds: searchQueryParams?.productToCompare?.trim().split(',') || [],
				period: selectedYear,
				products: products?.map((p: any) => {return p.objectId;}),
			},
		],
		queryFn: getSuppliersToCompareAction,
	});

	return queryResult;
};

export const useSupplierComparePeriod = () => {
	const { products } = useCompareProduct();
	const queryResult = useQuery({
		queryKey: [
			'getCompareResultSupplierByPeriod',
			{
				productUids: products?.map((p: any) => {return p.uid;}),

			},
		],
		queryFn: getCompareResultSupplierByPeriodAction,
	});

	return queryResult;
};
