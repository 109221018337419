import { useCallback, useMemo, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { alpha } from '@mui/system/colorManipulator';
import { useQuery } from '@tanstack/react-query';

import { CARBON_INDICATOR, WATER_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { getTemporalityLifeChartAction } from '@carbonmaps/ui/actions/dashboard.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Skeleton from '../../components/Skeleton';
import LifeCycleChart from '../../components/charts/LifeCycleChart';
import LifeCycleChartTooltip from '../../components/charts/LifeCycleChartTooltip';
import ProductCount from '../../components/dashboard/temporality/ProductCount';
import TotalImpact from '../../components/dashboard/temporality/TotalImpact';
import DashboardGraphTitle from '../../components/dashboard/v2/DashboardGraphTitle';
import Unit from '../../components/units/Unit';
import { useGlossary } from '../../hooks/useGlossary';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';
import { useSize } from '../../hooks/useSize';
import { useTeam } from '../../hooks/useTeam';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';

import EmptyImpact from './EmptyImpact';

const styles = {
	container: css({
		padding: 24,
		borderRadius: '16px',
		border: `1px solid ${siteColors.grey500}`,
	}),
	title: css({
		marginBottom: '24px !important',
	}),
	divTitle: css({
		display: 'inline-block',
		cursor: 'pointer',
	}),

	skeleton: css({
		marginTop: 24,
		height: '175px !important',
		width: '100% !important',
	} as any),
	noDataContainer: css({
		display: 'flex',
		flexDirection: 'column',
	}),
	axisContainer: css({
		paddingTop: 10,
	}),
};

const DashboardLifeCycleGraph = ({
	showTotalImpact = true,
	title,
	heightGraph = 131,
	bottomPosition = 35,
	iconSize = 20,
}: {
	showTotalImpact?: boolean;
	title?: string;
	heightGraph?: number;
	bottomPosition?: number;
	iconSize?: number;
}) => {
	const theme = useTheme();
	const { indicator: viewMode } = useApp();

	const { selectedYear } = useYearSelection();

	// team
	const { team } = useTeam();

	const [searchQueryParams] = useSearchQueryParams();

	// translation
	const { t } = useTranslation();
	// const { g } = useGlossary();
	const containerRef = useRef(null);
	const size = useSize(containerRef);

	// ---- fetch data graph for this period selected ---- //
	const { data: indicator, isLoading } = useQuery({
		queryKey: [
			'getTemporalityLifeChart',
			{
				facetFilters: searchQueryParams?.facetFilters || [],
				period: selectedYear,
				team: team,
			},
		],
		queryFn: getTemporalityLifeChartAction,
	});

	const svgParentSize = useMemo(() => {
		if (!size)
			return {
				width: 730,
				height: 230,
			};

		// const height = (size as any)?.height > 195 ? 195 : (size as any)?.height;
		const height = heightGraph || 220;

		return {
			width: (size as any)?.width || 730,
			height,
		};
	}, [size, heightGraph]);

	// get the values in percentage
	const getGESInPercent = useCallback(() => {
		if (!indicator) {
			return Array.from<{ value: number; link?: string }>({ length: 6 }).fill({ value: 0, link: '#' });
		}

		const input = [
			// indicator.sumGesIngredients,
			{
				value: indicator.gesAgricultureImpactTotal,
				tooltip: (
					<LifeCycleChartTooltip
						value={indicator?.gesAgricultureImpactTotal}
						label={t('agriculture')}
						unit={<Unit measure="carbonImpactTon" />}
						precision={0}
					/>
				),
			},
			{
				value: indicator.gesTransformationImpactTotal,
				tooltip: (
					<LifeCycleChartTooltip
						value={indicator?.gesTransformationImpactTotal}
						label={t('transformation')}
						unit={<Unit measure="carbonImpactTon" />}
						precision={0}
					/>
				),
			},
			{
				value: indicator.gesPackagingImpactTotal,
				tooltip: (
					<LifeCycleChartTooltip
						value={indicator?.gesPackagingImpactTotal}
						label={t('emballage')}
						unit={<Unit measure="carbonImpactTon" />}
						precision={0}
					/>
				),
			},
			{
				value: indicator.gesTransportImpactTotal,
				tooltip: (
					<LifeCycleChartTooltip
						value={indicator?.gesTransportImpactTotal}
						label={t('transport')}
						unit={<Unit measure="carbonImpactTon" />}
						precision={0}
					/>
				),
			},
			{
				value: indicator.gesDistributionImpactTotal,
				tooltip: (
					<LifeCycleChartTooltip
						value={indicator?.gesDistributionImpactTotal}
						label={t('distribution')}
						unit={<Unit measure="carbonImpactTon" />}
						precision={0}
					/>
				),
			},
			{
				value: indicator.gesConsumptionImpactTotal,
				tooltip: (
					<LifeCycleChartTooltip
						value={indicator?.gesConsumptionImpactTotal}
						label={t('consommation')}
						unit={<Unit measure="carbonImpactTon" />}
						precision={0}
					/>
				),
			},
		];

		return input.map((e) => {
			return {
				...e,
				value: (e.value * 100) / indicator.carbonImpactTotal,
			};
		});
	}, [indicator, t]);

	const getWaterUseInPercent = useCallback(() => {
		if (!indicator || !indicator?.waterImpactTotal)
			return Array.from<{ value: number; link?: string }>({ length: 6 }).fill({ value: 0, link: '#' });

		const input = [
			// indicator.sumGesIngredients,
			{
				value: indicator?.waterUseAgricultureImpactTotal,
				tooltip: (
					<LifeCycleChartTooltip
						value={indicator?.waterUseAgricultureImpactTotal * 1000}
						label={t('agriculture')}
						unit={<Unit measure="waterImpact" />}
						precision={0}
					/>
				),
			},
			{
				value: indicator.waterUseTransformationImpactTotal,
				tooltip: (
					<LifeCycleChartTooltip
						value={indicator?.waterUseTransformationImpactTotal * 1000}
						label={t('transformation')}
						unit={<Unit measure="waterImpact" />}
						precision={0}
					/>
				),
			},
			{
				value: indicator.waterUsePackagingImpactTotal,
				tooltip: (
					<LifeCycleChartTooltip
						value={indicator?.waterUsePackagingImpactTotal * 1000}
						label={t('emballage')}
						unit={<Unit measure="waterImpact" />}
						precision={0}
					/>
				),
			},
			{
				value: indicator.waterUseTransportImpactTotal,
				tooltip: (
					<LifeCycleChartTooltip
						value={indicator?.waterUseTransportImpactTotal * 1000}
						label={t('transport')}
						unit={<Unit measure="waterImpact" />}
						precision={0}
					/>
				),
			},
			{
				value: indicator.waterUseDistributionImpactTotal,
				tooltip: (
					<LifeCycleChartTooltip
						value={indicator?.waterUseDistributionImpactTotal * 1000}
						label={t('distribution')}
						unit={<Unit measure="waterImpact" />}
						precision={0}
					/>
				),
			},
			{
				value: indicator.waterUseConsumptionImpactTotal,
				tooltip: (
					<LifeCycleChartTooltip
						value={indicator?.waterUseConsumptionImpactTotal * 1000}
						label={t('consommation')}
						unit={<Unit measure="waterImpact" />}
						precision={0}
					/>
				),
			},
		];

		return input.map((e) => {
			return {
				...e,
				value: (e.value * 100 * 1000) / indicator.waterImpactTotal,
				// a: e.value,
				// b: indicator.waterImpactTotal,
			};
		});
	}, [indicator, t]);

	return (
		<>
			<div
				className={cx(
					'flex1 ',
					styles.container,
					!isLoading && indicator && !indicator[`${viewMode}ImpactTotal`] ? 'flexColumn gap16' : '',
				)}
				ref={containerRef}
			>
				<div className="flexColumn width100 gap4">
					<DashboardGraphTitle
						title={title || t('Impact total de vos produits')}
						measure={viewMode === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'}
						rightContent={<ProductCount />}
					/>
					{showTotalImpact ? <TotalImpact /> : null}
				</div>

				{isLoading ?
				(
					<Skeleton width="100%!important" className={styles.skeleton}></Skeleton>
				) :
				 !indicator[`${viewMode}ImpactTotal`] ?
				 (
					<EmptyImpact text={t('volume-empty-text', { period: selectedYear || t('selectedYear') })} />
				) :
				 (
					<LifeCycleChart
						datas={viewMode === WATER_INDICATOR ? getWaterUseInPercent() : getGESInPercent()}
						viewBox={`0 0 ${svgParentSize.width} ${svgParentSize.height}`}
						withLabel={false}
						iconColor={siteColors.grey700}
						colors={
							viewMode === WATER_INDICATOR
								? {
										main: siteColors.water500,
										line: siteColors.water100,
										polygonGradient: [
											siteColors.water100,
											siteColors.water100,
											// alpha(siteColors.water500, 0.7),
											// alpha(siteColors.water500, 0.6),
										],
								  }
								: {
										main: siteColors.carbon500,
										line: siteColors.carbon100,
										polygonGradient: [siteColors.carbon100, siteColors.carbon100],
								  }
						}
						areaHeight={svgParentSize.height}
						areaWidth={svgParentSize.width}
						iconSize={iconSize}
						labelColor={viewMode === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500}
						bottomPosition={bottomPosition}
						strokeWidth={1.5}
						lineWidth={2}
						/*	fontStyles={{
								title: 20,
								subtitle: 16,
							}}*/
						fontStyles={{
							title: 12,
							subtitle: 12,
						}}
						topValueContainer={{
							width: 70,
							//	height: 40,
							height: 30,
						}}
						axisClassName={styles.axisContainer}
					/>
				)}
			</div>
		</>
	);
};

export default DashboardLifeCycleGraph;
