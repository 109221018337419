/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import { nanoid } from 'nanoid';

import {
	ECO_CONCEVABLE_OPTION_LABEL,
	FILTER_NAME_ECO_CONCEVABLE,
	FOLDER_NAME_ECO_CONCEVABLE,
	INDEX_NAME_FACETS,
	INDEX_NAME_SEARCH,
	ITEM_TYPE_INGREDIENT,
	ITEM_TYPE_PRODUCT,
	TAG_ADVANCED_OPTION_LABEL,
	TYPE_MODELIZATION_INGREDIENT,
} from './constants';

export const FACETS_CONFIG_MAP = new Map();

export const FACETS_CONFIG = [
	{
		field: 'typeModelizationIngredient',
		label: 'Modélisation recette',
		type: 'string',
		global: true,
		itemType: ITEM_TYPE_PRODUCT,
		collection: 'Product',
	},
	{
		field: 'tagAdvancedModelization',
		label: 'acv-ingredient',
		type: 'string',
		global: true,
		itemType: ITEM_TYPE_PRODUCT,
		collection: 'Product',
	},
	{
		field: 'type_error',
		label: 'Types d’erreur',
		type: 'string',
		width: 310,
	},
	{
		field: 'tagAdvanced',
		label: 'acv-ingredient',
		global: true,
		type: 'string',
		itemType: ITEM_TYPE_INGREDIENT,
		collection: 'Ingredient',
	},
];

FACETS_CONFIG.forEach((item: any) => {
	FACETS_CONFIG_MAP.set(`${item.field}${item?.companyCode || ''}`, item);
});

export function getConfigFacets(company?: any, withGlobal = true, collection = 'Product') {
	// console.log('==========withGlobal============', withGlobal);
	let facetsGlobal: any = [];
	let facetsCompany = [];

	const facet = (company?.facets || [])?.filter((f: any) => {
		return f.collection?.includes(collection) && f.indexName === `facets_${company?.code}`;
	});

	//show only field authorized
	if (facet?.length) {
		facetsCompany = facet[0]?.fields?.filter((f: any) => {
			return f.isVisibleInFront === true;
		});
	}

	// add with global with facet global
	if (withGlobal === true) {
		facetsGlobal =
			FACETS_CONFIG.filter((f: any) => {
				return f?.collection?.includes(collection);
			}) || [];
	}

	return _.compact(_.concat(facetsGlobal, facetsCompany));
}

export const getFacetType = (path: string, config: any) => {
	const item = config.find((item: { field: string; type: string }) => {
		return item.field === path;
	});
	return item?.type || 'string';
};

export const getFacetLabel = (key: string, company: any, collections =  ['Product', 'Ingredient', 'Supplier']) => {
	if (key === FILTER_NAME_ECO_CONCEVABLE) {
		return FOLDER_NAME_ECO_CONCEVABLE;
	}


	// const configItem = getFacetConfig(key, company, collection);
	const confItems = collections.map((collection) => {
		return getFacetConfig(key, company, collection);
	});

	const configItem = confItems.filter(Boolean)[0];
	return configItem ? configItem?.label : key;
};

export const getFacetConfig = (key: string, company: any, collection = 'Product') => {
	const facets = getConfigFacets(company, false, collection);
	const item = facets.find((f: any) => {
		return f.field === key;
	});

	// find in global
	if (!item) return FACETS_CONFIG_MAP.get(key);

	return {
		...item,
		itemType: collection.toLowerCase(),
	};
};

export const getFacetConfigIngredient = (key: string, company: any, collection = 'Ingredient') => {
	const facets = getConfigFacets(company, false, collection);
	const item = facets.find((f: any) => {
		return f.field === key;
	});

	// find in global
	if (!item) return FACETS_CONFIG_MAP.get(key);

	return {
		...item,
		itemType: collection.toLowerCase(),
	};
};

export const getLabelValue = (key: string, bucketId: any, t?: Function) => {
	if (key === 'typeModelizationIngredient') {
		return (TYPE_MODELIZATION_INGREDIENT as any)[String(bucketId).toLowerCase()];
	}

	if (['tagAdvanced', 'tagAdvancedModelization'].includes(key)) {
		const iKey = String(bucketId).toLowerCase();

		return (TAG_ADVANCED_OPTION_LABEL as any)[iKey];
	}

	if (key === FILTER_NAME_ECO_CONCEVABLE) {
		return (ECO_CONCEVABLE_OPTION_LABEL as any)[String(bucketId).toLowerCase()];
	}

	if (key === 'status_contact') {
		return String(bucketId);
	}

	return String(bucketId);
};

export const getIndexNameFacet = (companyCode: any, type: 'search' | 'facets') => {
	if (type === INDEX_NAME_SEARCH) {
		return `${INDEX_NAME_SEARCH}_${companyCode}`;
	}

	return `${INDEX_NAME_FACETS}_${companyCode}`;
};

export const median = (numbers: any, withSort = true) => {
	const sorted = withSort
		? (Array.from(numbers).sort((a: any, b: any) => {
				return a - b;
		  }) as any)
		: numbers;
	const middle = Math.floor(sorted.length / 2);

	if (sorted.length % 2 === 0) {
		return (sorted[middle - 1] + sorted[middle]) / 2;
	}

	return sorted[middle];
};

/**
 *
 * @param data
 * @param value
 * @returns
 */
export const in_array = (data: any, value: any) => {
	return data?.includes(value);
};

export const calculateQ1 = (data: any) => {
	return quartile(data, 0.25);
};

export const calculateQ3 = (data: any) => {
	return quartile(data, 0.75);
};

export const calculateMedian = (data: any) => {
	return quartile(data, 0.5);
};

export const quartile = (arrays: any, quartilePercent: number) => {
	const sortedArray = Array.from(arrays).sort((a: any, b: any) => {
		return a - b;
	}) as any;

	const pos = (sortedArray.length - 1) * quartilePercent;

	const base = Math.floor(pos);
	const rest = pos - base;

	if (sortedArray[base + 1]) {
		return sortedArray[base] + rest * (sortedArray[base + 1] - sortedArray[base]);
	} else {
		return sortedArray[base];
	}
};

/**
 * formatUrl
 * @param url
 * @param search
 * @param replace
 * @returns
 */
export const formatUrl = (url?: string, search = '/', replace = '__') => {
	if (!url) return url;
	const regex = new RegExp(search, 'g');
	return url.replace(regex, replace);
};

export const parseUrl = (url?: string, search = '__', replace = '/') => {
	if (!url) return url;
	const regex = new RegExp(search, 'g');
	return url.replace(regex, replace);
};

export const isWhitespace = (str: string) => {
	return /^\s*$/.test(str);
};

/**
 *
 * @returns
 */
export const uuidUniq = () => {
	return Date.now().toString(16) + Math.random().toString(16).substr(2);
};

export const getUserFullName = (person: Record<string, any>): string => {
	const lastName = _.trim(_.get(person, 'lastName', ''));
	const firstName = _.trim(_.get(person, 'firstName', ''));
	const name = _.trim(`${firstName} ${lastName}`);
	return name;
};


// simple Validate emails
export const isValidEmail = (email: string): boolean => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
};


export const formatDataSupplier = (data: Record<string, any>[]) => {
	const res = _.groupBy(data, (e) => {
		return e.id;
	});

	const _data: any[] = [];

	_.entries(res).forEach((entry) => {
		const [id, contacts] = entry;

		const first = contacts[0];

		const supplierName = _.get(first, 'supplierName');
		const supplierUid = _.get(first, 'supplierUid');

		const item = {
			id,
			supplierName,
			supplierUid,
			subRows: contacts.map((c) => {
				return {
					...c,
					id: nanoid(10),
				};
			}) as never,
		};

		_data.push(item);
	});

	return _data;
};

export const sleep = <T = unknown>(timeout: number, value?: T) => {
	return new Promise<T>((resolve) => {
		return setTimeout(() => {
			resolve(value as never);
		}, timeout);
	});
};
