import { ComponentProps, useCallback, useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import { CircularProgress } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { eachOfLimit } from 'async';
import { useForm } from 'react-hook-form';

import { getFacetConfig, getFacetLabel, getLabelValue } from '@carbonmaps/shared/utils/utils';
import { TeamFormInput, teamFormValidationSchema } from '@carbonmaps/shared/validations/teamForm.validations';
import { getCategoriesProduct } from '@carbonmaps/ui/actions/product.actions';
import { saveTeamAction } from '@carbonmaps/ui/actions/team.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import Filter, { IFilterFolder } from '../../components/Filter';
import { Option } from '../../components/form/fields/MultiSelectCheckboxField';
import Typography from '../../components/Typography';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';


const styles = {
	button: css	({
		opacity: 1,
	'&.Mui-disabled': {
		opacity: 0.5,
	},

	}),
	loading: css({
		color: `${siteColors.common.white} !important`,
	}),
};

export type Team = {
	name: string;
	filters: Array<{
		path: string,
		values: Array<string>,
	}>
	objectId: string;
	description?: string;
	users?: Array<{
		email: string;
		firstName: string;
		lastName: string;
		objectId: string;
	}>;
}

type TeamFormProps = {
	onClose: () => void;
	team?: Team
	isEdit?: boolean;
}

const TeamForm = ({ team, onClose, isEdit }: TeamFormProps) => {

	const { t } = useTranslation();

	const [appliedValuesByFolders, setAppliedValuesByFolders] = useState<IFilterFolder[]>([]);
	const [selectedValues, setSelectedValues] = useState<Option[]>([]);
	const [filterFolders, setFilterFolders] = useState<IFilterFolder[]>([]);

	const { selectedYear } = useYearSelection();

	const [filters, setFilters] = useState<any>();

	// ------------------------------------- //
	// ----------- fetch facets ------------ //
	// ------------------------------------- //
	const { data: facets, isLoading: isFacetsLoading } = useQuery({
		queryKey: [
			'getCategory',
			{
				facetFilters: [],
				period: selectedYear,
				withGlobal: false,
			},
		],
		queryFn: getCategoriesProduct,
	});

	// -------------------------------------------- //
	// --------------- user connected info -------- //
	// -------------------------------------------- //
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// ---------------------------------------------- //
	// --------------- set filter folders ----------- //
	// ---------------------------------------------- //
	useEffect(() => {
		const asyncWrapper = async () => {
			// don't change current filter
			if (!facets) return;
			const otherFacetFilterFolders: IFilterFolder[] = [];

			for await (const entry of Object.entries(facets.facet)) {
				const [key, value] = entry;
				const { buckets } = value as any;
				const { label, type, global, itemType } = getFacetConfig(key, authData?.session.company) ?? {};

				const facetLabel = label || getFacetLabel(key, authData?.session.company);
				const currentFacetFolder: IFilterFolder = {
					label: facetLabel,
					options: [],
					isFacet: true,
					path: key,
					showCount: true,
					global,
				};

				await eachOfLimit(buckets as any[], 100, async (bucket, k) => {


					currentFacetFolder.options.push({
						value: bucket._id,
						label: getLabelValue(key, bucket._id),
						labelNode: undefined,
						count: bucket.count,
						folderLabel: facetLabel,
						isFacetBucket: true,
						facetPath: key,
						type: type ?? 'hardcoded-undefined-facet-type',
						global,
						itemType,
					});
					return;
				});
				otherFacetFilterFolders.push(currentFacetFolder);

			}

			setFilterFolders((folders) => {
				const notFacetFolders = folders.filter((e) => {
					return !e.isFacet;
				});

				return [...notFacetFolders, ...otherFacetFilterFolders];
			});
		};

		asyncWrapper();
	}, [facets, authData?.session.company, t]);

	// ------------------------------------------------------- //
	// ---------------- confirm filter trigger --------------- //
	// ------------------------------------------------------- //
	const onTriggerFilter: ComponentProps<typeof Filter>['onTriggerFilter'] = useCallback(
		({ allFoldersSelectedValues }) => {
			const _facetFiltersMap = new Map();

			//  format facet filters
			for (const e of allFoldersSelectedValues) {
				if (e.isFacetBucket) {
					const inMapFacetFilter = _facetFiltersMap.get(e.facetPath);

					if (!inMapFacetFilter) {
						_facetFiltersMap.set(e.facetPath, {
							path: e.facetPath,
							type: e.type ,
							values: [ e.value ],
							global: false,
							itemType: e.itemType,
							folderLabel: e.folderLabel,
						});
						continue;
					}

					inMapFacetFilter.values.push(e.value);
					continue;
				}
			}

			// set applied values
			setFilters((oldValue: any) => {
				return {
					...oldValue,
					// trackingFilters: [],
					facetFilters: [..._facetFiltersMap.values()],
				};
			});
	}, []);


		useEffect(() => {
			const facetFilters = filters?.facetFilters ?? [];


			//	if there are filters in the url
			if ((facetFilters && facetFilters.length)) {
				const currentSelectedValues = [] as any;
				const currentAppliedValuesFolders = [] as any;

				// for facet
				if (facetFilters && facetFilters.length) {
					facetFilters.forEach((item: any) => {
						const arrayValues = item.values.map((v: any) => {
							const value = {
								facetPath: item.path,
								value: v,
								folderLabel:
								item?.folderLabel ?? item.path,
								label: getLabelValue(item.path, v),
								isFacetBucket: true,
								type: typeof v,
								global: item.global === true ? true : false,
								itemType: item?.itemType?.toLowerCase() || 'product',
							};

							currentSelectedValues.push(value);
							return value;
						});

						currentAppliedValuesFolders.push({
							isFacet: true,
							label:
								t(item?.folderLabel, {
									products: t('products'),
									recipe: t('recipe'),
									recipe_lowercase: toLowerCase(t('recipe')),
									ingredient: t('ingredient'),
									an_ingredient: t('an_ingredient'),
									ingredient_lowercase: toLowerCase(t('ingredient')),
									product: t('product'),
									of_product: t('of_product'),
									ingredients: t('ingredients'),
								}) ?? item.path,
							options: arrayValues,
							showCount: true,
						});
					});
				}

				setSelectedValues(currentSelectedValues);
				setAppliedValuesByFolders(currentAppliedValuesFolders);
			}
		}, [filters, t]);

	// -------------------------------------------- //
	// ------------ init react hooks form --------- //
	// -------------------------------------------- //
	const {
			register,
			handleSubmit,
			formState: { errors },
		} = useForm<TeamFormInput>({
			resolver: zodResolver(teamFormValidationSchema),
			defaultValues: async () => {
				return {
					name: team?.name || '',
					description: team?.description || '',
				};
			},
			mode: 'onChange',
		});


	// -------------------------------------------- //
	// --------------- save team ------------------ //
	// -------------------------------------------- //

	// react query mutation to save team
	const { mutate: saveTeam, isLoading } = useMutation({
    mutationKey: ['saveTeam'], // This mutation is used to save a team by sending a message
    mutationFn: saveTeamAction,
    onSuccess: async () => {
			// Marks the operation as successful upon completion
			onClose();
    },
    onError: (error: any) => {
			/* Handle error here */
    },
});

// init event June
const analytics = useJuneTrack();
const trackEvent = useCallback(
	(evenName: string, options?: any) => {
		if (!analytics) return;
		analytics.track(evenName, options, {
			context: { category: 'Manager User' },
		});
	},
	[analytics],
);

	// handle submit
	const handleSavedButton = (data: any) => {
		// save team
		saveTeam({
			...data,
			filters: filters.facetFilters,
			id: team?.objectId,
		});

		// add event tracking for new team
		if (!team?.objectId) {
			trackEvent('New Team Created');
		}
	};

	// ------------------------------------------ //
	// ----------- values filter when edit ------ //
	// ------------------------------------------ //

	useEffect(() => {
		if (!team) return;

		setFilters({ facetFilters: team.filters });

	}, [team]);

	return (
		<div className="flexColumn gap16 width100">
			<form className="flexColumn gap16 width100">
				{ !isEdit && <BInput
					required
					id="name"
					{...register('name')}
					label={t('team')}
					error={errors.name ? true : false}
					errorMessage={t(errors?.name?.message)}
					fullWidth
					margin="dense"
					name="name"
					className= "width100"
				/> }
				<BInput
					id="description"
					label="Description"
					fullWidth
					margin="dense"
					{...register('description')}
					name="description"
					autoComplete="off"
					isTextarea={true}
					placeholder={t('Réponse')}
					className={'width100'}
				/>
				<div className="flexColumn width100 gap12">
					<Typography>{t('filtrage')}</Typography>
					<div className="flexRow gap12 alignCenter">
						<Filter
							skeleton={isFacetsLoading}
							appliedValuesByFolders={appliedValuesByFolders}
							setAppliedValuesByFolders={setAppliedValuesByFolders}
							selectedValues={selectedValues}
							setSelectedValues={setSelectedValues}
							isLoading={isFacetsLoading}
							folders={filterFolders}
							onTriggerFilter={onTriggerFilter}
							showCount={false}
							label={t('add-filter')}
							isMultiple={false}
							renderValue={(item: Option ) => {return <Typography>{t('filter-text', {
								category: item.folderLabel,
								value: item.value,
							})} </Typography>;}}
						/>
					</div>
				</div>
				<BButton
					onClick={handleSubmit(handleSavedButton)}
					variant="primary"
					label={isLoading
						? <CircularProgress size={20} className={styles.loading} /> :
								<Typography color="white" fontWeight={600}>
									{t('save-button')}
								</Typography>
							}
					isDisabled={!filters?.facetFilters?.length || isLoading}
					className= {styles.button}
				/>
			</form>
		</div>
	);



};

export default TeamForm;
