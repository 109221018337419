
import { Calendar, PackageOpen, Wheat } from 'lucide-react';

import PerKiloIcon from '@carbonmaps/media/icons/perKiloChart.svg?react';
import { CARBON_INDICATOR, WATER_INDICATOR } from '@carbonmaps/shared/utils/constants';


import Unit from '../components/units/Unit';
import Agriculture from '../containers/products/compareProducts/content/Agriculture';
import Packaging from '../containers/products/compareProducts/content/Packaging';
import PerKiloContent from '../containers/products/compareProducts/content/PerKiloContent';
import PerPeriodContent from '../containers/products/compareProducts/content/PerPeriodContent';
import SupplierPerKiloContent from '../containers/supplier/general/tabs/comparison/SupplierPerKiloContent';
import SupplierPerPeriodContent from '../containers/supplier/general/tabs/comparison/SupplierPerPeriodContent';

import { AGRICULTURE, PACKAGING, PER_KILO_TYPE, PER_PERIOD_TYPE } from './constants';

// ------------------------------------------------ //
// ------------ available compare types ----------- //
// ------------------------------------------------ //
const perKiloItemConfig =
{
	value: PER_KILO_TYPE,
	label: 'Par kilo',
	icon: <PerKiloIcon />,
	element : PerKiloContent,
};

const perPeriodItemConfig =
{
	value: 'per_period',
	label: 'Par period',
	icon: <Calendar size={16} />,
	element : PerPeriodContent,
};

const agricultureItemConfig ={
	value: AGRICULTURE,
	label: 'Agriculture',
	icon: <Wheat size={16} />,
	element : Agriculture,
};
const packagingItemConfig = {
	value: PACKAGING,
	label: 'Emballage',
	icon: <PackageOpen size={16}/>,
	element : Packaging,
};

// options for compare type
export const compareTypeOptions = [
	{
		title: 'Impact',
		items: [
			perKiloItemConfig,
			perPeriodItemConfig,
		],
	},
	{
		title: 'Analyse du cycle de vie',
		items: [
			agricultureItemConfig,
			packagingItemConfig,
		],
	},

];

const perKiloItemConfigSupplier =
{
	value: PER_KILO_TYPE,
	label: 'Par kilo',
	icon: <PerKiloIcon />,
	element : SupplierPerKiloContent,
};

const perPeriodItemConfigSupplier =
{
	value: 'per_period',
	label: 'Par period',
	icon: <Calendar size={16} />,
	element : SupplierPerPeriodContent,
};

export const suppliersTypeOptions = [
	{
		title: 'Impact',
		items: [
			perKiloItemConfigSupplier,
			perPeriodItemConfigSupplier,
		],
	},
];

export const supplierCompareContentMap = new Map();
supplierCompareContentMap.set(PER_KILO_TYPE, perKiloItemConfigSupplier);
supplierCompareContentMap.set(PER_PERIOD_TYPE, perPeriodItemConfigSupplier);

// config for content
export const compareContentMap = new Map();
compareContentMap.set(PER_KILO_TYPE, perKiloItemConfig);
compareContentMap.set(PER_PERIOD_TYPE, perPeriodItemConfig);
compareContentMap.set(AGRICULTURE, agricultureItemConfig);
compareContentMap.set(PACKAGING, packagingItemConfig);

// ----------------------------------------------------- //
// -------------- compare product config --------------- //
// ----------------------------------------------------- //

export const compareProductConfig = new Map();

compareProductConfig.set(PER_KILO_TYPE, {
	[CARBON_INDICATOR]: {
		field: 'carbonIntensity',
		unit: <Unit measure="carbonIntensityKg" className="grey7"/>,
		precision: 2,
	},
	[WATER_INDICATOR]: {
		field: 'waterIntensity',
		unit: <Unit measure="waterIntensity" className="grey7"/>,
		precision: 2,
	},
});

compareProductConfig.set(PER_PERIOD_TYPE, {
	[CARBON_INDICATOR]: {
		field: 'carbonImpact',
		unit: <Unit measure="carbonImpactTon" className="grey7"/>,
		precision: 0,
	},
	[WATER_INDICATOR]: {
		field: 'waterImpact',
		unit: <Unit measure="waterImpact" className="grey7"/>,
		precision: 0,
	},
});

compareProductConfig.set(AGRICULTURE, {
	[CARBON_INDICATOR]: {
		field: 'carbonIntensity',
		fieldStep: 'gesAgriculture',
		unit: <Unit measure="carbonIntensityKg" className="grey7"/>,
		precision: 2,
	},
	[WATER_INDICATOR]: {
		field: 'waterIntensity',
		fieldStep: 'waterUseAgriculture',
		unit: <Unit measure="waterIntensity" className="grey7"/>,
		precision: 2,
	},
});

compareProductConfig.set(PACKAGING, {
	[CARBON_INDICATOR]: {
		field: 'carbonIntensity',
		fieldStep: 'gesPackaging',
		unit: <Unit measure="carbonIntensityKg" className="grey7"/>,
		precision: 2,
	},
	[WATER_INDICATOR]: {
		field: 'waterIntensity',
		fieldStep: 'waterUsePackaging',
		unit: <Unit measure="waterIntensity" className="grey7"/>,
		precision: 2,
	},
});
