import { css } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import SupplierIcon from '@carbonmaps/media/icons/supplier.svg?react';
import { supplierCompanyCount } from '@carbonmaps/ui/actions/supplier.actions';

import IconContainer from '../../../../components/layout/list/IconContainer';
import ListHeader from '../../../../components/layout/list/ListHeader';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useTeam } from '../../../../hooks/useTeam';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import SupplierCompareButton from '../tabs/comparison/SupplierCompareButton';

const styles = {
	button: css({
		'&.MuiButtonBase-root': {
			background: `${siteColors.grey4} !important`,
			borderColor:`${siteColors.grey4} !important`,
			'&:hover': {
				background: `${siteColors.grey5} !important`,
				borderColor: siteColors.grey5,
			},
		},
	}),
};

const SuppliersGeneralHeader = () => {
	const theme = useTheme();
	const { selectedYear } = useYearSelection();
	const { team } = useTeam();

	// translation
	const { t } = useTranslation();
	// ---- fetch count product ---- //
	// get count product for a company. this count will appear in header list
	const { data: supplierCount } = useQuery({
		queryKey: ['getSupplierCompanyCount', { selectedYear, team }],
		queryFn: supplierCompanyCount,
	});

	return (
		<ListHeader
			title={t('suppliers')}
			subtitle={`${supplierCount ?? ''} ${t('suppliers')}`}
			icon={
				<IconContainer
					element={<SupplierIcon color={theme.palette.common.white} width={32} height={32} />}
					color={siteColors.blueGray500}
				/>
			}
			rightElement={<SupplierCompareButton title={t('Comparer les fournisseurs')} className={styles.button} />}
		/>
	);
};

export default SuppliersGeneralHeader;
