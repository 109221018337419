import { useEffect } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { applyDeleteAction } from '@carbonmaps/ui/actions/version.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';

const DeleteConsoleUpdate = () => {
	const queryClient = useQueryClient();

	// ---- apply version ---- //
	const { mutate: applyDelete } = useMutation({
		mutationKey: ['applyDelete'],
		mutationFn: applyDeleteAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getVersionData'] });
		},
		onError: (error: any) => {
			console.log('error', error);
		},
	});

	const [searchParams] = useSearchParams();

	const {
		data,
		isLoading,
		refetch: fetchVersionData,
	} = useQuery({
		queryKey: [
			'getDeleteStatus',
			{
				companyId: _.toString(searchParams.get('companyId')),
			},
		] as const,
		queryFn: async (ctx) => {
			try {
				const { companyId } = ctx.queryKey[1];
				const res = await await Parse.Cloud.run('getDeleteStatus', { companyId });
				return res;
			} catch (error) {
				console.log('error', error);
			}
		},
	});

	// UNDO HERE
	useEffect(() => {
		const interval = setInterval(() => {
			const func = async () => {
				fetchVersionData?.();
			};

			func();
		}, 4 * 1000);

		return () => {
			clearInterval(interval);
		};
	}, [fetchVersionData]);

	if (isLoading) {
		return <h3>Loading...</h3>;
	}

	return (
		<div
			css={{
				marginTop: 32,
			}}
		>
			<div className="flexRow" css={{ gap: 48, flexWrap: 'nowrap' }}>
				{/* <h2>Console de suppression des anciens updates</h2> */}
				<div>
					<BButton
						isDisabled={!_.get(data, 'canDelete', false)}
						label={!_.get(data, 'canDelete', false) ? 'Aucun update à supprimer' : 'Supprimer les anciens updates'}
						variant={!_.get(data, 'canDelete', false) ? 'secondary' : 'primary'}
						onClick={() => {
							applyDelete({ companyId: _.toString(searchParams.get('companyId')) });
						}}
					/>
				</div>
				<div css={{ flexGrow: 1, width: '55%' }}>
					<h4 css={{ marginTop: 12 }}>STATUS: {_.toUpper(String(_.get(data, 'status')))}</h4>
					<div>{JSON.stringify(_.get(data, 'logs', {}), null)}</div>
				</div>
			</div>
		</div>
	);
};

export default DeleteConsoleUpdate;
