import { css, cx } from '@emotion/css';
import _ from 'lodash';

import IngredientIcon from '@carbonmaps/media/icons/ingredient-chart-icon.svg?react';
import ProductIcon from '@carbonmaps/media/icons/product-chart-icon.svg?react';
import SupplierIcon from '@carbonmaps/media/icons/supplier-chart-icon-blue.svg?react';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import Supplier from '@carbonmaps/ui/components/saas/Table/Supplier';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import ChartContainer from '../../../components/charts/ChartContainer';
import IndicatorGroupGraph from '../../../components/charts/IndicatorGroupGraph';
import { useTranslation } from '../../../hooks/useTranslation';
import IngredientModel from '../../../models/Ingredient.model';
import SupplierModel from '../../../models/Supplier.model';
import { orderBy } from '../../../utils/array.utils';
import { useDataProducts } from '../analyse.hooks';

import IngredientDonut from './breakdownIndicator/IngredientDonut';
import ProductDonut from './breakdownIndicator/ProductDonut';
import SupplierDonut from './breakdownIndicator/SupplierDonut';
import AnalysisDonutContent from './component/graph/AnalysisDonutContent';
import TooltipProductChart from './component/graph/TooltipProductChart';

const formatDataChart = (data: any, t: any, category = 'products', viewMode = 'carbon', limit = 20) => {
	const sortingData = orderBy(data, viewMode === 'carbon' ? 'carbonImpact' : 'waterImpact', 'desc');

	const firstData = sortingData.slice(0, limit) as any;
	const othersData = sortingData?.length > limit ? sortingData.slice(limit + 1, sortingData?.length) : [];

	if (othersData.length) {
		const carbonImpact = _.sumBy(othersData, 'carbonImpact');
		const carbonImpactPercent = _.sumBy(othersData, 'carbonImpactPercent');
		const waterImpact = _.sumBy(othersData, 'waterImpact');
		const waterImpactPercent = _.sumBy(othersData, 'waterImpact');

		const soldItems = _.sumBy(othersData, 'soldItems');
		const volume = _.sumBy(othersData, 'volume');
		const netWeight = _.sumBy(othersData, 'netWeight');
		const volumeMass = _.sumBy(othersData, 'volumeMass');

		const otherElement = {
			category: t(category),
			label: t('Autres'),
			carbon: carbonImpact,
			carbonImpactPercent,
			water: waterImpact,
			waterImpactPercent,
			volume,
			soldItems,
			netWeight,
			volumeMass,
			y_carbon: carbonImpact,
			y_water: waterImpact,
			isOther: true,
		};

		firstData.push(otherElement);
	}

	return firstData;
};

const styles = {
	container: css({ gap: '32px' }),
	innerContainer: css({ gap: '24px' }),
	skeleton: css({
		width: '100% !important',
		height: '250px !important',
	}),
};

const BreakDownIndicator = () => {
	const { t } = useTranslation();
	const { viewMode, indicator: indicatorSelected } = useApp();

	// const { data, isLoading } = useDataProducts({});

	return (
		<ChartContainer showToggle={false} title={t('indicator-bloc-title')}>
			<div className={cx('flexRow alignCenter justifyCenter nowrap', styles.container)}>
				<ProductDonut />
				<IngredientDonut />
				<SupplierDonut />
			</div>
		</ChartContainer>
	);
};

export default BreakDownIndicator;
