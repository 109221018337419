import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { HelpCircle, Hourglass } from 'lucide-react';

import { COLOR_SCORING } from '@carbonmaps/shared/utils/scoring';
import BPopover from '@carbonmaps/ui/components/saas/BPopover';

import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import PopoverItem from '../table/popover/PopoverItem';

type SupplierScoreProps = {
	score: string;
	// color: string;
	width?: number;
	height?: number;
	radius?: number;
	fontSize?: number;
	withInfoIcon?: boolean;
	iconSize?: number;
	awaitingResponse?: boolean;
};

const styles = {
	container: css({
		display: 'flex',
		padding: '6px 7.5px 6px 6px',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '6px',
		border: '0.75px solid rgba(0, 0, 0, 0.10)',
		background: 'var(--backgroundColor)',
		color: '#FFF',
		fontWeight: 700,
		textAlign: 'center',
	}),
	a: css({
		padding: '18px',
		maxWidth: '300px',
	}),
};

const SupplierScore = ({
	score,
	width = 36,
	height = 24,
	radius = 18,
	fontSize = 11.25,
	withInfoIcon = false,
	iconSize = 18,
	awaitingResponse = false,
}: SupplierScoreProps) => {
	const color = (COLOR_SCORING as any)[score] || siteColors.grey400;
	const { t } = useTranslation();

	let content: any = score;

	if (awaitingResponse) {
		content = (
			<BPopover
				anchorOriginHorizontal="center"
				anchorOriginVertical="top"
				transformOriginHorizontal="center"
				transformOriginVertical="bottom"
					modeHover
					content={() => {
						return (
							<div className={cx(styles.a)}>
								<PopoverItem value={t('awaiting-response')} label={undefined} />
							</div>
						);
					}}
				>
							<Hourglass size={iconSize} color={siteColors.grey700} />
				</BPopover>
		);
	}

	if (withInfoIcon) {
		if (!score || !['a+', 'a', 'b', 'c', 'd', 'e'].includes(_.toLower(score))) {
			content = (
				<BPopover
				anchorOriginHorizontal="center"
				anchorOriginVertical="top"
				transformOriginHorizontal="center"
				transformOriginVertical="bottom"
					modeHover
					content={() => {
						return (
							<div className={cx(styles.a)}>
								<PopoverItem value={t('no-rse-note')} label={undefined} />
							</div>
						);
					}}
				>
					<HelpCircle size={iconSize} color={siteColors.grey700} />
				</BPopover>
			);
		}
	}

	return (
		<div
			className={cx(styles.container)}
			style={{ ['--backgroundColor' as any]: color, width, height, borderRadius: radius, fontSize }}
		>
			{content}
		</div>
	);
};

export default SupplierScore;
