//--------------------------------------------------------------------------------------//
//                                    css utilities                                     //
//--------------------------------------------------------------------------------------//
export const remToPx = (value: number) => {
	return Math.round(parseFloat(String(value)) * 16);
};

export const pxToRem = (value: number) => {
	return `${value / 16}rem`;
};

export const important = (value: string) => {
	return `${value}!important`;
};
