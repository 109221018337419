import { FC, ReactNode, isValidElement } from 'react';

import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { HelpCircle, Minus } from 'lucide-react';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { Indicator } from '@carbonmaps/ui/contexts/AppProvider';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import SoldItemsPopover from '../table/popover/SoldItemsPopover';

export type IndicatorSingleCardProps = {
	indicator: Indicator;
	icon: ReactNode | FC;
	title: string;
	value?: string | number;
	unity?: ReactNode | string;
	indicatorType?: 'carbon' | 'intensity';
	noValue?: any;
	isUndefinedValue?: boolean;
	showHelpNoValue?: boolean;
};

const styles = {
	content: css({
		gap: '8px',
		justifyContent: 'center',
		alignItems: 'center!important',
	}) as any,
	subContent: css({
		gap: '7px',
		alignItems: 'center!important',
	}) as any,
	value: css({
		gap: pxToRem(8),
		alignItems: 'flex-end',
	}) as any,
	icon: css({
		height: '30px',
	}) as any,
};

const IndicatorSingleCard = ({
	title,
	value,
	unity,
	indicator = 'carbon',
	indicatorType,
	icon: _icon,
	noValue,
	isUndefinedValue = false,
	showHelpNoValue = false,
}: IndicatorSingleCardProps) => {
	const theme = useTheme();

	// translation

	const { t } = useTranslation();

	return (
		<div className={cx('flexColumn', styles.content)}>
			<Typography
				fontWeight={600}
				fontSize={pxToRem(14)}
				lineHeight={1}
				sx={{ textTransform: 'none', whiteSpace: 'noWrap', color: siteColors.grey800 }}
			>
				{t(title)}
			</Typography>
			{showHelpNoValue ?
				<HelpCircle size={40} color={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500} />
				:
				<div className={cx('flexRow nowrap', styles.subContent)}>
					<div className={cx(styles.icon)}>
						{isValidElement(_icon) ?
							(
								_icon
							) :
							(
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								// eslint-disable-next-line react/jsx-pascal-case
								<_icon
									color={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500}
									size={30}
									fontWeight={400}
									strokeWidth={1.5}
								/>
							)}
					</div>
					<div className={cx('flexRow', styles.value)}>
						{isUndefinedValue === true && noValue
							? (
								noValue
							)
							: typeof value === 'number' && value <= 0
								? (
									indicatorType === 'carbon'
										? (
											<SoldItemsPopover
												value={value}
												color={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500}
												sx={{ padding: '0px !important' }}
											/>
										)
										: (
											noValue ?? <Minus color={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500} />
										)
								)
								: (
									<>
										<Typography
											fontWeight={500}
											fontSize={pxToRem(24)}
											color={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500}
											lineHeight={1}
											className="kpi-value" // don't remove this class because it is used in tests
										>
											{value === null || !value || value === ''
												? (
													<Minus color={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500} />
												)
												: (value)}
										</Typography>
									</>
								)}
					</div>
				</div>
			}
			{!showHelpNoValue && (
				<Typography
					fontWeight={400}
					fontSize={pxToRem(12)}
					sx={{
						whiteSpace: 'noWrap',
						color: siteColors.grey700,
						marginTop: '-8px',
						textTransform: 'none',
					}}
				>
					{unity}
				</Typography>
			)}
		</div>
	);
};

export default IndicatorSingleCard;
