import { type QueryFunctionContext } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';


export async function findReportsClientTableAction(context: any) {
	try {
		const {
			queryKey: { 1: params },
		} = context;
		const result = await Parse.Cloud.run(functionName.temporality.findReportsClient, { ...params });
		return result;
	} catch (error) {
		console.log('--------- findReportsClientTableAction error: ', error);
		return Promise.reject(error);
	}
}


export async function findQuoteReportsClientTableAction(context: any) {
	try {
		const {
			queryKey: { 1: params },
		} = context;
		const result = await Parse.Cloud.run(functionName.quote.findQuoteReportsClient, { ...params });
		return result;
	} catch (error) {
		console.log('--------- findQuoteReportsClientTableAction error: ', error);
		return Promise.reject(error);
	}
}

export async function findQuoteReportsClientGraphAction(context: any) {
	try {
		const {
			queryKey: { 1: params },
		} = context;
		const result = await Parse.Cloud.run(functionName.quote.findQuoteReportsClientGraph, { ...params });
		return result;
	} catch (error) {
		console.log('--------- findQuoteReportsClientGraphAction error: ', error);
		return Promise.reject(error);
	}
}



export async function getCountReportsClientAction(context: any) {
	try {
		const {
			queryKey: { 1: params },
		} = context;

		const result = await Parse.Cloud.run(functionName.temporality.countReportsClient, { ...params });
		return result;
	} catch (error) {
		console.log('--------- findReportsClientTableAction error: ', error);
		return Promise.reject(error);
	}
}
