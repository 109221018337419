import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import Checkbox from '@mui/material/Checkbox';
import _ from 'lodash';
import { FileDown } from 'lucide-react';

import BCheckbox from '@carbonmaps/ui/components/saas/BCheckbox';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import Dialog from '../dialogs/Dialog';

import AuditButton from './AuditButton';

type AuditExportModalProps = {
	title: any;
	open: boolean;
	isLoading: boolean;
	errorTypeOptions: any;
	onExportError: (a?: any) => void;
	onClose: () => void;
	data: any;
	selectedRow: any;
};

const styles = {
	actionsContainer: css({
		padding: '0px 24px',
	}),

	button: css({
		gap: 8,
	}),

	infoContent: css({
		paddingTop: '16px',
		fontSize: '16px !important',
	}),
	checkbox: css({
		border: cn('1px solid', siteColors.grey500),
		background: siteColors.common.white,
		paddingLeft: 16,
	}),
	disabledButton: css({
		pointerEvents: 'none',
		opacity: '0.8',
	}),
};

const AuditExportModal = ({
	title,
	open,
	isLoading,
	errorTypeOptions,
	data,
	selectedRow,
	onExportError,
	onClose,
}: AuditExportModalProps) => {
	const [selectedValue, setSelectedValue] = useState<any>([]);

	// translation
	const { t } = useTranslation();

	const handleChange = (v: any, isChecked: boolean) => {
		setSelectedValue((prev: any) => {
			return isChecked
				? [...prev, v]
				: prev.filter((o: any) => {
						return o !== v;
				  });
		});
	};

	useEffect(() => {
		if (!errorTypeOptions) return;

		setSelectedValue(
			errorTypeOptions.map((item: any) => {
				return item?.value;
			}),
		);
	}, [errorTypeOptions]);

	return (
		<Dialog
			title={title}
			open={open}
			toggle={onClose}
			isLoading={isLoading}
			actions={
				<div className={styles.actionsContainer}>
					<AuditButton
						text={
							<div className={cx('flexRow alignCenter', styles.button)}>
								{t('upload-button')}
								<FileDown size={20} />
							</div>
						}
						className={!selectedValue?.length ? styles.disabledButton : ''}
						onClick={() => {
							return onExportError(selectedValue);
						}}
					/>
				</div>
			}
		>
			{(errorTypeOptions || []).map((item: any, key: number) => {
				return (
					<BCheckbox
						checked={selectedValue.includes(item?.value)}
						onChange={(e: any) => {
							handleChange(item.value, e?.target?.checked);
						}}
						label={t(item?.label)}
						control={<Checkbox />}
						className={styles.checkbox}
						key={key}
					/>
				);
			})}

			<div
				dangerouslySetInnerHTML={{
					__html: t('correct-error-description', {
						productCount: data,
						products: toLowerCase(t('products')),
					}),
				}}
				className={styles.infoContent}
			/>
		</Dialog>
	);
};

export default AuditExportModal;
