import { useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { AUTO_CLOSE_SIDEBAR_ROUTES } from '../layouts/_common/SidebarContext';

type AutoCloseSidebarOptions = {
  /**
   * Liste des routes où la sidebar doit être fermée automatiquement
   * Peut être un string exact ou une expression régulière
   * Si non fourni, utilise les routes par défaut définies dans SidebarContext
   */
  autoCloseRoutes?: (string | RegExp)[];
};

/**
 * Hook pour gérer automatiquement l'ouverture/fermeture de la sidebar selon la route
 *
 * @param options Configuration du hook (optionnel)
 * @returns Objet contenant l'état de la sidebar et la fonction pour la manipuler
 */
export const useAutoCloseSidebar = (options?: AutoCloseSidebarOptions) => {
  // État d'ouverture de la sidebar
  const [isOpen, setIsOpen] = useState(true);

  // Route actuelle
  const { pathname } = useLocation();

  // Utiliser les routes fournies ou celles par défaut
  const routes = options?.autoCloseRoutes || AUTO_CLOSE_SIDEBAR_ROUTES;

  // Référence pour stocker le dernier chemin visité
  const prevPathRef = useRef<string | null>(null);

  // Référence pour savoir si la sidebar a été fermée automatiquement
  const wasAutoClosedRef = useRef<boolean>(false);

  // Vérifie si une route doit fermer la sidebar
  const shouldCloseForRoute = (path: string): boolean => {
    return routes.some(route => {
      if (route instanceof RegExp) {
        return route.test(path);
      }

      return path === route;
    });
  };

  // Effet pour gérer l'ouverture/fermeture automatique
  useEffect(() => {
    const isAutoCloseRoute = shouldCloseForRoute(pathname);
    const wasPreviousAutoCloseRoute = prevPathRef.current
      ? shouldCloseForRoute(prevPathRef.current)
      : false;

    // Uniquement fermer la sidebar automatiquement si nous arrivons sur une nouvelle route
    // et non pas quand l'utilisateur interagit manuellement avec la sidebar
    if (isAutoCloseRoute && isOpen && prevPathRef.current !== pathname) {
      // On entre sur une page qui doit fermer la sidebar
      setIsOpen(false);
      wasAutoClosedRef.current = true;
    } else if (!isAutoCloseRoute && !isOpen && wasAutoClosedRef.current && wasPreviousAutoCloseRoute) {
      // On quitte une page qui avait fermé la sidebar automatiquement
      setIsOpen(true);
      wasAutoClosedRef.current = false;
    }

    // Mettre à jour la référence du chemin précédent
    prevPathRef.current = pathname;
  }, [pathname, isOpen, routes]);

  // Fonction pour manipuler la sidebar manuellement
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    // Réinitialiser le flag si l'utilisateur manipule manuellement la sidebar
    wasAutoClosedRef.current = false;
  };

  return {
    isOpen,
    toggleSidebar,
  };
};
