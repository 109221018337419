import MetaDataBlock from '../synthesis/MetaDataBlock';
import ProductCalculationsBlock from '../synthesis/ProductCalculsBlock';
import CategoriesBlock from '../synthesis/ProductCategoriesBlock';
import ProductQuantityBlock from '../synthesis/ProductQuantityBlock';

const ProductDataDetails = () => {
	return (
		<div
			css={{
				display: 'grid',
				gridTemplateColumns: '1fr 1fr',
				gap: 32,
			}}
		>
			<div>
				<CategoriesBlock />
				<ProductQuantityBlock />
				<MetaDataBlock />
			</div>
			<div>
				<ProductCalculationsBlock />
			</div>
		</div>
	);
};

export default ProductDataDetails;
