import { Fragment, ReactNode, SyntheticEvent, useEffect } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import defaultLogo from '@carbonmaps/media/images/default-logo.png';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import AvatarContent from '@carbonmaps/ui/components/saas/AvatarContent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import TabsBar, { TabItem } from '../../components/TabsBar';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import { useGetImgProxy } from '../../lib/react-query/features/img/img.hooks';
import Page404 from '../404/Page404';
import Teams from '../team/Teams';

import Config from './Config';
import FormCompany from './FormCompany';
import UsersList from './UsersList';

// Layout constants
const LAYOUT_MAX_WIDTH = 1200;

type ITab = {
	title: string;
	element: ReactNode;
};

const tabs: Record<string, ITab> = {
	entreprise: {
		title: 'company-tab',
		element: <FormCompany />,
	},
	utilisateurs: {
		title: 'user-tab',
		element: <UsersList />,
	},
	team: {
		title: 'team-tab',
		element: <Teams />,
	},
	config: {
		title: 'settings',
		element: <Config />,
	},
};

const ManagerSettings = () => {
	const navigate = useNavigate();
	const params = useParams();
	const { setBreadcrumbs } = useApp();
	// const [devInProgress, setDevInProgress] = useState(false);
	const location = useLocation();

	// translation
	const { t } = useTranslation();

	useEffect(() => {
		if (!params.tab) {
			setBreadcrumbs([
				{
					text: t('leave-settings'),
				},
			]);
			return;
		}

		setBreadcrumbs([
			{
				text: t('leave-settings'),
				link: FRONT_PATH_NAMES.home,
			},
		]);
	}, [params.tab, setBreadcrumbs, t]);

	const {
		result: { data: authData, isLoading: isGetClientAuthLoading },
	} = useGetClientAuthQuery();

	const {
		result: { data: logoUrl, isLoading: isGetCompanyLogoUrlLoading },
	} = useGetImgProxy({
		params: { filename: authData?.session.company?.logo || '' },
	});

	const handleTabChange = (_: SyntheticEvent, value: any) => {
		navigate(`${FRONT_PATH_NAMES.managerSettings}/${value}`);
	};

	if (!params.tab) {
		return <Navigate replace state={{ from: location }} to={`${FRONT_PATH_NAMES.managerSettings}/entreprise`} />;
	}

	if (!Object.keys(tabs).includes(params.tab)) {
		return <Page404 />;
	}

	return (
		<div>
			<Box sx={{
				margin: '0 auto',
				maxWidth: LAYOUT_MAX_WIDTH,
				px: 3,
				border: `1px solid ${siteColors.grey500}`,
				padding: '24px',
				borderRadius: '16px',
				mt: '24px',
			}}>
				<div
					className="flexRow alignCenter width100"
					css={{
						margin: '0 auto 16px',
					}}
				>
					<AvatarContent
						url={authData?.session.company?.logo ? logoUrl : defaultLogo}
						addStyle={{
							width: 48,
							height: 48,
							borderRadius: 4,
						}}
					>
						<div css={{ maxWidth: 350 }}>
							{isGetClientAuthLoading
	? (
								<Fragment>
									<Skeleton variant="rounded" width="68px" height="24px" sx={{ mb: '2px' }} />
									<Skeleton variant="rounded" width="100px" height="16px" />
								</Fragment>
							)
	: (
								<Fragment>
									<p
										css={{
											fontSize: 14,
											color: siteColors.grey700,
											margin: 0,
											mb: '4px',
										}}
									>
										{t('organization-settings')}
									</p>
									<p
										css={{
											fontWeight: 600,
											fontSize: 24,
											color: siteColors.text,
											margin: 0,
											mb: '2px',
										}}
									>
										{authData?.session.company?.name}
									</p>
								</Fragment>
							)}
						</div>
					</AvatarContent>
				</div>
				<Box>
					<TabsBar value={params.tab} onChange={handleTabChange}>
						{Object.entries(tabs).map(([tabName, value]) => {
							if (['roles-et-droits', 'offres', 'facturation', 'paiement'].includes(tabName))
								return (
									<TabItem
										title="Section en cours de développement"
										key={tabName}
										label={t(value.title)}
										value={tabName}
										disabled
									/>
								);

							return <TabItem key={tabName} label={t(value.title)} value={tabName} />;
						})}
					</TabsBar>
				</Box>
				<Box
					sx={{
						margin: '32px auto',
						padding: '16px',
					}}
				>
					{Object.entries(tabs).map(([tabName, value], index) => {
						return tabName === params.tab && <Fragment key={index}>{value.element}</Fragment>;
					})}
				</Box>
			</Box>
		</div>
	);
};

export default ManagerSettings;
