import { useCallback } from 'react';

import { css, cx } from '@emotion/css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Share2 } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import Popover from '../Popover';

type Props = {
	anchorEl: Element | null;
	products?: Array<string>
};

const styles = {
	button: css({
		minWidth: '40px !important',
		paddingRight: '0px !important',
		textTransform: 'unset !important',
		'&:hover': {
			background: 'unset !important',
			color: `${siteColors.grey900} !important`,
		},
	} as any),


	popoverContent: css({
		padding: 24,
		width: 445,
	}),
	sharePage: css({
		fontWeight: 600,
		marginBottom: '16px',
	}),

	content: css({
		marginBottom: 16,
		padding: 16,
		borderRadius: '8px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	}),
	copyText: css({
		fontWeight: 600,
		color: '#FFF !important',
	}),
};

const ShareButton = ({ anchorEl, products }: Props) => {
	const { t } = useTranslation();
	const analytics = useJuneTrack();

	const track = useCallback(() => {
		analytics.track(
		'General Share',
			{
				context: { category: 'General' },
			},
		);
	}, [analytics]);

	return (
		<Popover
			disableScrollLock
			anchorEl={anchorEl ?? undefined}
			anchorOrigin={{
				horizontal: 'right',
				vertical: 'bottom',
			}}
			trigger={
				<Button disableRipple className={styles.button}>
					<Share2 className={cx('grey7')} size={20} />
				</Button>
			}
			renderContent={({ close }) => {
				return (
					<div className={styles.popoverContent}>
						<Typography className={styles.sharePage}>{t('share-page-title')}</Typography>
						<div className={cx(styles.content, 'borderGrey700')}>{`${window.location.href?.replace('&productToCompare=', '')}${products?.length ? window.location.search ? `&productToCompare=${products?.join(',')}` : `?productToCompare=${products?.join(',')}` : ''}`}</div>
						<BButton
							label={<Typography className={styles.copyText}>{t('copy-link-text')}</Typography>}
							variant="primary"
							addStyles={{ borderRadius: '8px' }}
							onClick={() => {
								navigator.clipboard.writeText(`${window.location.href?.replace('&productToCompare=', '')}${products?.length ? window.location.search ? `&productToCompare=${products?.join(',')}` : `?productToCompare=${products?.join(',')}` : ''}`);
								close();
							}}
						/>
					</div>
				);
			}}
			onClick={track}
		/>
	);
};

export default ShareButton;
