import { useCallback, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { getSheetDataAudit } from '@carbonmaps/ui/actions/audit.actions';
import { useRowTableSelection } from '@carbonmaps/ui/hooks/useRowTableSelection';

import Skeleton from '../../components/Skeleton';
import AuditButton from '../../components/audit/AuditButton';
import AuditExportModal from '../../components/audit/AuditExportModal';
import LoadingDialog from '../../components/dialogs/LoadingDialog';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';
import { useTranslation } from '../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import { exportAuditAction } from '../../lib/react-query/features/export/audit.actions';

const styles = {
	title: css({
		fontSize: '16px !important',
	}),
	button: css({
		width: '200px !important',
	}),
};

type AuditExportProps = {
	isLoading: boolean;
};

const AuditExport = ({ isLoading }: AuditExportProps) => {
	const [openModal, setOpenModal] = useState(false);
	const params = useParams();
	const [searchQueryParams] = useSearchQueryParams();
	const { selectedYear } = useYearSelection();
	const { t } = useTranslation();

	const [isExportLoading, setIsExportLoading] = useState(false);

	// ---- get audit ---- //
	const { data } = useQuery({
		queryKey: [
			'getSheetDataAudit',
			{
				itemType: params.itemType,
				input: searchQueryParams?.input,
				facetFilters: searchQueryParams?.facetFilters || [], //searchQueryParams.input
				ids: searchQueryParams?.productId ? [searchQueryParams?.productId] : null,
				period: selectedYear,
				isExport: true,
			},
		],
		queryFn: getSheetDataAudit,
	} as any);

	// ---- get audit ---- //
	const { data: countData } = useQuery({
		queryKey: [
			'getSheetDataAudit',
			{
				itemType: params.itemType,
				input: searchQueryParams?.input,
				facetFilters: searchQueryParams?.facetFilters || [], //searchQueryParams.input
				ids: searchQueryParams?.productId ? [searchQueryParams?.productId] : null,
				period: selectedYear,
				isCount: true,
			},
		],
		queryFn: getSheetDataAudit,
	} as any);

	// const errorTypeOptions = useMemo(() => {
	// 	if (!data) return;
	// 	const dataUniq = _.uniqBy(data as any, 'errorType');

	// 	return dataUniq.map((item: any) => {
	// 		return {
	// 			value: item?.errorType,
	// 			label: item?.errorType,
	// 			productId: item?.objectId,
	// 		};
	// 	});
	// }, [data]);

	const errorTypeOptions = useMemo(() => {
		if (!data) return;

		return (data as any)?.map((item: any) => {
			return {
				value: item,
				label: item,
				productId: item,
			};
		});
	}, [data]);

	// ---- select rows selected in store ---- //
	const { selectedRows } = useRowTableSelection();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	/**
	 * export data
	 */
	const handleExport = useCallback(
		async (errorTypeSelected: any) => {
			try {
				setIsExportLoading(true);
				setOpenModal(false);
				await exportAuditAction({
					t,
					typeErrors: errorTypeSelected,
					itemType: params.itemType,
					input: searchQueryParams?.input,
					period: selectedYear,

					facetFilters: (searchQueryParams?.facetFilters || []).filter((item: any) => {
						return item?.path !== 'type_error';
					}), //searchQueryParams.input

					allData: true,
					ids: (selectedRows?.length
						? selectedRows
						: searchQueryParams?.productId
						? [{ original: { objectId: searchQueryParams?.productId } }]
						: []
					).map((item: any) => {
						return item?.original?.objectId;
					}),

					companyCode: authData?.session.company?.code,
				});
				setIsExportLoading(false);
			} catch (error) {
				setIsExportLoading(false);
				console.error(error);
			}
		},
		[
			authData?.session.company?.code,
			params.itemType,
			searchQueryParams?.facetFilters,
			searchQueryParams?.input,
			searchQueryParams?.productId,
			selectedRows,
		],
	);

	const elem = (
		<>
			<AuditButton
				text={t('error-button')}
				onClick={() => {
					return setOpenModal(true);
				}}
				className={styles.button}
			/>
			<AuditExportModal
				title={
					<Typography variant="h2" className={styles.title}>
						{t('audit-error-message')}
					</Typography>
				}
				open={openModal}
				onClose={() => {
					return setOpenModal(false);
				}}
				errorTypeOptions={errorTypeOptions}
				isLoading={isExportLoading}
				onExportError={handleExport}
				selectedRow={(selectedRows || []).map((item: any) => {
					return item?.original?.objectId;
				})}
				data={countData}
			/>
			<LoadingDialog open={isExportLoading} content={`${t('Export en cours')}...`} />
		</>
	);

	return isLoading ? <Skeleton>{elem}</Skeleton> : elem;
	// return elem;
};

export default AuditExport;
