import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { HelpCircle, Minus, Undo2 } from 'lucide-react';

import { classNames } from '@carbonmaps/shared/utils/constants';

import { useTranslation } from '../../../../../apps/front/src/hooks/useTranslation';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import { WATER_INDICATOR } from '../../../../../apps/front/src/utils/constants';
import { useDebounce } from '../../../hooks/useDebounce';
import { formatNumber } from '../../../utils/numberFormat';
import BButton from '../BButton';
import BPopover from '../BPopover';

const styles = {
	outerContainer: css({
		paddingTop: 8,
		paddingBottom: 8,
	}),

	inputGreenText: css({
		input: {
			color: cn(siteColors.teal500, '!important'),
		},
	}),

	container: css({
		maxWidth: 150,

		'.MuiInputBase-root': {
			height: 42,
			borderRadius: '8px !important',
			background: 'var(--background)',
			// minWidth: 148,
			minWidth: '120px !important',
		},
		'.MuiOutlinedInput-notchedOutline': {
			border: cn('1px solid', siteColors.grey500),
		},

		input: {
			textAlign: 'right !important',
			color: cn(siteColors.grey800, '!important'),
			fontWeight: '400 !important',
		},
	} as never),

	endAdornment: css({
		p: {
			color: cn(siteColors.grey700, '!important'),
			fontSize: '14px !important',
			fontWeight: 500,
		},
	}),

	endButton: css({
		color: cn(siteColors.grey700, '!important'),
		padding: '0px !important',
		height: 'auto !important',
		minWidth: 'auto !important',

		background: 'none !important',
		border: 'none',
	}),
	text: css({
		fontSize: '14px !important',
		fontWeight: '400 !important',
	}),

	popoverContent: css({
		padding: '16px',
	}),
};

const isNumberKeyPress = (event: any) => {
	const { keyCode } = event;
	return (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);
};

const InputField = (props: any) => {
	const {
		value: initialValue,
		row: { index, ...restRow },
		column: { id, props: propsColumn },
		updateMyData,
	} = props;
	const { column, row } = props;

	const { t } = useTranslation();
	const [value, setValue] = useState(initialValue);
	const [isChange, setIsChange] = useState(false);
	const [isFocused, setIsFocused] = useState(false);

	const debounceValue = useDebounce(value, 1500);

	const prevData = propsColumn?.cloneData;
	let prevValue = prevData?.get(restRow?.original?.objectKey);

	if (propsColumn?.prevValueKey) {
		prevValue = _.get(prevValue, propsColumn?.prevValueKey, 0);
	}

	const isNoRevertButton = propsColumn?.isNoRevertButton;

	const queryClient = useQueryClient();

	const { mutateAsync: updateSimulationDate } = useMutation({
		mutationKey: ['updateSimulationDate'],
		mutationFn: async ({ simulationId }: { simulationId: string;}) => {
			try {
				// await updateSimulationDate(data);
				const simObject = new Parse.Object(classNames.SIMULATION);
				simObject.id = simulationId;
				simObject.set('updateDate', new Date());

				await simObject.save();
			} catch (error) {
				return Promise.reject(error);
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				 queryKey: ['getProductSimulationDataAction'],
			});
		},
	});

	const isNotEqual = useMemo(() => {
		// remove revert value
		if (isNoRevertButton) return false;

		const isPreviousValue = prevValue ? parseFloat(prevValue) !== parseFloat(value) : isFocused;
		return isPreviousValue;
	}, [prevValue, value, isFocused, isNoRevertButton]);

	const inputBgColor = useMemo(() => {
		let value = props.row.original.isOriginal ? 'transparent' : '#E3F5F7';

		if (isNotEqual) {
			value = '#E3F5F7';
		}

		return value;
	}, [isNotEqual, props.row.original.isOriginal]);

	useEffect(() => {
		if (!isChange) return;

		// trigger change each 2s
		const valueDb = debounceValue === '' ? 0 : parseFloat(debounceValue?.toString().replace(',', '.'));

		updateMyData(index, id, valueDb);
	}, [debounceValue]);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	if (!initialValue && propsColumn.page === 'eco-packaging') {
		return (
			<div className={cx(styles.text, 'number')} style={{ color: column?.props?.color || siteColors.primary }}>
				-
			</div>
		);
	}

	// get container actions row
	const elem = document.getElementsByClassName(`row-action-${index}`).item(0);

	const suffix = propsColumn?.suffix || '%';

	const onChange = (e: any) => {
		// eslint-disable-next-line no-useless-escape
		let val = e.target.value?.replace(/[^0-9\,\.]/g, '').replace(',', '.');

		// remove the input like 004 to  4
		if (val.match(/\b0[0-9]\w*\b/g)) {
			val = val.substring(1);
		}

		setValue(val);
		setIsFocused(false);

		if (column.props.page === 'simulation-scenario') {
			_.debounce(updateSimulationDate, 500)({ simulationId: column.props.simulationId });
		}
	};

	const onRevert = () => {
		setValue(prevValue);
		const valueDb = prevValue === '' ? 0 : parseFloat(prevValue?.toString().replace(',', '.'));

		updateMyData(index, id, valueDb);
		setIsFocused(false);
	};

	// We'll only update the external data when the input is blurred
	const onBlur = () => {
		const valueDb = value === '' ? 0 : parseFloat(value?.toString().replace(',', '.'));
		updateMyData(index, id, valueDb);

		elem?.setAttributeNS(null, 'style', 'display:none');
		setIsFocused(false);
	};

	const onFocus = () => {
		elem?.setAttributeNS(null, 'style', 'display:none');
	};

	const handleKeyPress = (evt: any) => {
		if (!isNumberKeyPress(evt)) return;
		// force only input for number
		// eslint-disable-next-line no-useless-escape
		let val = evt.target.value?.replace(/[^0-9\,\.]/g, '').replace('.', ',');

		setIsFocused(isNumberKeyPress(evt));

		// remove the input like 004 to  4
		if (val.match(/\b0[0-9]\w*\b/g)) {
			val = val.substring(1);
		}

		setValue(val);
		setIsChange(true);
		// eslint-disable-next-line no-useless-escape
	};

	// const isNotEqual = prevValue ? parseFloat(prevValue) !== parseFloat(value) : false;

	const isDisabledInput =
		(column.props.page === 'simulation-scenario' && row.original.isOriginal && !row.original.isPresent) ||
		column.props.disabled === true;

	const getInputValue = (value: any, isDisabledInput: boolean) => {
		if (column.props.type === 'string') {
			return value;
		}

		if (isDisabledInput) {
			return isNaN(value) && (!/,/.test(value) || !/./.test(value)) ? 0 : formatNumber(value, '', 2).replace('.', ',');
		}

		return isNaN(value) && (!/,/.test(value) || !/./.test(value)) ? 0 : _.toString(value).replace('.', ',');
	};

	const hasGesRawMaterialRecycled = row?.original?.packaging
		?.get('sim_modelization')
		?.materials?.some((material: any) => {
			const intensityRecycled =
				material?.original?.[`${propsColumn?.indicator === WATER_INDICATOR ? 'waterUse' : 'ges'}RawMaterialRecycled`];
			return !_.isNil(intensityRecycled);
			// return material?.recycled;
		});

	const cannotUpdate = propsColumn?.prevValueKey === 'recycled' && !hasGesRawMaterialRecycled;

	if (cannotUpdate) {
		return (
			<BPopover
				modeHover
				content={() => {
					return <div className={cx(styles.popoverContent)}>{t('eco-design-no-editable-materials')}</div>;
				}}
				anchorOriginHorizontal="center"
				anchorOriginVertical="top"
				transformOriginHorizontal="center"
				transformOriginVertical="bottom"
			>
				<div>
					{/* <HelpCircle size={22} color={siteColors.grey700} /> */}
					<Minus size={18} color={siteColors.grey700} />
				</div>
			</BPopover>
		);
	}

	return (
		<div className={styles.outerContainer}>
			<FormControl
				variant="outlined"
				className={cx(
					styles.container,
					isNotEqual ? styles.inputGreenText : '',
					!row.original.isOriginal ? styles.inputGreenText : '',
					propsColumn?.className,
				)}
				style={{ ['--background' as any]: inputBgColor }}
			>
				<OutlinedInput
					disabled={isDisabledInput || cannotUpdate}
					startAdornment={
						isNotEqual
? (
							<InputAdornment position="start">
								<BButton
									variant="tertiary"
									label={<Undo2 size={16} />}
									className={styles.endButton}
									onClick={onRevert}
								></BButton>
							</InputAdornment>
						)
: null
					}
					endAdornment={
						<InputAdornment position="end" className={styles.endAdornment}>
							{suffix}
						</InputAdornment>
					}
					aria-describedby="outlined-weight-helper-text"
					value={getInputValue(value, isDisabledInput)}
					onChange={onChange}
					onBlur={onBlur}
					onFocus={onFocus}
					onKeyUp={handleKeyPress}
				/>
			</FormControl>
		</div>
	);
};

export default InputField;
