import { useCallback } from 'react';

import { css, cx } from '@emotion/css';
import { Drawer, Toolbar } from '@mui/material';

import Skeleton from '../../../components/Skeleton';
import ProductItem from '../../../components/comparison/ProductItem';
import { product, useCompareProduct } from '../../../hooks/useCompareProduct';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { siteColors } from '../../../lib/colors';


const drawerWidth = 316;

const styles = {
	drawer: css({
		width: drawerWidth,
		flexShrink: 0,

	}),

	drawerPaper: css({
		width: drawerWidth,
		backgroundColor: `${siteColors.backgroundDrawer} !important`,
		borderRight: '1px solid transparent !important',
		zIndex: '1140 !important',
	}),
	drawerContainer: css({
		paddingLeft: 32,
		paddingRight: 16,
		paddingTop: 16,
	}),
	skeleton: css({
		width: '100%',
		height: '150px !important',

	}),
};

type CompareLeftMenuProps = {
	title: React.ReactNode;
	isLoading: boolean;
}

const CompareLeftMenu = ({ title, isLoading }: CompareLeftMenuProps) => {

	// the compared products
	const { products, removeProduct } = useCompareProduct();

	// search params
	const [searchQueryParams, setSearchParams] = useSearchQueryParams();

	const handleRemoveProduct = useCallback((product: product) => {
		removeProduct(product);
		const prevProducts = searchQueryParams.productToCompare.split(',');
		const newProducts = prevProducts.filter((p: string) => {return p !== product.objectId;});
		setSearchParams({ productToCompare: newProducts.join(',') });
		// update query params

	}, [removeProduct, searchQueryParams.productToCompare, setSearchParams]);

		return (
			<Drawer
				className={styles.drawer}
				variant="permanent"
				classes={{
					paper: styles.drawerPaper,
				}}
			>
				<Toolbar />
				<div className={cx('flexColumn width100 gap16', styles.drawerContainer)}>
					{title}
					{ isLoading  && !products?.length &&  <Skeleton  className={styles.skeleton} /> }
					<div className="flexColumn gap12 width100">
						{products.map((product, index) => {
							return <ProductItem key={index} product={product} onClose={handleRemoveProduct}/>;
						})}
					</div>
				</div>
			</Drawer>
		);
};


export default CompareLeftMenu;
