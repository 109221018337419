import { useCallback, useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { create } from 'zustand';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useJuneTrack } from '../../../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../../../hooks/useSearchQueryParams';
import { CARBON_INDICATOR } from '../../../../../utils/constants';
import ModelizationFilter from '../../../../audit/modelization/ModelizationFilter';
import ModelizationTable from '../../../../audit/modelization/ModelizationTable';

import AuditModelizationsIntro from './AuditModelizationsIntro';

const styles = {
	container: css({
		paddingTop: 32,
		// gap: 42,
	}),
};

// eslint-disable-next-line react-refresh/only-export-components
export const useStore = create((set: any) => {
	return {
		selectedRow: [] as any,
		onSelectionChange: (changedRows: any) => {
			return set((state: any) => {
				return {
					...state,
					selectedRow: changedRows,
				};
			});
		},
	};
});

const AuditModelizations = (/* props: Props */) => {
	const { indicator, setIndicator } = useApp();

	// init filters values
	const [searchQueryParams] = useSearchQueryParams();
	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(name: string, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Audit' },
			});
		},
		[analytics],
	);


	// ---- init switch mode with carbon ---- //
	useEffect(() => {
		if (indicator) return;
		setIndicator && setIndicator(CARBON_INDICATOR);
	}, [setIndicator, indicator]);

	// track event 	when the user lands on the Modelization listing page
	useEffect(() => {
		trackEvent('Audit Land Modelizations');
	}, []);

	return (
		<div className={cx('flexColumn width100', styles.container)}>
			<ModelizationFilter />
			<AuditModelizationsIntro />
			<ModelizationTable filterOptions={searchQueryParams} manualSortBy />
		</div>
	);
};

export default AuditModelizations;
