import { css, cx } from '@emotion/css';
import { Typography } from '@mui/material';

import { formatNumber } from '../../../utils/numberFormat';

type Props = {
	value: any;
	row: any;
	column: any;
};

const styles = {
	text: css({
		color: '#292430 !important',
	}),
};

const FilterField = ({
	value,
	row,
	column,
}: Props) => {
const currentFilter =  value?.length > 0 ?  value[0] : {};
const category = currentFilter?.folderLabel ||currentFilter?.path;
const filterValue = currentFilter?.values?.length ? currentFilter?.values[0] : null;
console.log('filterValue', filterValue);

	const t = column?.props?.translateFunc || ((v: string) => {return v;});
	return (
		filterValue
? <Typography className={cx('number', styles.text)}>{t('filter-text', {
			category: category,
			value: filterValue,
		})}</Typography>
: ''
	);
};

export default FilterField;
