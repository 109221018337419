import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import dayjs from 'dayjs';
import _ from 'lodash';
import { AlertCircle } from 'lucide-react';
import { nanoid } from 'nanoid';

import SupplierIcon from '@carbonmaps/media/icons/supplier.svg?react';
import BPopover from '@carbonmaps/ui/components/saas/BPopover';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import LabelLogo from '../../../../../components/question/LabelLogo';
import TitleList from '../../../../../components/question/TitleList';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../lib/colors';
import { useGetClientAuthQuery } from '../../../../../lib/react-query/features/auth/auth.hooks';
import { useFindCampaign } from '../../../questionForms.hooks';

const today = dayjs();

const styles1 = {
	main: css({
		paddingBottom: 56,
	}),
	container: css({
		width: '100%',
	}),
};

const QuestionListHistory = () => {
	const { t, i18n } = useTranslation();
	const {
		result: { data },
	} = useGetClientAuthQuery();

	const { data: campaigns, isLoading  } = useFindCampaign({
		companyId: _.toString(data?.session.company?.objectId),
	});

	const languageCode = getIsoCountryCode(i18n.language);

	return (
		<div className={cx('flexColumn gap32', styles1.main)}>
			{!isLoading && _.isEmpty(campaigns)
? (
				<div className={cx('width100')} css={{ textAlign: 'center' }}>
					{t('no-campaigns-sent-yet')}
				</div>
			)
: null}
			{(campaigns || [])?.map((campaign) => {
				const dateSent = _.get(campaign, 'createdAt');
				return (
					<CampaignFormItem
						key={nanoid()}
						title={
							_.get(campaign, `questionForm.translation.${languageCode}.name`) ||
							_.get(campaign, `questionFormObject.translation.${languageCode}.name`)
						}
						dateSent={t('x-days-ago', { x: today.diff(dateSent, 'day') })}
						recipients={_.get(campaign, 'recipients')}
						logs={_.get(campaign, 'logs')}
					/>
				);
			})}
		</div>
	);
};

export default QuestionListHistory;

const CampaignFormItem = ({
	title,
	dateSent,
	recipients,
	logs,
}: {
	title: string;
	dateSent: string;
	recipients: Record<string, any>[];
	logs: Record<string, any>[];
}) => {
	return (
		<div className={cx('flexColumn gap16', styles1.container)}>
			<TitleList dateSent={dateSent} title={title} count={recipients?.length || 0} />
			<ContactsGroup recipients={recipients} logs={logs} />
		</div>
	);
};

const styles2 = {
	container: css({
		gap: '1px',
		borderRadius: '16px',
		border: cn('1px solid', siteColors.grey500),
		padding: 24,
	}),

	email: css({
		padding: '8px 16px 8px 16px',
		background: siteColors.grey300,
		minWidth: '351px',
		borderRadius: '8px',
		color: siteColors.grey900,
	}),

	content: css({
		gap: '8px',
		marginBottom: 3,
	}),

	c2: css({
		padding: '8px 16px 8px 16px',
	}),
};

const ContactItem = ({
	supplierName,
	supplierUid,
	contactEmail,
	isLog,
}: {
	supplierName: string;
	supplierUid: string;
	contactEmail: string;
	isLog?: boolean;
}) => {
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<div className={cx('flexRow flex1 width100')}>
			<LabelLogo
				label={supplierName}
				uid={supplierUid}
				icon={<SupplierIcon css={{ '& path': { fill: theme.palette.grey[700] } }} width={20} height={20} />}
			/>

			{isLog && (
				<BPopover
					modeHover
					content={(_onclose) => {
						return (
							<div className={cx(styles2.c2)}>
								<div>{t('error-while-sending-survey')}</div>
							</div>
						);
					}}
				>
					<AlertCircle color="#C74600" />
				</BPopover>
			)}

			<div className={cx(styles2.email)}>{contactEmail}</div>
		</div>
	);
};

const ContactsGroup = ({ recipients, logs }: { recipients: Record<string, any>[]; logs: Record<string, any>[] }) => {
	return (
		<div className={cx('flexColumn gap16 width100', styles2.container)}>
			{recipients?.map((recipient) => {
				return (
					<ContactItem
						key={nanoid()}
						supplierName={_.get(recipient, 'supplier.name')}
						supplierUid={_.get(recipient, 'supplier.uid')}
						contactEmail={_.get(recipient, 'contact.email')}
					/>
				);
			})}
			{logs?.map((log) => {
				return (
					<ContactItem
						key={nanoid()}
						supplierName={_.get(log, 'supplier.name')}
						supplierUid={_.get(log, 'supplier.uid')}
						contactEmail={_.get(log, 'contact.email')}
						isLog
					/>
				);
			})}

		</div>
	);
};
