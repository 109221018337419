import { useQuery } from '@tanstack/react-query';

import { getIngredientKPIs } from '@carbonmaps/ui/actions/ingredient.actions';

import { useYearSelection } from '../../hooks/useImpactYear';
import { useTeam } from '../../hooks/useTeam';
import { useTranslation } from '../../hooks/useTranslation';
import ImpactKpi from '../packagings/components/ImpactKpi';
import TonnageKpi from '../packagings/components/TonnageKpi';

import IngredientCountKpi from './kpi/IngredientCountKpi';

const KpiIngredients = () => {

	const { selectedYear } = useYearSelection();
	const { team } = useTeam();
	// --------------------------------------- //
	// ----------- fetch data ---------------- //
	// --------------------------------------- //
	const { data, isLoading } = useQuery({
		queryKey: ['getIngredientKPIs', { period: selectedYear, team }],
		queryFn: getIngredientKPIs,
	});

	return (
		<div className="flexRow  width100 stretch gap16">
		<ImpactKpi data={data} isLoading={isLoading} withHelp={!data?.tonnage}  />
		<TonnageKpi tonnage={data?.tonnage} isLoading={isLoading} withHelp={!data?.tonnage} />
		<IngredientCountKpi />
	</div>
	);
};

export default KpiIngredients;
