import { useCallback } from 'react';

import CompareProduct from '../../../containers/products/compareProducts/CompareProduct';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';
import { exportProductAction } from '../../../lib/react-query/features/export/product.actions';
import CompareFloatingMenu from '../layout/CompareFloatingMenu';

type ProductFloatingMenuProps = {
	itemNature?: string;
}

const ProductFloatingMenu = ({ itemNature }: ProductFloatingMenuProps) => {

		const [searchQueryParams] = useSearchQueryParams();

		const { t, i18n } = useTranslation();
		const {
				result: { data: authData },
		} = useGetClientAuthQuery();
		const { selectedYear } = useYearSelection();

		const analytics = useJuneTrack();

		const trackEvent = useCallback(
			(name: string, options?: any) => {
				if (!analytics) return;
				analytics.track(name, options, {
					context: { category: 'Product' },
				});
			},
			[analytics],
		);

		// on export
		const handleExport = useCallback((format: string, selectedRows: any) => {
			trackEvent('Export product', { count: selectedRows.length });

			exportProductAction(
					{
						ids: selectedRows.map((e: any) => {
							return e.original.objectId;
						}),
						facetFilters: searchQueryParams.facetFilters,
						supplierIds: searchQueryParams?.supplierIds ?? [],
						input: searchQueryParams.input ?? '',
						format: format,
						companyCode: authData?.session.company?.name,
						selectedYear: selectedYear as any,
						language: i18n?.language,
						itemNature: itemNature,
					},
					t,
				);
		}, [searchQueryParams.facetFilters, searchQueryParams?.supplierIds, searchQueryParams.input, authData?.session.company?.name, selectedYear, i18n?.language, itemNature, t]);

	return (
		<CompareFloatingMenu element={CompareProduct} onExport={handleExport} tag="produit" />
	);
};

export default ProductFloatingMenu;
