import { ReactNode, useEffect, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/system/colorManipulator';
import { Maximize2 } from 'lucide-react';

import RectangleIcon from '@carbonmaps/media/icons/rectangle-b.svg?react';
import ScatterPlot, { Indicators } from '@carbonmaps/ui/components/saas/charts/ScatterChart';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import { useSize } from '../hooks/useSize';
import { useTranslation } from '../hooks/useTranslation';
import { cn, siteColors } from '../lib/colors';

import Skeleton from './Skeleton';
import TradeOffHeader from './TradeOffHeader';
import HeaderFullScreen from './layout/HeaderFullScreen';

const styles: any = {
	fullScreenContainer: css({
		background: alpha('#000', 0.8),
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1201,
	}),

	fullScreenContent: css({
		width: '95%',
		height: '86vh',
	}),

	innerContainer: css({
		background: 'white',
		borderRadius: pxToRem(8),
		padding: pxToRem(16),
		border: cn('1px solid', siteColors.grey500),
	}),

	fullScreenInnerContainer: css({
		width: '97%',
		height: 'calc(100vh - 100px)',
		marginLeft: '50%',
		transform: 'translateX(-50%)',
		marginTop: '16px',
		padding: pxToRem(32),
	}),

	legendTitle: css({
		color: cn(siteColors.grey700, '!important'),
		margin:'0 8px 0px 0',
		fontSize: '13px',
		fontWeight: 500,
	}),

	legendIcon: css({
		fontSize: '14px !important',
		color: cn(siteColors.grey700, '!important'),
		fontWeight: 600,
		display: 'inline-block',
		marginRight: '6px !important',
		verticalAlign: 'middle',
	}),

	text: css({
		fontSize: '14px !important',
		color: cn(siteColors.grey700, '!important'),
		display: 'inline-block',
	}),

	title: css({
		color: siteColors.grey900,
		margin: '4px 0 0 0 !important',
	}),

	subtitle: css({
		color: cn(siteColors.grey800, '!important'),
		fontSize: '13px !important',
		margin: '4px 0 0px 0 !important',
		fontWeight: 400,
	}),

	headerContainer: css({
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		marginBottom: pxToRem(12),
	}),

	titleContainer: css({
		display: 'flex',
		flexDirection: 'column',
	}),

	rightContainer: css({
		display: 'flex',
		alignItems: 'flex-start',
		gap: pxToRem(16),
	}),

	fullScreenButton: css({
		cursor: 'pointer',
		padding: pxToRem(4),
		borderRadius: pxToRem(8),
		transition: 'all 0.2s ease',
		'&:hover': {
			background: siteColors.grey100,
		},
	}),
};

interface TradeOffChartProps {
	datas: any[];
	displayLine?: string | JSX.Element;
	indicators: Indicators[];
	setUseValue?: (e: { x: Indicators; y: Indicators }) => void;
	useValue: { x: Indicators; y: Indicators };
	loading?: boolean;
	skeleton?: boolean;
	height?: number;
	enableFullscreen?: boolean;
	viewAllLink?: ReactNode;
	toggleImpact?: boolean;
	legend?: ReactNode;
	hcOptions?: any;
	showAxisLabels?: boolean;
	title?: string;
	subtitle?: string;
}

const TradeOffChart = ({
	datas,
	displayLine,
	indicators,
	setUseValue,
	useValue,
	loading,
	height,
	skeleton = false,
	enableFullscreen = true,
	viewAllLink,
	toggleImpact = true,
	legend,
	hcOptions,
	showAxisLabels,
	title,
	subtitle,
}: TradeOffChartProps) => {
	const [fullScreen, setFullScreen] = useState(false);

	const ref = useRef(null);
	const size = useSize(ref);

	const [initialSize, setInitialSize] = useState<any>(null);

	useEffect(() => {
		if (fullScreen) return;
		setInitialSize(size);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [size]);

	const toggleFullScreen = () => {
		setFullScreen((prev) => {
			if (!prev) setInitialSize(initialSize);
			return !prev;
		});
	};

	useEffect(() => {
		const bodyElement = document.getElementsByTagName('body')[0];

		if (fullScreen) {
			bodyElement.style.overflowY = 'hidden';
		} else {
			bodyElement.style.overflowY = 'scroll';
		}
	}, [fullScreen]);

	const innerChart = (
		<InnerChart
			indicators={indicators}
			setUseValue={setUseValue}
			datas={datas}
			displayLine={displayLine}
			useValue={useValue}
			fullScreen={fullScreen}
			toggleFullScreen={toggleFullScreen}
			size={fullScreen ? size : initialSize || size}
			loading={loading}
			height={height}
			enableFullscreen={enableFullscreen}
			viewAllLink={viewAllLink}
			toggleImpact={toggleImpact}
			legend={legend}
			hcOptions={hcOptions}
			showAxisLabels={showAxisLabels}
			title={title}
			subtitle={subtitle}
		/>
	);

	return (
		<div className={fullScreen ? styles.fullScreenContainer : 'width100 relative'}>
			{fullScreen ? <HeaderFullScreen onResize={toggleFullScreen} /> : null}
			<div ref={ref} className={fullScreen ? cx('width100 absolute', styles.fullScreenContent) : ''}>
				<Skeleton sx={{ display: !skeleton ? 'none' : 'block' }}>{innerChart}</Skeleton>

				<div className={skeleton ? 'display-none' : 'display-block'}>{innerChart}</div>
			</div>
		</div>
	);
};

export default TradeOffChart;

type InnerChartProps = TradeOffChartProps & {
	sx?: any;
	fullScreen: boolean;
	toggleFullScreen: () => void;
	size?: { width: number; height: number };
	height?: number;
	enableFullscreen?: boolean;
	viewAllLink?: ReactNode;
	toggleImpact?: boolean;
	legend?: ReactNode;
	hcOptions?: any;
	showAxisLabels?: boolean;
	title?: string;
	subtitle?: string;
};

const InnerChart = ({
	indicators,
	setUseValue,
	useValue,
	loading = false,
	datas,
	displayLine,
	sx = {},
	fullScreen,
	size,
	toggleFullScreen,
	height,
	enableFullscreen = true,
	viewAllLink,
	toggleImpact = true,
	legend,
	hcOptions,
	showAxisLabels = true,
	title,
	subtitle,
}: InnerChartProps) => {
	const { t } = useTranslation();

	return (
		<div
			id='tradeoff-chart'
			className={cx(
				'inner-chart-container',
				styles.innerContainer,
				fullScreen ? styles.fullScreenInnerContainer : '',
				css(sx),
			)}
		>
			<div className={styles.headerContainer}>
				{title && (
					<div className={styles.titleContainer}>
						<Typography variant="h3" className={styles.title}>{title}</Typography>
						{subtitle && <Typography className={styles.subtitle}>{subtitle}</Typography>}
					</div>
				)}
				<div className={styles.rightContainer}>
					{legend}
					{enableFullscreen && (
						<div className={styles.fullScreenButton} onClick={toggleFullScreen}>
							<Maximize2 size={20} color={siteColors.grey700} />
						</div>
					)}
				</div>
			</div>
			<TradeOffHeader
				toggleImpact={toggleImpact}
				indicators={indicators}
				setUseValue={(value) => {
					if (!setUseValue) return;
					return setUseValue({ ...useValue, y: value });
				}}
				useValue={useValue.y}
				disabledValue={useValue.x}
				legend={null}
				showAxisLabels={showAxisLabels}
			/>
			<ScatterPlot
				ScatterData={datas}
				xAxis={useValue.x}
				yAxis={useValue.y}
				css={{
					position: 'relative',
					top: -24,
				}}
				loading={loading}
				fullScreen={fullScreen}
				toggleFullScreen={toggleFullScreen}
				size={size}
				height={height}
				enableFullscreen={false}
				viewAllLink={viewAllLink}
				t={t}
				hcOptions={hcOptions}
			/>
			{displayLine && showAxisLabels
? (
				<div className="width100 flexRow spaceBetween alignCenter">
					{displayLine ? <p id="data-count-graph" className={styles.legendTitle}>{displayLine}</p> : null}
					{enableFullscreen === true && (
						<div className="italic">
							<Typography className={styles.legendIcon}>
								<RectangleIcon />
							</Typography>
							<Typography className={cx(styles.text, 'fontWeight600')}>{t('trick-chart')} :</Typography>{' '}
							<Typography className={styles.text}>{t('tradeoff-legend')}</Typography>
						</div>
					)}
					<TradeOffHeader
						toggleImpact={toggleImpact}
						indicators={indicators}
						setUseValue={(value) => {
							if (!setUseValue) return;
							return setUseValue({ ...useValue, x: value });
						}}
						useValue={useValue.x}
						disabledValue={useValue.y}
						popperPlacement="bottom-end"
						showAxisLabels={showAxisLabels}
					/>
				</div>
			)
: null}
		</div>
	);
};
