import { WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { displayValue, formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { GesUnit } from '../../utils/graphIndicator';
import PopoverItem from '../table/popover/PopoverItem';
import TypeModelization from '../TypeModelization';

type LifeCycleChartTooltipProps = {
	value: number;
	label?: string;
	unit?: React.ReactNode;
	precision?: number;
	withMinValue?: boolean;
	typeModelization?:string;
};

const LifeCycleChartTooltip = ({
	label = WORDING_TERMS.INTENSITY,
	value,
	unit = GesUnit,
	precision = 2,
	withMinValue,
	typeModelization,
}: LifeCycleChartTooltipProps) => {

	return (
		<PopoverItem
			label={label}
			value={
				<>
					{ withMinValue && value<=0.09 ? '<0,1': displayValue(value, undefined, precision)} {unit}
				</>
			}
			other={<TypeModelization value={typeModelization}/>}
		/>
	);
};

export default LifeCycleChartTooltip;
