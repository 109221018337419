import { useMutation } from '@tanstack/react-query';

import QuoteModel from '../../../../../models/Quote.model';
import { getQuotePDFArgs } from '../useQuote';
import { getSalesPDFArgs } from '../useSales';

export const pdfWorker = new ComlinkWorker(new URL('./pdfWorker', import.meta.url), {
  /* normal Worker options*/
});

export type PDFDocumentProps = {
	quote: QuoteModel | undefined;
	companyLogo: string | undefined;
	form: any | undefined;
	indicator?: string;
	t: any;
	companyCode: any;
	facetsConfig: any;
	preferredFacet: any;
	translations: any[];
	languageCode: string;
	isChecked: boolean;
};

export const useGenerateQuotePDF = () => {
	const result = useMutation({
		mutationKey: ['GenerateQuotePDFInWorker'],
		mutationFn: async ({
			companyCode,
			facetsConfig,
			form,
			preferredFacet,
			quote,
			t,
			indicator,
			translations,
			languageCode,
			isChecked,
		}: PDFDocumentProps) => {
			const returnValue = getQuotePDFArgs({
				companyCode,
				facetsConfig,
				form,
				preferredFacet,
				quote,
				indicator,
				t,
				translations,
				languageCode,
				isChecked,
			});
			return pdfWorker.renderPDFInWorker(returnValue);
		},
	});

	return result;
};

export const useGenerateSalesPDF = () => {
	const result = useMutation({
		mutationKey: ['GenerateSalesPDFInWorker'],
		mutationFn: async ({
			// companyCode,
			// facetsConfig,
			// form,
			// preferredFacet,
			// quote,
			t,
			indicator,
			translations,
			languageCode,
			isChecked,
			clientName,
			products,
			sortedFacetsConfigs,
		}: Parameters<typeof getSalesPDFArgs>[0]) => {
			const returnValue = getSalesPDFArgs({
				// companyCode,
				// facetsConfig,
				// form,
				// preferredFacet,
				// quote,
				indicator,
				t,
				translations,
				languageCode,
				isChecked,
				clientName,
				products,
				sortedFacetsConfigs,
			});
			return pdfWorker.renderPDFInWorker({ ...returnValue, exportType: 'sales_client' });
		},
	});

	return result;
};
