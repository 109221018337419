import { useMemo } from 'react';

import { css } from '@emotion/css';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { ArrowRight, Carrot } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useIngredientsProduct } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import BlockCard from '../../../components/BlockCard';
import BlockTitle from '../../../components/BlockTitle';
import BottomCard from '../../../components/BottomCard';
import { useTranslation } from '../../../hooks/useTranslation';
import IngredientModel from '../../../models/Ingredient.model';
import ProductModel from '../../../models/Product.model';

import DonutProductChart from './DonutProductChart';
const styles = {
	mb0: css({
		marginBottom: '0px !important',
	}),
};

const getPrecisionValue = (value: number) => {
	return parseFloat(formatNumber(value, undefined, 1).replace(',', '.'));
};

const ProductDonutBlock = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const params = useParams();
	const { indicator } = useApp();

	const { t } = useTranslation();

	const handleViewDetailsClick = () => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		navigate(`${FRONT_PATH_NAMES.products.details}/${params.productId!}/ingredients`);
	};

	// const { data: ingredientsData, isLoading } = useModelizationProduct({
	// 	productId: params.productId!,
	// });

	const { data: ingredientsData, isLoading } = useIngredientsProduct({
		productId: params.productId!,
	});

	const productObject = new ProductModel(_.isString(ingredientsData) ? {} : ingredientsData);
	//const total = productObject?.getIntensity(indicator === 'carbon' ? 'gesTotal' : 'waterUseTotal');

	const isRecetteType = ['n1_semantic', 'n1_typical', 'typical_ingredients'].includes(
		_.toLower(ingredientsData?.typeModelizationCompoIngredient),
	);

	const isRecetteCmaps = ['cmaps_ingredients'].includes(_.toLower(ingredientsData?.typeModelizationCompoIngredient));

	const gesIngredients = isRecetteType
		? productObject.getIntensity('gesAgriculture')
		: productObject.getGesIngredientV2();

	const waterUseIngredients = isRecetteType
		? productObject.getIntensity('waterUseAgriculture')
		: productObject.getWaterIngredientV2();


	// Calcul du total avec useMemo
	const total = useMemo(() => {
		if (!ingredientsData?.ingredients) return 0;

		return _.sumBy(ingredientsData.ingredients, (ingredient: any) => {
			const ingredientObject = new IngredientModel(ingredient);
			return indicator === 'carbon'
				? ingredientObject.getCarboneWeightedIntensity()
				: ingredientObject.getWaterWeightedIntensity();
		});
	}, [ingredientsData, indicator]);

	// Génération des données avec useMemo
	const data = useMemo(() => {
		if (!ingredientsData?.ingredients || total === 0) return [];

		return _.map(ingredientsData.ingredients, (ingredient: any) => {
			const ingredientObject = new IngredientModel(ingredient);
			const intensity =
				indicator === 'carbon'
					? ingredientObject.getCarboneWeightedIntensity()
					: ingredientObject.getWaterWeightedIntensity();


			return {
				value: (intensity * 100) / total,
				name: ingredient?.ingredient?.get('label') ?? ingredient?.ingredient?.get('labelCmaps'),
				isBlueCheck: ingredientObject.isN2ModelizationType(),
			};
		});
	}, [ingredientsData, indicator, total]);

	// Filtrage des données du graphe avec useMemo
	const graphData = useMemo(() => {
		return (data || []).filter((item: any) => { return getPrecisionValue(item.value) > 0.08; });
	}, [data]);

	if (_.isEmpty(graphData)) {
		return null;
	}

	return (
		<div>
			<BlockCard
				sx={{
					marginBottom: 0,
					borderRadius: (ingredientsData as any)?.isCmaps === true ? '16px 16px 0px 0px' : '16px',
				}}
			>
				<div className="flexRow spaceBetween alignCenter">
					<BlockTitle className={styles.mb0} icon={<Carrot size={20} color={theme.palette.grey[700]} />}>
						{t('ingredients')}
					</BlockTitle>
					<Box display="flex" justifyContent="flex-end">
						<Button
							onClick={handleViewDetailsClick}
							sx={{
								textTransform: 'none',
								'&:hover': {
									backgroundColor: 'unset',
									textDecoration: 'underline',
									textUnderlineOffset: '6px',
								},
							}}
							disableRipple
							endIcon={<ArrowRight size={18} />}
						>
							{t('Voir le détail')}
						</Button>
					</Box>
				</div>
				{!isLoading && (
					<DonutProductChart
						key={indicator}
						data={graphData}
						data2={{
							value: indicator === 'carbon' ? gesIngredients : waterUseIngredients,
							name: 'KG',
							// noCenterValue: isRecetteCmaps || isRecetteType ? true : false,
							noCenterValue: false, //SCM-4393
						}}
						indicator={indicator}
					/>
				)}
			</BlockCard>
			{(ingredientsData as any)?.isCmaps === true && !isLoading && (
				<BottomCard>{t('recipe-reference-info-alert', { recipe: t('recipe') })}</BottomCard>
			)}
		</div>
	);
};

export default ProductDonutBlock;
