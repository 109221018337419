import { useMemo } from 'react';

import { css, cx } from '@emotion/css';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Typography from '../../../../../components/Typography';
import Unit from '../../../../../components/units/Unit';
import Card from '../../../../../components/v3/Card';
import DonutChartLegend from '../../../../../components/v3/DonutChartLegend';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../lib/colors';
import { WATER_INDICATOR } from '../../../../../utils/constants';
import CarbonFootprintTooltip from '../../../../dashboard/v3/carbon_footprint/CarbonFootprintTooltip';
import DonutChart from '../../../components/DonutChart';
import { useMaterialKpi } from '../../../packagings.hooks';

const styles = {
	title: css({
		textTransform: 'uppercase',
	}),
	container: css({
		boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
	}),
	legend: css({
		width: '230px !important',
		maxWidth: '100% !important',
	}),
};

type MaterialCardProps = {
	legendTitle?: React.ReactNode;
};

const MaterialCard = ({ legendTitle }: MaterialCardProps) => {
	const { t } = useTranslation();

	const { indicator } = useApp();

	const { data, isLoading } = useMaterialKpi({ indicator });

	const items = useMemo(() => {
		if (!data?.length) {
			return [
				{
					color: siteColors.grey3,
					value: 99.9,
					tonnage: 0,
					carbonImpact: 0,
					waterImpact: 0,
				},
			];
		}

		const firstElement = data.filter((item: any) => {return item?.label !== 'other';});
		const otherElement = data.find((item: any) => {return item?.label === 'other';});
		const items = firstElement.map((item: any, index: number) => {
			return {
				...item,
				// https://carbonmaps.atlassian.net/jira/software/c/projects/SCM/boards/8?selectedIssue=SCM-4284
				...(indicator === WATER_INDICATOR
? {
					value: item[`${indicator}ImpactTotalPercent`],
				}
: {
					value: item[`${indicator}ImpactPercent`],
				}),
				color: (siteColors.materialColors as any)[`color${index}`],
			};
		});
		items.push({
			...otherElement,
			value: otherElement[`${indicator}ImpactPercent`],
			color: siteColors.materialColors.other,
		});

		return items;
	}, [data]);

	return (
		<Card
			title={
				<Typography variant="h3" className="grey8 fontWeight600">
					{t('Impact par matériaux')}
				</Typography>
			}
			containerClassName={'flex1'}
			loading={isLoading}
		>
			<DonutChart
				width={200}
				height={171}
				items={items}
				innerRadius={83}
				outerRadius={66}
				legend={<DonutChartLegend items={items} title={legendTitle} />}
				withTooltip
				renderTooltip={(item) => {
					return (
						<CarbonFootprintTooltip
							label={t(item?.label)}
							value={item && item[`${indicator}Impact`]}
							unit={<Unit measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'} />}
						/>
					);
				}}
				data={items}
				className="width100 nowrap gap16"
			/>
		</Card>
	);
};

export default MaterialCard;
