import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { getTotalImpactByPeriod } from '@carbonmaps/ui/actions/dashboard.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTeam } from '../../../hooks/useTeam';
import { siteColors } from '../../../lib/colors';
import Skeleton from '../../Skeleton';
import Typography from '../../Typography';
import Unit from '../../units/Unit';

const styles = {
	container: css({
		minHeight: 42,
	}),
	value: css({
		color: 'var(--color) !important',
		fontSize: '32px !important',
		lineHeight: '21px !important',
	}),
	skeleton: css({
		height: '42px !important',
		width: '100% !important',
	} as any),

	contentContainer: css({
		alignItems: 'baseline !important',
	}),
};

type TotalImpactProps = {
	withUnit?: boolean;
	category?: boolean;
};

const TotalImpact = ({ withUnit = false, category = false }: TotalImpactProps) => {
	const { indicator } = useApp();
	const theme = useTheme();
	const [searchQueryParams] = useSearchQueryParams();

	// team
	const { team } = useTeam();

	// period selected
	const { selectedYear } = useYearSelection();
	// ---- fetch count product by period ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getTotalImpactByPeriod',
			{ period: selectedYear, category, facetFilters: searchQueryParams?.facetFilters || [], team },
		],
		queryFn: getTotalImpactByPeriod,
	});

	return isLoading
? (
		<Skeleton width="100%!important" className={styles.skeleton}></Skeleton>
	)
: data?.[`${indicator}Impact`] || data
? (
		<div className={cx('flexRow alignCenter gap16 nowrap', styles.container)}>
			<div className={cx(styles.contentContainer, 'flexRow gap8')}>
				<Typography
					className={cx('number', styles.value)}
					style={{ ['--color' as any]: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500 }}
				>
					{data?.[`${indicator}Impact`]
						? formatNumber(data?.[`${indicator}Impact`], undefined, 0)
						: formatNumber(data, undefined, 0)}
				</Typography>
				{withUnit ? <Unit measure={'carbonImpactTon'} className={'grey8'} /> : null}
			</div>
		</div>
	)
: null;
};

export default TotalImpact;
