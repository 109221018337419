import { useMemo, useState } from 'react';

import useTheme from '@mui/material/styles/useTheme';
import ReactECharts from 'echarts-for-react';
import { PieChart, type PieSeriesOption } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { siteColors } from '../../../lib/colors';

echarts.use([PieChart, CanvasRenderer, LabelLayout]);

type EChartsOption = echarts.ComposeOption</* TooltipComponentOption | */ PieSeriesOption>;
type Data = NonNullable<PieSeriesOption['data']>;

type Props = {
	data: {
		value: number;
		name: string;
		isBlueCheck?: boolean;
	}[];
	data2: {
		value: number;
		name: string;
		isBlueCheck?: boolean;
		noCenterValue?: boolean;
	};
	indicator: string;
};

const DonutProductChart = ({ data, data2, indicator }: Props) => {
	// const myRef = useRef<HTMLElement>(null);
	const theme = useTheme();
	const _data: Data = data
		.sort((a, b) => {
			return b.value - a.value;
		})
		.map((e, index) => {
			if (index === 0) {
				return {
					...e,
					itemStyle: { color: indicator === 'water' ? siteColors.water500 : theme.palette.primary.main },
				};
			}

			return e;
		});

	const _data2 = useMemo(() => {
		const result = [];
		result.push(data2);

		return result;
	}, [data2]);

	// eslint-disable-next-line
	const [option, _setOption] = useState<EChartsOption>({
		tooltip: {
			trigger: 'item',
			formatter: (params: any) => {
				return `<div style="font-size: 14px; padding: 8px;">
					<div style="font-weight: 600; margin-bottom: 4px;">${params.name}</div>
					<div>${formatNumber(params.value, undefined, 1)}% de l'impact total</div>
				</div>`;
			},
			backgroundColor: siteColors.common.white,
			borderWidth: 0,
			shadowBlur: 10,
			shadowColor: 'rgba(0, 0, 0, 0.2)',
			shadowOffsetX: 0,
			shadowOffsetY: 0,
			borderRadius: 4,
			textStyle: {
				color: theme.palette.grey[900],
			},
		},
		series: [
			{
				silent: false,
				name: 'Access From',
				type: 'pie',
				animation: false,
				radius: [0, '35%'],
				center: ['50%', '50%'],
				label: {
					position: 'center',
					formatter: (params) => {
						const data2displayValue = (params.data as any).noCenterValue
							? '-'
							: typeof params.value === 'number' && params.value < 0.01 ? '<0,01' : formatNumber(params.value, undefined, 2);

						if (indicator === 'carbon') {
							return `{value|${data2displayValue}}\n{text1|KG}{text2|EQ}{text1|CO}{text2|2}{text1|/KG}`;
						}

						if (indicator === 'water') {
							return `{value|${data2displayValue}}\n{text1|m}{text3|3}{text1|/KG}`;
						}

						return `${params.value}`;
					},
					rich: {
						value: {
							fontSize: 28,
							color: indicator === 'water' ? siteColors.water500 : theme.palette.primary.main,
						},
						text1: {
							fontSize: 12,
							color: indicator === 'water' ? siteColors.water500 : theme.palette.primary.main,
						},
						text2: {
							fontSize: 8,
							color: indicator === 'water' ? siteColors.water500 : theme.palette.primary.main,
							verticalAlign: 'bottom',
							padding: [8, 0, 0, 0],
						},
						text3: {
							fontSize: 8,
							color: indicator === 'water' ? siteColors.water500 : theme.palette.primary.main,
							verticalAlign: 'bottom',
							padding: [0, 0, 2, 0],
						},
					},
				},
				data: _data2,
				emphasis: {
					disabled: true,
				},
				itemStyle: {
					color: siteColors.common.white,
				},
			},
			{
				silent: false,
				name: 'Access From',
				type: 'pie',
				animation: false,
				radius: ['45%', '65%'],
				center: ['50%', '50%'],
				avoidLabelOverlap: true,
				data: _data.map((item : any, index) => {return {
					...item,
					label: {
						show: index < 5,
					},
				};}),
				emphasis: {
					disabled: false,
					scale: true,
					scaleSize: 5,
					itemStyle: {
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowOffsetY: 0,
						shadowColor: 'rgba(0, 0, 0, 0.2)',
					},
				},
				label: {
					show: true,
					position: 'outside',
					alignTo: 'none',
					bleedMargin: 3,
					distanceToLabelLine: 3,
					formatter: (params: any) => {
						const name = params.name.length > 20 ? `${params.name.slice(0, 20)  }...` : params.name;
						return `{value|${formatNumber(params.value, undefined, 1)}%}\n{text|${name}}`;
					},
					rich: {
						value: {
							fontSize: '13px',
							padding: [0, 0, 4, 0],
							fontWeight:400,
							color: theme.palette.grey[900],
						},
						text: {
							fontSize: '14px',
							fontWeight: 500,
							padding: [0, 4],
							color: theme.palette.grey[800],
						},
					},
				},
				labelLayout: {
					hideOverlap: true,
					moveOverlap: 'shiftY',
				},
				labelLine: {
					show: true,
					length: 8,
					length2: 8,
					minTurnAngle: 90,
					lineStyle: {
						color: theme.palette.grey[600],
						width: 1,
					},
				},
			},
		],
	});
	return <ReactECharts
		option={option}
		opts={{ renderer: 'svg' }}
		style={{ height: '250px', width: '100%' }}
	/>;
};

export default DonutProductChart;
