import { Fragment, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { ChevronDown, ChevronUp, Leaf } from 'lucide-react';
import { useParams } from 'react-router-dom';

import AcidificationTerrestreEauDouceIcon from '@carbonmaps/media/icons/other-indicator/AcidificationTerrestreEauDouce.svg?react';
import AppauvrissementCoucheOzoneIcon from '@carbonmaps/media/icons/other-indicator/AppauvrissementCoucheOzone.svg?react';
import EcotoxiciteEauDouceIcon from '@carbonmaps/media/icons/other-indicator/EcotoxiciteEauDouce.svg?react';
import EpuisementRessourcesEnergetiquesIcon from '@carbonmaps/media/icons/other-indicator/EpuisementRessourcesEnergetiques.svg?react';
import EpuisementRessourcesMinerauxIcon from '@carbonmaps/media/icons/other-indicator/EpuisementRessourcesMineraux.svg?react';
import EutrophisationEauDouceIcon from '@carbonmaps/media/icons/other-indicator/EutrophisationEauDouce.svg?react';
import EutrophisationMarineIcon from '@carbonmaps/media/icons/other-indicator/EutrophisationMarine.svg?react';
import EutrophisationTerrestreIcon from '@carbonmaps/media/icons/other-indicator/EutrophisationTerrestre.svg?react';
import FormationPhotoChimiqueOzoneIcon from '@carbonmaps/media/icons/other-indicator/FormationPhotoChimiqueOzone.svg?react';
import PEFBiodiversityNoteIcon from '@carbonmaps/media/icons/other-indicator/PEF-biodiversity-note.svg?react';
import ParticulesFinesIcon from '@carbonmaps/media/icons/other-indicator/ParticulesFine.svg?react';
import RayonnementsIonisantsIcon from '@carbonmaps/media/icons/other-indicator/RayonnementsIonisants.svg?react';
import SubstanceCancerogeneIcon from '@carbonmaps/media/icons/other-indicator/SubstanceCancerogene.svg?react';
import SubstanceNonCancerogeneIcon from '@carbonmaps/media/icons/other-indicator/SubstanceNonCancerogene.svg?react';
import UtilisationSolIcon from '@carbonmaps/media/icons/other-indicator/UtilisationSol.svg?react';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useGetIngredientSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetIngredientSheet';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import BlockCard2 from '../../../components/BlockCard2';
import BlockTitle from '../../../components/BlockTitle';
import OtherIndicatorBloc from '../../../components/OtherIndicatorBloc';
import OtherIndicatorItem from '../../../components/OtherIndicatorItem';
import PefNote from '../../../components/PefNote';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import IngredientModel from '../../../models/Ingredient.model';
import { stylesOtherIndicator } from '../../productSheet/synthesis/OtherIndicatorsBlock';

import OtherIndicatorsTable from './OtherIndicatorsTable';


const toExponential = (num: number, digits = 2) => {
	return num.toExponential(digits);
};

const IngredientOtherIndicatorsBlock = () => {
	const params = useParams();

	// translation
	const { t } = useTranslation();

	const { data } = useGetIngredientSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.ingredientId!,
	});

	const toValueIndicatorByView = (value: any) => {
		if (value === 0) {
			return '-';
		}

		return toExponential(value);
	};

	const otherIndicatorsData = useMemo(() => {
		const ingredientObject = new IngredientModel(data?.ingredient);

		/* eslint-disable quotes */
		return [
			{
				label: t('Particules fines'),
				measure: toValueIndicatorByView(
					(ingredientObject?.getIntensity('particulesAgriculture') ?? 0) +
					(ingredientObject?.getIntensity('particulesTransformationDownstream') ?? 0),
				), unit: 'disease increase/kg',
				description: t('Particules fines - description'),
				icon: <ParticulesFinesIcon />,
			},
			{
				label: t('Epuisement des ressources énergétiques'),
				measure: toValueIndicatorByView(
					(ingredientObject?.getIntensity('fossilUseAgriculture') ?? 0) +
					(ingredientObject?.getIntensity('fossilUseTransformationDownstream') ?? 0),
				),
				unit: 'MJ/kg',
				description: t('Épuisement des ressources énergétiques - description'),
				icon: <EpuisementRessourcesEnergetiquesIcon />,
			},
			{
				label: t("Utilisation du sol"),
				measure: toValueIndicatorByView(
					(ingredientObject?.getIntensity('landUseAgriculture') ?? 0) +
					(ingredientObject?.getIntensity('landUseTransformationDownstream') ?? 0),
				),
				unit: 'Pt/kg',
				description: t('Utilisation du sol - description'),
				icon: <UtilisationSolIcon />,
			},
			{
				label: t('Epuisement des ressources minérales'),
				measure: toValueIndicatorByView(
					(ingredientObject?.getIntensity('mineralUseAgriculture') ?? 0) +
					(ingredientObject?.getIntensity('mineralUseTransformationDownstream') ?? 0),
				),
				unit: 'kg Sb eq/kg',
				description: t('Épuisement des ressources minérales - description'),
				icon: <EpuisementRessourcesMinerauxIcon />,
			},
			{
				label: t("Appauvrissement de la couche d'ozone"),
				measure: toValueIndicatorByView(
					(ingredientObject?.getIntensity('ozone1Agriculture') ?? 0) +
					(ingredientObject?.getIntensity('ozone1TransformationDownstream') ?? 0),
				), unit: 'kg CFC11 eq/kg',
				description: t("Appauvrissement de la couche d'ozone - description"),
				icon: <AppauvrissementCoucheOzoneIcon />,
			},
			{
				label: t('Acidification terrestre et eau douce'),
				measure: toValueIndicatorByView(
					(ingredientObject?.getIntensity('acidificationAgriculture') ?? 0) +
					(ingredientObject?.getIntensity('acidificationTransformationDownstream') ?? 0),
				),
				unit: 'mol H+ eq/kg',
				description: t('Acidification terrestre et eau douce - description'),
				icon: <AcidificationTerrestreEauDouceIcon />,
			},

			{
				label: t('Rayonnements ionisants'),
				measure: toValueIndicatorByView(
					(ingredientObject?.getIntensity('rayonnementsAgriculture') ?? 0) +
					(ingredientObject?.getIntensity('rayonnementsTransformationDownstream') ?? 0),
				),
				unit: 'kBq U235 eq/kg',
				description: t('Rayonnements ionisants - description'),
				icon: <RayonnementsIonisantsIcon />,
			},
			{
				label: t('Eutrophisation terrestre'),
				measure: toValueIndicatorByView(
					(ingredientObject?.getIntensity('eutrophisation3Agriculture') ?? 0) +
					(ingredientObject?.getIntensity('eutrophisation3TransformationDownstream') ?? 0),
				),
				unit: 'mol N eq/kg',
				description: t('Eutrophisation terrestre - description'),
				icon: <EutrophisationTerrestreIcon />,
			},
			{
				label: t('Eutrophisation eau douce'),
				measure: toValueIndicatorByView(
					(ingredientObject?.getIntensity('eutrophisation1Agriculture') ?? 0) +
					(ingredientObject?.getIntensity('eutrophisation1TransformationDownstream') ?? 0),
				),
				unit: 'kg P eq/kg',
				description: t('Eutrophisation eau douce - description'),
				icon: <EutrophisationEauDouceIcon />,
			},
			{
				label: t('Eutrophisation marine'),
				measure: toValueIndicatorByView(
					(ingredientObject?.getIntensity('eutrophisation2Agriculture') ?? 0) +
					(ingredientObject?.getIntensity('eutrophisation2TransformationDownstream') ?? 0),
				),
				unit: 'kg N eq/kg',
				description: t('Eutrophisation marine - description'),
				icon: <EutrophisationMarineIcon />,
			},
			{
				label: t("Formation photochimique d'ozone"),
				measure: toValueIndicatorByView(
					(ingredientObject?.getIntensity('ozone2Agriculture') ?? 0) +
					(ingredientObject?.getIntensity('ozone2TransformationDownstream') ?? 0),
				),
				unit: 'kg NMVOC eq/kg',
				description: t("Formation photochimique d'ozone - description"),
				icon: <FormationPhotoChimiqueOzoneIcon />,
			},
			{
				label: t('Effets toxicologiques sur la santé humaine : substances cancérogènes'),
				measure: toValueIndicatorByView(
					(ingredientObject?.getIntensity('toxicite2Agriculture') ?? 0) +
					(ingredientObject?.getIntensity('toxicite2TransformationDownstream') ?? 0),
				),
				unit: 'CTUh/kg',
				description: t('Effets toxicologiques sur la santé humaine : substances cancérogènes - description'),
				icon: <SubstanceCancerogeneIcon />,
			},
			{
				label: t('Effets toxicologiques sur la santé humaine : substances non-cancérogènes'),
				measure: toValueIndicatorByView(
					(ingredientObject?.getIntensity('toxicite1Agriculture') ?? 0) +
					(ingredientObject?.getIntensity('toxicite1TransformationDownstream') ?? 0),
				),
				unit: 'CTUh/kg',
				description: t('Effets toxicologiques sur la santé humaine : substances non-cancérogènes - description'),
				icon: <SubstanceNonCancerogeneIcon />,
			},

			{
				label: t("Ecotoxicité pour écosystèmes aquatiques d'eau douce"),
				measure: toValueIndicatorByView(
					(ingredientObject?.getIntensity('ecotoxAgriculture') ?? 0) +
					(ingredientObject?.getIntensity('ecotoxTransformationDownstream') ?? 0),
				),
				unit: 'CTUe/kg',
				description: t("Ecotoxicité pour écosystèmes aquatiques d'eau douce - description"),
				icon: <EcotoxiciteEauDouceIcon />,
			},

			{
				label: t('PEF-biodiversity-note'),
				measure: 0,
				unit: 'CTUe/kg',
				description: t('PEF-biodiversity-note - description'),
				icon: <PEFBiodiversityNoteIcon />,
				isDisabled: true,
			},
		];
		/* eslint-enable quotes */
	}, [data?.ingredient, t]);

	const [hidden, setHidden] = useState(true);

	return (
		<>
			<BButton
				addStyles={{ width: '100%', marginBottom: '8px' }}
				variant="secondary"
				// eslint-disable-next-line quotes
				label={
					hidden ?
					(
						<>
							{/* eslint-disable-next-line quotes */}
							{t("Voir plus d'indicateurs")}
							<ChevronDown size={18} />
						</>
					) :
					(
						<>
							{t('voir moins')}
							<ChevronUp size={18} />
						</>
					)
				}
				onClick={() => {
					return setHidden(!hidden);
				}}
			/>
			<Box mb={pxToRem(100)} className={cx(hidden ? stylesOtherIndicator.hidden : '')} >
				<OtherIndicatorBloc data={otherIndicatorsData} />
			</Box>
		</>
	);
};

export default IngredientOtherIndicatorsBlock;
