import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getTotalImpactPerPeriod } from '@carbonmaps/ui/actions/dashboard.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import ImpactTonnageGraph from '../../../components/dashboard/temporality/ImpactTonnageGraph';
import TemporalityTitle from '../../../components/dashboard/temporality/TemporalityTitle';
import Skeleton from '../../../components/Skeleton';
import Card from '../../../components/v3/Card';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTeam } from '../../../hooks/useTeam';
import { useTranslation } from '../../../hooks/useTranslation';
import { orderBy } from '../../../utils/array.utils';
import EmptyImpact from '../../dashboard/EmptyImpact';

const styles = {
	skeleton: css({
		marginTop: 24,
		height: '175px !important',
		width: '100% !important',
	} as any),
	cardContainer: css({
		paddingBottom: '0px!important',
	}),
};

/**
 * getDataByIndicator
 * @param data
 * @param indicator
 * @returns
 */
const getDataByIndicator = (data: any, indicator: string) => {
	return data.filter((item: any) => {
		return item[`${indicator}Impact`] || item[`${indicator}ImpactEstimated`];
	});
};

const TemporalityClient = ({ graphWidth }: { graphWidth?: string }) => {
	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	const { indicator } = useApp();

	const { team } = useTeam();

	const params = useParams();
	const { data, isLoading } = useQuery({
		queryKey: [
			'getTotalImpactPerPeriod',
			{
				facetFilters: searchQueryParams?.facetFilters || [],
				period: selectedYear,
				slugClient: params.slugClient!,
				team,
			},
		],
		queryFn: getTotalImpactPerPeriod,
	});

	return (
		<div >
			<Card title={<TemporalityTitle />} containerClassName={cx(styles.cardContainer)}>
				{isLoading
					? (
						<Skeleton width="100%!important" className={styles.skeleton} />
					)
					: data?.length
						? (
							<ImpactTonnageGraph
								graphWidth={graphWidth}
								data={orderBy(
									getDataByIndicator(
										searchQueryParams?.facetFilters?.length
											? data.filter((item: any) => {
												return item[`${indicator}Impact`];
											})
											: data,
										indicator,
									),
									'objectId',
									'asc',
								)}
								// isFilter={searchQueryParams?.facetFilters?.length}
							/>
						)
						: (
							<EmptyImpact text={t('temporality-empty-text', { period: selectedYear || t('selectedYear') })} />
						)}
			</Card>
		</div>
	);
};

export default TemporalityClient;
