import { css, cx } from '@emotion/css';
import Tooltip, { tooltipClasses, type TooltipProps } from '@mui/material/Tooltip';
import styled from '@mui/material/styles/styled';
import type { SxProps } from '@mui/system';

import BlueCheckIconKO from '@carbonmaps/media/icons/bluechecknok.svg?react';
import BlueCheckIcon from '@carbonmaps/media/icons/bluecheckok.svg?react';

import Typography from './Typography';

type BlueCheckProps = {
	title?: React.ReactNode;
	origin?: React.ReactNode;
	iconSize?: number;
	fontSize?: number;
	sx?: SxProps;
	withIcon?: boolean;
	ok?: boolean;
	isHeader?: boolean;
	className?: string;
	fontWeight?: number;
	variant?: string;
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => {
	return <Tooltip {...props} classes={{ popper: className }} />;
})(({ theme }) => {
	return {
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			// color: 'rgba(0, 0, 0, 0.87)',
			color: theme.palette.common.black,
			boxShadow: theme.shadows[2],
			fontSize: 12,
		},
	};
});

const styles = {
	container: css({
		gap: 8,
	}),

	iconContainer: css({
		'& svg': { width: '100%', height: '100%' },
	}),
	title: css({
		maxWidth: '300px',
		textTransform: 'uppercase',
	}),
	subtile: css({
		maxWidth: '250px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	}),
};

const BlueCheck = ({
	title,
	origin,
	iconSize = 24,
	fontSize,
	withIcon = true,
	sx,
	ok = true,
	isHeader = false,
	className = '',
	fontWeight,
	variant = 'body1',
}: BlueCheckProps) => {
	return (
		<div className={cx('flexRow alignCenter nowrap', styles.container, className, css(sx as any))}>
			{withIcon && (
				<div
					className={cx(styles.iconContainer, 'blue-check-icon-container')}
					style={{ width: iconSize, height: iconSize }}
				>
					{ok ? <BlueCheckIcon /> : <BlueCheckIconKO />}{' '}
				</div>
			)}

			{title
? (
				<div>
					<LightTooltip title={title}>
						<Typography
							className={
								((!isHeader ? styles.title : '',
								'fontWeight600',
								fontSize ? css({ fontSize: `${fontSize}px !important` }) : '',
								fontWeight ? css({ fontWeight: `${fontWeight} !important` }) : ''),
								className)
							}
							noWrap
							variant={variant}
							fontSize={fontSize}
						>
							{title}
						</Typography>
					</LightTooltip>
					{origin && origin !== 'Origine non pris en compte' && (
						<LightTooltip title={origin}>
							<Typography className={styles.subtile} noWrap variant="h5">
								{origin}
							</Typography>
						</LightTooltip>
					)}
				</div>
			)
: null}
		</div>
	);
};

export default BlueCheck;
