import type { Theme } from '@mui/material/styles/createTheme';

import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';

export const classes = (theme: Theme) => {
	return {
		containerButton: {
			gap: 12,
			minWidth: '400px',
		},
		title: {
			fontWeight: 600,
			fontSize: 32,
			marginTop: 0,
		},
		subtitle: {
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
			fontWeight: 600,
			fontSize: 20,
			color: theme.palette.common.black,
			paddingBottom: 10,
		},
		avatar: {
			width: 130,
			height: 130,
			borderRadius: 130,
			left: 0,
			top: 0,
			background: `linear-gradient(321.3deg, #4E4DBE 11.46%, ${siteColors.green500} 80.17%), #FEF8C0`,
			border: '5px solid #FFFFFF',
			boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
		},
		infosTitle: {
			marginTop: 48,
			marginBottom: 48,
		},
		link: {
			color: siteColors.primary,
			textDecoration: 'none',
		},
		alert: {
			border: cn('1px solid', siteColors.primary),
			boxShadow: cn('inset 4px 0px 0px', siteColors.primary),
			borderRadius: '0px 8px 8px 0px',
			marginBottom: 25,
			alignItems: 'center',
			padding: 25,
			gap: 8,
		},
	};
};
