import { css, cx } from '@emotion/css';
import { type QueryFunction, useQuery } from '@tanstack/react-query';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useYearSelection } from '../../hooks/useImpactYear';
import { useTeam } from '../../hooks/useTeam';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import Typography from '../Typography';

import Card from './Card';
import CategoryTable from './CategoryTable';

const styles = {
	title: css({
			fontSize: '16px !important',
			lineHeight: '1.2 !important',
		}),
		subtitle: css({
			lineHeight: '1.2 !important',
			color: `${siteColors.grey7} !important`,
		}),
};

type CategoryCardProps = {
	category: string;
	label: string;
	subtitle?: string;
	emptyMessageData?: string;
	data?: Array<{
		label: string;
		value: number;
		objetId: string;
		category: string;
	}>;
	isLoading?:boolean;
	footer?: React.ReactNode;

	queryKey: string
	queryFn: QueryFunction<unknown, any>

}

const CategoryCard = ({ category, label, subtitle,  footer, queryKey, emptyMessageData,  queryFn }: CategoryCardProps) => {

	// ---- hooks translation ---- //
	const { t }= useTranslation();
	const { selectedYear } = useYearSelection();
	const { team } = useTeam();
	const { indicator } = useApp();

		// ---- fetch segmentation data ---- //
		const { data, isLoading } = useQuery({
			queryKey: [
				'getDataFacet',
				{
					period: selectedYear,
					category,
					viewMode: indicator,
					team,
					limit: 6,
				},
			],
			queryFn: queryFn,
		});

	return (
		<Card
			title={
				<div className="flexColumn gap6">
					<Typography className={cx('fontWeight600', styles.title)}>
						{t('analyze-tab-title', { category:  label })}
					</Typography>
					{ subtitle && <Typography className={styles.subtitle}>{subtitle}</Typography>}
				</div>
			}
			footer={footer}
			loading={isLoading}
		>
			<CategoryTable data={data as any} emptyMessageData={emptyMessageData}/>
		</Card>
	);

};

export default CategoryCard;
