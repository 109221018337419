import { useState } from 'react';

import { css, cx } from '@emotion/css';
import CircularProgress from '@mui/material/CircularProgress';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { Component, Copy, Database, FileEdit, Globe, LogIn, Plus, Send, Settings, Users } from 'lucide-react';

import SupplierIcon from '@carbonmaps/media/icons/supplier.svg?react';
import { roleLabels } from '@carbonmaps/shared/utils/constants';

import { siteColors } from '../../../../../apps/front/src/lib/colors';
import BButton from '../BButton';

import DeleteButton from './DeleteButton';

const LUCIDE_ICON_SIZE = 20;

const styles = {
	bgTransparent: css({
		background: 'transparent!important',
		minWidth: '20px!important',
	}),
	noCursor: css({
		pointer: 'not-allowed',
	}),
};

const Action = (props: any) => {
	const [isLoading, setIsLoading] = useState(false);
	// theme
	const theme = useTheme();
	const { value, row, column } = props;
	const { tooltips = {} } = column;

	const isSameId = row?.original?.uid === column?.props?.currentUid;

	if ((column?.props?.page === 'simulation' || column?.props?.page === 'devisCarbone') && row.original?.level !== 2) {
		return (
			<div className="flexRow  nowrap justifyEnd">
				{column?.callbackAdd && (
					<BButton
						variant={column?.edit?.label ? 'secondary' : 'tertiary'}
						iconLeft={<Plus size={LUCIDE_ICON_SIZE} color={theme.palette.grey[700]} />}
						label={''}
						onClick={() => {
							return column?.callbackAdd(row.original);
						}}
					/>
				)}
			</div>
		);
	}

	return (
		<div
			className={
				column?.props?.page === 'simulation' ||
				column?.props?.page === 'devisCarbone' ||
				column?.props?.page === 'translation'
					? 'flexRow  nowrap justifyEnd'
					: 'flexRow  nowrap '
			}
		>
			{column?.callbackLoginAs && (
				<div>
					<BButton
						variant="tertiary"
						iconRight={
							isLoading
? (
								<CircularProgress size={20} css={{ color: siteColors.common.white }} />
							)
: (
								<LogIn size={LUCIDE_ICON_SIZE} color={theme.palette.grey[700]} />
							)
						}
						label={''}
						tooltip={tooltips.loginAs}
						onClick={() => {
							setIsLoading(true);
							return column?.callbackLoginAs(row?.original?.objectId);
						}}
					/>
				</div>
			)}
			{(column?.callbackEdit || column?.edit?.callback) && (
				<div>
					<BButton
						isDisabled={row?.original?.isDisabled ?? false}
						variant={column?.edit?.variant ?? (column?.edit?.label ? 'secondary' : 'tertiary')}
						iconLeft={
							column?.edit?.showIcon === false
? (
								''
							)
: column?.editIcon
? (
								column.editIcon
							)
: (
								<FileEdit size={LUCIDE_ICON_SIZE} color={theme.palette.grey[700]} />
							)
						}
						label={column?.edit?.label ?? ''}
						tooltip={tooltips.edit}
						onClick={() => {
							return column?.callbackEdit ? column?.callbackEdit(row?.original) : column?.edit?.callback(row?.original);
						}}
						className={cx(
							column?.edit?.variant ? '' : styles.bgTransparent,
							row?.original?.isDisabled ? styles.noCursor : '',
						)}
					/>
				</div>
			)}

			{column?.callbackEditSupplierUser &&
				(row.original?.role?.label === roleLabels.CONTRIBUTOR
? (
					<div>
						<BButton
							variant={column?.edit?.label ? 'secondary' : 'tertiary'}
							iconLeft={<SupplierIcon css={{ '& path': { fill: theme.palette.grey[700] } }} width={20} height={20} />}
							label={column?.edit?.label ?? ''}
							onClick={() => {
								return column?.callbackEditSupplierUser(row?.original);
							}}
							className={cx(styles.bgTransparent)}
						/>
					</div>
				)
: (
					<div>
						<BButton
							isDisabled
							variant={column?.edit?.label ? 'secondary' : 'tertiary'}
							label={''}
							onClick={() => {
								console.log('disabled');
							}}
							className={cx(styles.bgTransparent)}
						/>
					</div>
				))}

			{column?.callbackUser && (
				<div title="Users">
					<BButton
						variant="tertiary"
						iconLeft={<Users size={LUCIDE_ICON_SIZE} color={theme.palette.grey[700]} />}
						label={''}
						tooltip={tooltips.users}
						onClick={() => {
							return column?.callbackUser(row?.original?.objectId);
						}}
					/>
				</div>
			)}
			{column?.callbackSetting && (
				<div title="Facets">
					<BButton
						variant="tertiary"
						iconLeft={<Settings size={LUCIDE_ICON_SIZE} color={theme.palette.grey[700]} />}
						label={''}
						tooltip={tooltips.settings}
						onClick={() => {
							return column?.callbackSetting(row?.original);
						}}
					/>
				</div>
			)}

			{column?.callBackDbVersion && (
				<div title="Version">
					<BButton
						variant="tertiary"
						iconLeft={<Database size={LUCIDE_ICON_SIZE} color={theme.palette.grey[700]} />}
						label={''}
						tooltip={tooltips.version}
						onClick={() => {
							return column?.callBackDbVersion(row?.original);
						}}
					/>
				</div>
			)}

			{column?.callBackDbModule && (
				<div title="Module">
					<BButton
						variant="tertiary"
						iconLeft={<Component size={LUCIDE_ICON_SIZE} color={theme.palette.grey[700]} />}
						label={''}
						tooltip={tooltips.module}
						onClick={() => {
							return column?.callBackDbModule(row?.original);
						}}
					/>
				</div>
			)}

			{/* {column?.callbackQuestionnaire && (
				<div title="Questionnaire préféré">
					<BButton
						variant="tertiary"
						iconLeft={
							<Send
								size={LUCIDE_ICON_SIZE}
								color={!_.get(row, 'original.preferredSurvey') ? siteColors.orange500 : theme.palette.grey[700]}
							/>
						}
						label={''}
						onClick={() => {
							return column?.callbackQuestionnaire(row?.original);
						}}
					/>
				</div>
			)} */}

			{column?.callBackTranslation && (
				<div title="Translations">
					<BButton
						variant="tertiary"
						iconLeft={<Globe size={LUCIDE_ICON_SIZE} color={theme.palette.grey[700]} />}
						label={''}
						tooltip={tooltips.translation}
						onClick={() => {
							return column?.callBackTranslation(row?.original);
						}}
					/>
				</div>
			)}

			{column?.callBack && (
				<div>
					<BButton
						variant="secondary"
						iconLeft={column?.props?.icon}
						label={isSameId ? column?.props?.initLabel : column?.labelButton}
						onClick={() => {
							return column?.callBack(row?.original);
						}}
						className={cx(styles.bgTransparent, isSameId && column?.props?.className)}
					/>
				</div>
			)}

			{column?.callbackCopy && (
				<div>
					<BButton
						variant={'tertiary'}
						iconLeft={<Copy size={LUCIDE_ICON_SIZE} color={theme.palette.grey[700]} />}
						label={column?.copy?.label ?? ''}
						onClick={() => {
							return column?.callbackCopy(row?.original);
						}}
						className={cx(styles.bgTransparent)}
					/>
				</div>
			)}

			{((column.withArchive && row?.original?.deleted === true) ||
				(column?.withConfirm && !row?.original?.canNotDeleted)) && (
				<DeleteButton row={row} column={column} value={value} buttonClassName={cx(styles.bgTransparent)} tooltip={tooltips.delete} />
			)}
		</div>
	);
};

export default Action;
