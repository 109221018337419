import { css } from '@emotion/css';
import _ from 'lodash';

import ProductFloatingMenu from '../../../../../components/comparison/product/ProductFloatingMenu';
import { useSearchQueryParams } from '../../../../../hooks/useSearchQueryParams';


import ProductsFilter from './ProductsFilter';
import ProductsTable from './ProductsTable';
import ProductTradeoff from './ProductTradeoff';

const styles = {

	container: css({
		position:'relative',
	'.filterContainer-wrapper': {
				paddingLeft: 0,
				paddingRight: 0,
				top: `${_.toString(58 * 2 + 2)}px`,
			},
	}),
};

const ProductsList = ({ itemNature }: { itemNature?: string }) => {
	const [searchQueryParams] = useSearchQueryParams();
	const { productToCompare, compareProduct, ...filteredQueryParams } = searchQueryParams;

	return (
		<div
			className={styles.container}
		>
			<ProductsFilter itemNature={itemNature} />
			<div className="flexColumn width100 gap8">
					<ProductTradeoff filterOptions={filteredQueryParams} itemNature={itemNature} />
				<ProductsTable filterOptions={filteredQueryParams} manualSortBy itemNature={itemNature} />
			</div>
			<ProductFloatingMenu itemNature={itemNature} />
		</div>
	);
};

export default ProductsList;
