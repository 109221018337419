import { Typography } from '@mui/material';

const TeamLabel = (props: any) => {
	const { value, row, column } = props;

	return (

			<div className="flexRow nowrap alignCenter gap16">
				{row?.original?.icon}
				<div className="flexColumn">
					<Typography className="grey9">{value}</Typography>
					<Typography className="grey7">{row?.original?.description}</Typography>
				</div>
			</div>

	);

};

export default TeamLabel;
