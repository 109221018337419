import { css, cx } from '@emotion/css';
import { Typography } from '@mui/material';

import { formatNumber } from '../../../utils/numberFormat';

type Props = {
	value: any;
	row: any;
	column: any;
};

const styles = {
	text: css({
		color: '#292430 !important',
	}),
};

const NumberField = ({
	value,
	row,
	column,
}: Props) => {
	return (
		<Typography className={cx('number', styles.text)}>{formatNumber(value, undefined, 0)}</Typography>
	);
};

export default NumberField;
