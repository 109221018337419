import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { Box } from 'lucide-react';

import IconContainer from '../../../components/layout/list/IconContainer';
import ListHeader from '../../../components/layout/list/ListHeader';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import CompareButton from '../../productSheet/CompareButton';

const styles = {
	button: css({
		'&.MuiButtonBase-root': {
			background: `${siteColors.grey4} !important`,
			borderColor:`${siteColors.grey4} !important`,
			'&:hover': {
				background: `${siteColors.grey5} !important`,
				borderColor: siteColors.grey5,
			},
		},
	}),
};

const ProductsHeader = () => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<ListHeader
			title={t('products')}
			icon={<IconContainer element={<Box color={theme.palette.common.white} size={32} />} />}
			rightElement={<CompareButton title={t('Comparer des produits')} className={styles.button} />}
		/>
	);
};

export default ProductsHeader;
