import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

export const MAX_ROWS_UPLOAD = 300;

//------------------------------------------------------------//
//--data to import after upload in page prepare question------//
//------------------------------------------------------------//
export const getFormattedDataUpload = (parsedData: any) => {
	const formattedData = parsedData.slice(1).map((row: any) => {
		return {
			supplierName: row[0], // Column 1: Supplier Name
			contactFirstName: row[1], // Column 2: Contact First Name
			contactName: row[2], // Column 3: Contact Last Name
			contactEmail: row[3]?.toLowerCase(), // Column 4: Contact Email
			contactLanguage: getIsoCountryCode(row[4]), // Column 5: Contact Language
		};
	});

	return formattedData;

};
