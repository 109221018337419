import { type QueryFunctionContext } from '@tanstack/react-query';

type getDataVersionType = {
	companyId?: string;
	page: number;
	size: number;
};

/**
 *  data version by company
 * @param context
 * @returns
 */
export const getDataVersionAction = async (
	context: QueryFunctionContext<readonly ['getDataVersionAction', getDataVersionType]>,
) => {
	try {
		const {
			queryKey: {
				1: { size, page, ...rest },
			},
		} = context;

		const { results, count, isRunning } = await Parse.Cloud.run('getDataVersion', {
			limit: size,
			skip: (page - 1) * size,
			...rest,
		});

		return {
			data: results,
			meta: {
				last_page: count ? Math.ceil(count / size) : 1,
				count,
			},
			isRunning,
		};
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 * apply or stop version
 * @param param0
 * @returns
 */
export const applyVersionAction = async ({
	updateId,
	status,
	company,
}: {
	updateId: string;
	status: boolean;
	company: Parse.Object;
}) => {
	try {
		const res = await Parse.Cloud.run('applyVersion', {
			updateId,
			status,
			company,
		});

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const applyDeleteAction = async ({
	companyId,
}: {
	companyId: string;
}) => {
	try {
		const res = await Parse.Cloud.run('applyDelete', { companyId });
		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};


/**
 * delete version
 * @param param0
 * @returns
 */
export const deleteVersionAction = async ({ updateId }: { updateId: string }) => {
	try {
		const res = await Parse.Cloud.run('deleteVersion', {
			updateId,
		});

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const stopRunningAction = async ({ updateId }: { updateId: string }) => {
	try {
		const res = await Parse.Cloud.run('stopRunning', {
			updateId,
		});

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};
