import React, { useCallback, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Button } from '@mui/material';
import { UnfoldHorizontal } from 'lucide-react';


import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useRowTableSelection } from '@carbonmaps/ui/hooks/useRowTableSelection';

import Skeleton from '../../components/Skeleton';
import { useCompareProduct } from '../../hooks/useCompareProduct';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';
import { siteColors } from '../../lib/colors';
import CompareProduct from '../products/compareProducts/CompareProduct';

const styles = {
	button: css({
		'&.MuiButtonBase-root': {
			display: 'flex',
			height: '42px',
			padding: '8px 16px',
			justifyContent: 'center',
			alignItems: 'center',
			// gap: '8px',
			borderRadius: '8px',
			border: '1px solid var(--Grey-5, #D1D5DB)',
			background: 'var(--Grey-0, #FFF)',
			'&:hover': {
				background: 'var(--Grey-0, #FFF)',
				borderColor: siteColors.grey900,
			},
			textTransform: 'unset',
			color: siteColors.blackButton,
		},
	}),
};

type CompareButtonProps = {
	title: string;
	isLoading?: boolean;
	product?:any;
	className?: string;
};

const CompareButton = ({ title, isLoading, product, className }: CompareButtonProps) => {

	const { indicator } = useApp();

	// ---- products selected ---- //
	const { selectedRows, removeAllSelectedRows } = useRowTableSelection();

	// state to open compare modal
	const [isCompareOpen, setIsCompareOpen] = useState(false);

	// store for compare product
	const { setProducts, setIndicator, setProductToCompare, setReset } = useCompareProduct();

	// state to open floating menu

	const [isOpenMenu, setIsOpenMenu] = useState(false);


	const analytics = useJuneTrack();

	const [searchQueryParams, setSearchParams] = useSearchQueryParams();


	// ------------------------------------------------------ //
	// ----------- handle compare button click -------------- //
	// ------------------------------------------------------ //
	const handleCompareClick = useCallback(() => {



		// console.log('<**************', currentProductCompare);
		// set products to compare
		// const productIds: Array<string> = [];
		setProducts(
			// currentProductCompare,
		[...product ? [product] : [] , ...selectedRows].map((item: any, index: number) => {
				// productIds.push(item.original.objectId);
				return {
					...(index === 0 ? item : item.original),
					color: (siteColors.compareProductColors as any)[`color${index % 5}`],
				};
			}),
		);
		setIndicator(indicator);

		// set current product to compare
		// setProductToCompare(selectedRows[0]?.original);
		product && setProductToCompare(product) ;
		// open dialog compare
		setIsCompareOpen(true);

		setReset(0);

		// add event Jun
		analytics.track(
			'Compare products',
			{},
			{
				context: { category: 'Products' },
			},
		);

		setSearchParams({ compareProduct: 'true', productToCompare: product ? product.objectId : '' });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setProducts, selectedRows, setIndicator, indicator,  product, setProductToCompare]);

	// ------------------------------------------------------------- //
	// ----------------- handle close compare modal  --------------- //
	// ------------------------------------------------------------- //
	const handleCloseModal = () => {
		setIsCompareOpen(false);
		setSearchParams({ productToCompare: '', compareProduct: 'false' });
		removeAllSelectedRows();
		setIsOpenMenu(false);
	};

	const buttonElement = (
		<Button
				className={cx(styles.button, className)}
				disableRipple
				onClick={handleCompareClick}
				startIcon={<UnfoldHorizontal color={siteColors.grey700} />}

			>
				{title}
			</Button>
	);

	return (
		<>
			{ isLoading
			? <Skeleton>
							{buttonElement}
						</Skeleton>
			: buttonElement}
			{isCompareOpen && <CompareProduct onClose={handleCloseModal} />}
		</>
	);
};

export default CompareButton;
