import { useMemo, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES, ITEM_NATURE_NON_FOOD, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useModelizationProduct, useProductImpact, useProductInfo, useProductLifeChart } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import BlockCard from '../../../components/BlockCard';
import BlockTitle from '../../../components/BlockTitle';
import EmptyValueHelpCircle from '../../../components/EmptyValueHelpCircle';
import LifeCycleChart from '../../../components/charts/LifeCycleChart';
import LifeCycleChartTooltip from '../../../components/charts/LifeCycleChartTooltip';
import Unit from '../../../components/units/Unit';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useSize } from '../../../hooks/useSize';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { CARBON_INDICATOR } from '../../../utils/constants';
import { WaterUnit } from '../../../utils/graphIndicator';

const styles = {
	chip: css({
		cursor: 'pointer',
		display: 'flex',
		padding: '7px 8px',
		alignItems: 'center',
		gap: '8px',
		alignSelf: 'stretch',
		color: 'var(--Grey-8, #4B5563)',
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '140%',
		bordeRadius: '8px !important',
	}),
	activeChip: css({
		backgroundColor: siteColors.grey400,
		borderRadius: '8px',
	}),
};

const ProductLifeCycleBlock = () => {
	const { indicator } = useApp();
	// const theme = useTheme();
	const params = useParams();

	const { data: productInfo } = useProductInfo({ objectId: _.toString(params.productId), viewMode: indicator });

	const containerRef = useRef(null);
	const size = useSize(containerRef);
	const svgParentSize = useMemo(() => {
		if (!size)
			return {
				width: 730,
				height: 230,
			};

		const height = 231;

		return {
			width: (size as any)?.width || 730,
			height,
		};
	}, [size]);

	// ---- fetch data chart ---- //
	const [searchQueryParams] = useSearchQueryParams();
	const { data } = useProductLifeChart({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		productId: params.productId!,
		viewMode: indicator,
		uid: searchQueryParams?.uid,
	});

	const { data: kpiData } = useProductImpact({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.productId!,
		viewMode: indicator,
		uid: searchQueryParams?.uid,
	});


	const { data: dataModelization, isLoading } = useModelizationProduct({
		productId: params.productId!,
	});

	// translation
	const { t } = useTranslation();

	const [unit, setUnit] = useState('kilo');

	const isEmpty = useMemo(() => {
		if (kpiData) {
			return (kpiData as any)[`${indicator}${unit === 'kilo' ? 'Intensity' : 'ImpactPortion'}`] === 0;
		}

		return null;
	}, [kpiData, indicator /* , unit */]);


	const stages = ['Agriculture', 'Transformation', 'Packaging', 'Transport', 'Distribution', 'Consumption'];


	const useCalculatePercentage = (keyPrefix: string, unit?: React.ReactNode) => {
		return useMemo(() => {
			if (!data) return Array.from({ length: stages.length }).fill({ value: 0, link: '#' });

			const input = stages.map((stage, index) => {
				return {
					value: (data as any)?.[`${keyPrefix}${stage}`],
					tooltip: <LifeCycleChartTooltip
						value={(data as any)?.[`${keyPrefix}${stage}`]}
						unit={unit}
						label={t(_.toLower(stage))}
						typeModelization={(dataModelization as any)?.[`typeModelization${stage === 'Agriculture' || stage === 'Packaging' ? 'Compo' : ''}${stage === 'Agriculture' ? 'Ingredient' : stage}`]}
					/>,
					link: index < 3 ? `${FRONT_PATH_NAMES.products.details}/${params.productId!}/${keyPrefix.toLowerCase()}` : undefined,
				};
			});

			const totalValue = _.sumBy(input, (item) => { return item.value ?? 0; });
			return input.map((e) => {
				return {
					...e,
					value: totalValue !== 0 ? ((e.value || 0) * 100) / totalValue : 0,
					link: (productInfo as any)?.['itemNature'] !== ITEM_NATURE_NON_FOOD ? e.link : undefined,
				};
			});
		}, [data, dataModelization, productInfo]);
	};

	const gesInPercent: any = useCalculatePercentage('ges');
	const waterUseInPercent: any = useCalculatePercentage('waterUse', WaterUnit);

	return (
		<div ref={containerRef}>
			<BlockCard css={{ marginBottom: 'unset' }}>
				<div className="flexRow alignCenter" css={{ gap: 8 }}>
					<BlockTitle className="flexRow alignCenter" css={{ marginBottom: 12 }}>
						<Typography variant="h3" component="div">
							{t('Impact du produit', { product: t('product') })}
						</Typography>
					</BlockTitle>
					<div className="flexRow" css={{ gap: 8, position: 'relative', top: '-6px', marginLeft: '8px' }}>
						<div
							onClick={() => {
								setUnit('kilo');
							}}
							className={cx(styles.chip, unit === 'kilo' ? styles.activeChip : '')}
						>
							{t('Par kilo')}
						</div>
						<div
							onClick={() => {
								setUnit('unit');
							}}
							className={cx(styles.chip, unit === 'unit' ? styles.activeChip : '')}
						>
							{t('Par unité')}
						</div>
					</div>
				</div>

				<div className="flexRow" css={{ gap: 8 }}>
					<div
						css={{
							color: indicator === WATER_INDICATOR ? siteColors.water500 : siteColors.carbon500,
							fontVariantNumeric: 'lining-nums tabular-nums',
							fontFamily: 'Inter',
							fontSize: '24px',
							fontStyle: 'normal',
							fontWeight: 400,
							lineHeight: '82.4%',
							letterSpacing: '-0.48px',
							marginBottom: '1rem',
						}}
					>
						{isEmpty
							? (
								<EmptyValueHelpCircle />
							)
							: (
								displayValue(
									kpiData && (kpiData[`${indicator}${unit === 'kilo' ? 'Intensity' : 'ImpactPortion'}`] as number),
								)
							)}
					</div>
					<div
						css={{
							color: siteColors.grey800,
							leadingTrim: 'both',
							textEdge: 'cap',
							fontVariantNumeric: 'lining-nums tabular-nums',
							fontFamily: 'Inter',
							fontSize: '14px',
							fontStyle: 'normal',
							fontWeight: 400,
							lineHeight: '150%',
						}}
					>
						<Unit
							measure={
								indicator === WATER_INDICATOR
									? unit === 'kilo'
										? 'waterIntensity'
										: 'waterImpact'
									: unit === 'kilo'
										? 'carbonIntensityKg'
										: 'carbonImpact'
							}
						/>
					</div>
				</div>

				<LifeCycleChart
					datas={indicator === CARBON_INDICATOR ? gesInPercent : waterUseInPercent}
					viewBox={`0 0 ${svgParentSize.width} ${svgParentSize.height}`}
					withLabel={true}
					iconColor={siteColors.grey700}
					colors={
						indicator === WATER_INDICATOR
							? {
								main: siteColors.water500,
								polygonGradient: [siteColors.water100, siteColors.water100],
							}
							: undefined
					}
					areaHeight={svgParentSize.height}
					areaWidth={svgParentSize.width}
					iconSize={24}
					labelColor={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500}
					strokeWidth={1.5}
					lineWidth={2}
					fontStyles={{
						title: 12,
						subtitle: 12,
					}}
					topValueContainer={{
						width: 70,
						height: 30,
					}}
					itemNature={(productInfo as any)?.['itemNature']}
				/>
			</BlockCard>
		</div>
	);
};

export default ProductLifeCycleBlock;
