// ---------------------------------------------------------- //
// ----------------- GRAPH INDICATOR ------------------------ //
// ---------------------------------------------------------- //

import { Cloud, Cloudy, Droplet, Droplets } from 'lucide-react';

import CarbonImpactPortionIcon from '@carbonmaps/media/icons/cloud-box.svg?react';
import WaterImpactPortionIcon from '@carbonmaps/media/icons/drop-box.svg?react';
import { WORDING_TERMS } from '@carbonmaps/shared/utils/constants';

import Unit from '../components/units/Unit';

import { siteColors } from '../lib/colors';
import { CARBONE_IMPACT_VIEW, CARBON_INDICATOR, INTENSITY_VIEW, WATER_IMPACT_VIEW, WATER_INDICATOR } from './constants';

type Item = {
	value: string;
	label: string;
	unit: React.ReactNode;
	popover: { label: string; key: string; unit: React.ReactNode; precision: number }[];
};

export const DEFAULT_GRAPH_INDICATOR_OPTIONS: Record<string, Item[]> = {
	[CARBON_INDICATOR]: [
		{
			value: 'carbonImpact',
			label: 'Carbone',
			unit: (
				<Unit
					measure="carbonImpactTon"
					fontWeight={500}
					fontSize="12px"
					color={siteColors.grey700}
					lineHeight={1}
					// sx={{ textTransform: 'uppercase' }}
				/>
			),
			popover: [
				{
					label: WORDING_TERMS.IMPACT,
					key: 'carbonImpact',
					unit: (
						<Unit
							measure="carbonImpactTon"
							fontWeight={600}
							lineHeight={'16.8px'}
							fontSize="14px"
							color={siteColors.text}
							// sx={{ textTransform: 'capitalize' }}
						/>
					),
					precision: 0,
				},
				{
					label: 'Volume',
					key: 'volume',
					unit: (
						<Unit
							measure="massTon"
							fontWeight={500}
							fontSize="12px"
							color={siteColors.grey700}
							lineHeight={1}
							// sx={{ textTransform: 'uppercase' }}
						/>
					),
					precision: 0,
				},
			],
		},
	],
	[WATER_INDICATOR]: [
		{
			value: 'waterImpact',
			label: 'Eau',
			unit: (
				<Unit
					measure="waterImpact"
					fontWeight={500}
					fontSize="12px"
					color={siteColors.grey700}
					lineHeight={1}
					// sx={{ textTransform: 'uppercase' }}
				/>
			),
			popover: [
				{
					label: WORDING_TERMS.IMPACT,
					key: 'waterImpact',
					unit: (
						<Unit
							measure="waterImpact"
							fontWeight={600}
							lineHeight={'16.8px'}
							fontSize="14px"
							color={siteColors.text}
							// sx={{ textTransform: 'capitalize' }}
						/>
					),
					precision: 2,
				},
				{
					label: 'Volume',
					key: 'volume',
					unit: (
						<Unit
							measure="massTon"
							fontWeight={500}
							fontSize="12px"
							color={siteColors.grey700}
							lineHeight={1}
							// sx={{ textTransform: 'uppercase' }}
						/>
					),
					precision: 0,
				},
			],
		},
	],

	[WATER_IMPACT_VIEW]: [
		{
			value: 'waterImpact',
			label: 'Eau',
			unit: (
				<Unit
					measure="waterImpact"
					fontWeight={500}
					fontSize="12px"
					color={siteColors.grey700}
					lineHeight={1}
					sx={{ textTransform: 'uppercase' }}
				/>
			),
			popover: [
				{
					label: WORDING_TERMS.IMPACT,
					key: 'waterImpact',
					unit: (
						<Unit
							measure="waterImpact"
							fontWeight={600}
							lineHeight={'16.8px'}
							fontSize="14px"
							color={siteColors.text}
							// sx={{ textTransform: 'capitalize' }}
						/>
					),
					precision: 2,
				},
				{
					label: 'Volume',
					key: 'volume',
					unit: <span>Tonnes</span>,
					precision: 0,
				},
			],
		},
		{
			value: 'carbonImpact',
			label: 'Carbone',
			unit: (
				<Unit
					measure="carbonImpactTon"
					fontWeight={500}
					fontSize="12px"
					color={siteColors.grey700}
					lineHeight={1}
					sx={{ textTransform: 'uppercase' }}
				/>
			),
			popover: [
				{
					label: WORDING_TERMS.IMPACT,
					key: 'carbonImpact',
					unit: (
						<Unit
							measure="carbonImpactTon"
							fontWeight={600}
							lineHeight={'16.8px'}
							fontSize="14px"
							color={siteColors.text}
							// sx={{ textTransform: 'capitalize' }}
						/>
					),
					precision: 0,
				},
				{
					label: 'Volume',
					key: 'volume',
					unit: <span>Tonnes</span>,
					precision: 0,
				},
			],
		},
	],
};

export const getDefaultGraphIndicator = (t: any) => {
	const defaultConfig = {
		[CARBON_INDICATOR]: [
			{
				value: 'carbonImpact',
				label: t('carbon'),
				unit: (
					<Unit
						measure="carbonImpactTon"
						fontWeight={500}
						fontSize="12px"
						color={siteColors.grey700}
						lineHeight={1}
						sx={{ textTransform: 'uppercase' }}
					/>
				),
				popover: [
					{
						label: t(WORDING_TERMS.IMPACT),
						key: 'carbonImpact',
						unit: (
							<Unit
								measure="carbonImpactTon"
								fontWeight={600}
								lineHeight={'16.8px'}
								fontSize="14px"
								color={siteColors.text}
								// sx={{ textTransform: 'capitalize' }}
							/>
						),
						precision: 0,
					},
					{
						label: t('Volume'),
						key: 'volume',
						unit: <span>{t('Tonnes')}</span>,
						precision: 0,
					},
				],
			},
		],
		[WATER_INDICATOR]: [
			{
				value: 'waterImpact',
				label: t('water'),
				unit: (
					<Unit
						measure="waterImpact"
						fontWeight={500}
						fontSize="12px"
						color={siteColors.grey700}
						lineHeight={1}
						sx={{ textTransform: 'uppercase' }}
					/>
				),
				popover: [
					{
						label: t(WORDING_TERMS.IMPACT),
						key: 'waterImpact',
						unit: (
							<Unit
								measure="waterImpact"
								fontWeight={600}
								lineHeight={'16.8px'}
								fontSize="14px"
								color={siteColors.text}
								// sx={{ textTransform: 'capitalize' }}
							/>
						),
						precision: 2,
					},
					{
						label: 'Volume',
						key: 'volume',
						unit: <span>{t('Tonnes')}</span>,
						precision: 0,
					},
				],
			},
		],

		[WATER_IMPACT_VIEW]: [
			{
				value: 'waterImpact',
				label: t('water'),
				unit: (
					<Unit
						measure="waterImpact"
						fontWeight={500}
						fontSize="12px"
						color={siteColors.grey700}
						lineHeight={1}
						sx={{ textTransform: 'uppercase' }}
					/>
				),
				popover: [
					{
						label: t(WORDING_TERMS.IMPACT),
						key: 'waterImpact',
						unit: (
							<Unit
								measure="waterImpact"
								fontWeight={600}
								lineHeight={'16.8px'}
								fontSize="14px"
								color={siteColors.text}
								// sx={{ textTransform: 'capitalize' }}
							/>
						),
						precision: 2,
					},
					{
						label: 'Volume',
						key: 'volume',
						unit: <span>{t('Tonnes')}</span>,
						precision: 0,
					},
				],
			},
			{
				value: 'carbonImpact',
				label: t('carbon'),
				unit: (
					<Unit
						measure="carbonImpactTon"
						fontWeight={500}
						fontSize="12px"
						color={siteColors.grey700}
						lineHeight={1}
						sx={{ textTransform: 'uppercase' }}
					/>
				),
				popover: [
					{
						label: t(WORDING_TERMS.IMPACT),
						key: 'carbonImpact',
						unit: (
							<Unit
								measure="carbonImpactTon"
								fontWeight={600}
								lineHeight={'16.8px'}
								fontSize="14px"
								color={siteColors.text}
								// sx={{ textTransform: 'capitalize' }}
							/>
						),
						precision: 0,
					},
					{
						label: 'Volume',
						key: 'volume',
						unit: <span>{t('Tonnes')}</span>,
						precision: 0,
					},
				],
			},
		],
	};
	return defaultConfig;
};

export const CATEGORIES_GRAPH_INDICATOR_OPTIONS: Record<string, Item[]> = {
	[CARBONE_IMPACT_VIEW]: [
		{
			value: 'carbonImpact',
			label: 'Carbone',
			unit: (
				<Unit
					measure="carbonImpactTon"
					fontWeight={500}
					fontSize="12px"
					color={siteColors.grey700}
					lineHeight={1}
					sx={{ textTransform: 'uppercase' }}
				/>
			),
			popover: [
				{
					label: WORDING_TERMS.IMPACT,
					key: 'carbonImpact',
					unit: (
						<Unit
							measure="carbonImpactTon"
							fontWeight={600}
							lineHeight={'16.8px'}
							fontSize="14px"
							color={siteColors.text}
							// sx={{ textTransform: 'capitalize' }}
						/>
					),
					precision: 0,
				},
				{
					label: 'Quantité vendue',
					key: 'volume',
					unit: <span>unités de vente</span>,
					precision: 0,
				},
			],
		},
		{
			value: 'waterImpact',
			label: 'Eau',
			unit: (
				<Unit
					measure="waterImpact"
					fontWeight={500}
					fontSize="12px"
					color={siteColors.grey700}
					lineHeight={1}
					sx={{ textTransform: 'uppercase' }}
				/>
			),
			popover: [
				{
					label: WORDING_TERMS.IMPACT,
					key: 'waterImpact',
					unit: (
						<Unit
							measure="waterImpact"
							fontWeight={600}
							lineHeight={'16.8px'}
							fontSize="14px"
							color={siteColors.text}
							// sx={{ textTransform: 'capitalize' }}
						/>
					),
					precision: 2,
				},
				{
					label: 'Quantité vendue',
					key: 'volume',
					unit: <span>unités de vente</span>,
					precision: 0,
				},
			],
		},
	],
	[INTENSITY_VIEW]: [
		{
			value: 'carbonIntensity',
			label: 'Carbone',
			unit: (
				<Unit
					measure="carbonIntensityKg"
					fontWeight={500}
					fontSize="12px"
					color={siteColors.grey700}
					lineHeight={1}
					// sx={{ textTransform: 'uppercase' }}
				/>
			),
			popover: [
				{
					label: WORDING_TERMS.INTENSITY,
					key: 'carbonIntensity',
					unit: (
						<Unit
							measure="carbonIntensityKg"
							fontWeight={600}
							lineHeight={'16.8px'}
							fontSize="14px"
							color={siteColors.text}
							// sx={{ textTransform: 'capitalize' }}
						/>
					),
					precision: 2,
				},
				{
					label: 'Quantité vendue',
					key: 'volume',
					unit: <span>unités de vente</span>,
					precision: 0,
				},
			],
		},
		{
			value: 'waterIntensity',
			label: 'Eau',
			unit: (
				<Unit
					measure="waterIntensity"
					fontWeight={500}
					fontSize="12px"
					color={siteColors.grey700}
					lineHeight={1}
					sx={{ textTransform: 'uppercase' }}
				/>
			),
			popover: [
				{
					label: WORDING_TERMS.INTENSITY,
					key: 'waterIntensity',
					unit: (
						<Unit
							measure="waterIntensity"
							fontWeight={600}
							lineHeight={'16.8px'}
							fontSize="14px"
							color={siteColors.text}
							// sx={{ textTransform: 'capitalize' }}
						/>
					),
					precision: 2,
				},
				{
					label: 'Quantité vendue',
					key: 'volume',
					unit: (
						<Unit
							measure="uvc"
							fontWeight={600}
							lineHeight={'16.8px'}
							fontSize="14px"
							color={siteColors.text}
							// sx={{ textTransform: 'capitalize' }}
						/>
					),
					precision: 0,
				},
			],
		},
	],
};

const iconProps = {
	// color: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500,
	size: 30,
	fontWeight: 400,
	strokeWidth: 1.5,
};

const waterColor = siteColors.water500;
const carbonColor = siteColors.carbon500;

export const GLOBAL_INDICATOR_CONFIG = new Map([
	[
		CARBON_INDICATOR,
		{
			impactTitle: WORDING_TERMS.IMPACT,
			impactIcon: <Cloudy {...iconProps} />,
			impactUnity: <Unit measure="carbonImpactTon" color={siteColors.grey700} />,
			intensityTitle: WORDING_TERMS.INTENSITY,
			intensityIcon: <Cloud {...iconProps} />,
			intensityUnity: <Unit measure="carbonIntensityKg" color={siteColors.grey700} />,
			indicatorTitle: 'Indicateurs carbone',
			impactPortionIcon: <CarbonImpactPortionIcon css={{ path: { stroke: carbonColor } }} />,
			impactPortionTitle: WORDING_TERMS.IMPACT_PORTION,
			impactPortionUnity: <Unit measure="carbonImpactPortion" color={siteColors.grey700} />,
		},
	],
	[
		WATER_INDICATOR,
		{
			impactTitle: WORDING_TERMS.IMPACT,
			impactIcon: <Droplets {...iconProps} color={waterColor} />,
			impactUnity: <Unit measure="waterImpact" color={siteColors.grey700} />,
			intensityTitle: WORDING_TERMS.INTENSITY,
			intensityIcon: <Droplet {...iconProps} color={waterColor} />,
			intensityUnity: <Unit measure="waterIntensity" color={siteColors.grey700} />,
			indicatorTitle: 'Indicateurs eau',
			impactPortionIcon: <WaterImpactPortionIcon css={{ path: { stroke: waterColor } }} />,
			impactPortionTitle: WORDING_TERMS.IMPACT_PORTION,
			impactPortionUnity: <Unit measure="waterImpactPortion" color={siteColors.grey700} />,
		},
	],
]);
