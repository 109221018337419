import { type QueryFunctionContext } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';

type sbtiFlagKpiField = {
	tonnage: number;
	carbonImpact: number;
	gesFlagImpact: number;
	gesFlagLmCo2Impact: number;
	gesFlagLmImpact: number;
	gesFlagLmNonCo2Impact: number;
	gesFlagLulucImpact: number;
	gesFlagRemovalImpact:number;
	gesNonFlagImpact: number;
	gesAgricultureImpact: number;
	year: number;

}

/**
 * getSBTIFlagKpiDataAction
 * @param context
 * @returns
 */
export const getSBTIFlagKpiDataAction = async (
	context: QueryFunctionContext<readonly ['getDataMaterialKpi', { period?: string | number; team?:string, indicator?: string }]>,
): Promise<sbtiFlagKpiField> => {
	try {
		const {
			queryKey: [, { period, team }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.sbtiFlag.getKpiData, {
			period,
			team,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

type sbtiFlagGraphField = {
	year: number;
	gesImpactFlag: number;
	gesTotal: number;
}

export const getSBTIGraphDataAction = async (
	context: QueryFunctionContext<readonly ['getSBTIGraphData', {team: string}]>,
): Promise<Array<sbtiFlagGraphField>> => {
	try {
		const {
			queryKey: [, { team }],
		} = context;
		const res = (await Parse.Cloud.run(functionName.sbtiFlag.getSBTIGraphData, {
			team,
		})) as any; // Everything must be already JSONs from the server.
		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};


type GetFacetParams = {
	period?: number;
	category?: string;
	limit?: number;
	team?: string;

};


export const getSBTiDataFacetAction = async (
	context: QueryFunctionContext<readonly ['getSBTiDataFacet', GetFacetParams]>,
) => {
	try {
		const {
			queryKey: {
				1: {  category, period, team, limit },
			},
		} = context;
		const result = await Parse.Cloud.run(functionName.sbtiFlag.getSBTiDataFacet, {
			category,
			period,
			team,
			limit,
		});
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};
