import { useCallback } from 'react';

import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';

const styles = {
	container: css({
		minWidth: 50,
		minHeight: 20,
	}),
};

const Teams = (props: any) => {
	const {
		value,
		row,
		column,
	} = props;

	const translate = (v: string) => {return v;};

	const transFunc = column?.props?.transFunc || translate;
	const teams = (value || [])?.map((team: any) => {return transFunc(team.get('name'));});
	const callback = column?.props?.callback;

	// add a callback to the row
	const handleClick = useCallback(() => {
		if (callback) {
			callback(row.original);
		}

	}, [callback, row]);

	return (
		<div className={cx('pointer', styles.container)} onClick={handleClick}>
			<Typography className="grey9 width100">{teams.join(' - ')}</Typography>
		</div>
	);
};

export default Teams;
