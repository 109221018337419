import { useCallback, useMemo, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Building2, Globe, Trash2 } from 'lucide-react';
import { useToggle } from 'react-use';

import { ALL_OPTION_VALUE } from '@carbonmaps/shared/utils/constants';
import { deleteTeamAction, getTeamsAction } from '@carbonmaps/ui/actions/team.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import SearchAutocomplete from '@carbonmaps/ui/components/saas/Table/SearchAutocomplete';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';

import Drawer from '../../components/dialogs/Drawer';
import TableHeaderCell from '../../components/table/TableHeaderCell';
import Typography from '../../components/Typography';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { getClientAuthKey } from '../../lib/react-query/features/auth/auth.actions';

import TeamForm, { Team } from './TeamForm';
import TeamTab from './TeamTab';


const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			borderBottom: `1px solid ${theme.palette.grey[400]}`,

		},
		'.bordered th': {
			background: theme.palette.common.white,
			paddingBottom: '16px !important',
		},
		'.bordered th:last-child':  {
			width: 150,
		},
		// hover states
		'& table tbody > tr:hover > td': {
			background: `${theme.palette.grey[200]} !important`,
		},
	};
};

type UsersProps = {
	companyId?: string;
};


const Teams = ({ companyId }: UsersProps) => {

	const { t } = useTranslation();
	const fetchIdRef = useRef(0);
	const queryClient = useQueryClient();
	// theme
	const theme = useTheme();
	// styles
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	const [open, toggleDialog] = useToggle(false);

	const [team, setTeam] = useState<Team | null>();



	// ------------------------------------------- //
	// ------------- state table ----------------- //
	// ------------------------------------------- //
	const [pageCount] = useState(0);
	const [paramsTable, setTableParams] = useState({
		input: '',
		page: 1,
		size: 100,
	});

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);


		// ---- fetch data --- //
		const { data: dataTable, isLoading: isTableLoading } = useQuery({
			queryKey: [
				'getTeamsTable',
				paramsTable,
			],
			queryFn: getTeamsAction,
		});

	// -------------------------------------- //
	// ------------ handle delete row ------- //
	// -------------------------------------- //

	// mutation delete team
	const { mutate: deleteTeam, isLoading } = useMutation({
    mutationKey: ['deleteTeam'], // This mutation is used to save a team by sending a message
    mutationFn: deleteTeamAction,
    onSuccess: async () => {
			// Marks the operation as successful upon completion
			// onClose();
				queryClient.invalidateQueries({ queryKey: ['getTeamsTable'] });
				queryClient.invalidateQueries({ queryKey: ['findUsers'] });
				queryClient.invalidateQueries({ queryKey: getClientAuthKey });

			},
			onError: (error: any) => {
				/* Handle error here */
			},
	});

	// callback delete team
	const handleDeleteRow = useCallback((id: string) => {
		deleteTeam({ id });
	}, [deleteTeam]);

	// columns
	const columns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('team')}  />;
				},
				accessor: 'name',
				editable: true,
				component: 'TeamLabel',
				type: 'string',

			},
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('filtre')} />;
				},
				accessor: 'filters',
				editable: true,
				component: 'FilterField',
				type: 'string',
				disableSortBy: true,
				props: {
					translateFunc: t,
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('')} />;
				},
				accessor: 'id',
				editable: true,
				component: 'Action',
				type: 'action',
				icon: <Trash2 color={theme.palette.grey[700]} size={20}/>,
				withConfirm: true,
				callback: handleDeleteRow,
				callbackEdit: (value: any) => {
					setTeam(value);
					toggleDialog();
				},
				// callbackEditSupplierUser: (value: any) => {
				// 	handleClickEdit(value);
				// },
				messageConfirm: 'Voulez vous suppimer cet equipe ?',
				disableSortBy: true,
			},
		];

	}, [t, theme.palette.grey, handleDeleteRow, toggleDialog]);

	const handleCloseDialog  = () => {
		toggleDialog();
		queryClient.invalidateQueries({ queryKey: ['getTeamsTable'] });
		queryClient.invalidateQueries({ queryKey: ['findUsers'] });
		queryClient.invalidateQueries({ queryKey: getClientAuthKey });

	};

	const onAddButtonClick = () => {
		setTeam(null);
		toggleDialog();
	};


	return (
		<div className="flexColumn width100 gap16">
			<h3
				css={{
					fontWeight: 600,
					fontSize: 32,
					marginTop: 0,
					marginBottom: 24,
					color: siteColors.text,
				}}
			>
				{t('teams')}
			</h3>
			<div className="flexRow alignCenter spaceBetween width100 gap16">
				<SearchAutocomplete
					setParamsAutocomplete={(params) => {
						// setQueryParams({ ...queryParams, ...params });
						setTableParams({ ...paramsTable, ...params });
					}}
					isLoading={false}
					placeholder={t('team-search-placeholder')}
					options={[]}
				/>
				<BButton
					onClick={onAddButtonClick}
					iconLeft={<AddIcon />}
					variant="primary"
					label={
						<Typography color="white" fontWeight={600}>
							{t('add-team-button')}
						</Typography>
					}
					addStyles={{ display: 'inline-flex', alignSelf: 'center', minWidth: 'fit-content' }}
				/>
			</div>
			<TableComponent
				skeleton={isTableLoading}
				loading={isTableLoading}
				tableName={'team'}
				fetchData={updateTableParams}
				onSortingColumn={handleSortingTable}
				pageCount={dataTable?.meta?.last_page || 0}
				isSelectable={false}
				columns={columns}
				data={(dataTable?.data || []).map((item) => {
					return {
						...item,
						name: t(item.name),
						icon: item.name === ALL_OPTION_VALUE ? <Globe color={siteColors.grey7} size={20} /> : <Building2  color={siteColors.grey7} size={20}/>,
				};
				})}
				pageSize={paramsTable.size}
				addStyles={stylesTable}
			/>
			<Drawer onClose={toggleDialog} open={open}>
				{team
					? <TeamTab onClose={handleCloseDialog} team={team} /> :
						<TeamForm onClose={handleCloseDialog} />
					}
			</Drawer>
		</div>
	);

};

export default Teams;
