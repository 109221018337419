import { ReactNode } from 'react';

import { css } from '@emotion/css';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

import BubbleChatButton from '../../components/bubbleChat/BubbleChatButton';

const styles = {
	chatBubble: css({
		position: 'fixed',
		bottom: 0,
		right: 20,
	}),
};

type Props = {
	children?: ReactNode;
	sidebar?: ReactNode;
};

const BaseLayout = ({ children, sidebar }: Props) => {
	return (
		<div className="flexRow relative">
			{sidebar}
			<Box
				minHeight="100vh"
				display="flex"
				flexDirection="column"
				sx={{ flexGrow: 1, width: { sm: 'calc(100% - 328px)' } }}
			>
				{children ? children : <Outlet />}
			</Box>
			{/* <BubbleChatButton /> */}
		</div>
	);
};

export default BaseLayout;
