import React, { ReactNode } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import type { SxProps } from '@mui/system';
import _ from 'lodash';

import BlueCheck from '@carbonmaps/media/icons/bluecheckok.svg?react';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import { siteColors } from '../../../lib/colors';

export type SheetPageHeaderProps = {
	icon: ReactNode;
	rightElement?: ReactNode;
	sx?: SxProps;
} & (
	| {
			variant: 'fixed';
			title: React.ReactNode;
			subtitle?: React.ReactNode;
			isBlueCheck?: string | boolean;
			overTitle?: string;
	  }
	| {
			variant: 'flexible';
			leftElement: ReactNode;
	  }
);

const SheetPageHeader = ({ icon, rightElement, sx = {}, ...props }: SheetPageHeaderProps) => {
	const theme = useTheme();
	return (
		<Box
			sx={_.merge(
				{
					// background:
					// 	'linear-gradient(90deg, #E6EAFF 0.53%, #EFE0FF 99.99%), linear-gradient(90deg, #E4F3E3 0.53%, #E0F0F3 99.99%), linear-gradient(90deg, #E6EAFF 0.53%, #EFE0FF 99.99%)',
					background: siteColors.primary10,
					borderRadius: '16px',
					mb: pxToRem(16),
				},
				sx,
			)}
		>
			<Box
				className="flexRow alignCenter spaceBetween"
				// height="175px"
				paddingRight="24px"
				flexWrap="nowrap"
			>
				{/* on the left */}
				<Box className="flexRow alignCenter" height="120px" flexWrap="nowrap">
					{/* Box Icon */}
					<Box
						className="flexRow alignCenter justifyCenter"
						border={`0.475px solid ${theme.palette.grey[700]}`}
						borderRadius="8px"
						bgcolor="#FFF"
						width="72px"
						height="72px"
						marginLeft="-8px"
					>
						{/* <ProductIcon css={{ width: '38px', height: '38px' }} /> */}
						{icon}
					</Box>

					{props.variant === 'fixed'
? (
						<Box ml="24px">
							{props.overTitle && (
								<Box className="flexRow alignCenter">
									<Typography variant="h3" fontWeight={500}>
										{props.overTitle}
									</Typography>
								</Box>
							)}

							<Box className="flexRow alignCenter" mb={pxToRem(8)}>
								{(props.isBlueCheck === 'yes' || props.isBlueCheck === true) && (
									<BlueCheck css={{ marginRight: '8px' }} />
								)}
								<Typography variant="h2" fontWeight={700}>
									{props.title}
								</Typography>
							</Box>

							{props.subtitle && (
								<Box className="flexRow alignCenter">
									<Typography variant="h3" fontWeight={500}>
										{props.subtitle}
									</Typography>
								</Box>
							)}
						</Box>
					)
: (
						props.leftElement
					)}
				</Box>

				{/* on the right */}
				{rightElement && rightElement}
			</Box>
		</Box>
	);
};

export default SheetPageHeader;
