import { useEffect } from 'react';

import { css } from '@emotion/css';
import { t } from 'i18next';
import { HeartPulse, Presentation } from 'lucide-react';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import IconContainer from '../../components/layout/list/IconContainer';
import ListHeader from '../../components/layout/list/ListHeader';
import { siteColors } from '../../lib/colors';
import AuditDataSet from '../audits/general/tabs/dataset/AuditDataSet';

import DataDiagHeader from './DataDiagHeader';

const CONTENT_WIDTH = 'calc(100% - 4rem)';
const CONTENT_MAX_WIDTH = '1200px';

const DataDiagnostics = () => {
	const { setBreadcrumbs } = useApp();

	useEffect(() => {
		setBreadcrumbs([{ text: t('Rapports') }]);
	}, [setBreadcrumbs]);

	return (
		<div>
			<DataDiagHeader />
			<div
				style={{
					width: CONTENT_WIDTH,
					margin: 'auto',
					maxWidth: CONTENT_MAX_WIDTH,
				}}
				css={{
					display: 'flex',
					flexWrap: 'wrap',
					gap: 32,
				}}
			>
				 <AuditDataSet />
			</div>
		</div>
	);
};

export default DataDiagnostics;
