import { css, cx } from '@emotion/css';
import { Toolbar } from '@mui/material';

import { siteColors } from '../../../lib/colors';

const styles = {
	container: css({
		paddingTop: 16,
		flexGrow: 1,
		padding: 8,
		paddingLeft: 0,

	}),

	innerContainer: css({
		padding: 8,
		background: siteColors.common.white,
		boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
		borderRadius: 8,
		overflow: 'hidden',
	}),
};

type CompareProductContentProps = {
	compareTypeMenu: React.ReactNode;
	children: React.ReactNode;
}

const CompareContent = ({ compareTypeMenu, children }: CompareProductContentProps) => {

	return (
		<main className={styles.container}>
			<Toolbar />
			<div className={cx('flexRow gap24 nowrap', styles.innerContainer)}>
				{compareTypeMenu}
				{children}
			</div>
		</main>
	);

};

export default CompareContent;
