

import { SyntheticEvent, useEffect, useMemo } from 'react';

import { css } from '@emotion/css';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import { ITab } from '../../components/layout/page/SheetPageLayout';
import TabsBar, { TabItem } from '../../components/TabsBar';
import { useTranslation } from '../../hooks/useTranslation';
import { TOP_BAR_HEIGHT } from '../../utils/constants';

import SBTiFlagHeader from './SBTiFlagHeader';
import Analyze from './tabs/analyze/Analyze';

const CONTENT_WIDTH = 'calc(100% - 4rem)';
const CONTENT_MAX_WIDTH = '1200px';

const styles= {
	container: css({
		paddingBottom: 100,
	}),
	header: css({
		maxWidth: CONTENT_MAX_WIDTH,
	}),
};

const DashboardSbtiFlag = () => {

	// ---- translation ---- //
	const { t } = useTranslation();
// ---- bread crumbs ---- //
	const { setBreadcrumbs } = useApp();

	// ---------------------------------------- //
	// ------------ items tab ----------------- //
	// ---------------------------------------- //
	const tabs: Record<string, ITab> = useMemo(() => {
		return {
			analyse: {
				title: t('Analyse'),
				element: <Analyze />,
				tabName: t('Analyse'),
			},
			declarationForm: {
				title: t('Formulaire de déclaration SBTi'),
				element: <div>Test</div>,
				tabName: t('declarationForm'),
				disabled: true,
			},
			documentation: {
				title: t('Documentation'),
				element: <div>Test</div>,
				tabName: t('documentation'),
				disabled: true,
			},
		};
	}, [t]);

	// ---- active tab ---- //
	const params = useParams();
	const currentTab = params.tab || _.keys(tabs)[0];

	// ---- handle tab ---- //
	const handleTabChange = (_: SyntheticEvent, value: string) => {/*  */};

	useEffect(() => {
			setBreadcrumbs([{ text: t('Rapports'), link: FRONT_PATH_NAMES.rapports },  { text: t('SBTi FLAG') }]);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [t]);

		const renderTabs = (
			<TabsBar
				value={currentTab}
				onChange={handleTabChange}
				sticky
				sx={{
					mb: pxToRem(50),
					width: CONTENT_WIDTH,
					maxWidth: CONTENT_MAX_WIDTH,
					mx: 'auto',
					top: TOP_BAR_HEIGHT,
					'&.sticky': { width: '100%', borderRadius: 0 },
					marginTop: 3,
					marginBottom: '0',
					backgroundColor: '#F3F4F6',
				}}
			>
				{Object.entries(tabs).map(([tabKey, { title, disabled }]) => {
					return <TabItem key={tabKey} value={tabKey} label={title} disabled={disabled} />;
				})}
			</TabsBar>
		);

		const renderTabContent = (
			<div
				style={{
					width: CONTENT_WIDTH,
					margin: 'auto',
					maxWidth: CONTENT_MAX_WIDTH,
				}}
			>
				{tabs[currentTab]?.element}
			</div>
		);

	return (
	<div className={styles.container}>
			<SBTiFlagHeader className={styles.header}/>
			{renderTabs}
			{renderTabContent}
		</div>
	);

};

export default DashboardSbtiFlag;
