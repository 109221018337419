import { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { ListChecks } from 'lucide-react';
import { nanoid } from 'nanoid';
import { Link } from 'react-router-dom';

import { classNames, FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { getUserFullName } from '@carbonmaps/shared/utils/utils';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import IconContainer from '../../components/layout/list/IconContainer';
import ListHeader from '../../components/layout/list/ListHeader';
import Skeleton from '../../components/Skeleton';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

const styles = {
	container: css({
		padding: '8px 0px 0px !important',
		width: 'calc(100% - 4rem)',
		margin: '0 auto',
		gap: '24px',
	}),
	waitingBlock: css({
		width: 540,
		display: 'flex',
		padding: '23px 24px',
		flexDirection: 'column',
		alignItems: 'flex-start',
		gap: '24px',
		borderRadius: '16px',
		background: '#FFF',
		boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.07), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
	}),
	waitingElement: css({
		padding: 16,
		borderRadius: '16px',
		border: '1px solid rgba(0, 0, 0, 0.10)',
		background: 'var(--Grey-1, #F9FAFB)',
	}),
};

const MyQuestionnaires = () => {
	const { t, i18n } = useTranslation();
	const { setBreadcrumbs } = useApp();

	const languageCode = getIsoCountryCode(i18n.language);

	useEffect(() => {
		setBreadcrumbs([{ text: t('Requêtes') }]);
	}, [setBreadcrumbs, t]);

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const questionnaires = _.get(authData, 'dataForm.questionnaires');

	const answeredQuestionnaires = _.filter(questionnaires, (item) => {
		return _.get(item, 'status') === 'completed';
	});

	const waitingQuestionnaires = _.filter(questionnaires, (item) => {
		return _.get(item, 'status') !== 'completed';
	});

	return (
		<div>
			<div css={{ marginBottom: 32 }}>
				<ListHeader
					title={t('suppliers')}
					icon={
						<IconContainer
							element={<ListChecks color={siteColors.white} width={32} height={32} />}
							color={siteColors.grey800}
						/>
					}
				/>
			</div>
			<div className={cx(styles.container)}>
				<div className={cx(styles.waitingBlock)} css={{ marginBottom: 32 }}>
					<div className={cx('fontWeight500 grey900')} css={{ fontSize: 16 }}>
						{t('Demandes en attente')}
					</div>
					{_.map(waitingQuestionnaires, (item, index) => {
						const qName =
							_.get(item, `questionForm.translation.${languageCode}.name`) ||
							_.get(item, 'questionForm.translation.fr.name') ||
							_.get(item, 'questionForm.translation.en.name') ||
							_.get(item, 'questionForm.translation.it.name') ||
							_.get(item, 'questionForm.translation.es.name') ||
							_.get(item, 'questionForm.translation.de.name');
						const year = _.get(item, 'questionForm.year');

						const supplierName = _.get(item, 'supplierName');
						const supplierUid = _.get(item, 'supplierUid');
						const questionFormId = _.get(item, 'questionForm.objectId');

						return (
							<div
								key={questionFormId}
								className={cx(styles.waitingElement, 'width100 flexRow alignCenter spaceBetween')}
							>
								<div>
									<div
										className={cx('fontWeight500 grey900')}
										css={{
											lineHeight: '150%',
										}}
									>
										{qName} {year}
									</div>
									<div className="fontWeight400 grey700" css={{ fontSize: 13 }}>
										{supplierName} ({supplierUid})
									</div>
								</div>
								<div>
									<Link to={`${FRONT_PATH_NAMES.questionnaire}?questionFromId=${questionFormId}`}>
										<BButton label={t('Compléter')} variant="secondary" />
									</Link>
								</div>
							</div>
						);
					})}
				</div>

				{/* table */}
				<div>
					<table
						css={{
							width: '100%',
							'& tr': {
								height: 60,
								'& th, & td': {
									borderBottom: `1px solid ${siteColors.grey500}`,
								},
							},
							'& th': {
								color: siteColors.grey700,
								textTransform: 'uppercase',
								fontSize: 12,
							},
							'& tr > th, & tr > td': {
								textAlign: 'left',
								width: '50%',
							},
						}}
					>
						<thead>
							<tr>
								<th>{t('Questionnaires passés')}</th>
								<th>{t('Complété par')}</th>
							</tr>
						</thead>
						<tbody>
							{_.map(answeredQuestionnaires, (item, index) => {
								return <QRow key={nanoid()} item={item} />;
							})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default MyQuestionnaires;

const QRow = ({ item }: { item: any }) => {
	const { i18n } = useTranslation();

	const languageCode = getIsoCountryCode(i18n.language);
	const qName =
		_.get(item, `questionForm.translation.${languageCode}.name`) ||
		_.get(item, 'questionForm.translation.fr.name') ||
		_.get(item, 'questionForm.translation.en.name') ||
		_.get(item, 'questionForm.translation.it.name') ||
		_.get(item, 'questionForm.translation.es.name') ||
		_.get(item, 'questionForm.translation.de.name');
	const year = _.get(item, 'questionForm.year');

	const supplierName = _.get(item, 'supplierName');
	const supplierUid = _.get(item, 'supplierUid');
	const questionFormId = _.get(item, 'questionForm.objectId');

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const { data, isLoading } = useQuery({
		queryKey: ['findQFormRow_xxx', {}],
		queryFn: async () => {
			try {
				const rseObject = await new Parse.Query(classNames.CACHE_RSE_TABLE)
					.equalTo('company', new Parse.Object(classNames.COMPANY, { objectId: authData?.session.company?.objectId }))
					.equalTo('questionForm', new Parse.Object(classNames.QUESTION_FORM, { objectId: questionFormId }))
					.equalTo('supplierUid', supplierUid)
					.select(['answerFromUserId'])
					.first();

				if (!rseObject) {
					return Promise.resolve({
						user: null,
					});
				}

				return await Parse.Cloud.run('getAdditionalData_xxx', { id: rseObject?.get('answerFromUserId') });
			} catch (error) {
				return Promise.reject(error);
			}
		},
	});

	const loadingElement = <Skeleton />;

	return (
		<tr>
			<td css={{ color: siteColors.grey800 }}>
				<div>
					{qName} {year}
				</div>
				<div>
					{supplierName} ({supplierUid})
				</div>
			</td>
			<td css={{ color: siteColors.grey800 }}>
				{isLoading
? loadingElement
: !data.user
? (
					<div>-</div>
				)
: (
					<>
					<div>
					{getUserFullName(data?.user)}
				</div>
				<div>{_.get(data?.user, 'email')}</div>
					</>
				)}
			</td>
		</tr>
	);
};
