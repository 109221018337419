import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // Import the UTC plugin
import _ from 'lodash';

dayjs.extend(utc);

export function convertToUTC(dateString: string) {
	return dayjs(dateString, 'YYYY-MM-DD').utc().valueOf();
}

export function generateRandomDateTuples(count: number, startDate: string, endDate: string) {
	const uniqueDates = new Set();

	while (uniqueDates.size < count) {
		// Generate random date within the given range
		const randomDate = randomDateInRange(startDate, endDate);
		uniqueDates.add(randomDate); // Ensures uniqueness
	}

	// Map unique dates to tuples with random numbers
	return Array.from(uniqueDates).map(date => { return [date, _.random(1, 100)]; }) as [string, number][];
}

export function randomDateInRange(startDate: string, endDate: string) {
	const start = new Date(startDate).getTime();
	const end = new Date(endDate).getTime();

	// Generate a random timestamp within the range
	const randomTimestamp = _.random(start, end);

	// Convert timestamp back to a date string
	return new Date(randomTimestamp).toISOString().split('T')[0];
}

export function calculateCumulativeSum(data: [number, number][]) {
	let cumulativeSum = 0; // To store the running total
	return data.map(([date, value]) => {
		cumulativeSum += value; // Add the current value to the cumulative sum
		return [date, cumulativeSum] as [number, number]; // Return the date with the cumulative sum
	});
}

export const handleOneSeries = (seriesData: [string, number][]) => {
	let tuples = seriesData as unknown as [number, number][];
	tuples = tuples
		.map(([date, value]) => {
			return [convertToUTC(date as never), value] as [number, number];
		})
		.sort((a, b) => {
			return a[0] - b[0];
		});

	tuples = calculateCumulativeSum(tuples);

	return tuples;
};

export const getMinAndMaxDate = (...series: [number, number][][]) => {
	let minDate: number |undefined;
	let maxDate: number |undefined;

	let minIndex: number |undefined;
	let maxIndex: number |undefined;

	const emptySeries: number[] = [];

	series.forEach((s, index) => {
		const firstUtcDate = _.first(s)?.[0];
		const lastUtcDate = _.last(s)?.[0];

		if (!firstUtcDate || !lastUtcDate) {
			emptySeries.push(index);
			return;
		}

		if (minDate === undefined) {
			minDate = firstUtcDate;
		}

		if (maxDate === undefined) {
			maxDate = lastUtcDate;
		}

		minDate = _.min([minDate, firstUtcDate]);

		if (minDate === firstUtcDate) {
			minIndex = index;
		}

		maxDate = _.max([maxDate, lastUtcDate]);

		if (maxDate === lastUtcDate) {
			maxIndex = index;
		}
	});

	return { minDate, minIndex, maxDate, maxIndex, emptySeries };
};
