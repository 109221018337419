import { ReactNode } from 'react';

import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import { useAutoCloseSidebar } from '../../hooks/useAutoCloseSidebar';
import { useImpactYear, useYearSelection } from '../../hooks/useImpactYear';
import BaseLayout from '../_common/BaseLayout';
import SideBar from '../_common/SideBar';
import TopBar from '../_common/TopBar';

type Props = {
	children?: ReactNode;
	disableIndicatorSwitch?: boolean;
	disableYearSelection?: boolean;
	disableAllYear?: boolean;
	withTeamSelect ?: boolean;
};

const MainLayout = ({ children, disableIndicatorSwitch, disableYearSelection, disableAllYear, withTeamSelect }: Props) => {
	// Utiliser le hook personnalisé sans définir les routes (utilise celles par défaut)
	const { isOpen, toggleSidebar } = useAutoCloseSidebar();

	return (
		<BaseLayout sidebar={<SideBar open={isOpen} />}>
			<TopBar
				open={isOpen}
				onToggle={toggleSidebar}
				disableIndicatorSwitch={disableIndicatorSwitch}
				disableYearSelection={disableYearSelection}
				disableAllYear={disableAllYear}
				withTeamSelect={withTeamSelect}
			/>

			{/* The actual content */}
			<Box component="main" flexGrow={1}>
				{children ? children : <Outlet />}
			</Box>
		</BaseLayout>
	);
};

export default MainLayout;
