import { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { CARBON_INDICATOR } from '@carbonmaps/ui/utils/constants';

import { useJuneTrack } from '../../../../../hooks/useJuneTrack';

import SuppliersFilter from './SuppliersFilter';
import SuppliersTable from './SuppliersTable';
import TradeOffSupplier from './SupplierTradeoff';

const Impacts = () => {
	const { t } = useTranslation();

	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('suppliers') }, { text: 'Impacts' }]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	const { indicator, setIndicator, setBreadcrumbs } = useApp();

	const analytics = useJuneTrack();
		const trackEvent = useCallback(
			(name: string, options?: any) => {
				if (!analytics) return;
				analytics.track(name, options, {
					context: { category: 'Explore' },
				});
			},
			[analytics],
		);

	useEffect(() => {
			if (indicator) return;
			setIndicator && setIndicator(CARBON_INDICATOR);

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [trackEvent]);

	// ---- useEffect track even on land page ---- //
		useEffect(() => {
			trackEvent('Explore Land suppliers');
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [trackEvent]);

	return (
		<div
			css={{
				'.filterContainer-wrapper': {
					paddingLeft: 0,
					paddingRight: 0,
				},
			}}
		>
			<SuppliersFilter />
			<TradeOffSupplier />
			<SuppliersTable manualSortBy />
		</div>
	);
};

export default Impacts;
