import { css, cx } from '@emotion/css';
import { Trash } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import { siteColors } from '../../lib/colors';
import Typography from '../Typography';


const styles = {
	container: css({
		paddingTop: 14,
		paddingBottom: 14,
		borderBottom: `1px solid ${siteColors.grey5}`,
	}),
	logo: css({
		width: 40,
		height: 40,
		background: siteColors.grey4,
		borderRadius: '40px',
		color: siteColors.grey7,
		fontWeight: 700,
	}),
	contentInfo: css({
		gap: 12,
	}),
	button: css({
		border: `1px solid ${siteColors.grey5} !important`,
		borderRadius: '4px !important',
		minWidth: '32px !important',
		padding: '6px !important',
		height: '32px !important',
	}),
};
export type User = {
	firstName: string;
	lastName: string;
	email: string;
	objectId: string;
}

type MemberCardProps = {
	user: User;
	onRemoveUser: (user: User) => void;
}

const MemberCard = ({ user, onRemoveUser }: MemberCardProps) => {

	return (
		<div className={cx('flexRow alignCenter width100 spaceBetween', styles.container)}>
			<div className={cx('flexRow alignCenter', styles.contentInfo)}>
				<div className={cx('flexCenter', styles.logo)}>
					{user?.firstName?.charAt(0)}{user?.lastName?.charAt(0)}
				</div>
				<div className='flexColumn'>
					<Typography className="grey9">{user.firstName} {user.lastName}</Typography>
					<Typography className="grey7">{user.email}</Typography>
				</div>
			</div>
			<BButton iconLeft={<Trash  size={16} color={siteColors.grey7}/>} label="" className={styles.button} onClick={() =>{return onRemoveUser(user);}} variant="tertiary" />
		</div>
	);

};

export default MemberCard;
