import { useMemo } from 'react';

import { css } from '@emotion/css';
import { useTheme } from '@mui/material';
import { ArrowRight } from 'lucide-react';

import { CARBON_INDICATOR, FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { formatUrl } from '@carbonmaps/shared/utils/utils';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';


import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import ImpactCarbonePopover from '../table/popover/ImpactCarbonePopover';
import WaterImpactPopover from '../table/popover/WaterImpactPopover';
import TableHeaderCell from '../table/TableHeaderCell';

const styles = {
	percentContainer: css({
		height: '14px !important',
		width: '60% !important',
	}),
	popoverImpactStyle: css({ paddingRight: 0 }),

	label: css({
		maxWidth: '200px !important',
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
	}),
	arrowIcon: css({
		opacity: 0,
		flexShrink: 0,
	}),
};
type CategoryTableProps = {
	data?: Array<{
		label: string;
		value: number;
		objetId: string;
		category: string;
	}>;
	emptyMessageData?:string;
};

const formatDataThenSetData = (data: any) => {
	if (!data) return [];
	const category = data[0]?.category;
	return data?.map((item: any) => {
		return {
			...item,
			link: item?.link || `${FRONT_PATH_NAMES.analyse}/${category}/${formatUrl(item.label)}/synthesis`,
		};
	});
};

const CategoryTable = ({ data, emptyMessageData }: CategoryTableProps) => {
	const { t } = useTranslation();

	const { indicator } = useApp();
	const theme = useTheme();

	// ---- define columns table ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={''} />;
				},
				accessor: 'label',
				editable: true,
				sortDescFirst: true,
				component: 'TagLabel',
				type: 'string',
				disableSortBy: true,
				props: {
					className: styles.label,
					emptyValue: t('no-category'),
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={''} />;
				},
				accessor: 'value',
				editable: true,
				sortDescFirst: true,
				component: 'PercentField',
				disableSortBy: true,
				type: 'percent',
				props: {
					containerClassName: styles.percentContainer,
					bgColor: indicator === CARBON_INDICATOR ? siteColors.carbon100 : siteColors.water100,
					color: {
						primary: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500,
						secondary: indicator === CARBON_INDICATOR ? siteColors.carbon100 : siteColors.water100,
					},
					popoverComponent: indicator === CARBON_INDICATOR ? ImpactCarbonePopover : WaterImpactPopover,
					popoverClassName: styles.popoverImpactStyle,
				},
			},
		];
	}, [indicator, t]);

	return (
		<TableComponent
			skeleton={false}
			loading={false}
			fetchData={() => { /*  */ }}
			pageCount={1}
			noDataMessage={emptyMessageData || t('Vous devez passer à la nouvelle version pour avoir cette information')}
			pageSize={10}
			columns={tableColumns}
			data={formatDataThenSetData((data || []) as any).map((item: any) => {return {
				...item,
				label: (
					<div className={styles.label}>
						<span>{item.label}</span>
						<ArrowRight className={styles.arrowIcon} size={16} />
					</div>
				),
			};})}
			stickyHeader
			transFunc={t}
			isSelectable={false}
			addStyles={{
				'.bordered': {
					border: 'none !important',
				},
				' .bordered th': {
					borderLeft: 'none!important',
					background: `${theme.palette.common.white} !important`,
				},
				'.bordered th >div': {
					paddingTop: '0px !important',
					paddingLeft: '0px !important',
				},
				'.bordered tr': {
					'&:hover': {
						[`.${styles.arrowIcon}`]: {
							opacity: 1,
						},
						'td:first-of-type': {
							color: 'red',
						},
					},
				},
				'.bordered td': {
					borderLeft: 'none!important',
					background: `${theme.palette.common.white} !important`,
					// borderBottom: `1px solid ${theme.palette.grey[400]}`,
					borderTop: `1px solid ${indicator === CARBON_INDICATOR ? siteColors.carbon100 : siteColors.water100
						} !important`,
					padding: '0px !important',
					'.MuiButtonBase-root': {
						paddingRight: '0px !important',
						width: '240px !important',
						maxWidth: '100%',
					},
					[`.${styles.arrowIcon}`]: {
						color: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500,
					},
				},
				'.bordered th:last-child': {
					borderRadius: '6px !important',
				},
			}}
			pagination={false}
		/>
	);
};

export default CategoryTable;
