import _ from 'lodash';

import LeverIcon from '@carbonmaps/media/icons/lever.svg?react';
import { APBO_MAP, MESSAGE_KEY_APBO } from '@carbonmaps/shared/utils/constants';

import HorizontalBarChart from '../../../components/apbo/barchart/HorizontalBarChart';
import ApboCard from '../../../components/apbo/card/ApboCard';
import Comment from '../../../components/apbo/Comment';
import Typography from '../../../components/Typography';
import Card from '../../../components/widget/Card';
import { useYearOptions } from '../useApbo.hook';

import StaticIndicator from './StaticIndicator';

// const dataInit = [
// 	{ value: 67.44, label: 'Cultures' },
// 	{ value: 32.56, label: 'Energie & Effluents' },
// 	{ value: 58.14, label: 'Ration' },
// 	{ value: 86.05, label: 'Troupeau' },
// ];

// const exploitationData = [
// 	{ value: 6.98, label: '1' },
// 	{ value: 13.95, label: '2' },
// 	{ value: 25.58, label: '3' },
// 	{ value: 23.26, label: '4' },
// 	{ value: 4.65, label: '5' },
// 	{ value: 25.58, label: '6 et +' },
// ];

const LeverActionsView = () => {
	const { value: year } = useYearOptions();

	const dataInit = _.entries(_.get(APBO_MAP.get(year), 'leverActionsView.dataInit', {})).map(([key, value]) => {
		return { value, label: key };
	});

	const exploitationData = _.entries(_.get(APBO_MAP.get(year), 'leverActionsView.exploitationData', {})).map(([key, value]) => {
		return { value, label: String(key) };
	});

	return (
		<ApboCard messageKey={MESSAGE_KEY_APBO.leversAction}>
			<Card
				title="Leviers d’action"
				icon={<LeverIcon />}
				center={false}
				sx={{
					padding: '24px !important',
					width: '100%',
					gap: 0,
				}}
				rightContent={<Comment messageKey={MESSAGE_KEY_APBO.leversAction} />}
			>
				<div className="flexColumn width100 gap24">
					<div className="flexColumn width100 gap16">
						<div className="flexRow nowrap alignCenter width100 gap24 stretch">
							<HorizontalBarChart data={dataInit} className="flex1" title="Catégories des plans d’action" />
							<HorizontalBarChart data={exploitationData} className="flex1" title="Leviers par exploitation" />
						</div>
						<Typography className="fontWeight600">Nombre total d’exploitations avec leviers : {_.get(APBO_MAP.get(year), 'leverActionsView.nbExploitation', 0)}</Typography>
					</div>
					<StaticIndicator />
				</div>
			</Card>
		</ApboCard>
	);
};

export default LeverActionsView;
