import useTheme from '@mui/material/styles/useTheme';
import { UnfoldHorizontal } from 'lucide-react';

import IconContainer from '../../../components/layout/list/IconContainer';
import ListHeader from '../../../components/layout/list/ListHeader';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';

type AuditsHeaderProps = {
	title?: string;
	subtitle?: string;
};

const AuditsHeader = ({ title, subtitle }: AuditsHeaderProps) => {
	const { t } = useTranslation();

	const default_title = t('Jeu de données');

	return (
		<ListHeader
			title={title || default_title}
			subtitle={subtitle}
			icon={
				<IconContainer
					element={<UnfoldHorizontal size={32} color={siteColors.white} />}
					color={siteColors.blueGray500}
				/>
			}
		/>
	);
};

export default AuditsHeader;
