import { read, utils } from 'xlsx';

import BlueCheckOK from '@carbonmaps/media/icons/bluecheckok.svg?react';
import {
	ECO_CONCEVABLE_OPTION_LABEL,
	FILTER_NAME_ECO_CONCEVABLE,
	FILTER_NAME_TYPE_MODELIZATION_ING,
	FOLDER_NAME_TYPE_MODELIZATION_ING,
	TAG_ADVANCED_OPTION_LABEL,
	TYPE_MODELIZATION_INGREDIENT,
} from '@carbonmaps/shared/utils/constants';
import { translation } from '@carbonmaps/ui/i18n/translation';

import { ACCUMULATOR_NUMBER_FACET } from './constants';

export const getLabelFacetNumber = (number: number) => {
	return `${number} à ${number + ACCUMULATOR_NUMBER_FACET}`;
};

interface SortValue {
	id: string;
	direction: number;
}

export const getSortingOrientation = (sortData: {
	id: string;
	isSortedDesc: boolean | undefined;
	isSorted: boolean;
}): SortValue => {
	const { id, isSortedDesc, isSorted } = sortData;
	const data = {
		direction: 0,
		id,
	};

	if (!isSorted && isSortedDesc === undefined) {
		data.direction = 1;
	} else if (isSorted && isSortedDesc === false) {
		data.direction = -1;
	} else {
		data.direction = 0;
	}

	return data as SortValue;
};

export function getServerUrl() {
	if (window.LOCAL) {
		//---- LOCAL ----//
		return 'http://127.0.0.1:1339/';
	} else if (window.PRODUCTION) {
		//---- PROD ----//
		return 'https://app.carbonmaps.io/';
	} else if (window.SANDBOX) {
		//---- SANDBOX ----//
		return 'https://sandbox.carbonmaps.io/';
	} else if (window.DEV) {
		return 'https://front-dev-dot-carbonmaps-preproduction.ew.r.appspot.com/';
	} else {
		//---- PREPROD ----//
		return 'https://carbonmaps-preproduction.ew.r.appspot.com/';
	}
}

export const defineWindowConstant = (constName: string) => {
	Object.defineProperty(window, constName, {
		value: 'myValue',
		writable: false,
		enumerable: true,
		configurable: false,
	});
};

export const isCategoryNotFound = (items: any) => {
	return (
		items?.length &&
		!items.find((item: any) => {
			return item?.index === 'marque';
		})
	);
};

export const getLabelNodeFacet = (key: string, bucketId: any, trans?: any) => {
	const t = trans || translation;
	const iKey = String(bucketId).toLowerCase();

	if (['tagAdvanced', 'tagAdvancedModelization'].includes(key)) {
		return (
			<>
				{iKey === 'yes' ? <BlueCheckOK css={{ marginRight: '4px' }} /> : null}
				{t((TAG_ADVANCED_OPTION_LABEL as any)[iKey])}
			</>
		);
	}

	if (key === 'typeModelizationIngredient') {
		// return t((TYPE_MODELIZATION_INGREDIENT as any)[iKey], {
		// 	recipe: t('recipe'),
		// 	recipe_lowercase: t('recipe'),
		// });
		return t(`${iKey}`, {
			recipe: t('recipe'),
			recipe_lowercase: t('recipe'),
		});
	}

	if (key === FILTER_NAME_ECO_CONCEVABLE) {
		return <>{t((ECO_CONCEVABLE_OPTION_LABEL as any)[iKey], { products: t('products') })}</>;
	}

	return String(bucketId);
};

export const getLabelValueTranslate = (key: string, bucketId: any, t: any) => {
	// if (key === 'typeModelizationIngredient') {
	// 	return t((TYPE_MODELIZATION_INGREDIENT as any)[String(bucketId).toLowerCase()], {
	// 		recipe: t('recipe'),
	// 		recipe_lowercase: t('recipe'),
	// 	});
	// }
	const iKey = String(bucketId).toLowerCase();

	if (['tagAdvanced', 'tagAdvancedModelization'].includes(key)) {
		return (TAG_ADVANCED_OPTION_LABEL as any)[iKey];
	}

	if (key === FILTER_NAME_TYPE_MODELIZATION_ING) {
		// return t((TYPE_MODELIZATION_INGREDIENT as any)[iKey], {
		// 	recipe: t('recipe'),
		// 	recipe_lowercase: t('recipe'),
		// });
		return t(`${iKey}`, {
			recipe: t('recipe'),
			recipe_lowercase: t('recipe'),
		});
	}

	if (key === FILTER_NAME_ECO_CONCEVABLE) {
		return t((ECO_CONCEVABLE_OPTION_LABEL as any)[String(bucketId).toLowerCase()], { products: t('products') });
	}

	return String(bucketId);
};

export const getFiltersValue = (searchParams: any) => {
	Object.keys(searchParams).reduce((prev: any, cur: any) => {
		return [...prev, { path: cur, values: searchParams[cur], type: typeof searchParams[cur][0] }];
	}, []);
};

export const getFacetToShow = (facetFilters: any, data: any, allData: any) => {
	const paths = facetFilters.map((f: any) => {
		return f.path;
	});

	const facets = data?.facet; /*  || {} */
	const allFacets = allData?.facet;

	for (const path of paths) {
		facets[path] = allFacets?.[path];
	}

	return {
		...data,
		facet: facets,
	};
};

export const SUPPLIER_LABEL_FOLDER = 'Fournisseur';


export const excelDateToJSDate = (date: any) => {
	let converted_date: any = new Date(Math.round((date - 25569) * 864e5));
	converted_date = String(converted_date).slice(4, 15);
	date = converted_date.split(' ');

	const day = date[1];
	let month = date[0];

	month = 'JanFebMarAprMayJunJulAugSepOctNovDec'.indexOf(month) / 3 + 1;
	if (month.toString().length <= 1)
		month = `0${month}`;
	const year = date[2];
	return String(`${month}/${day}/${year.slice(2, 4)}`);
};

export const moveItemToFront = (arr: any[], id: string | number) => {
	// Separate the item with the given id from the rest of the array
	const itemToMove = arr.filter((item) => { return item.field === id; });
	const otherItems = arr.filter((item) => { return item.field !== id; });

	// Combine the item with the rest of the array, placing it at the front
	return itemToMove.concat(otherItems);
};

//----------------------------------------------------------------------------------//
//------------------------ for upload file via csv or excel ------------------------//
//----------------------------------------------------------------------------------//
export const getParsedDataFromCsvOrExcel = (e: any, fileExtension: string) => {
	if (!e.target?.result) return; // Exit if no result is found
	const bstr = e.target.result;
	let workbook: any;
	const isCsv = fileExtension === '.csv';

	// Decode the file content as UTF-8 text
	if (isCsv === true) {
		const textDecoder = new TextDecoder('utf-8');
		const decodedText = textDecoder.decode(new Uint8Array(bstr as ArrayBuffer));
		// Read the decoded text as a workbook
		workbook = read(decodedText, { type: 'string' });
	}
	else {
		workbook = read(bstr, { type: 'binary' });
	}

	// Extract the first sheet from the workbook
	const sheetName = workbook.SheetNames[0];
	const sheet = workbook.Sheets[sheetName];

	// Convert the sheet data into a JSON array
	const parsedData = utils.sheet_to_json<any>(sheet, { header: 1 });

	return parsedData;

};

//----------------------------------------------------------------------------------//
//------------------------ valid upload file csv or excel -------------------------//
//----------------------------------------------------------------------------------//
export const isValidCsvExcelFileExtension = (fileName: string) => {
	const validExtensions = ['.csv', '.xls', '.xlsx'];
	const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

	return validExtensions.includes(fileExtension);
};


export const sorting = (data: Array<number>, sort: 'asc' | 'desc'='asc') => {
	if (sort === 'asc') {
		return data.sort((a, b) => {return a - b;});
	} else {
		return data.sort((a, b) => {return b - a;});
	}
};
