import { css } from '@emotion/css';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import BlockTitle from '../../../../../components/BlockTitle';
import ProductFloatingMenu from '../../../../../components/comparison/product/ProductFloatingMenu';
import LightTooltipGlossary from '../../../../../components/LightTooltipGlossary';
import SupplyTable from '../../../../../components/supplier/SupplyTable';
import { useGlossary } from '../../../../../hooks/useGlossary';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { useSupplierSetup } from '../../hooks/supplier.hooks';


const styles = {
	mb40: css({
		marginBottom: '40px',
	}),
};

const formatDataTable = (data: any, indicator: string) => {
	if (!data?.length) return [];
	return data.map((item: any) => {
		return {
			...item,
			percentage: indicator === CARBON_INDICATOR ? item.carbonIntensityPercent : item.waterIntensityPercent,
		};
	});
};

const Supply = () => {
	const { indicator } = useApp();
	const { productsSupplier, supplierObject, isLoading } = useSupplierSetup();
	const { t } = useTranslation();
	const { g } = useGlossary();

	return (
		<div className={styles.mb40}>
			<BlockTitle>
				<LightTooltipGlossary title={g('approvisionnements')}>
					{t('Approvisionnements issus de ce fournisseur')}
				</LightTooltipGlossary>
			</BlockTitle>
			<SupplyTable
				isTableLoading={isLoading}
				data={formatDataTable(productsSupplier || [], indicator)}
				indicator={indicator}
				withPercent
				withImpactPortion={supplierObject?.supplier.iTypeItems?.includes('product')}
			/>
			<ProductFloatingMenu />
		</div>
	);
};

export default Supply;
