import React from 'react';

import { css, cx } from '@emotion/css';
import { Dialog, DialogContent, type DialogProps, DialogTitle, IconButton } from '@mui/material';
import _ from 'lodash';
import { X } from 'lucide-react';

import { siteColors } from '../../../lib/colors';
import Typography from '../../Typography';


type ModalSelectProps = {
	title: React.ReactNode;
	subtitle?: React.ReactNode;
	open: DialogProps['open'];
	onClose?: DialogProps['onClose'];
	children: React.ReactNode;
	container?: any;
	search?: React.ReactNode;
}

const filterContainerStyle = css({
	'& .filterContainer-wrapper': {
		padding: '0!important',
		width: '90%',
	},
});

const ModalSelect = ({ title, open, onClose, search,  children, container }: ModalSelectProps) => {

		return (
			<Dialog
			container={container}
				open={open}
				onClose={onClose}
				scroll="paper"
				PaperProps={{
					sx: {
						borderRadius: 3,
					},
				}}
				fullWidth
				maxWidth="lg"
			>
				<DialogTitle component="div" id="scroll-dialog-title" sx={{ padding: '32px', position: 'relative' }}>
					<Typography variant="h2" sx={{ color: siteColors.primary, mb: '16px' }}>
						{title}
					</Typography>

					<div className={cx('flexRow alignCenter', filterContainerStyle)}>
						{search}
					</div>

					<IconButton
						sx={{ position: 'absolute', right: 10, top: 10 }}
						onClick={(e) => {
							onClose?.(e, 'backdropClick');
						}}
					>
						<X size={20} />
					</IconButton>
				</DialogTitle>
				<DialogContent
					dividers={true}
					sx={{
						padding: 0,
					}}
				>
					{children}
				</DialogContent>
			</Dialog>
		);

};

export default ModalSelect;
