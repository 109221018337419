import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { useQuery } from '@tanstack/react-query';
import { AlertCircle } from 'lucide-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import z from 'zod';

import { IUser } from '@carbonmaps/shared/types/user.types';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import AlertStatic from '../../components/Toast/AlertStatic';
import { useTranslation } from '../../hooks/useTranslation';
import { getUserByUsernameAction } from '../../lib/react-query/features/user/user.actions';

const resetPasswordSchema = z
	.object({
		password: z
			.string({ required_error: 'Veuillez entrer un nouveau mot de passe' })
			.min(12, 'Le mot de passe doit contenir au moins 12 caractères')
			.regex(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, 'Le mot de passe doit contenir au moins un caractère spécial.'),
		confirmPassword: z
			.string({ required_error: 'Vous devez confirmer votre mot de passe' })
			.min(1, 'Vous devez confirmer votre mot de passe'),
	})
	.refine((data) => { return data.password === data.confirmPassword; }, {
		message: "Le mot de passe saisi n'est pas identique",
		path: ['confirmPassword'],
	});

type ResetPasswordInput = z.infer<typeof resetPasswordSchema>;

const ResetPasswordForm = () => {
	const [searchParams] = useSearchParams();
	const username = searchParams.get('username');
	const token = searchParams.get('token');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const theme = useTheme();
	const [message, setMessage] = useState(null);

	const { t, i18n } = useTranslation();
	const languageCode = getIsoCountryCode(i18n.language);

	// ---- fetch user email ---- //
	const { data: email, isLoading: isLoadingGetUsername } = useQuery({
		queryKey: [
			'getUserByUsername',
			{ username },
		],
		queryFn: getUserByUsernameAction,
	});

	const onSubmit: SubmitHandler<ResetPasswordInput> = async (values) => {
		try {
			setIsLoading(true);
			await Parse.Cloud.run('resetPassword', {
				username,
				token,
				newPassword: values.password,
				languageCode,
			});
			setIsLoading(false);
			navigate(FRONT_PATH_NAMES.logIn);
		} catch (error: any) {
			setMessage(t(error.message));
			setIsLoading(false);
		}
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ResetPasswordInput>({
		resolver: zodResolver(resetPasswordSchema),
	});

	return (
		<Box px={0} py={2} maxWidth={'386px'}>
			<Typography
				variant="inherit"
				css={{
					fontWeight: 400,
					fontSize: 24,
				}}
			>
				{t('Réinitialiser le mot de passe')}
			</Typography>

			<Box mb="32px">
				<Typography mb={1.5}>
					{t('welcome')} {!isLoadingGetUsername && (email ?? username)},
				</Typography>
				<Typography mb={1.5}>
					{t('Entrez le nouveau mot de passe pour votre compte')}
				</Typography>
				<Typography>{t('Vous pourrez ensuite vous y connecter')}.</Typography>
			</Box>


			<div css={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
				{message && <AlertStatic text={t(message)} type="error" icon={<AlertCircle css={{ color: '#C74600' }} />} />}
				<BInput
					required
					id="password"
					label={t('password')}
					type="password"
					fullWidth
					margin="dense"
					{...register('password')}
					name="password"
					error={errors.password ? true : false}
					errorMessage={t(errors?.password?.message)}
				/>
				<BInput
					required
					id="confirmPassword"
					label={t('confirm-password')}
					type="password"
					fullWidth
					margin="dense"
					{...register('confirmPassword')}
					name="confirmPassword"
					error={errors.confirmPassword ? true : false}
					errorMessage={t(errors?.confirmPassword?.message)}
				/>
			</div>

			<Box mt={3} css={{ marginBottom: 48 }}>
				<BButton
					variant="primary"
					label={
						isLoading
							? (
								<CircularProgress size={20} css={{ color: theme.palette.common.white }} />
							)
							: (
								t('register-and-login')
							)
					}
					onClick={handleSubmit(onSubmit)}
					sx={{ minWidth: '400px' }}
				/>
			</Box>
		</Box>
	);
};

export default ResetPasswordForm;
