import { css } from '@emotion/css';

import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import Dialog from '../dialogs/Dialog';

import SimulationForm from './SimulationForm';

type CreateScenarioModalProps = {
	title: any;
	open: boolean;
	isLoading: boolean;
	onSubmit: (a?: any) => void;
	onClose: () => void;
	options?: any;
	message?: string;
	isSuccess?: any;
	isError?: any;
	selectedOption?: any;
	setSelectedOption?: any;
	setSearch?: any;
	isLoadingOption?: boolean;
};

const styles = {
	actionsContainer: css({
		padding: '0px 24px',
	}),

	button: css({
		gap: 8,
	}),

	infoContent: css({
		paddingTop: '16px',
		fontSize: '16px !important',
	}),
	checkbox: css({
		border: cn('1px solid', siteColors.grey500),
		background: siteColors.common.white,
		paddingLeft: 16,
	}),
	disabledButton: css({
		pointerEvents: 'none',
		opacity: '0.8',
	}),
};

const CreateScenarioModal = ({
	title,
	open,
	isLoading,
	onSubmit,
	onClose,
	options = [],
	selectedOption,
	setSelectedOption,
	message,
	isError,
	isSuccess,
	setSearch,
	isLoadingOption,
}: CreateScenarioModalProps) => {
	const { t } = useTranslation();
	return (
		<Dialog
			title={''}
			open={open}
			toggle={onClose}
			isLoading={isLoading}
			paperProps={{
				width: '500px',
			}}
			withCancelButton={false}
		>
			<SimulationForm
				options={options}
				title={title}
				subTitle={t('scenario-form-subtitle')}
				toggle={onClose}
				onSubmit={onSubmit}
				message={message}
				isError={isError}
				isSuccess={isSuccess}
				isLoading={isLoading}
				setSelectedOption={setSelectedOption}
				selectedOption={selectedOption}
				setSearch={setSearch}
				isLoadingOption={isLoadingOption}
			/>
		</Dialog>
	);
};

export default CreateScenarioModal;
