import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { t } from 'i18next';
import _ from 'lodash';
import { Minus } from 'lucide-react';
import ReactDOMServer from 'react-dom/server';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatCompactNumber, formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { usePeriodOptions } from '../../../hooks/useImpactYear';
import { siteColors } from '../../../lib/colors';
import { CARBON_INDICATOR } from '../../../utils/constants';
import Unit from '../../units/Unit';

const styles = {
	container: css({
		justifyContent: 'space-between' /* Optionnel si vous souhaitez un espace entre les éléments */,
		alignItems: 'center',
		display: 'flex',
		width: '100%',
		color: siteColors.grey800,
	}),
	left: css({}),
	right: css({
		marginLeft: 'auto',
	}),
	tooltipContainer: css({
		zIndex: 50000,
		padding: 16,
		minWidth: 300,
		gap: 16,
	}),
	tooltipByKilo: css({
		marginTop: 8,
		paddingTop: 8,
		borderTop: `1px solid ${siteColors.grey500}`,
	}),
	tooltipIndicator: css({
		display: 'flex',
		justifyContent: 'space-between',
	}),
	tooltipLabel: css({
		fontSize: '14px',
		fontWeight: 400,
		color: siteColors.grey900,
		display: 'flex',
		alignItems: 'center',
		verticalAlign: 'middle',
	}),
	tooltipValue: css({
		fontSize: '14px',
		fontWeight: 400,
	}),
	carbon: css({
		color: siteColors.carbon500,
	}),
	water: css({
		color: siteColors.water500,
	}),
};

type ImpactTonnageGraphProps = {
	data: any;
	graphWidth?: string;
	showLegend?: boolean;
};

const formatLabel: any = function (this: any) {
	return formatCompactNumber(this?.value as any);
};

const ImpactTonnageGraph = ({
	data,
	graphWidth,
	showLegend = true,
}: ImpactTonnageGraphProps) => {
	const { indicator } = useApp();
	const { setPeriodOptions } = usePeriodOptions();

	// calculate percent with each item
	const dataPercent = useMemo(() => {
		const maxValue = Math.max(
			...data.map((item: any) => {
				return item[`${indicator}Impact`] || 0;
			}),
			...data.map((item: any) => {
				return item[`${indicator}ImpactEstimated`] || 0;
			}),
		);

		const result = data.map((item: any) => {
			const value = item[`${indicator}Impact`];
			const estimatedValue = item[`${indicator}ImpactEstimated`];
			return {
				...item,
				label: item.objectId,
				tonnage: item?.tonnage, //todo verify if tonnes is correct
				value,
				estimatedValue,
				percentValue: value ? (value * 100) / maxValue : 0,
				percentEstimatedValue: estimatedValue ? (estimatedValue * 100) / maxValue : 0,
			};
		});

		setPeriodOptions(
			result.map((item: any) => {
				return { data: item, value: item.objectId, label: item.objectId };
			}),
		);

		return result;
	}, [data, indicator]);

	const unit =
		indicator === CARBON_INDICATOR
? (
			<>
				tCO<sub>2</sub>e
			</>
		)
: (
			<>
				m<sup>3</sup>
			</>
		);

	const tooltipFormatter: any = function (this: any) {
		return ReactDOMServer.renderToString(
			<div className={cx('flexColumn', styles.tooltipContainer)}>
				<div className="flexColumn">
					<span style={{ fontSize: '16px', fontWeight: 600, color: siteColors.grey900 }}>{this.x}</span>
				</div>

				<div className="width100">
					<div className={cx(styles.tooltipIndicator)}>
						<span className={cx(styles.tooltipLabel)}>
							<Minus color={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500} />
							<span className={cx(styles.tooltipValue, indicator === CARBON_INDICATOR ? styles.carbon : styles.water)}>
								{t('Impact')}
							</span>
						</span>
						<span className={cx(styles.tooltipValue, indicator === CARBON_INDICATOR ? styles.carbon : styles.water)}>
							{formatNumber(this.points[0].y.toFixed(0), undefined, 0)} {unit}
						</span>
					</div>
					<div className={cx(styles.tooltipIndicator)}>
						<span className={cx(styles.tooltipLabel)}>
							<Minus color={siteColors.grey600} />
							{t('Sales')}
						</span>
						<span className={cx(styles.tooltipValue)}>
							{formatNumber(this.points[this.points.length === 4 ? 2 : 1].y.toFixed(0), undefined, 0)} {t('tonnes')}
						</span>
					</div>
					<div className={cx(styles.tooltipIndicator, styles.tooltipByKilo)}>
						<span className={cx(styles.tooltipLabel)}>{t('Impact par kilo')}</span>
						<span className={cx(styles.tooltipValue, indicator === CARBON_INDICATOR ? styles.carbon : styles.water)}>
							{formatNumber(this.points[0].y / (this.points[this.points.length === 4 ? 2 : 1].y ?? 1), undefined, 2)}{' '}
							<Unit
								variant="caption"
								measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
							/>
						</span>
					</div>
				</div>
			</div>,
		);
	};

	const currentYear = new Date().getFullYear();

	const countBeforeCurrentYear = useMemo(() => {
		return _.filter(
			_.sortBy(
				data?.map?.((d: any) => {
					return d.objectId;
				}) || [],
			),
			(year) => {
				return year < currentYear;
			},
		).length;
	}, [data]);

	const arrayNullBeforeCurrentYear = useMemo(() => {
		return _.times(countBeforeCurrentYear, () => {
			return null;
		});
	}, [countBeforeCurrentYear]);

	const commonOptionsTonnage = {
		name: 'Tonnage',
		lineWidth: 2,
		type: 'line',
		color: siteColors.grey600, // Ligne grise pour le tonnage
		marker: {
			enabled: true,
			symbol: 'circle', // Points en forme de petit rond
			radius: 4, // Taille du cercle
			lineWidth: 2, // Épaisseur du contour du cercle
			fillColor: 'transparent', // Cercle vide,
			lineColor: siteColors.grey600, // Couleur du contour rouge
		},
		yAxis: 0,
	};

	const commonOptionsImpact = {
		name: 'Impact',
		type: 'line',
		lineWidth: 2,
		color: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500,
		marker: {
			enabled: true,
			symbol: 'circle', // Points en forme de petit rond
			radius: 4, // Taille du cercle
			lineWidth: 2, // Épaisseur du contour du cercle
			fillColor: 'transparent', // Cercle vide
			lineColor: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500, // Couleur du contour rouge
		},
		yAxis: 1,
	};

	const options = {
		chart: {
			type: 'line',
			width: null,
			reflow: true, // Active le redimensionnement automatique
		},
		title: {
			text: null, // Pas de titre au centre
		},
		xAxis: {
			categories: dataPercent?.map((d: any) => {
				return d.label;
			}), // Remplacez par vos années spécifiques
			labels: {
				style: {
					color: siteColors.grey800, // Change la couleur des chiffres de l'axe X en rouge
				},
			},
			lineColor: siteColors.grey500,
			crosshair: {
				color: siteColors.grey600, // Couleur de la ligne verticale
				width: 1, // Largeur de la ligne
			},
		},
		yAxis: [
			{
				min: 0,
				maxPadding: 0.2, // Ajoute un espace supplémentaire en haut de l'échelle

				// Axe pour l'Impact
				title: {
					text: '', // Étiquette à droite
				},
				labels: {
					formatter: formatLabel,
					style: {
						color: siteColors.grey800, // Change la couleur des chiffres de l'axe X en rouge
					},
				},
				opposite: true,
			},
			{
				min: 0,
				maxPadding: 4, // Ajoute un espace supplémentaire en haut de l'échelle

				// Axe pour le Tonnage
				title: {
					text: '', // Étiquette à droite
				},
				labels: {
					formatter: formatLabel,
					style: {
						color: siteColors.grey800, // Change la couleur des chiffres de l'axe X en rouge
					},
				},
			},
		],
		legend: {
			align: 'right',
			verticalAlign: 'top',
			layout: 'vertical',
			x: 0,
			y: 0,
			floating: true,
			enabled: false, // Désactive la légende
		},
		series: [
			{
				...commonOptionsImpact,
				data: dataPercent
					?.filter((f: any) => {
						return parseInt(f.label) <= currentYear;
					})
					?.map((d: any) => {
						return indicator === CARBON_INDICATOR
							? d.carbonImpact !== 0
								? d.carbonImpact
								: d.carbonImpactEstimated
							: d.waterImpact !== 0
							? d.waterImpact
							: d.waterImpactEstimated;
					}),
				dashStyle: 'Solid',
			},
			{
				...commonOptionsImpact,
				// eslint-disable-next-line no-unsafe-optional-chaining
				data: [
					...arrayNullBeforeCurrentYear,
					// eslint-disable-next-line no-unsafe-optional-chaining
					...dataPercent
						?.filter((f: any) => {
							return parseInt(f.label) >= currentYear;
						})
						?.map((d: any) => {
							return indicator === CARBON_INDICATOR
								? d.carbonImpact !== 0
									? d.carbonImpact
									: d.carbonImpactEstimated
								: d.waterImpact !== 0
								? d.waterImpact
								: d.waterImpactEstimated;
						}),
				],
				dashStyle: 'Dash',
			},
			{
				...commonOptionsTonnage,
				data: dataPercent
					?.filter((f: any) => {
						return parseInt(f.label) <= currentYear;
					})
					?.map((d: any) => {
						return d.tonnage;
					}),
				dashStyle: 'Solid',
			},
			{
				...commonOptionsTonnage,
				// eslint-disable-next-line no-unsafe-optional-chaining
				data: [
					...arrayNullBeforeCurrentYear,
					// eslint-disable-next-line no-unsafe-optional-chaining
					...dataPercent
						?.filter((f: any) => {
							return parseInt(f.label) >= currentYear;
						})
						?.map((d: any) => {
							return d.tonnage;
						}),
				],
				dashStyle: 'Dash',
			},
		],
		tooltip: {
			useHTML: true,
			shared: true,
			backgroundColor: siteColors.common.white,
			formatter: tooltipFormatter,
			style: {
				boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
			},
			borderRadius: 8,
			borderWidth: 0,
			outside: true,
		},
		credits: {
			enabled: false,
		},
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 200,
					},
					chartOptions: {
						legend: {
							align: 'center',
							verticalAlign: 'bottom',
							layout: 'horizontal',
						},
					},
				},
			],
		},
	};

	return (
		<>
			{showLegend
? (<div className={cx(styles.container)}>
				<div className={cx('flexRow nowrap', styles.left)}>
					<Minus color={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500} />
					{indicator === CARBON_INDICATOR
? (
						<div style={{ color: siteColors.carbon500 }}>
							Impact (tCO<sub>2</sub>e)
						</div>
					)
: (
						<div style={{ color: siteColors.water500 }}>
							Impact (m<sup>3</sup>)
						</div>
					)}
				</div>
				<div className={cx('flexRow nowrap', styles.right)}>
					{' '}
					<Minus color={siteColors.grey600} />{' '}
					<div>
						{t('Sales')} ({t('tonnes')})
					</div>
				</div>
			</div>)
: null}
			<div style={{ maxWidth: '100%', width: '100%', height: '100%', overflow: 'visible' }}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
					containerProps={{
						style: {
							width: graphWidth ?? '100%',
							height: '155px',
						},
					}}
				/>
			</div>
		</>
	);
};

export default ImpactTonnageGraph;
