import { css, cx } from '@emotion/css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Trash2 } from 'lucide-react';
import { useToggle } from 'react-use';

import TrashIcon from '@carbonmaps/media/icons/trash.svg?react';

import Popover from '../../../../../apps/front/src/components/Popover';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';

type Props = {
	value: any;
	parseKey: string;
	callback: (...params: any[]) => void;
	isOpen?: any;
};

const styles = {
	popoverContainer: css({
		padding: '24px',
		width: '294px',
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: '#FFFFFF',
		boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.4), 0px 1px 2px rgba(0, 0, 0, 0.45)',
	}),

	titlePopover: css({
		textAlign: 'left !important',
		fontSize: '16px !important',
		fontWeight: '600 !important',
	} as any),

	content: css({
		gap: 20,
		marginTop: '16px',
	}),
	button: css({
		fontSize: '16px !important',
		fontWeight: 600,
		textTransform: 'none !important',
		gap: 10,
		color: '#C74600 !important',
		background: '#FFE4CB !important',
		'& :hover': {
			background: '#FFCA99 !important',
		},
		'& .Mui-disabled': {
			// color: '#C74600',
			color: '#C74600 !important',
			background: '#FFE4CB !important',
		},
		borderRadius: '8px !important',
	} as any),
	ctButton: css({
		'&:hover': { background: 'unset !important' },
	}),
};

const ActionsCell = ({
	value,
	parseKey,
	callback, // This is a custom function that we supplied to our table instance
}: Props) => {
	const [isClose, toggleClose] = useToggle(true);

	return (
		<div>
			<Popover
				isClose={isClose}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				trigger={
					<Button className={cx('width100', styles.ctButton)}>
						{/* <TrashIcon /> */}
						<Trash2 color={siteColors.grey700} />
					</Button>
				}
				content={
					<div className={styles.popoverContainer}>
						<Typography
							textAlign={'left'}
							sx={{
								fontWeight: 600,
								fontSize: 16,
							}}
						>
							Supprimer l'utilisateur {value?.firstName} {value?.lastName} ?
						</Typography>

						<Typography textAlign={'left'}>Cette action est irréversible</Typography>

						<div className={cx('flexColumn width100', styles.content)}>
							<Button
								data-key={parseKey}
								data-id={value?.objectId}
								id={value?.objectId}
								onClick={(event) => {
									callback(parseKey, value?.objectId);
									setTimeout(() => {
										toggleClose();
									}, 500);
								}}
								className={styles.button}
							>
								Confirmer la suppression
							</Button>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default ActionsCell;
