import { css } from '@emotion/css';
import { Drawer as MuiDrawer } from '@mui/material';

const styles = {
	container: css({
		'.MuiPaper-root': {
				minWidth: 420,
				padding: 24,
		},
	}),
};

type DrawerProps = {
	open: boolean;
	anchor?: 'left' | 'right' | 'top' | 'bottom';
	onClose: () => void;
	children: React.ReactNode;
}

const Drawer = ({ open, onClose, children, anchor='right' }: DrawerProps) => {

	return (
		<MuiDrawer
			anchor={anchor}
			open={open}
			onClose={onClose}
			className={styles.container}
		>
		{children}
		</MuiDrawer>
	);

};

export default Drawer;
