import React from 'react';

import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { HelpCircle } from 'lucide-react';

import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import { siteColors } from '../../../../../apps/front/src/lib/colors';
import BPopover from '../BPopover';

import EmptyValue from './EmptyValue';

const styles = {
	container: css({
		height: 16,
		width: '45%',
		minWidth: 100,
		borderRadius: 500,
		overflow: 'hidden',
		background: 'var(--background)',
	}),
	innerContainer: css({
		height: '100%',
		borderRadius: 6,
		width: '100%',
	}),
	text: css({
		marginRight: '8px',
		fontWeight: 600,
		fontSize: '16px ! important',
	}),
	percent: css({
		marginRight: '8px !important',
	}),
};

const PercentField = (props: any) => {
	const { value, row, column, sortedRows } = props;
	// popover component
	const PopoverContent = column?.props?.popoverComponent || React.Fragment;

	const theme = useTheme();

	const currentIndex = (sortedRows as any[]).findIndex((e: any) => {
		return e.index === row.index;
	});

	let start =
		row.original?.previousMetaData && row.original?.previousMetaData[`${column.id}LastPosition`]
			? row.original?.previousMetaData[`${column.id}LastPosition`]
			: 0;

	for (let index = 0; index < currentIndex /* row.index */; index++) {
		start = start + sortedRows[index]['values'][column.id];
	}

	if (value === 0) {
		return (
			<div className="flexRow alignCenter justifyEnd ">
				<Typography
					color={column?.props?.color?.primary || siteColors.primary}
					className={styles.text}
				>
					{column?.props?.emptyValue || '-'}
				</Typography>
			</div>
		);
	}


	return (
		<div className="flexRow alignCenter justifyEnd ">
			{!isNaN(value)
				?
				(
					<BPopover
						className={cx('width100', column?.props?.popoverClassName)}
						anchorOriginHorizontal={'right'}
						transformOriginVertical={'top'}
						transformOriginHorizontal={'right'}
						anchorOriginVertical={'bottom'}
						modeHover
						content={(onClose) => {
							return <PopoverContent item={row.original} />;
						}}
					>
						<div className={cx('flexRow width100 alignCenter nowrap justifyEnd', column?.props?.className)}>
							{row.original?.volume ?
								(
									<>
										{value <= 0 || row.original?.volume < 0 ?
											(
												<Typography
													color={column?.props?.color?.primary || theme.palette.primary.main}
													className={styles.text}
												>
													<>
														<HelpCircle color={column?.props?.color?.primary || theme.palette.primary.main} />
														{/* AAA */}
													</>
												</Typography>
											) :
											(
												<>
													<Typography
														color={column?.props?.color?.primary || theme.palette.primary.main}
														className={cx(' number textNoWrap', styles.percent)}
													>
														{displayValue(value, undefined, column?.props?.decimal ?? 1)} %
													</Typography>

													<div
														className={cx(column?.props?.containerClassName, styles.container)}
														style={{
															['--background' as any]: column?.props?.bgColor || theme.palette.grey[500],
														}}
													>
														<div
															className={cx('percent-progress', styles.innerContainer)}
															style={{
																['--width-box' as any]: `${value}%`,
																['--background-color-box' as any]:
																	column?.props?.color?.primary || theme.palette.primary.main,
																['--margin-left-box' as any]: `${start}%`,
															}}
														/>
													</div>
												</>
											)}
									</>
								)
								:
								(
									<Typography color={column?.props?.color?.primary || theme.palette.primary.main} className={styles.text}>
										{column?.props?.emptyValue || '-'}
									</Typography>
								)}
						</div>
					</BPopover>
				)
				:
				(
					<>{column?.props?.emptyValue || <EmptyValue />}</>
				)}
		</div>
	);
};

export default PercentField;
