

import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { useParams } from 'react-router-dom';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useReportClientInfo } from '@carbonmaps/ui/hooks/useGetReportClientSheet';
import { capitalizeFirstLetter } from '@carbonmaps/ui/utils/utils';

import { positiveColor } from '../../../components/sales/Difference';
import KpiCard from '../../../components/sales/KpiCard';
import Percent from '../../../components/sales/Percent';
import Unit from '../../../components/units/Unit';
import { useGlossary } from '../../../hooks/useGlossary';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import { CARBON_INDICATOR } from '../../../utils/constants';
const styles = {
	description: css({
		fontSize: '12px',
		fontWeight: 400,
		color: siteColors.grey8,
	}),
	skeleton: css({
		height: '300px !important',
		width: '100% !important',
	} as any),
	// container: css({
	// 	padding: '24px 32px 0px 32px'
	// }),
};

const ClientReportsBlocInfo = () => {
	const { t } = useTranslation();
	const { indicator } = useApp();
	const params = useParams();
	const { g } = useGlossary();

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const { data: reportClientInfo, isLoading } = useReportClientInfo({
		objectId: params.slugClient!,
		viewMode: indicator,
	});



	const data = useMemo(() => {
		/* eslint-disable quotes */
		return [
			{
				label: t('Volume Total'),
				value: reportClientInfo?.tonnage,
				unit: <Unit measure={'tonne'} className={'grey800'} />,
				color: siteColors.grey9,
				withPercent: false,
				precision: 0,
			},
			{
				label: t('Impact économisé'),
				value: reportClientInfo?.[`${indicator}ImpactEco`],
				unit: <Unit measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'} className={'grey800'} />,
				color: siteColors.green500,
				withInfo: true,
				withPercent: false,
				precision: reportClientInfo?.[`${indicator}ImpactEco`] < 0 && reportClientInfo?.[`${indicator}ImpactEco`] > -1 ? 2 : 0,
			},
			{
				label: t('vs-Market-Average'),
				value: `${(reportClientInfo?.[`totalVsReference${capitalizeFirstLetter(indicator)}`])} %`,
				color: reportClientInfo?.[`totalVsReference${capitalizeFirstLetter(indicator)}`] < 0 ? siteColors.green500 : positiveColor,
				withInfo: true,
				withPercent: true,
				precision: reportClientInfo?.[`totalVsReference${capitalizeFirstLetter(indicator)}`] === 0 ? 0 : 2,
				withCursor: reportClientInfo?.[`totalVsReference${capitalizeFirstLetter(indicator)}`] === 0 ? false : true,
				info: g('g-vs-Market-Average'),
				icon: reportClientInfo?.[`totalVsReference${capitalizeFirstLetter(indicator)}`] < 0
? (
					<svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 5" fill="none">
						<path d="M5 5L9.33013 0.5H0.669873L5 5Z" fill="#6C8C40" />
					</svg>
				)
: (
					<><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 5" fill="none">
						<path d="M5 0L0.669873 4.5H9.33013L5 0Z" fill={positiveColor} />
					</svg>
						<>
						</></>
				),
			},
		];

	}, [reportClientInfo, indicator, t]);

	return (
		<div className={cx('flexRow gap16 nowrap')}>
			{data?.map((item: any, index: any) => {
				return (
					<KpiCard
						key={`KpiCard${  index}`}
						title={item?.label}
						value={
							<>
								{item?.withCursor && item?.icon ?  item?.icon : <></>}
								<Percent
									value={item?.value}
									color={item?.color}
									withPercent={item?.withPercent}
									precision={item?.precision}
									withCursor={item?.withCursor}
								/></>
						}
						unit={item?.unit}
						withInfo={item?.withInfo}
						isLoading={isLoading}
						info={item?.info}
					/>);
			},
			)}
		</div>

	);
};

export default ClientReportsBlocInfo;
