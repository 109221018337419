
import { type DialogProps } from '@mui/material';
import _ from 'lodash';

import SearchProduct from '../../../containers/products/general/tabs/products/SearchProduct';
import { product } from '../../../hooks/useCompareProduct';
import { useTranslation } from '../../../hooks/useTranslation';
import ProductTableSelect from '../product/ProductTableSelect';

import ModalSelect from './ModalSelect';

type ProductModalSelectProps= {
	open: DialogProps['open'];
	onClose?: DialogProps['onClose'];
	onAddProduct: (product: product) => void;
	onRemoveProduct: (product: product) => void;
	container?: any;
}


const ProductModalSelect = ({ open, onClose, onAddProduct, onRemoveProduct , container }: ProductModalSelectProps) => {
		const { t }= useTranslation();
		return (
			<ModalSelect
				title={t('add-product-quote', { product: _.toLower(t('product')), of_product: t('of_product') })}
				open={open} onClose={onClose}
				search={<SearchProduct withMore={false} />}
				container={container}
			>
				<ProductTableSelect onAddProduct={onAddProduct} onRemoveProduct={onRemoveProduct} />
			</ModalSelect>
		);

};

export default ProductModalSelect;
