import BButton from "@carbonmaps/ui/components/saas/BButton";
import { useCallback, useEffect } from "react";
import { useRouteError } from "react-router-dom";
import { useJuneTrack } from "../hooks/useJuneTrack";
import { useTranslation } from '../hooks/useTranslation';

const BeautyErrorPage = () => {
	const { t } = useTranslation();

	let error = useRouteError();
	const serialized = serializeError(error);

	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		() => {
			if (!analytics) return;
			analytics.track('Error page landing', {
				url: window.location.href,
				serialized,
			}, {
				context: { category: 'Error report' },
			});
		},
		[analytics, serialized, window.location.href],
	);

	useEffect(() => {
		trackEvent();
	}, [])

	return (
		<div
			className="flexCenter"
			css={{
				width: '100vw',
				height: '100vh',
				margin: 0,
				padding: 0,
			}}
		>
			<div className="flex flexColumn" css={{ gap: 30, maxWidth: 300 }}>
				<div
					css={{
						color: 'var(--Grey-9, #3E3A45)',
						leadingTrim: 'both',
						textEdge: 'cap',
						fontVariantNumeric: 'lining-nums tabular-nums',
						fontFamily: 'Inter',
						fontSize: '40px',
						fontStyle: 'normal',
						fontWeight: 600,
						lineHeight: 'normal',
					}}
				>
					🌱
				</div>
				<div>
					<div
						css={{
							color: 'var(--Grey-9, #3E3A45)',
							leadingTrim: 'both',
							textEdge: 'cap',
							fontVariantNumeric: 'lining-nums tabular-nums',
							fontFamily: 'Inter',
							fontSize: '24px',
							fontStyle: 'normal',
							fontWeight: 600,
							lineHeight: 'normal',
						}}
					>
						{t('something-went-wrong')}
					</div>
				</div>
				<div
					css={{
						color: "var(--Grey-8, #6A618A)",
						leadingTrim: "both",
						textEdge: "cap",
						fontVariantNumeric: "lining-nums tabular-nums",
						fontFamily: "Inter",
						fontSize: "14px",
						fontStyle: "normal",
						fontWeight: 400,
						lineHeight: "150%"
					}}
				>
					{t('error-page-long-text')} <a css={{
						  color: "var(--Grey-8, var(--CM-CM_purple, #7176FF))",
							leadingTrim: "both",
							textEdge: "cap",
							fontVariantNumeric: "lining-nums tabular-nums",
							fontFamily: "Inter",
							fontSize: "14px",
							fontStyle: "normal",
							fontWeight: 400,
							lineHeight: "150%"
					}} href="mailto:support@carbonmaps.io">support@carbonmaps.io</a>
				</div>

				<BButton label={t('reload')} variant={"primary"} onClick={() => {
					window.location.reload();
				}}/>
			</div>
		</div>
	);
};

export default BeautyErrorPage;

function serializeError(error: unknown): any {
  if (!(error instanceof Error)) {
    return error; // If it's not an Error object, return as-is
  }

  const serializedError = {
    name: error.name, // Error type (e.g., "Error", "TypeError")
    message: error.message, // Error message
    stack: error.stack, // Stack trace (if available)
    // Add any additional properties you want to include
  };

  return serializedError;
}
