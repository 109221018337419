import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { functionName } from '@carbonmaps/shared/utils/constants';

import { Card } from '../../../../../../../components/_comp/Card';
import { useTranslation } from '../../../../../../../hooks/useTranslation';

import StatusTrackingGraph from './StatusTrackingGraph';

const GraphBlock = () => {
	const params = useParams();
	const questionFormId = _.toString(params.questionFormId);
	const { t } = useTranslation();

	const { data, isLoading } = useQuery({
		queryKey: [functionName.questions.getSupplierTrackingGraphData, { questionFormId }] as const,
		queryFn: async (ctx) => {
			try {
				const { questionFormId } = ctx.queryKey[1];
				const seriesData = Parse.Cloud.run(functionName.questions.getSupplierTrackingGraphData, { questionFormId });
				return seriesData;
			} catch (error) {
				console.log('--- getSupplierTrackingGraphData error -----', error);
				return Promise.reject(error);
			}
		},
	});

	const seriesData = _.get(data, 'data', []);
	console.log('🛵🛵🛵🛵🛵🛵🛵🛵🛵', data);

	const isEmpty = !isLoading && _.every(seriesData, _.isEmpty);

	return (
		<Card css={{ marginBottom: 32 }}>
			{!isEmpty
? (
				<StatusTrackingGraph seriesData={_.map(seriesData, (series) => {
					return _.filter(series, (item) => {
						return !_.isNil(item[0]);
					});
				})} />
			)
: (
				<div className="flexCenter" css={{ height: 200 }}>
					<div>{t('campaign-not-started')}</div>
				</div>
			)}
		</Card>
	);
};

export default GraphBlock;
