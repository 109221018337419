import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Search } from 'lucide-react';

import { addUserToTeamAction, RemoveUserTeamAction } from '@carbonmaps/ui/actions/team.actions';

import MemberCard, { User } from '../../components/team/MemberCard';
import { cn, siteColors } from '../../lib/colors';
import { getUserOptionsAction } from '../../lib/react-query/features/user/user.actions';


import { Team } from './TeamForm';

const styles = {
	autoCompleteContainer: css({

		'& label.Mui-focused': {
			color: 'transparent !important',
			border: `1px solid ${siteColors.primary} !important`,
			boxShadow: `0px 0px 0px 2px ${siteColors.carbon100}`,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: 'transparent !important',
		},
		'& .MuiAutocomplete-popupIndicator': {
			display: 'none !important',
		},
		'& .MuiOutlinedInput-root': {
			padding: '0px !important',
			// width: `${AUTOCOMPLETE_WIDTH}px !important`,
			// height: `${AUTOCOMPLETE_HEIGHT}px !important`,
			background: '#FFFFFF !important',
			border: cn('1px solid', siteColors.grey500, '!important'),
			borderRadius: '8px !important',
			flex: 'none !important',
			order: 2,
			alignSelf: 'stretch !important',
			flexGrow: 0,
			'& fieldset': {
				borderColor: 'transparent !important',
				transition: '100ms',
			},
			'&:hover fieldset': {
				borderColor: 'transparent !important',
			},
			'&.Mui-focused fieldset': {
				border: `1px solid ${siteColors.carbon300} !important`,
				boxShadow: `0px 0px 0px 2px ${siteColors.carbon100}`,
			},
			'.MuiAutocomplete-input': {
				padding: '8px 4px 8px 4px !important',
			},
		},
	}),
	search: css({
		marginLeft: 8,
	}),
	input: css({
		'& .MuiInputBase-input::placeholder': {
      color: siteColors.cmText, // Change la couleur du placeholder
      opacity: 1, // Assurez-vous qu'il est visible (sinon il peut être à 0.42)
    },
	}),
	content: css({
		paddingTop: 8,
	}),
};

type TeamAddMemberProps = {
	team: Team;
	companyId?: string;
}

const TeamAddMember = ({ team, companyId }: TeamAddMemberProps) => {
	const [members, setMembers] = useState<Array<User>>([]);
	const queryClient = useQueryClient();
	// fetch users from team
	const { data, isLoading } = useQuery({
			queryKey: ['getUserOptions', { companyId }],
			queryFn: getUserOptionsAction,
		} as any);
	useEffect(() => {
		if(!team?.users) {
			return;
		}

		setMembers(team.users);
	}, [team]);

	// search options
	const options = useMemo(() => {
		if (!data) return [];
		const userMailsFilter = members.map(m => {return m.objectId;});

		return (data as any)?.filter((i: User) => {return !userMailsFilter.includes(i.objectId);}).map((user: User) => {
			return {
				...user,
				value: user.objectId,
				label: `${user.firstName} ${ user.lastName}`,
			};
		});

	}, [data, members]);

	// ---------------------------------------------- //
	// --------------- user to team  ---------------- //
	// ---------------------------------------------- //

	// react query mutation to  team
	const { mutate: addUserToTeam, isLoading: isLoadingAddUser } = useMutation({
			mutationKey: ['addUserToTeam'], // This mutation is used to save a team by sending a message
			mutationFn: addUserToTeamAction,
			onSuccess: async () => {
				// Marks the operation as successful upon completion
				queryClient.invalidateQueries({ queryKey: ['findUsers'] });
				queryClient.invalidateQueries({ queryKey: ['getTeamsTable'] });
			},
			onError: (error: any) => {
				/* Handle error here */
			},
	});

	// handle event on confirm add users to team
	const handleAddMembers = (user: User) => {
		if (!user) return;

		addUserToTeam({ userId: user.objectId, teamId: team.objectId });
		setMembers((prev: any) => {return [...prev, user];});
		//user
	};

	// ---------------------------------------------- //
	// --------------- remove user in team  ---------------- //
	// ---------------------------------------------- //

	// react query mutation to  team
	const { mutate: RemoveUserTeam, isLoading: isLoadingRemoveUser } = useMutation({
		mutationKey: ['RemoveUserTeam'], // This mutation is used to save a team by sending a message
		mutationFn: RemoveUserTeamAction,
		onSuccess: async () => {
			// Marks the operation as successful upon completion
			queryClient.invalidateQueries({ queryKey: ['findUsers'] });
			queryClient.invalidateQueries({ queryKey: ['getTeamsTable'] });
		},
		onError: (error: any) => {
			/* Handle error here */
		},
});

	// handle event on remove user from team
	const handleRemoveMembers = (user: User) => {
		if (!user) return;
		RemoveUserTeam({ userId: user.objectId, teamId: team.objectId });
		setMembers((prev: any) => {return prev.filter((m: User) => {return m.objectId !== user.objectId;});});
	};

	return (
		<div className="width100">
			<Autocomplete

				options={options}
      	renderInput={(params) => {
					return <TextField {...params} placeholder="Ajouter un utilisateur"  InputProps={{
					...params.InputProps,
					startAdornment: (
						<InputAdornment position="start">
							{' '}
							<Search className={styles.search} color={siteColors.grey7} />
						</InputAdornment>
					),

				}} className={styles.input}
				/>;}}
				onChange={(event, value: any) => {return handleAddMembers(value);}}
				className={styles.autoCompleteContainer}
    	/>
			<div className={cx('flexColumn width100', styles.content)}>
				{ members.map((member: User, index: number) => {
					return <MemberCard key={index} user={member} onRemoveUser={handleRemoveMembers} />;
				},
				)}
			</div>
		</div>
	);

};


export default TeamAddMember;

