import { ReactNode } from 'react';

import _ from 'lodash';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { MOD_CAP2ER, MOD_DASHBOARD } from '@carbonmaps/shared/utils/module';
import { isContributorByAuthData } from '@carbonmaps/shared/utils/parseRole.utils';
import { getPluginCompany } from '@carbonmaps/ui/utils/utils';

import LandingPage from '../containers/landingPages/LandingPage';
import UnAuthorized from '../containers/unAuthorized/UnAuthorized';
import { useGetClientAuthQuery } from '../lib/react-query/features/auth/auth.hooks';

type Props = {
	children?: ReactNode;
	module: string;
};

const CompanyModuleGuard = ({ children, module }: Props) => {
	const location = useLocation();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	if (!authData) {
		return <h1>CompanyModuleGuard loader</h1>;
	}

	if (!authData.session.company) {
		return <Navigate replace state={{ from: location }} to={FRONT_PATH_NAMES.userNoCompany} />;
	}

	const isContributor = isContributorByAuthData(authData);

	const plugin = getPluginCompany(authData.session?.company?.plugins ?? [], module);

	const questionnaires = _.get(authData, 'dataForm.questionnaires');

	const answeredQuestionnaires = _.filter(questionnaires, (item) => {
		return _.get(item, 'status') === 'completed';
	});

	const firstQuestionnaire = _.first(answeredQuestionnaires);
	const supplierId = _.get(firstQuestionnaire, 'supplierId');

	const hasAnsweredSurvey = _.get(authData, 'dataForm.hasAlreadyAnswered');

	// for questionnaire module
	if (isContributor) {
		if (
			isContributor &&
			module === MOD_DASHBOARD &&
			!getPluginCompany(authData.session?.company?.plugins ?? [], MOD_CAP2ER)?.active
		) {
			return (
				<Navigate
					replace
					state={{ from: location }}
					to={(() => {
						if (hasAnsweredSurvey) {
							return `${FRONT_PATH_NAMES.contributor}/${supplierId}/synthesis?rse:questionFormId=${_.get(
								firstQuestionnaire,
								'questionForm.objectId',
							)}`;
						} else {
							if (questionnaires.length > 1) {
								return FRONT_PATH_NAMES.myQuestionnaires;
							}

							return FRONT_PATH_NAMES.questionnaire;
						}
					})()}
				/>
			);
		}

		if (module === MOD_CAP2ER && !getPluginCompany(authData.session?.company?.plugins ?? [], MOD_CAP2ER)?.active) {
			return <UnAuthorized />;
		}

		return <UnAuthorized />;
	}

	if (plugin?.active !== true) {
		if (module === MOD_DASHBOARD && !getPluginCompany(authData.session?.company?.plugins ?? [], MOD_CAP2ER)?.active) {
			return <LandingPage />;
		}

		// for cap2er module && page dashboard
		if (module === MOD_DASHBOARD && getPluginCompany(authData.session?.company?.plugins ?? [], MOD_CAP2ER)?.active) {
			return <Navigate replace state={{ from: location }} to={FRONT_PATH_NAMES.cap2er} />;
		}

		return <UnAuthorized />;
	}

	if (module === MOD_CAP2ER && !getPluginCompany(authData.session?.company?.plugins ?? [], MOD_CAP2ER)?.active) {
		return <UnAuthorized />;
	}

	return children ? children : <Outlet />;
};

export default CompanyModuleGuard;
