import { useCallback, useEffect, useRef } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useIntersection } from 'react-use';

import { functionName } from '@carbonmaps/shared/utils/constants';

import TableHeadCell from '../../../../../../components/_comp/TableHeadCell';
import Skeleton from '../../../../../../components/Skeleton';
import { useYearSelection } from '../../../../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../../lib/colors';
import ContactsList from '../components/table/ContactsList';
import SupplierImpactPercent from '../components/table/SupplierImpactPercent';
import SupplierLabel from '../components/table/SupplierLabel';

const SuppliersDetailsTable = () => {
	const params = useParams();

	const [searchQueryParams] = useSearchQueryParams();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const searchParams = searchQueryParams?.facetFilters ?? [];
	const input = searchQueryParams?.input;

	const { data, fetchNextPage, isFetching, isLoading, isFetchingNextPage } = useInfiniteQuery({
		queryKey: [
			'findSuppliersTracking',
			{ questionFormId: _.toString(_.get(params, 'questionFormId')), filters: searchParams, input },
		] as const,
		queryFn: async ({ pageParam = 1, queryKey }) => {
			try {
				const { questionFormId, filters, input } = queryKey[1];
				return Parse.Cloud.run(functionName.questions.findSuppliersTrackingList, {
					questionFormId,
					...(searchQueryParams?.pageKey === 'supplier_pre_campaign'
						? {
							filters,
							input,
						}
						: {}),
					page: pageParam,
					limit: 200,
				});
			} catch (error) {
				console.log('--- findSuppliersTracking action error ----', error);
				return Promise.reject(error);
			}
		},

		keepPreviousData: true,

		getNextPageParam: (lastPage, allPages) => {
			const nextPageCursor = lastPage.meta.page + 1;

			if (nextPageCursor <= lastPage.meta.pages) {
				return nextPageCursor;
			}

			return null;
		},
		getPreviousPageParam: (firstPage, allPages) => {
			const previousPageCursor = firstPage.meta.currentPage - 1;

			if (previousPageCursor < 0) {
				return null;
			}

			return previousPageCursor;
		},
	});

	const pages = data?.pages?.[0]?.meta?.pages ?? 0;
	const fetchedPages = data?.pages?.length ?? 0;

	//called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
	const fetchMoreOnBottomReached = useCallback(
		(intersection: IntersectionObserverEntry | null) => {
			if (intersection) {
				//once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
				if (intersection.intersectionRatio === 1 && !isFetching && fetchedPages < pages) {
					console.log('--- fetchMoreOnBottomReached ----');
					fetchNextPage();
				}
			}
		},
		[isFetching, fetchedPages, pages, fetchNextPage],
	);

	const intersectionRef = useRef(null);

	const intersection = useIntersection(intersectionRef, {
		root: null,
		rootMargin: '300px',
		threshold: 0.5,
	});

	useEffect(() => {
		const handleScroll = () => {
			fetchMoreOnBottomReached(intersection);
		};

		document.addEventListener('scroll', handleScroll);

		return () => {
			document.removeEventListener('scroll', handleScroll);
		};
	}, [intersection, fetchMoreOnBottomReached]);

	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();

	const isSearch = !_.isEmpty(searchParams) || !_.isEmpty(input);
	const isEmptyResults = _.isEmpty(_.get(data, 'pages.0.data', []));

	return (
		<div
			css={{
				marginBottom: '100px',
			}}
		>
			<table
				css={{
					borderCollapse: 'collapse',
					width: '100%',
					'& td': {
						position: 'relative',
					},
					'& th, & td': {
						paddingTop: '12px',
						paddingBottom: '12px',
						borderBottom: `1px solid ${siteColors.grey500}`,
						background: 'white',
					},
					'& tr > th:last-of-type, & tr > td:last-of-type': {
						maxWidth: '250px',
					},
					'& tr > th:nth-of-type(2), & tr > td:nth-of-type(2)': {
						maxWidth: '30px',
					},
					'& tbody tr:hover td': {
						background: `${siteColors.grey200} !important`,
					},
					'& .MuiIconButton-root': {
						'&:hover': {
							background: `${siteColors.grey400}`,
						},
					},
				}}
			>
				<thead>
					<tr>
						<th>
							<TableHeadCell label={t('Fournisseurs')} withPlaceholderUnit />
						</th>
						<th>
							<div className="flexRow justifyEnd" css={{ marginRight: '8px' }}>
								<TableHeadCell
									label={`${t('for')} ${selectedYear}`}
									variant="measure"
									measure="carbonImpactTon"
									valueType="number"
								/>
							</div>
						</th>
						<th>
							<TableHeadCell label={'contacts'} withPlaceholderUnit />
						</th>
					</tr>
				</thead>

				<tbody>
					{_.map(_.get(data, 'pages', []), (page, index1) => {
						return _.map(page.data, (supplier, index) => {
							return (
								<tr key={`${index1}_${index}`}>
									<td css={{ position: 'relative' }}>
										<div
											className="height100 width100"
											css={{ paddingRight: '8px', position: 'absolute', top: 16, left: 0 }}
										>
											<SupplierLabel supplier={supplier} />
										</div>
									</td>
									<td css={{ position: 'relative' }}>
										<SupplierImpactPercent supplier={supplier} />
									</td>
									<td>
										<ContactsList objectIds={_.get(supplier, 'objectIds', [])} supplier={supplier} />
									</td>
								</tr>
							);
						});
					})}
					{isFetchingNextPage || isLoading
						? Array.from({ length: 5 }).map((_, index) => {
							return (
								<tr key={index}>
									<td colSpan={3}>
										<Skeleton height={50} />
									</td>
								</tr>
							);
						})
						: null}
				</tbody>
			</table>
			{!isFetching && !isSearch && isEmptyResults
				? (
					<div className="grey800 flexCenter" css={{ height: 72 }}>
						{t('Aucun fournisseur associé à ce questionnaire. Faites un premier envoi.')}
					</div>
				)
				: null}
			{!isFetching && isSearch && isEmptyResults
				? (
					<div className="grey800 flexCenter" css={{ height: 72 }}>
						{t('Aucun fournisseur ne correspond à votre recherche')}
					</div>
				)
				: null}
			<div ref={intersectionRef}></div>
		</div>
	);
};

export default SuppliersDetailsTable;
