import { useQuery } from '@tanstack/react-query';
import { Carrot } from 'lucide-react';

import { getIngredientCount } from '@carbonmaps/ui/actions/ingredient.actions';

import { useYearSelection } from '../../../hooks/useImpactYear';
import { useTeam } from '../../../hooks/useTeam';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import KPICard from '../../packagings/components/KPICard';
import KpiValue from '../../packagings/components/KpiValue';

const IngredientCountKpi = () => {

	const { selectedYear } = useYearSelection();
	const { team } = useTeam();
	const { t }= useTranslation();
	// ---- count ingredient ---- //
	const { data: ingredientCount, isLoading } = useQuery({
			queryKey: ['getIngredientCount', { period: selectedYear, team }],
			queryFn: getIngredientCount,
		});
	return (
		<KPICard
			className="flex1"
			label={t('reference_ingredients', { ingredients: t('ingredients') })}
			value={<KpiValue value={ingredientCount} unit={''} />}
			icon={<Carrot size={30} color={siteColors.grey7} />}
			isLoading={isLoading}
		/>
	);
};

export default IngredientCountKpi;
