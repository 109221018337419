import { useState } from 'react';

import { css, cx } from '@emotion/css';
import { Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { useQuery } from '@tanstack/react-query';
import { PackageOpen } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { functionName } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Skeleton from '../../../components/Skeleton';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import { useGetOneProductModelization, useGetOneProductModelizationLifecycle } from '../../../lib/react-query/features/modelization/modelization.hooks';

import AgribalyseTable from './AgribalyseTable';
import CustomTable from './CustomTable';

const styles = {
	container: css({
		width: '100%',
	}),
	title: css({
		fontSize: '16px',
		fontWeight: 600,
		alignItems: 'stretch!important',
	}),
	flex1: css({
		flex: 1,
		border: cn('1px solid', siteColors.grey500),
		borderRadius: '16px',
	}),

	stretch: css({
		alignItems: 'stretch',
	}),
	tabMenu: css({
		borderBottom: 1,
		borderColor: 'divider',
		width: '100%',
	}),
	p3: css({
		padding: 3,
	}),
	width100: css({
		width: '100%',
	}),
	skeleton: css({
		height: '175px !important',
		width: '100% !important',
	} as any),
};

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			className={cx(styles.width100)}
		>
			{value === index && (
				<div className={cx(styles.p3)}>
					<Typography>{children}</Typography>
				</div>
			)}
		</div>
	);
};

const PackagingBlock = () => {
	const theme = useTheme();
	const { t } = useTranslation();
	const [value, setValue] = useState(0);
	const params = useParams();
	const { indicator } = useApp();
	const { data, isLoading, isError, error } = useGetOneProductModelization({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		productId: params.modelizationId!,
		viewMode: indicator,
	});


	const { data: lifeCycleData, isLoading: isLoading2  } = useGetOneProductModelizationLifecycle({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		productId: params.modelizationId!,
		viewMode: indicator,
	});


	return (
		<div className={cx('flexColumn gap16', styles.container)}>
			<div className={cx(styles.title)}>{t('modelization-title-comparison-packaging')}</div>
			<CustomTabPanel value={value} index={0}>
				<div className={cx('flexRow flex1 nowrap alignCenter stretch width100 gap24', styles.title)}>
					{isLoading ?
						(
							<Skeleton width="100%!important" className={styles.skeleton} />
						) : (
							<>
								{/* <div className={cx(styles.flex1)}>
								<AgribalyseTable data={data?.packagingAgribalyse} />
							</div> */}

								{data?.packagingAgribalyse && data?.packagingAgribalyse?.length > 0 ? (
									<div className={cx(styles.flex1)}>
										<CustomTable
											difference={0} //to hidden
											data={formatData(data?.packagingAgribalyse)}
											icon={<PackageOpen color={theme.palette.grey[700]} />}
											withPercent={false}
											isExtern={true}
										/>
									</div>
								) :
									<div className={cx(styles.flex1)}>
										<AgribalyseTable data={data?.packagingAgribalyse} />
									</div>
								}

								{data?.packagingCustom && data?.packagingCustom?.length > 0 && (
									<div className={cx(styles.flex1)}>
										<CustomTable
											data={formatData(data?.packagingCustom)}
											difference={
												lifeCycleData?.data?.find((item: any) => {
													return item?.key === 'packaging';
												})?.diffPercent
											}
											icon={<PackageOpen color={theme.palette.grey[700]} />}
											withPercent={false}
										/>
									</div>
								)}
							</>
						)}
				</div>
			</CustomTabPanel>
		</div>
	);
};

export default PackagingBlock;

const formatData = (data: any) => {
	return data.map((item: any) => {
		return {
			...item,
			composition: item?.presencePercentage || 0,
		};
	});
};
