import { useQuery } from '@tanstack/react-query';

import { getSBTIFlagKpiDataAction, getSBTIGraphDataAction } from '@carbonmaps/ui/actions/sbtiFlag.actions';

import { useYearSelection } from '../../hooks/useImpactYear';
import { useTeam } from '../../hooks/useTeam';

export const useSBTiFlagKpiData = () => {
	const { selectedYear } = useYearSelection();
	const { team }= useTeam();

		const queryResult = useQuery({
			queryKey: [
				'getSBTIFlagKpiData',
				{
					period: selectedYear,
					team,
				},
			] as any,
			queryFn: getSBTIFlagKpiDataAction,
			retry: false,
		});

		return queryResult;
};

export const useSBTiFlagGraphData = () => {
	const { selectedYear } = useYearSelection();
	const { team }= useTeam();

		const queryResult = useQuery({
			queryKey: [
				'getSBTIGraphData',
				{
					period: selectedYear,
					team,
				},
			] as any,
			queryFn: getSBTIGraphDataAction,
			retry: false,
		});

		return queryResult;
};

