import { Fragment } from 'react';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';

import ArrowDownIcon from '@carbonmaps/media/icons/chevron-1.svg?react';
import { roleLabels, ROLES_HIERARCHY } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BPopover from '@carbonmaps/ui/components/saas/BPopover';

import ChipsRole from './ChipsRole';

type Props = {
	value: any;
	row: any;
	//column: any,
	callback: (...params: any[]) => void;
};

const RoleCellNew = ({
	value,
	row,
	//column: { id },
	callback,
}: Props) => {
	if (!value) return null;

	if (value.label === roleLabels.CONTRIBUTOR) return <ChipsRole role={value} />;

	return (
		<BPopover
			anchorOriginVertical={'bottom'}
			anchorOriginHorizontal={'left'}
			transformOriginVertical={'top'}
			transformOriginHorizontal={'left'}
			content={(onClose) => {
				return (
					<List css={{ width: 250 }}>
						{ROLES_HIERARCHY.map((role, index) => {
							return (
								<Fragment key={role.label + index}>
									<ListItemButton
										data-key={role.suffixName}
										data-label={role.label}
										data-user={row.userId ?? row?.original?.objectId}
										onClick={callback}
									>
										<ChipsRole role={role} />
									</ListItemButton>
									{index < ROLES_HIERARCHY.length - 1 && <Divider />}
								</Fragment>
							);
						})}
					</List>
				);
			}}
		>
			<BButton
				iconRight={<ArrowDownIcon />}
				variant="secondary"
				label={<ChipsRole role={value} />}
				addStyles={{
					display: 'flex',
					justifyContent: 'space-between',
					width: '100%',
					background: 'none',
					padding: 0,
					'&:hover': { background: 'none' },
				}}
			/>
		</BPopover>
	);
};

export default RoleCellNew;
