import { useCallback, useEffect } from 'react';

import { useJuneTrack } from '../../../../../hooks/useJuneTrack';

import QuestionFormDetailsKPIs from './blocks/QuestionFormDetailsKPIs';
import QuestionFormLatestStatusUpdates from './blocks/QuestionFormLatestStatusUpdates';
import QuestionFormStatusRepartition from './blocks/QuestionFormStatusRepartition';
import GraphBlock from './blocks/graph/GraphBlock';


const QuestionFromDetailsOverView = () => {
	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(name: string) => {
			if (!analytics) return;
			analytics.track(name, {
				context: { category: 'Question Form' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		trackEvent('Question Form Land');
	}, [trackEvent]);

	return (
		<div css={{
			marginTop: '24px',
			marginBottom: '3rem',
		}}>
			<QuestionFormDetailsKPIs/>
			<GraphBlock />
			<QuestionFormLatestStatusUpdates />
		</div>
	);
};

export default QuestionFromDetailsOverView;
