import { Fragment, ReactNode, SyntheticEvent, useEffect } from 'react';

import Box from '@mui/material/Box';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import defaultAvatar from '@carbonmaps/media/images/default-avatar.png';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import AvatarContent from '@carbonmaps/ui/components/saas/AvatarContent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import TabsBar, { TabItem } from '../../components/TabsBar';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import { useGetImgProxy } from '../../lib/react-query/features/img/img.hooks';
import Page404 from '../404/Page404';

import AccountForm from './AccountForm';
import EmailForm from './EmailForm';
import SecurityForm from './SecurityForm';

// Layout constants
const LAYOUT_MAX_WIDTH = 1200;

type ITab = {
	title: string;
	element: ReactNode;
};

const tabs: Record<string, ITab> = {
	account: {
		title: 'user-profil-tab',
		element: <AccountForm />,
	},
	email: {
		title: 'user-email-tab',
		element: <EmailForm />,
	},
	security: {
		title: 'user-security-tab',
		element: <SecurityForm />,
	},
	'personal-data': {
		title: 'user-data-tab',
		element: null,
	},
};

const UserSettings = () => {
	const navigate = useNavigate();
	const params = useParams();
	const { setBreadcrumbs } = useApp();
	const location = useLocation();

	const { t } = useTranslation();

	useEffect(() => {
		if (!params.tab) {
			setBreadcrumbs([
				{
					text: t('leave-settings'),
				},
			]);
			return;
		}

		setBreadcrumbs([
			{
				text: t('leave-settings'),
				link: FRONT_PATH_NAMES.home,
			},
		]);
	}, [params.tab, setBreadcrumbs, t]);

	const handleTabChange = (_: SyntheticEvent, value: any) => {
		navigate(`${FRONT_PATH_NAMES.userSettings}/${value}`);
	};

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const {
		result: { data: avatarUrl },
	} = useGetImgProxy({
		params: { filename: authData?.user.avatar || '' },
	});

	if (!params.tab) {
		return <Navigate replace state={{ from: location }} to={`${FRONT_PATH_NAMES.userSettings}/account`} />;
	}

	if (!Object.keys(tabs).includes(params.tab)) {
		return <Page404 />;
	}

	return (
		<div>
			<Box sx={{
				margin: '0 auto',
				maxWidth: LAYOUT_MAX_WIDTH,
				px: 3,
				border: `1px solid ${siteColors.grey500}`,
				padding: '24px',
				borderRadius: '16px',
				mt: '24px',
			}}>
				<div
					className="flexRow alignCenter width100"
					css={{
						margin: '0 auto 16px',
					}}
				>
					<AvatarContent
						url={authData?.user.avatar ? avatarUrl : defaultAvatar}
						addStyle={{ width: 48, height: 48, objectFit: 'cover', borderRadius: '50%' }}
					>
						<div css={{ maxWidth: 350 }}>
							<p
								css={{
									fontSize: 14,
									color: siteColors.grey700,
									margin: 0,
									mb: '4px',
								}}
							>
								{t('user-profil-settings')}
							</p>
							<p
								css={{
									fontWeight: 600,
									fontSize: 24,
									color: siteColors.text,
									margin: 0,
									mb: '2px',
								}}
							>
								{authData?.user.firstName || authData?.user.username}
							</p>
							<p
								css={{
									fontWeight: 500,
									fontSize: 16,
									color: siteColors.grey900,
									margin: 0,
								}}
							>
								{authData?.user.email}
							</p>
						</div>
					</AvatarContent>
				</div>

				<Box>
					<TabsBar value={params.tab} onChange={handleTabChange}>
						{Object.entries(tabs).map(([tabName, value]) => {
							if (['notifications', 'personal-data'].includes(tabName))
								return (
									<TabItem
										title="Section en cours de développement"
										key={tabName}
										label={t(value.title)}
										value={tabName}
										disabled
										role="not-tab"
									/>
								);

							return <TabItem css={{}} key={tabName} label={t(value.title)} value={tabName} />;
						})}
					</TabsBar>
				</Box>
				<Box
					sx={{
						margin: '32px auto',
						padding: '16px',
					}}
				>
					{Object.entries(tabs).map(([tabName, value]) => {
						return tabName === params.tab && <Fragment key={tabName}>{value.element}</Fragment>;
					})}
				</Box>
			</Box>
		</div>
	);
};

export default UserSettings;
