import { Fragment, SyntheticEvent, useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from '@tanstack/react-query';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES, ITEM_TYPE_INGREDIENT } from '@carbonmaps/shared/utils/constants';
import { getSupplier } from '@carbonmaps/ui/actions/supplier.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import SEO from '../../../components/SEO';
import TabsBar, { TabItem } from '../../../components/TabsBar';
import { useGlossary } from '../../../hooks/useGlossary';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useTranslation } from '../../../hooks/useTranslation';
import SupplierModel from '../../../models/Supplier.model';

import SupplierHeader from './components/SupplierDetailsHeader';
import Achats from './tabs/achats/SupplierDetailsAchats';
import RseDetails from './tabs/rse/SupplierDetailsRse';
import Supply from './tabs/supply/Supply';
import Synthesis from './tabs/synthesis/SupplierDetailsSynthesis';

const tabsItems = [
	{
		id: 'synthesis',
		title: 'synthesis-tab',
		element: <Synthesis />,
		disabled: false,
		tabName: 'Summary',
	},
	{
		id: 'approvisionnements',
		title: 'approvisionnement-tab',
		element: <Supply />,
		glossary: 'approvisionnements',
		tabName: 'Supplies',
	},
	{
		id: 'achat-du-fournisseur',
		title: 'achat-supplier-tab',
		element: <Achats />,
		glossary: 'achat-du-fournisseur',
		tabName: 'Purchases',
	},
	{
		id: 'rse-performance',
		title: 'rse-performance-tab',
		element: <RseDetails isContributor={true} />,
		tabName: 'CSR Performance',
	},
];

const SupplierContributor = () => {
	const params = useParams();
	const navigate = useNavigate();
	const { setBreadcrumbs } = useApp();

	// translation
	const { t } = useTranslation();
	// glossary
	const { g } = useGlossary();

	const [supplierObject, setSupplierObject] = useState<SupplierModel>();
	const [, setDataCategories] = useState<Record<string, any>>({});
	const [, setProductsSupplier] = useState<any>([]);

	const queryParams = useQueryParams();
	const searchParams = createSearchParams(queryParams as any)?.toString();

	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(name: string, options: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	//--------------------------------------------------------------------------------------//
	//                                    Supplier info                                     //
	//--------------------------------------------------------------------------------------//
	// use query
	const [notFound, setNotFound] = useState(false);

	const { data, isLoading } = useQuery({
		queryKey: [
			'getSupplier',
			{
				supplierId: params.supplierId,
			},
		],
		queryFn: getSupplier,
	});

	const handleTabChange = (_: SyntheticEvent, value: string) => {
		navigate(`${FRONT_PATH_NAMES.contributor}/${params.supplierId}/${value}?${searchParams}`);

		const currentTab = tabsItems?.find((item) => {
			return item.id === value;
		});

		trackEvent('Explore Supplier Switch Tab', { SupplierTabTarget: (currentTab as any)?.tabName });
	};

	const formatThenSetData = (res: any, supplier: any) => {
		const data = res.map((item: any) => {
			const result = {
				...item,
				carbonImpactPercent: (item.carbonImpact / supplier.carbonImpact) * 100,
				waterImpactPercent: (item.waterImpact / supplier.waterImpact) * 100,
				carboneIntensityPercent: (100 * item.carbonIntensity) / (supplier.carboneIntensityMaxValue || 1),
				waterIntensityPercent: (100 * item.waterIntensity) / (supplier.waterIntensityMaxValue || 1),
				origin: item?.uid,
				link: `${
					item?.itemType === ITEM_TYPE_INGREDIENT ? FRONT_PATH_NAMES.ingredients : FRONT_PATH_NAMES.products.details
				}/${item?.objectId || item._id}/synthesis`,
			};

			return result;
		});
		setProductsSupplier(data || []);
	};

	useEffect(() => {
		if (!data) return;

		if (data.notFound) {
			setNotFound(true);
		}

		const { supplier, segmentationData, products } = data;
		setSupplierObject(new SupplierModel(supplier));
		setDataCategories(segmentationData);
		formatThenSetData(products, supplier);
	}, [data]);

	useEffect(() => {
		setBreadcrumbs([
			{ text: t('suppliers'), link: `${FRONT_PATH_NAMES.suppliers.general}?${searchParams}` },
			{ text: supplierObject?.getName() },
		]);
	}, [supplierObject, setBreadcrumbs, searchParams, t]);

	if (notFound) {
		return <h2>Le fournisseur: {params.supplierId}, n'existe pas.</h2>;
	}

	return (
		<Box>
			<SEO title={'CarbonMaps'} />
			<Box width="90%" mx="auto">
				<SupplierHeader />
			</Box>
			{isLoading
				? <Skeleton variant="rectangular" height="62px" sx={{ borderRadius: '16px', width: '90%', mx: 'auto' }} />
				: <TabsBar
					value={params.tab}
					onChange={handleTabChange}
					sticky
					sx={{
						mb: pxToRem(16),
						width: '90%',
						mx: 'auto',
						top: 62,
						'&.sticky': { width: '100%', borderRadius: '16px' },
					}}
				>
					{tabsItems.map((item: any) => {
						return (
							<TabItem
								key={item.id}
								label={t(item.title)}
								value={item.id}
								disabled={item.disabled}
								glossary={item?.glossary}
								g={g}
							/>
						);
					})}
				</TabsBar>}
			<Box width="90%" mx="auto">
				{tabsItems.map((item) => {
					return !isLoading && item.id === params.tab && <Fragment key={item.id}>{item.element}</Fragment>;
				})}
			</Box>
		</Box>
	);
};

export default SupplierContributor;
