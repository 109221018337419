/* eslint-disable quotes */
import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { Apple, Beef, Repeat2, Sprout, Trash, UtilityPole, Wheat } from 'lucide-react';

import CowIcon from '@carbonmaps/media/icons/cow.svg?react';
import { APBO_MAP } from '@carbonmaps/shared/utils/constants';

import BlockCard from '../../../components/apbo/card/BlockCard';
import ContentGrid from '../../../components/apbo/card/ContentGrid';
import IndicatorCard from '../../../components/apbo/card/IndicatorCard';
import { siteColors } from '../../../lib/colors';
import { orderBy } from '../../../utils/array.utils';
import { useYearOptions } from '../useApbo.hook';

const styles = {
	title: css({
		textDecoration: 'underline',
	}),
};

const StaticIndicator = () => {
	const { value: year } = useYearOptions();

	const dataYear = APBO_MAP.get(year);

	const getValue = (key1: string, key2: string) => {
		const val = dataYear?.leverActionsView?.staticIndicator?.[_.toLower(key1)]?.[_.toLower(key2)]?.percentage;
		return val;
	};

	let items = [
		{
			title: "Choix du plan d'action culture",
			color: '#F5F9EC',
			data: [
				{
					title: 'Raisonner et ajuster la fertilisation azotée',
					value: 9.3,
					icon: Apple,
				},
				{
					title: 'Optimiser la fertilisation minérale des surfaces dédiées au lait',
					value: 4.65,
					icon: Apple,
				},
				{
					title: 'Optimiser la fertilisation minérale des surfaces dédiées à la viande',
					value: 2.33,
					icon: Apple,
				},
				{
					title: 'Valoriser de manière optimale les déjections',
					value: 16.28,
					icon: Apple,
				},
				{
					title: 'Implanter des légumineuses dans les prairies temporaires',
					value: 6.98,
					icon: Apple,
				},
				{
					title: 'Introduire de la luzerne dans la rotation',
					value: 23.26,
					icon: Apple,
				},
				{
					title: 'Implanter des légumineuses à graines dans la rotation',
					value: 4.65,
					icon: Apple,
				},
				{
					title: 'Augmenter la part de prairies permanentes',
					value: 4.65,
					icon: Apple,
				},
				{
					title: 'Allonger la durée des prairies temporaires',
					value: 4.65,
					icon: Apple,
				},

				{
					title: "Implanter des couverts végétaux d'inter-culture - engrais vert",
					value: 4.65,
					icon: Apple,
				},

				{
					title: 'Implanter des haies',
					value: 27.91,
					icon: Apple,
				},
				{
					title: 'Enrichir les couverts végétaux en légumineuses',
					value: 0.0,
					icon: Apple,
				},
				{
					title: 'Implanter une culture dérobée pour produire du fourrage (méteil)',
					value: 4.65,
					icon: Apple,
				},
				{
					title: 'Autres : augmenter SFP arrêt céréales',
					value: 2.33,
					icon: Apple,
				},
				{
					title: 'Autres : enrichir les prairies avec sursemis',
					value: 2.33,
					icon: Apple,
				},
			],
		},
		{
			title: "Choix du plan d'action ration",
			color: siteColors.carbon100,
			data: [
				{
					title: 'Optimiser les concentrés dans la ration des femelles laitières - Réduire le gaspillage de concentrés',
					value: 18.6,
					icon: Wheat,
				},
				{
					title: 'Opter pour des concentrés de source européenne',
					value: 2.33,
					icon: Wheat,
				},
				{
					title: 'Optimiser la teneur en azote de la ration',
					value: 2.33,
					icon: Wheat,
				},
				{
					title: 'Introduire des lipides dans la ration',
					value: 2.33,
					icon: Wheat,
				},
				{
					title: "Favoriser l'autonomie protéique",
					value: 13.95,
					icon: Wheat,
				},
				{
					title: 'Mieux valoriser le pâturage - Augmenter la pratique du pâturage',
					value: 11.63,
					icon: Wheat,
				},
				{
					title: 'Produire des aliments à la ferme - autonomie en concentrés',
					value: 4.65,
					icon: Wheat,
				},
				{
					title: 'Augmenter la production laitière en améliorant la qualité des fourrages -UFL',
					value: 9.3,
					icon: Wheat,
				},
				{
					title: 'Améliorer la teneur en Mat des fourrages par le stade de récolte',
					value: 13.95,
					icon: Wheat,
				},
				{
					title:
						'Apporter des Oméga-3 à la ration des vaches laitières par les concentrés ou les fourrages (pâturés ou conservés)',
					value: 0,
					icon: Wheat,
				},
				{
					title: 'Optimiser les quantités de concentrés - Adapter les apports aux besoins',
					value: 6.98,
					icon: Wheat,
				},
				{
					title: "Produire un maximum de viande à partir des fourrages de l'exploitation",
					value: 4.65,
					icon: Wheat,
				},
				{
					title: 'Remplacer le tourteau de soja par du tourteau de colza',
					value: 2.33,
					icon: Wheat,
				},
				{
					title: 'Améliorer la qualité des fourrages - UFL',
					value: 6.98,
					icon: Wheat,
				},
				{
					title: 'Autres',
					value: 0,
					icon: Wheat,
				},
			],
		},
		{
			title: "Choix du plan d'action troupeau",
			color: '#F6F5F4',
			data: [
				{
					title: 'Optimiser la production laitière par vache',
					value: 48.84,
					icon: () => {
						return <CowIcon />;
					},
				},
				{
					title: 'Optimiser la production laitière par la conduite alimentaire',
					value: 18.6,
					icon: () => {
						return <CowIcon />;
					},
				},
				{
					title:
						'Maîtriser les réformes non désirées et réduire le taux de renouvellement = Augmenter la longévité des vaches laitières',
					value: 51.16,
					icon: () => {
						return <CowIcon />;
					},
				},
				{
					title: 'Maîtriser les boiteries',
					value: 13.95,
					icon: () => {
						return <CowIcon />;
					},
				},
				{
					title: 'Augmenter la production laitière par la maîtrise de la conduite sanitaire du troupeau',
					value: 6.98,
					icon: () => {
						return <CowIcon />;
					},
				},
				{
					title: 'Améliorer la reproduction du troupeau',
					value: 11.63,
					icon: () => {
						return <CowIcon />;
					},
				},
				{
					title: "Optimiser l'âge à la 1ère mise bas",
					value: 6.98,
					icon: () => {
						return <CowIcon />;
					},
				},
				{
					title: 'Améliorer la génétique du troupeau',
					value: 2.33,
					icon: () => {
						return <CowIcon />;
					},
				},
				{
					title: 'Maintenir les qualités maternelles des femelles laitières',
					value: 0,
					icon: () => {
						return <CowIcon />;
					},
				},
				{
					title: "Avoir des femelles laitières en bon état à l'entrée au bâtiment",
					value: 2.33,
					icon: () => {
						return <CowIcon />;
					},
				},
				{
					title: 'Réformer les vaches vides',
					value: 4.65,
					icon: () => {
						return <CowIcon />;
					},
				},
				{
					title: 'Maitriser le taux de gestation',
					value: 4.65,
					icon: () => {
						return <CowIcon />;
					},
				},
				{
					title: 'Limiter la mortalité des jeunes',
					value: 6.98,
					icon: () => {
						return <CowIcon />;
					},
				},
				{
					title: "Optimiser l'âge au 1er vêlage",
					value: 30.23,
					icon: () => {
						return <CowIcon />;
					},
				},
				{
					title: 'Autres',
					value: 0,
					icon: () => {
						return <CowIcon />;
					},
				},
			],
		},
		{
			title: "Choix du plan d'action énergie & effluents",
			color: siteColors.water100,
			data: [
				{
					title: 'Installer une unité de méthanisation',
					value: 2.33,
					icon: UtilityPole,
				},
				{
					title: 'Couvrir la fosse à lisier et brûler le méthane avec une torchère',
					value: 0,
					icon: UtilityPole,
				},
				{
					title: 'Réduire le nombre de jours au bâtiment du troupeau laitier',
					value: 0.0,
					icon: UtilityPole,
				},
				{
					title: 'Réduire le nombre de jours au bâtiment du troupeau viande',
					value: 0.0,
					icon: UtilityPole,
				},
				{
					title: 'Être plus autonome en paille',
					value: 2.33,
					icon: UtilityPole,
				},
				{
					title: 'Réduire la consommation de carburant',
					value: 9.3,
					icon: UtilityPole,
				},
				{
					title: "Réduire la consommation d'électricité",
					value: 4.65,
					icon: UtilityPole,
				},
				{
					title: 'Installer un pré-refroidisseur',
					value: 2.33,
					icon: UtilityPole,
				},
				{
					title: 'Installer un récupérateur de chaleur',
					value: 2.33,
					icon: UtilityPole,
				},
				{
					title: 'Mettre en place des techniques culturales simplifiées',
					value: 6.98,
					icon: UtilityPole,
				},
				{
					title: 'Régler le matériel et adapter la puissance du tracteur aux outils',
					value: 0,
					icon: UtilityPole,
				},
				{
					title: "Produire de l'électricité",
					value: 11.63,
					icon: UtilityPole,
				},
				{
					title: 'Conduire de manière économe',
					value: 2.33,
					icon: UtilityPole,
				},
				{
					title: 'Autres : Epandage de lisier au pendillard',
					value: 4.65,
					icon: UtilityPole,
				},
			],
		},
	];

	items = items.map((item) => {
		const k1 = item.title;
		return {
			...item,
			data: item.data.map((o) => {
				return {
					...o,
					value: getValue(k1, o.title),
				};
			}),
		};
	}) as never;

	return items.map((item: any, index: number) => {
		return (
			<BlockCard title={item.title} key={index} className={styles.title}>
				<ContentGrid>
					{orderBy(item.data, 'value').map((o: any, j: number) => {
						return (
							<IndicatorCard
								key={j}
								title={o.title}
								value={o.value}
								icon={o.icon}
								color={siteColors.grey700}
								background={item?.color}
							/>
						);
					})}
				</ContentGrid>
			</BlockCard>
		);
	});
};

export default StaticIndicator;
