import React, { useEffect } from 'react';

import MUIPopover, { type PopoverOrigin } from '@mui/material/Popover';
import _ from 'lodash';
type RenderContentProps = {
	close: () => void;
};

type Props = {
	isClose?: boolean;
	trigger: React.ReactElement<{ onclick?: React.MouseEventHandler } & Record<string, unknown>>;
	anchorEl?: Element;
	anchorOrigin?: PopoverOrigin;
	transformOrigin?: PopoverOrigin;
	disableScrollLock?: boolean;
	onClick?: () => void;
	onClose?: () => void;
	modeHover?: boolean;
} & (
	| {
			// variant: 'simple'
			content: React.ReactNode;
			renderContent?: undefined;
	  }
	| {
			// variant: 'composed'
			renderContent: (props: RenderContentProps) => React.ReactNode;
			content?: undefined;
	  }
);

const Popover = ({
	isClose,
	trigger,
	anchorEl,
	transformOrigin,
	anchorOrigin,
	disableScrollLock = true,
	onClose,
	modeHover,
	onClick,
	...props
}: Props) => {
	const [anchorElState, setAnchorEl] = React.useState<Element | null>(null);

	const handleOpen = (event: React.MouseEvent) => {
		setAnchorEl(anchorEl ?? event.currentTarget);
	};

	const handleClose = () => {
		onClose?.();
		setAnchorEl(null);
	};

	useEffect(() => {
		if (isClose === false) {
			onClose?.();
			setAnchorEl(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isClose]);

	const open = Boolean(anchorElState);

	const triggerClick = React.cloneElement(trigger, {
		onClick: (e: React.MouseEvent) => {
			trigger.props.onclick && trigger.props.onclick(e);
			handleOpen(e);

			if (onClick) onClick();
		},
	});

	const triggerHover = React.cloneElement(trigger, {
		onMouseEnter: (e: React.MouseEvent) => {
			_.isFunction(trigger.props.onMouseEnter) && trigger.props.onMouseEnter(e);
			handleOpen(e);
		},
		onMouseLeave: (e: React.MouseEvent) => {
			_.isFunction(trigger.props.onMouseLeave) && trigger.props.onMouseLeave(e);
			handleClose();
		},
	});

	return (
		<>
			{modeHover ? triggerHover : triggerClick}
			<MUIPopover
				open={open}
				anchorEl={anchorElState}
				onClose={handleClose}
				transformOrigin={transformOrigin}
				anchorOrigin={anchorOrigin}
				disableScrollLock={disableScrollLock}
			>
				{props.renderContent && props.renderContent({ close: handleClose })}
				{props.content && props.content}
			</MUIPopover>
		</>
	);
};

export default Popover;
