import { useQuery } from '@tanstack/react-query';

import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useTeam } from '../../../../hooks/useTeam';

import { countModelizationAction, findModelizationTableAction, findOneProductModelizationAction, findOneProductModelizationLifecycleAction } from './modelization.actions';



// ---- 2 --------------------------------------------------------------------------------
type UseFindModelizationTableProps = {
	params: any
}

export const useFindModelizationTable = ({
	params,
}: UseFindModelizationTableProps) => {
	const { selectedYear } = useYearSelection();
	const { team } = useTeam();

	const key = ['findModelizationTable', { selectedYear,team, ...params }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: findModelizationTableAction,
	});

	return { key, result };
};


// ---- get modelization count------------------------------------------------------------------
type UseCountModelizationProps = {
	params: any
}

export const useCountModelization = ({
	params,
}: UseCountModelizationProps) => {
	const { selectedYear } = useYearSelection();

	const key = ['countModelization', { selectedYear, ...params }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: countModelizationAction,
	});

	return { key, result };
};

type useOneProductModelizationProps = {
	objectId: string;
	// period: string | number;
}


export const useGetOneProductModelization = ({
	productId,
	viewMode,
	onError,
}: {
	productId: string;
	viewMode: 'carbon' | 'water'
	onError?: (err: unknown) => void;
}) => {

	const { selectedYear } = useYearSelection();

	const key = ['findOneProductModelization', { productId, period: selectedYear, viewMode }] as const;

	const queryResult = useQuery({
		queryKey: key,
		queryFn: findOneProductModelizationAction,
		onError,
		retry: false,
	});

	return queryResult;

};


export const useGetOneProductModelizationLifecycle = ({
	productId,
	viewMode,
	onError,
}: {
	productId: string;
	viewMode: 'carbon' | 'water'
	onError?: (err: unknown) => void;
}) => {

	const { selectedYear } = useYearSelection();

	const key = ['findOneProductModelizationLifecycle', { productId, period: selectedYear, viewMode }] as const;

	const queryResult = useQuery({
		queryKey: key,
		queryFn: findOneProductModelizationLifecycleAction,
		onError,
		retry: false,
	});

	return queryResult;

};
