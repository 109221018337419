import { ReactNode } from 'react';

import { css } from '@emotion/css';
import { Link } from 'react-router-dom';

type Props = { t1: ReactNode; t2: ReactNode; to: string; imgUrl: string };

const styles = {
	wrapper: css({
		borderRadius: '16px',
		background: '#FFF',
		boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
		display: 'flex',
		width: '346px',
		height: '210px',
		flexDirection: 'column',
		alignItems: 'center',
		overflow: 'hidden',
		'&:hover': {
			outline: '2px solid #00000015',
		},
	}),
	content: css({
		padding: 16,
	}),
	imgWrap: css({
		width: '100%',
		height: '100px',
		// background: 'url(https://via.placeholder.com/346x190) center center / cover no-repeat',
	}),
};

const RapportCard = ({ t1, t2, to, imgUrl }: Props) => {
	return (
		<Link to={to} css={{ textDecoration: 'none' }}>
			<div className={styles.wrapper}>
				<div
					className={styles.imgWrap}
					style={{
						background: `url(${imgUrl}) center center / cover no-repeat`,
					}}
				></div>
				<div className={styles.content}>
					<div className="grey900 fontWeight500">{t1}</div>
					<div className="grey800">{t2}</div>
				</div>
			</div>
		</Link>
	);
};

export default RapportCard;
