import { useCallback, useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';

import Typography from '../../../components/Typography';
import { useCompareProduct } from '../../../hooks/useCompareProduct';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';


const styles = {
	container: css({
		borderRadius: 4,

		minWidth: 250,
		boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
		height: 'calc(86vh + 5px)',
	}),
	innerContainer: css({
		paddingLeft: 16,
	}),
	title: css({
		padding: '16px 0px 8px 16px',
	}),


	itemContainer: css({
		padding: 8,
	}),

};

type CompareTypeMenuProps = {
	options: Array<{
		title: string;
		items: Array<{
			value: string,
			label: string,
			icon: React.ReactNode,
			element: React.ElementType
		}>
	}>
}

const CompareTypeMenu = ({ options }: CompareTypeMenuProps) => {

	const { t } = useTranslation();

	// comparison type selected
	const { compareType, setCompareType } = useCompareProduct();

	// all comparison type
	const menuItems = useMemo(() => {
		return options.map((item) => {
			return {
				...item,
				items: item.items.map((subItem) => {
					return {
						...subItem,
						label: t(subItem.label),
						isActive: compareType === subItem.value,
					};
				}),

			};
		});

	}, [t, compareType, options]);

	// handle switch type
	const handleTypeChange = useCallback((type: string) => {
		setCompareType(type);
	}, [setCompareType]);


	return (
		<div className={styles.container}>
			<Typography className={cx('grey9 fontWeight500', styles.title)}>{t('Type de comparaison')}</Typography>
			<div className={styles.itemContainer}>
				{menuItems?.map((list, index) => {
					return (
				<List
					component="nav"
					subheader={
						<ListSubheader
								component="div"
								id="nested-list-subheader"
								css={{ color: siteColors.grey9, fontSize: 14, paddingLeft: 8 }}
							>
								{t(list.title)}
						</ListSubheader>
					}
					key={index}
				>
						<div className={styles.innerContainer}>
						{
							list.items.map((item, index) => {
								return (
									<DisplayItem
										key={index}
										label={t(item.label)}
										icon={item.icon}
										isActive={item.isActive}
										onClick={() => {return handleTypeChange(item.value);}}
									/>
								);
							})
						}
						</div>
				</List>
					);
				})}
			</div>
		</div>
	);
};

export default CompareTypeMenu;

type DisplayItemProps = {
	isActive: boolean;
	icon: React.ReactNode;
	label: string;
	onClick: () => void;
}

const itemStyles = {
	container: css({
		item: css({
				padding: '8px 16px 8px 16px !important',
				borderRadius: '.5rem !important',
				transition: 'unset !important',
				marginBottom: '0px !important',
				fontSize: '14px !important',
				marginLeft: '8px !important',
				marginRight: '8px !important',

				'&:hover': {
					background: cn(siteColors.primary10, '!important'),
					'& svg': {
						color: siteColors.primary,
					},
					'& .MuiListItemText-root > .MuiTypography-root': {
						color: siteColors.primary,
					},
				},

				'&:hover .sidebar-icon': {
					path: {
						fill: siteColors.primary,
					},
				},
				'&:hover .sidebar-icon-lucide': {
					stroke: siteColors.primary,
					'& path': {
						stroke: siteColors.primary,
					},
				},
				'& [data-active="true"] .sidebar-icon': {
					path: {
						fill: siteColors.primary,
					},
				},
				'& [data-active="true"] .sidebar-icon-lucide': {
					stroke: siteColors.primary,
					'& path': {
						stroke: siteColors.primary,
					},
				},
			}),
	}),

	active: css({
		borderRadius: '4px !important',
		background: cn(siteColors.primary10, '!important'),
		'& svg': {
			color: siteColors.primary,
		},
		'& .MuiListItemText-root > .MuiTypography-root': {
			color: siteColors.primary,
		},
	}),
	inActive: css({
		'& .MuiListItemText-root > .MuiTypography-root': {
			color: siteColors.grey9,
		},
		'& svg': {
			color: siteColors.grey700,
			'& path': {
				stroke: siteColors.grey700,
			},
		},
	}),

	text: css({
		marginLeft: '12px !important',
		// marginTop: '0 !important',
		// marginBottom: '0 !important',
		'& > .MuiTypography-root': {
			fontWeight: 500,
			lineHeight: 'normal',
			color: `${siteColors.grey9}`,
		},
	}),
};

export const DisplayItem = ({ isActive, label, icon, onClick }: DisplayItemProps) => {
	return (
		<ListItemButton
			className={cx(itemStyles.container, isActive ? itemStyles.active : itemStyles.inActive)}
			onClick={onClick}
		>
			<ListItemIcon data-active={isActive} sx={{ minWidth: 'unset' }}>
			{icon}
			</ListItemIcon>
			<ListItemText
				primary={<div className="flexRow alignCenter nowrap">{label}</div>}
				className={itemStyles.text}
			/>
		</ListItemButton>
	);
};
