import { useCallback } from 'react';

import { css, cx } from '@emotion/css';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { MenuItem } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { ArrowLeft, Globe, Lock } from 'lucide-react';

import RemoveIcon from '@carbonmaps/media/icons/removeIcon.svg?react';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import Typography from '../Typography';
;

const styles = {
	container: css({
		minHeight: '42px',
	}),
	content: css({
		minHeight: 225,
		overflow: 'auto',
		maxHeight: 343,
		padding: '6px 0',
	}),
	searchContainer: css({
		padding: '12px 16px',
		gap: 8,
		borderBottom: `1px solid ${siteColors.grey5}`,
		minHeight: 60,
	}),
	head: css({
		background: siteColors.grey2,
		padding: 16,
		marginTop: '-8px',
		cursor: 'pointer',
		':hover': {
			background: siteColors.grey3,
		},
	}),
	footer: css({
		borderTop: `1px solid ${siteColors.grey5}`,
		padding: '8px 16px',
		paddingLeft:0,
	}),
	removeButton: css({
		color: `${siteColors.primary} !important`,
	}),
};
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
type TeamOptionsProps = {
	teamSelected: Array<string>;
	options: Array<{
		value: string;
		label: string;
		icon: React.FC<any>
	}>;
	onClose: () => void;
	onSelect: (value: string) => void;
	onRemoveAll: () => void;
	onConfirm: () => void;
}

const TeamOptions = ({ options, onClose, teamSelected, onSelect, onRemoveAll, onConfirm }: TeamOptionsProps) => {
	const { t } = useTranslation();

	const getLabel = useCallback((team: string) => {
		const valueLabelMap = new Map();
		options.forEach((option: {value: string, label: string}) => {
			valueLabelMap.set(option.value, option.label);
		});

		return valueLabelMap.get(team);
	}, [options]);

	return (
		<div className={cx('flexColumn width100', styles.container)}>
				<div className={cx('flexRow alignCenter gap8 width100', styles.head)} onClick={onClose}>
					<ArrowLeft color={siteColors.grey7}/>
					<Typography className='grey9'>{t('teams')}</Typography>
				</div>
				<div className={cx('flexColumn width100', styles.searchContainer)}>
					<div className="flexRow alignCenter gap8">
						{teamSelected.map((team, index: number) => {
							return (<TagSelect label={getLabel(team)} onRemove={() => {return onSelect(team);}}/>);
						})}
					</div>
				</div>
				<div className={cx('width100', styles.content)}>
				{
					options.map((option, index: number) => {
						const IconComponent =  option.icon;
						return (
							<MenuItem
								key={index}
								value={option.value}
								onClick={() => {return onSelect(option.value);}}
							>
								<div className="flexRow alignCenter width100 spaceBetween">
									<div className="flexRow alignCenter gap8">
										<Checkbox icon={icon} checkedIcon={checkedIcon} checked={teamSelected.includes(option.value)} />
										<Typography className="grey9">{option.label}</Typography>
									</div>
									<IconComponent size={16} color={siteColors.grey7} />
								</div>
						</MenuItem>
						);
					})
				}
				</div>
				<div className={cx('flexRow alignCenter width100 spaceBetween', styles.footer)}>
					<BButton
						onClick={onRemoveAll}
						label={t('remove-all-button')}
						variant="tertiary"
						className={styles.removeButton}
					/>
					<BButton
						onClick={onConfirm}
						label={t('valider')}
						variant="primary"
					/>
				</div>
		</div>
	);
};

export default TeamOptions;

type TagSelectProps = {
	label: string;
	onRemove: () => void;
}

const stylesTagSelect = {
	container: css({
		padding: 8,
		borderRadius: 4,
		background: siteColors.grey3,
		border: '1px solid rgba(0, 0, 0, 0.10)',
		cursor: 'pointer',
		':hover': {
			background: siteColors.grey4,
		},
	}),
};

export const TagSelect = ({ label, onRemove }: TagSelectProps) => {

	return (
		<div className={cx('flexRow alignCenter gap8', stylesTagSelect.container)}>
			<Typography>{label}</Typography>
			<RemoveIcon onClick={onRemove} />
		</div>
	);
};
