import { useCallback, useEffect } from 'react';

import { create } from 'zustand';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Container from '../../../components/layout/list/Container';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { CARBON_INDICATOR } from '../../../utils/constants';

import ClientReportsFilter from './ClientReportsFilter';
import ClientReportsHeader from './ClientReportsHeader';
import ClientReportsTable from './ClientReportsTable';



// eslint-disable-next-line react-refresh/only-export-components
export const useStore = create((set: any) => {
	return {
		selectedRow: [] as any,
		onSelectionChange: (changedRows: any) => {
			return set((state: any) => {
				return {
					...state,
					selectedRow: changedRows,
				};
			});
		},
	};
});

const PAGE_KEY = 'clientReportsGeneralView';

const ClientReports = (/* props: Props */) => {
	const { indicator, setIndicator, setBreadcrumbs } = useApp();
	const { t } = useTranslation();
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();

	// init filters values
	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(name: string, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('Valoriser') }]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);


	// for reset searchQueryParams when the user lands on the ClientReports listing page
		useEffect(() => {
			if (searchQueryParams?.pageKey !== PAGE_KEY) {
				setSearchQueryParams({ ...searchQueryParams,pageKey:PAGE_KEY,  input: '' });
			}
		}, []);

	// ---- init switch mode with carbon ---- //
	useEffect(() => {
		if (indicator) return;
		setIndicator && setIndicator(CARBON_INDICATOR);
	}, [setIndicator, indicator]);

	// track event 	when the user lands on the ClientReports listing page
	useEffect(() => {
		trackEvent('Explore Land ClientReports');
	}, [trackEvent]);

	return (
		<Container
			header={<ClientReportsHeader />}
			graph={null}
			actionFilter={
				<>
				<ClientReportsFilter
					withMore={false}
				/>
				</>
			}
			seoText={`${t('Valoriser')} - Carbon Maps`}
		>
			<ClientReportsTable filterOptions={searchQueryParams} manualSortBy />
		</Container >
	);
};

export default ClientReports;
