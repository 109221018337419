import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import _ from 'lodash';

import { product, useCompareProduct } from '../../hooks/useCompareProduct';
import { compareProductConfig } from '../../utils/compareProduct';

import BarItem from './BarItem';

const styles = {

	container: css({
		 maxHeight: 'calc(100vh - 254px)',
		overflow: 'auto',
		paddingRight: '8px',
	}),
};

type CompareProductBarChartProps = {
	products: Array<product>,
	isLifeChart?: boolean,
};

const CompareProductBarChart = ({ products, isLifeChart } : CompareProductBarChartProps) => {

	const { productToCompare, compareType, indicator } = useCompareProduct();

	// config for compare value
	const config = compareProductConfig.get(compareType)[indicator] as any;

	const dataChart = useMemo(() => {



		if (!products?.length) return [];

		const fieldStep = isLifeChart ? config.fieldStep : config.field;
		const total = (productToCompare as any)[fieldStep];
		const max = _.maxBy(products, config.field);


		return products.map((product: product) => {
			const diff = (product as any)[fieldStep] - total;
			return {
				...product,
				value: isLifeChart ?  (product as any)[config.fieldStep] :  (product as any)[config.field],
				percent: ((product as any)[config.field]  / ((max as any)[config.field] ||1)) * 100,
				percentDiff: (productToCompare as any).objectId === product.objectId ? 99 :  diff / total * 100,
				...isLifeChart ? { stepPercent : ((product as any)[config.fieldStep]  / ((product as any)[config.field] ||1)) * 100 } : {},
			};
		});

	}, [products, productToCompare, config, isLifeChart]);

	return (
		<div className={cx('flexColumn gap16 width100 flex1', styles.container)}>
			{ dataChart.map((product, index: number) => {
				return (<BarItem product={product} key={index} color={product.color} isLifeChart={isLifeChart} />);
			})}
		</div>
	);

};

export default CompareProductBarChart;
