import { css, cx } from '@emotion/css';

import Typography from '../Typography';

const styles = {
	subtitle: css({
		maxWidth: 'calc(50vw)',
	}),
};

type BlockTitleProps = {
	title: string;
	subtitle?: string;
}

const BlockTitle = ({ title, subtitle }: BlockTitleProps) => {
	return (
		<div className="flexColumn gap4">
			<Typography variant="h3" className="grey9">{title}</Typography>
			<Typography className={cx('grey8 numeric', styles.subtitle)}>{subtitle}</Typography>
		</div>
	);

};

export default BlockTitle;
