import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import useTheme from '@mui/material/styles/useTheme';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';

import defaultLogo from '@carbonmaps/media/images/default-logo.png';
import { CompanyFormInput, CompanyFormValidationSchema } from '@carbonmaps/shared/validations/company.validations';
import { updateCurrentCompanyAction, uploadCompanyLogo } from '@carbonmaps/ui/actions/user.actions';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';
import { classes } from '@carbonmaps/ui/components/saas/Form/FormStyle';

import { useGetClientAuthQuery } from '../../../../../apps/front/src/lib/react-query/features/auth/auth.hooks';
import { useGetImgProxy } from '../../../../../apps/front/src/lib/react-query/features/img/img.hooks';
import AlertStatic from '../../components/Toast/AlertStatic';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';

const FormCompany = () => {
	const theme = useTheme();
	const styles = classes(theme);
	const queryClient = useQueryClient();
	const [message, setMessage] = useState('');
	const [file, setFile] = useState(null);

	// translation
	const { t } = useTranslation();

	const {
		result: { data: authData },
		key: getClientAuthKey,
	} = useGetClientAuthQuery();

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
		// watch,
		reset,
	} = useForm<CompanyFormInput>({
		resolver: zodResolver(CompanyFormValidationSchema),
		defaultValues: async () => {
			// const { data } = await refetch();
			return {
				name: authData?.session.company?.name || '',
				objectId: authData?.session.company?.objectId || '',
				code: authData?.session.company?.code || '',
				maxUsers: authData?.session.company?.maxUsers || 15,
				hasSupplierIng: authData?.session.company?.hasSupplier?.ingredient || false,
				hasSupplierProduct: authData?.session.company?.hasSupplier?.product || false,
			} as any;
		},
		mode: 'onChange',
	});

	const {
		mutate: updateCurrentUserAccount,
		isError,
		isSuccess,
	} = useMutation({
		mutationKey: ['updateCurrentCompany'],
		mutationFn: updateCurrentCompanyAction,
		onSuccess: async () => {
			setMessage('Informations mises à jour');
			queryClient.invalidateQueries({ queryKey: getClientAuthKey });
			// refreshUser();
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	// ------------------------------------------------------------ //
	// ------------------------ upload photo ---------------------- //
	// ------------------------------------------------------------ //
	const { mutate: uploadCompanyUserLogo, isLoading: isUploadLoading } = useMutation({
		mutationKey: ['uploadCompanyLogo'] as any,
		mutationFn: uploadCompanyLogo,
		onSuccess: async () => {
			setMessage('Informations mises à jour');
			queryClient.invalidateQueries({ queryKey: getClientAuthKey });
			// refreshUser();
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const onSubmit: SubmitHandler<CompanyFormInput> = async (values) => {
		updateCurrentUserAccount({ ...values, file, objectId: authData?.session.company?.objectId } as CompanyFormInput & {
			objectId: string;
		});
	};

	const onError = (error: any) => {
		if (error.name) {
			setMessage(error.name.message);
		}
	};

	const {
		result: { data: logoUrl },
	} = useGetImgProxy({
		params: { filename: authData?.session.company?.logo || '' },
	});

	return (
		<FormContainer
			title={t('company-form-title') as any}
			subtitle={t('company-form-subtitle') as any}
			avatar
			defaultLogo={authData?.session.company?.logo ? logoUrl : defaultLogo}
			onSubmit={handleSubmit(onSubmit, onError)}
			file={file}
			setFile={(file) => {
				return uploadCompanyUserLogo({
					name: authData?.session.company?.name || '',
					objectId: authData?.session.company?.objectId || '',
					file,
				});
			}}
			loading={isUploadLoading}
			isSuccess={isSuccess}
			isError={isError}
			withSave
			alertSuccess={
				<AlertStatic text={message} type={'success'} icon={<CheckIcon css={{ color: siteColors.green500 }} />} />
			}
			alertError={<AlertStatic text={message} type={'error'} icon={<ErrorIcon css={{ color: '#C74600' }} />} />}
			addStyles={{
				gap: 24,
				margin: 'auto',
				logo: {
					borderRadius: 4,
				},
			}}
			t={t}
		>
			<p css={styles.subtitle}>{t('about-text')}</p>
			<div
				className="flexColumn width100"
				css={{
					gap: 24,
					'.input-container': {
						width: '100%',
					},
				}}
			>
				<BInput
					required
					id="name"
					label={t('user-company-name')}
					fullWidth
					margin="dense"
					{...register('name')}
					name="name"
					error={errors.name ? true : false}
					errorMessage={errors?.name?.message}
				/>
			</div>
		</FormContainer>
	);
};

export default FormCompany;
