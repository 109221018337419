import Parse from "parse";

import React from 'react';

import '@carbonmaps/css/global.css';
import '@carbonmaps/css/index.css';
import Hotjar from '@hotjar/browser';
import { AnalyticsBrowser } from '@june-so/analytics-next';
import ReactDOM from 'react-dom/client';

import App from './App';
import './i18n';
import { getServerUrl } from './utils/utils';

window.Parse = Parse;

const hostName = window.location.hostname;
window.LOCAL = ['localhost', '127.0.0.1'].includes(hostName);
window.PRODUCTION = hostName === 'app.carbonmaps.io';
// window.SANDBOX = hostName === 'sandbox-dot-carbonmaps-preproduction.ew.r.appspot.com';
window.SANDBOX = hostName === 'sandbox.carbonmaps.io';
window.DEV = hostName === 'front-dev-dot-carbonmaps-preproduction.ew.r.appspot.com';

const run = async () => {
	// initialize parse
	Parse.initialize('carbonmaps');
	Parse.serverURL = `${getServerUrl()}parse`;

	const analytics = AnalyticsBrowser.load({
		writeKey: window.PRODUCTION ? 'PetLwqdYa46WITjg' : window.SANDBOX ? 'wUMC79u89paLO7bg' : '0j01xqsAGQS0qA49',
	});

	window.analytics = analytics;

	// setup hotjar
	if (window.PRODUCTION) {
		const siteId = 3553034;
		const hotjarVersion = 6;
		Hotjar.init(siteId, hotjarVersion);
	}

	ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
	);
};

run();
