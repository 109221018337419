import { useState } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { saveEstimatedImpact } from '@carbonmaps/ui/actions/dashboard.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { usePeriodOptions } from '../../../../hooks/useImpactYear';

import DeleteButton from './DeleteButton';
import EstimatedField from './EstimatedField';
import SelectedYearField from './SelectedYearField';

type DisplayLineTableProps = {
	item?: any;
	currentYear?: boolean;
};

const DisplayLineTable = ({ item, currentYear = false }: DisplayLineTableProps) => {
	const [yearSelected, setYearSelected] = useState(item.value);
	const [estimatedValue, setEstimatedValue] = useState(item?.data?.estimatedValue);
	const [estimatedTonnageValue, setEstimatedTonnageValue] = useState(item?.data?.tonnage);
	const { removeEstimatedOption } = usePeriodOptions();

	const { indicator } = useApp();

	const queryClient = useQueryClient();

	// ---- save estimated data ---- //
	const { mutate: saveEstimatedValue, isLoading } = useMutation({
		mutationKey: ['saveEstimatedValue'],
		mutationFn: saveEstimatedImpact,
		onSuccess: async (value: any) => {
			// setMessage('Informations mises à jour');
			queryClient.invalidateQueries({ queryKey: ['getTotalImpactPerPeriod'] });
		},
		onError: (error: any) => {
			if (error.message) {
				// setMessage(error.message);
			}
		},
	});

	/**
	 * savedData
	 * @param value
	 */
	const savedData = (value: any, type: 'impact' | 'tonnage') => {
		if (type === 'tonnage') {
			setEstimatedTonnageValue(value);
			saveEstimatedValue({ tonnage: parseFloat(value), year: yearSelected });
		}
		else {
			setEstimatedValue(value);
			saveEstimatedValue({ [`${indicator}Impact`]: parseFloat(value), year: yearSelected });

		}
	};

	const handleDelete = (year: any) => {
		saveEstimatedValue({ year, isDisabled: true });
		removeEstimatedOption(year);
	};

	return (
		<tr>
			<td>
				<SelectedYearField item={item} value={yearSelected} onChange={setYearSelected} />
			</td>
			<td>
				<EstimatedField item={item} value={
					currentYear ? formatNumber(item?.data[`${indicator}Impact`], undefined, 0) : estimatedValue} onSaved={savedData} currentYear={currentYear} />
			</td>
			<td>
				<EstimatedField type={'tonnage'} item={item} value={currentYear
? formatNumber(item?.data['tonnage'], undefined, 0) :
					estimatedTonnageValue} onSaved={savedData} currentYear={currentYear} />
			</td>
			<td>
				{!currentYear
? (
					<DeleteButton
						onClick={() => {
							return handleDelete(item?.value);
						}}
					/>
				)
: null}
			</td>
		</tr>
	);
};

export default DisplayLineTable;
