import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { HelpCircle } from 'lucide-react';

import { ITypeModelizationIngredientKey, TYPE_MODELIZATION_INGREDIENT } from '@carbonmaps/shared/utils/constants';
import { displayValue, formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Lining from '../../../../../apps/front/src/components/sales/Lining';
import { translation } from '../../../i18n/translation';
import { toLowerCase } from '../../../utils/utils';
import IntensityChart from '../../charts/IntensityChart';
import BPopover from '../BPopover';

type Props = {
	value: any;
	row: any;
	column: any;
	updateMyData: (...params: any[]) => void;
};

const styles = {
	popoverContainer: css({
		padding: 24,
	}),
	innerPopoverContainer: css({ marginBottom: 12 }),

	width35: css({
		width: 45,
		whiteSpace: 'nowrap',
	}),

	content: css({
		gap: 8,
		color: 'var(--color)',
		fontWeight: 'normal',
		textAlign: 'right',
	}),
	typeModelizationText: css({
		width: 60,
		color: 'var(--color-text)',
	}),
};

const Intensity = ({ value, row, column }: Props) => {
	const t = column?.props?.t || translation;

	const isEmpty = (value === 0 || value < 0 || value === null || value === undefined || value === '') && column?.props?.emptyValue;

	const theme = useTheme();
	let color = ['n1_portfolio_customer_ingredient', 'n1_portfolio_agb_ingredient'].includes(
		row.original.typeModelizationIngredient?.toLocaleLowerCase() || '',
	)
		? theme.palette.grey[700]
		: theme.palette.primary.main;

	if (column?.props?.color?.primary) color = column.props.color.primary;

	let showDataviz = true;

	if (row?.original?.showDataviz === 0) {
		showDataviz = false;
		color = theme.palette.grey[700];
	}

	if (row.original.isQuote && column.props?.page === 'report-client') {
		return <Lining />;
	}

	if (isEmpty) {
		return <div className="flexRow justifyCenter alignCenter width100 gap8">
			{column?.props?.emptyValue}
			<IntensityChart
				percentage={0} color={color}
			/>
		</div>;
	}

	if (!row.original.typeModelizationIngredient && column.props?.page !== 'products') {
		return (
			<div
				className={cx('flexRow alignCenter nowrap justifyCenter', styles.content)}
				style={{ ['--color' as any]: color }}
			>
				<Typography
					className={cx(
						row.original.level !== 2 && styles.typeModelizationText,
						column?.props?.style ? css(column?.props?.style) : '',
						'number',
					)}
					style={{
						['--color-text' as any]: column?.props?.color?.primary || theme.palette.primary.main,
						color: column?.props?.color?.primary || theme.palette.primary.main,
						width: '45px',
					}}
				>
					{displayValue(value)}
				</Typography>
				{showDataviz && (
					<IntensityChart
						percentage={
							column?.props?.isWeighted === true
								? row?.original?.percentageWeighted || 0
								: row?.original?.percentage || 0
						}
						color={color}
					/>
				)}
			</div>
		);
	}



	return (
		<div className="flexRow justifyCenter alignCenter width100 ">
			<BPopover
				anchorOriginHorizontal={'right'}
				transformOriginVertical={'top'}
				transformOriginHorizontal={'right'}
				anchorOriginVertical={'bottom'}
				addStyles={{ padding: '0px' }}
				modeHover
				content={(onClose) => {
					return (
						<div className={styles.popoverContainer}>
							<div className={styles.innerPopoverContainer}>
								{!value || value < 0
									? (
										<Typography className="fontWeight600">{t('Valeur incorrecte')}</Typography>
									)
									: (
										<>
											<Typography className="fontWeight600">
												{t('Modélisation recette', {
													recipe: t('recipe'),
													recipe_lowercase: toLowerCase(t('recipe')),
												})}
											</Typography>
											<Typography>
												{t(
													`${(row.original?.typeModelizationIngredient?.toLowerCase() as ITypeModelizationIngredientKey) ||
													'n1_inferred_ingredient'
													}`,
													{ recipe: t('recipe'), recipe_lowercase: toLowerCase(t('recipe') ?? '') },
												)}
											</Typography>
										</>
									)}
							</div>
						</div>
					);
				}}
			>
				{value && value < 0
					? (
						<div
							className={cx('flexRow alignCenter nowrap justifyCenter number', styles.content)}
							style={{ ['--color' as any]: color }}
						>
							<span className={styles.width35}>
								<HelpCircle color={column?.props?.color?.primary || theme.palette.primary.main} />
							</span>
							{showDataviz && (
								<IntensityChart
									percentage={
										column?.props?.isWeighted === true
											? row?.original?.percentageWeighted || 0
											: row?.original?.percentage || 0
									}
									color={color}
								/>
							)}
						</div>
					)
					: (
						<div
							className={cx('flexRow alignCenter nowrap justifyCenter', styles.content)}
							style={{ ['--color' as any]: color }}
						>
							<span className={cx(styles.width35, 'number')}>{displayValue(value)}</span>
							{showDataviz && (
								<IntensityChart
									percentage={
										column?.props?.isWeighted === true
											? row?.original?.percentageWeighted || 0
											: row?.original?.percentage || 0
									}
									color={color}
								/>
							)}
						</div>
					)}
			</BPopover>
		</div>
	);
};

export default Intensity;
