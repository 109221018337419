import { useEffect, useMemo } from 'react';

import { css } from '@emotion/css';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { ArrowRight, PackageOpen } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import { IPackaging } from '@carbonmaps/shared/types/packaging.types';
import { FRONT_PATH_NAMES, WATER_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { usePackagingProduct } from '@carbonmaps/ui/hooks/useGetProductSheet';

import BlockCard from '../../../components/BlockCard';
import BlockTitle from '../../../components/BlockTitle';
import BottomCard from '../../../components/BottomCard';
import { useTranslation } from '../../../hooks/useTranslation';
import ProductModel from '../../../models/Product.model';
import { CARBON_INDICATOR } from '../../../utils/constants';

import DonutProductChart from './DonutProductChart';

const styles = {
	mb0: css({
		marginBottom: '0px !important',
	}),
};

const ProductDonutBlock = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const params = useParams();

	const { t } = useTranslation();

	const handleViewDetailsClick = () => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		navigate(`${FRONT_PATH_NAMES.products.details}/${params.productId!}/packaging`);
	};

	const { indicator, setIndicator } = useApp();

	const { data: packagingData, isLoading } = usePackagingProduct({
		productId: params.productId!,
	});

	const _data = _.isString(packagingData) ? {} : packagingData;

	const data = useMemo(() => {
		return _.get(_data, 'packaging', []).map((item: IPackaging) => {
			const productModel = new ProductModel(_data);

			let value = 0;

			switch (indicator) {
				case CARBON_INDICATOR:
					value = productModel.getIntensity('gesPackaging')
						? ((item?.gesWeighted || 0) / productModel.getIntensity('gesPackaging')) * 100
						: 0;
					break;
				case WATER_INDICATOR:
					value = productModel.getIntensity('waterUsePackaging')
						? ((item?.waterUseWeighted || 0) / productModel.getIntensity('waterUsePackaging')) * 100
						: 0;
					break;
				default:
					value = productModel.getIntensity('gesPackaging')
						? ((item?.gesWeighted || 0) / productModel.getIntensity('gesPackaging')) * 100
						: 0;
					break;
			}

			return {
				value,
				name: item?.packaging?.get('label') ?? item?.packaging?.get('labelCmaps'),
			};
		});
	}, [_data, indicator]);

	const data2 = useMemo(() => {
		let value = 0;
		let res;

		const productModel = new ProductModel(_data as any);

		switch (indicator) {
			case CARBON_INDICATOR:
				res = productModel.getIntensity('gesPackaging')?.toFixed(2);
				if (res) value = parseFloat(res);
				break;

			case WATER_INDICATOR:
				res = productModel.getIntensity('waterUsePackaging')?.toFixed(2);
				if (res) value = parseFloat(res);
				break;

			default:
				res = productModel.getIntensity('gesPackaging')?.toFixed(2);
				if (res) value = parseFloat(res);
				break;
		}

		return value;
	}, [data, indicator, theme]);

	useEffect(() => {
		if (indicator) return;
		setIndicator && setIndicator(CARBON_INDICATOR);
	}, [setIndicator]);

	const graphData = data || [];

	if (_.isEmpty(graphData)) return null;
	return (
		<div>
			<BlockCard
				sx={{
					marginBottom: 0,
					borderRadius: (packagingData as any)?.isCmaps === true ? '16px 16px 0px 0px' : '16px',
				}}
			>
				<div className="flexRow spaceBetween alignCenter">
					<BlockTitle className={styles.mb0} icon={<PackageOpen size={20} color={theme.palette.grey[700]} />}>{t('emballage')}</BlockTitle>
					<Box display="flex" justifyContent="flex-end">
						{/* <BButton variant="secondary" label={t('see_more_packaging_details')} onClick={handleViewDetailsClick} /> */}
						<Button
							onClick={handleViewDetailsClick}
							sx={{
								textTransform: 'none',
								'&:hover': {
									backgroundColor: 'unset',
									textDecoration: 'underline',
									textUnderlineOffset: '6px',
								},
							}}
							disableRipple
							endIcon={<ArrowRight size={18} />}
						>
							{t('Voir le détail')}
						</Button>
					</Box>
				</div>
				{!isLoading && (
					<DonutProductChart
						key={indicator}
						data={graphData}
						data2={{
							value: data2,
							name: 'KG',
						}}
						indicator={indicator}
					/>
				)}


			</BlockCard>
			{(packagingData as any)?.isCmaps === true && !isLoading && (
				<BottomCard>{t('packaging-reference-info-alert', { packaging: t('packaging') })}</BottomCard>
			)}
		</div>
	);
};

export default ProductDonutBlock;
