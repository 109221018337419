import { useQuery } from '@tanstack/react-query';

import { useYearSelection } from '../../../apps/front/src/hooks/useImpactYear';
import { useTeam } from '../../../apps/front/src/hooks/useTeam';
import { getOneSupplierAction, getSupplierSheetTimePeriodAction } from '../actions/supplier.actions';


export const useGetSupplierSheetTimePeriod = ({
	objectId,
	fromPath,
	onError,
	onSuccess,
}: {
	objectId: string;
	fromPath?: string;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const { team } = useTeam();
	const queryResult = useQuery({
		queryKey: ['getSupplierSheetTimePeriod', { objectId, fromPath, period: selectedYear, team }],
		queryFn: getSupplierSheetTimePeriodAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};



export const useGetOneSupplier = ({
	objectId,
	onError,
	onSuccess,
}: {
	objectId: string;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {

	const queryResult = useQuery({
		queryKey: ['getOneSupplierData', { objectId }],
		queryFn: getOneSupplierAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};
