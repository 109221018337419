import _ from 'lodash';

import SupplierScore from '../../../../../apps/front/src/components/supplier/SupplierScore';

const Scoring = (props: any) => {
	const { row } = props;

	const label = _.get(row, 'original.iNote') || '-';
	const isWaiting = _.get(row, 'original.isWaiting');

	return (
		<div className="width100 height100 flexCenter">
			<SupplierScore score={label} awaitingResponse={isWaiting} withInfoIcon={!isWaiting} iconSize={16} />
		</div>
	);
};

export default Scoring;
