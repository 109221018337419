import { type QueryFunctionContext } from '@tanstack/react-query';

import { IPackaging } from '@carbonmaps/shared/types/packaging.types';
import { functionName } from '@carbonmaps/shared/utils/constants';

export type FindPackagingSheetActionParams = {
	objectId?: string;
};

//--------------------------------------------------------------------------------------//
//                get data for one packaging with time period                    //
//--------------------------------------------------------------------------------------//
export const getPackagingSheetTimePeriodAction = async (
	context: QueryFunctionContext<
		readonly ['getPackagingSheetTimePeriod', { objectId: string; period: string | number }]
	>,
): Promise<any> => {
	try {
		const {
			queryKey: [, { objectId, period }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.temporality.findOnePackaging, {
			packagingId: objectId,
			period,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getPackagingSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export const findPackagingSheetAction = async (
	context: QueryFunctionContext<readonly ['findPackagingSheet', FindPackagingSheetActionParams]>,
): Promise<IPackaging> => {
	try {
		const {
			queryKey: { 1: params },
		} = context;

		const result = Parse.Cloud.run('getPackagingSheet', params);

		return result;
	} catch (error) {
		console.log(' ------ findPackagingSheetAction error: ', error);
		return Promise.reject(error);
	}
};

export async function findPackagingTableAction(context: any) {
	try {
		const {
			queryKey: { 1: params },
		} = context;

		// const result = await Parse.Cloud.run('findPackagingTable', params);
		const result = await Parse.Cloud.run(functionName.temporality.findPackaging, {
			view: 'general-view-of-packaging->packaging-table',
			...params,
		});
		return result;
	} catch (error) {
		console.log('--------- findPackagingTableAction error: ', error);
		return Promise.reject(error);
	}
}


export async function findPackagingTableEcoConceptionAction(context: any) {
	try {
		const {
			queryKey: { 1: params },
		} = context;

		const result = await Parse.Cloud.run(functionName.temporality.findPackagingEcoConception, params);
		return result;
	} catch (error) {
		console.log('--------- findPackagingEcoConception error: ', error);
		return Promise.reject(error);
	}
}

export async function findPackagingFamilyRepartitionAction(context: any) {
	try {
		const {
			queryKey: { 1: params },
		} = context;

		// const result = await Parse.Cloud.run('findPackagingTable', params);
		const result = await Parse.Cloud.run(functionName.temporality.findPackaging, {
			view: 'general-view-of-packaging->packaging-family-repartition',
			...params,
		});
		return result;
	} catch (error) {
		console.log('------- findPackagingFamilyRepartitionAction error: ', error);
		return Promise.reject(error);
	}
}

//--------------------------------------------------------------------------------------//
//                              count product for company                               //
//--------------------------------------------------------------------------------------//
export const getPackagingCompanyCountAction = async (context: QueryFunctionContext<readonly ['getPackagingCount', {team: string}]>) => {
	try {
		const {
			queryKey: [, { team }],
		} = context;
		const count = await Parse.Cloud.run('getPackagingCount', { team });
		return count;
	} catch (error) {
		console.log('--- getPackagingCompanyCountAction error: ', error);
		return Promise.reject(error);
	}
};

// ---- 3 --------------------------------------------------------------------------------
export const getPackagingFamiliesAction = async (context: QueryFunctionContext<readonly ['getPackagingFamilies']>) => {
	try {
		const result = await Parse.Cloud.run('getPackagingFamilies');
		return result;
	} catch (error) {
		console.log('--- getPackagingFamiliesAction error: ', error);
		return Promise.reject(error);
	}
};

export const getPackagingDetailsLifeCycleAction = async (
	context: QueryFunctionContext<
		readonly ['getPackagingDetailsLifeCycle', { selectedYear: number; packagingId: string; indicator: string }]
	>,
) => {
	try {
		const { indicator, packagingId, selectedYear } = context.queryKey[1];
		const result = Parse.Cloud.run(functionName.temporality.findPackagingDetailsLifeCycle, {
			indicator,
			packagingId,
			selectedYear,
		});
		return result;
	} catch (error) {
		console.log('--- getPackagingDetailsLifeCycleAction error: ', error);
		return Promise.reject(error);
	}
};

/**
 * getDataLifeCyclePackagingAction
 * @param context
 * @returns
 */
export const getDataLifeCyclePackagingAction = async (
	context: QueryFunctionContext<readonly ['getDataLifeCyclePackaging', { selectedYear: number; indicator: string; team: string }]>,
) => {
	try {
		const { indicator, selectedYear, team } = context.queryKey[1];
		const result = Parse.Cloud.run(functionName.packaging.getDataLifeCyclePackaging, {
			indicator,
			selectedYear,
			team,
		});
		return result;
	} catch (error) {
		console.log('--- getDataLifeCyclePackaging error: ', error);
		return Promise.reject(error);
	}
};

/**
 * facets packaging
 * @param context
 * @returns
 */
export const getFacetsPackagingAction = async (
	context: QueryFunctionContext<
		readonly ['getFacetsPackaging', { period: number; category: string; viewMode: string; limit: number; team: string }]
	>,
) => {
	try {
		const { period, category, viewMode, limit, team } = context.queryKey[1];
		const result = Parse.Cloud.run(functionName.packaging.getFacetsPackaging, {
			period,
			category,
			viewMode,
			team,
			limit,
		});
		return result;
	} catch (error) {
		console.log('--- getFacetsPackaging error: ', error);
		return Promise.reject(error);
	}
};
