import { css, cx } from '@emotion/css';
import { emphasize } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import { Equal } from 'lucide-react';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Lining from '../../../../../apps/front/src/components/sales/Lining';
import Unit from '../../../../../apps/front/src/components/units/Unit';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import { useApp } from '../../../hooks/useApp';
import { translation } from '../../../i18n/translation';
import { CARBON_INDICATOR } from '../../../utils/constants';
import BPopover from '../BPopover';

type Props = {
  value: number;
  row?: any;
  column: any;
  updateMyData?: (...params: any[]) => void;
};

// Exactly the same colors as used in the Difference component
const negativeColor = '#6C8C40'; // Green for good performance (negative diff)
const negativeBarColor = emphasize(negativeColor, 0.9); // Light green background for negative bars
const positiveColor = '#C70000'; // Red for poor performance (positive diff)
const positiveBarColor = emphasize(positiveColor, 0.9); // Light red/pink background for positive bars

const styles = {
  container: css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '36px', // Increased for better vertical alignment
    padding: '0 8px',
    minWidth: '400px', // Remettre une largeur minimale mais un peu moins grande qu'avant
  }),
  barContainer: css({
    position: 'relative',
    width: '100%',
    height: '36px',
    flex: '1 1 auto',
  }),
  centerLine: css({
    position: 'absolute',
    width: '1px',
    height: '100%',
    left: '50%',
    background: siteColors.grey500,
		zIndex:'10',
  }),
  valueBar: css({
    position: 'absolute',
    top: '8px', // Better vertical centering
    height: '24px',
    maxWidth: '50%', // Half of the container at most
  }),
  negativeBar: css({
    right: '50%', // Start from center and go left
    borderRadius: '4px 0 0 4px',
    background: negativeBarColor,
  }),
  positiveBar: css({
    left: '50%', // Start from center and go right
    borderRadius: '0 4px 4px 0',
    background: positiveBarColor,
  }),
  valueContainer: css({
    position: 'absolute',
    top: '8px', // Adjusted for better vertical alignment
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontWeight: 500,
    fontSize: '14px',
  }),
  valueNegative: css({
    color: negativeColor,
    right: 'calc(50% + 8px)', // Position to the left of the center
  }),
  valuePositive: css({
    color: positiveColor,
    left: 'calc(50% + 8px)', // Position to the right of the center
  }),
  valueEqual: css({
    color: siteColors.grey700,
    left: '50%',
    transform: 'translateX(-50%)', // Center the element horizontally
  }),
  popoverContainer: css({
    padding: 24,
  }),
  popoverContent: css({
    '& p': {
      color: siteColors.text,
      fontSize: 14,
      fontFamily: 'Inter',
      lineHeight: '25px',
      margin: 0,
    },
  }),
};

// Function to find the maximum absolute value in the dataset
const findMaxAbsoluteValue = (tableData: any[]) => {
  if (!tableData || !tableData.length) return 100; // Default value if no data

  // Extract all values and find the maximum absolute value
  let maxAbsValue = 0;
  tableData.forEach((item) => {
    if (item.VSExternePercent !== undefined && item.VSExternePercent !== null) {
      const absValue = Math.abs(item.VSExternePercent);

      if (absValue > maxAbsValue) {
        maxAbsValue = absValue;
      }
    }
  });

  // Cap the maximum value at 500 (500%)
  maxAbsValue = Math.min(maxAbsValue, 100);

  return maxAbsValue === 0 ? 100 : maxAbsValue; // Return 100 as default if all values are 0
};

const VsBarChart = ({
  value,
  row,
  column,
  updateMyData,
}: Props) => {
  const t = column?.props?.t || translation;
  const { indicator } = useApp();
  const theme = useTheme();

  // Handle quote case
  if (row?.original?.isQuote && column.props?.page === 'report-client') {
    return <Lining />;
  }

  // Handle undefined, null, or zero values
  if (value === undefined || value === null) {
    return <div className={styles.container}></div>;
  }

  // Show Equal icon when value is 0 or very close to 0
  if (value === 0 || Math.abs(value) < 0.01) {
    const equalComponent = (
      <div className={styles.barContainer}>
        <div className={cx(styles.valueContainer, styles.valueEqual)}>
          <Equal size={18} color={siteColors.grey700} />
        </div>
      </div>
    );

    return (
      <BPopover
        anchorOriginHorizontal={'right'}
        transformOriginVertical={'top'}
        transformOriginHorizontal={'right'}
        anchorOriginVertical={'bottom'}
        modeHover
        content={() => {return (
          <div className={styles.popoverContainer}>
            <div className={styles.popoverContent}>
              <p>{t('Pas de comparaison disponible')}</p>
            </div>
          </div>
        );}}
      >
        <div className={styles.container}>
          {equalComponent}
        </div>
      </BPopover>
    );
  }

  // Get the table data from props and find maximum absolute value
  const tableData = column?.props?.allData || [];
  const maxAbsoluteValue = findMaxAbsoluteValue(tableData);

  // Calculate bar width percentage (max 50% of container width)
  const maxBarPercentage = 50;
  const absValue = Math.abs(value);

  // Scale the bar width based on the maximum value in the dataset
  // We use the formula: (value / maxValue) * maxBarPercentage
  // This ensures all bars are proportional to the maximum value
  // We add a 1.2 multiplier to make the bars a bit more visible
  const barWidth = Math.min((absValue / maxAbsoluteValue) * maxBarPercentage * 1.2, maxBarPercentage);

  // Determine direction and styling based on value
  const isNegative = value < 0;

  // Choose appropriate CSS classes based on value
  const barClassName = isNegative ? styles.negativeBar : styles.positiveBar;
  const valueClassName = isNegative ? styles.valueNegative : styles.valuePositive;

  // Create arrow for direction indicator
  const arrow = (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
      <path
        d={isNegative ? 'M5 5L9.33013 0.5H0.669873L5 5Z' : 'M5 0L0.669873 4.5H9.33013L5 0Z'}
        fill={isNegative ? negativeColor : positiveColor}
      />
    </svg>
  );

  // Format display value avec 2 chiffres après la virgule
  const formattedValue = formatNumber(absValue, '', 2);

  // Component for displaying the value and bar
  const barComponent = (
    <div className={styles.barContainer}>
      <div className={styles.centerLine} />
      <div
        className={cx(styles.valueBar, barClassName)}
        style={{ width: `${barWidth}%` }}
      />
      <div className={cx(styles.valueContainer, valueClassName)}>
        {arrow}
        <span>{formattedValue} %</span>
      </div>
    </div>
  );

  // If popover is enabled
  if (column?.props?.showPopover) {
    return (
      <BPopover
        anchorOriginHorizontal={'right'}
        transformOriginVertical={'top'}
        transformOriginHorizontal={'right'}
        anchorOriginVertical={'bottom'}
        modeHover
        content={() => {
          if (column?.props?.page === 'view-modelization') {
            return (
              <div className={styles.popoverContainer}>
                <div className={styles.popoverContent}>
                  <p className="fontWeight700">{t('Differentiel')}</p>
                  <div className="flexRow alignCenter gap8">
                    <div className="flexRow alignCenter gap4">
                      {arrow}
                      <span className={isNegative ? 'color-success' : 'color-error'}>
                        {formattedValue}%
                      </span>
                    </div>
                    <div className="fontWeight400 fontSize16 alignCenter">
                      {formatNumber(row?.original?.VSReference ?? row?.original?.VSExterne, '', 2)}{' '}
                      <Unit measure={(indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity') as any} />
                    </div>
                  </div>
                  <p className="fontWeight700">{t('Source externe')}</p>
                  <p>{row?.original?.bddRefPortfolio}</p>
                </div>
              </div>
            );
          }

          return (
            <div className={styles.popoverContainer}>
              <div className={styles.popoverContent}>
                <p className="fontWeight700">{t('Differentiel')}</p>
                <div className="fontWeight400 fontSize16 alignCenter">
                  {formatNumber(row?.original?.VSReference, '', 2)}{' '}
                  <Unit measure={(indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity') as any} />
                </div>
                <p className="fontWeight700">{t('reference')}</p>
                <p>{t(row?.original.labelPortfolioCmaps)}</p>
                <p className="fontWeight700">Source</p>
                <p>{row?.original.bddRefPortfolio}</p>
              </div>
            </div>
          );
        }}
      >
        <div className={styles.container}>
          {barComponent}
        </div>
      </BPopover>
    );
  }

  // Basic render without popover
  return (
    <div className={styles.container}>
      {barComponent}
    </div>
  );
};

export default VsBarChart;
