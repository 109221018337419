import { useCallback, useEffect } from 'react';

import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { ALL_YEAR_OPTION_VALUE, ITEM_NATURE_NON_FOOD } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useProductInfo } from '@carbonmaps/ui/hooks/useGetProductSheet';

import { useYearSelection } from '../../../hooks/useImpactYear';
import { useJuneTrack } from '../../../hooks/useJuneTrack';

import OtherIndicatorsBlock from './OtherIndicatorsBlock';
import ProductIndicatorsBlock from './ProductIndicatorsBlock';
import ProductIngredientsRepartition from './ProductIngredientsRepartition';
import LifeCycleBlock from './ProductLifeCycleBlock';
import ProductPackagingRepartition from './ProductPackagingRepartition';
import ProductSuppliersBlock from './ProductSuppliersBlock';
import ProductTemporality from './ProductTemporality';

const ProductSynthesis = () => {
	// const {
	// 	result: { data: authData },
	// } = useGetClientAuthQuery();

	// const plugins = authData?.session.company?.plugins || [];
	// const hasRoles = useHasRoles();
	const params = useParams();

	const { indicator } = useApp();
	const { selectedYear, setSelectedYear } = useYearSelection();

	const { data: productInfo } = useProductInfo({ objectId: _.toString(params.productId), viewMode: indicator });

	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(options: any) => {
			if (!analytics) return;
			analytics.track('Explore Land Product', options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	// ---- track event product page ---- //
	useEffect(() => {
		if (!productInfo) return;

		trackEvent({ ProductLabel: productInfo?.label });

		const impactYear = productInfo.impactYear;

		const isFound = (impactYear || []).find((year) => {
			return year === selectedYear;
		});

		if (!isFound && impactYear && selectedYear !== ALL_YEAR_OPTION_VALUE) setSelectedYear(impactYear[0]);
	}, [productInfo, trackEvent]);

	return (
		<>
			{/* <div className="kpi">
				<ProductIndicatorsBlock />
			</div> */}
			<div
				css={{
					display: 'grid',
					gridTemplateColumns: 'calc(50% - 16px) calc(50% - 16px)',
					gap: 32,
					width: '100%',
					maxWidth: '100%',
					'@media (max-width: 1280px)': {
						gridTemplateColumns: '100%',
						gap: 24,
					},
					// gridAutoFlow: 'row',
					marginBottom: 32,
				}}
			>
				<LifeCycleBlock />
				<ProductTemporality />
				{_.get(productInfo, 'itemNature') !== ITEM_NATURE_NON_FOOD
? (
						<ProductIngredientsRepartition />
				)
: null}
				<ProductPackagingRepartition />
				<ProductSuppliersBlock />
			</div>
			<div>
				<OtherIndicatorsBlock />
			</div>
		</>
	);
};

export default ProductSynthesis;
