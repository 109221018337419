import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/css';
import { Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { HelpCircle } from 'lucide-react';
import { createSearchParams } from 'react-router-dom';

import SupplierIcon from '@carbonmaps/media/icons/supplier.svg?react';
import { ALL_YEAR_OPTION_VALUE, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { GetParams } from '@carbonmaps/ui/actions/supplier.actions';
import { TableComponent, getStickyHeaderStyle } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import EmptyValueHelpCircle from '../../../../../components/EmptyValueHelpCircle';
import CompareFloatingMenu from '../../../../../components/comparison/layout/CompareFloatingMenu';
import TableHeaderCell from '../../../../../components/table/TableHeaderCell';
import ImpactCarbonePopover from '../../../../../components/table/popover/ImpactCarbonePopover';
import WaterImpactPopover from '../../../../../components/table/popover/WaterImpactPopover';
import { useGlossary } from '../../../../../hooks/useGlossary';
import { useYearSelection } from '../../../../../hooks/useImpactYear';
import { useJuneTrack } from '../../../../../hooks/useJuneTrack';
import { useQueryParams } from '../../../../../hooks/useSearchParams';
import { useSearchQueryParams } from '../../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../lib/colors';
import { useGetClientAuthQuery } from '../../../../../lib/react-query/features/auth/auth.hooks';
import { exportSupplierAction } from '../../../../../lib/react-query/features/export/supplier.actions';
import { useFindSuppliersTable } from '../../../../../lib/react-query/features/supplier/supplier.hooks';
import { CARBON_INDICATOR } from '../../../../../utils/constants';
import CompareSupplier from '../comparison/CompareSupplier';

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},
		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		'.bordered tr th:not(:nth-of-type(1),:nth-of-type(2),:nth-of-type(3)) div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2), :nth-of-type(3)) div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2), :nth-of-type(3)) button':
			{
				justifyContent: 'flex-end!important',
				paddingRight: '0!important',
			},
	};
};

const styles = {
	popoverImpactStyle: css({ paddingRight: 0 }),
	percent: css({
		justifyContent: 'flex-end',
	}),
};

const formatData: any = (res: any, indicator: any, paramsUrlString: any) => {
	if (!res?.length) return [];
	return res.map((item: any) => {
		const searchParams = new URLSearchParams(paramsUrlString);

		if (item?.questionFormId) {
			searchParams.set('rse:questionFormId', item?.questionFormId);
		} else {
			searchParams.delete('rse:questionFormId');
		}

		return {
			...item,
			origin: null,
			reference: item?.uid,
			link: decodeURIComponent(`/suppliers/details/${item.objectId}/synthesis?${searchParams.toString()}`),
			isChecked: _.get(item, 'isBlueCheck', false),
		};
	});
};

type TableProps = {
	manualSortBy?: boolean;
	resetPage?: number;
};

const SuppliersTable = ({ manualSortBy }: TableProps) => {
	// ---- hooks ---- //

	// init filters values
	const [searchQueryParams] = useSearchQueryParams();
	const { selectedYear } = useYearSelection();
	// translation
	const { t, i18n } = useTranslation();
	// glossary
	const { g } = useGlossary();
	// get current switch mode value ( carbon or water )
	const { indicator } = useApp();
	// theme
	const theme = useTheme();
	// styles
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// query params
	const queryParams = useQueryParams();
	const searchParams = createSearchParams(queryParams as any)?.toString();

	// --- ref for fetch data table
	const fetchIdRef = useRef(0);

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	// ---- columns table definition ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} valueType="string" label={t('supplier-label')} />;
				},
				accessor: 'name',
				editable: true,
				sortDescFirst: true,
				component: 'TagLabel',
				icon: <SupplierIcon css={{ '& path': { fill: theme.palette.grey[700] } }} width={20} height={20} />,
				type: 'string',
				props: {
					gap: 8,
					style: {
						color: theme.palette.common.black,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
						textTransform: 'uppercase !important',
						onClick: (row: any) => {
							trackEvent('Explore Click Supplier', {
								SupplierLabel: row?.name,
							});
						},
					},
				},
				disableSortBy: true,
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('supplier-note')}
							variant="unit"
							unit={<Typography color={siteColors.grey700}>A+&mdash;E</Typography>}
						/>
					);
				},
				accessor: 'iNoteNum',
				editable: true,
				sortDescFirst: true,
				component: 'Scoring',
				type: 'number',
				props: {
					style: {
						width: '100px',
					},
				},
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={
								selectedYear === ALL_YEAR_OPTION_VALUE
									? t(WORDING_TERMS.IMPACT_ALL_YEAR)
									: t(WORDING_TERMS.IMPACT, { period: selectedYear || t('period') })
							}
							variant="measure"
							measure={'percent'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonImpactPercent' : 'waterImpactPercent',
				editable: true,
				sortDescFirst: true,
				component: 'PercentField',
				type: 'percent',
				props: {
					...(indicator === CARBON_INDICATOR
						? {
								popoverComponent: ImpactCarbonePopover,
								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,

								emptyValue: <HelpCircle color={siteColors.carbon500} />,
						  }
						: {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
								popoverComponent: WaterImpactPopover,
								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,
								emptyValue: <HelpCircle color={siteColors.water500} />,
						  }),
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.INTENSITY)}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonIntensity' : 'waterIntensity',
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				type: 'number',
				props: {
					...(indicator !== CARBON_INDICATOR
						? {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
						  }
						: null),
						emptyValue: <EmptyValueHelpCircle description={t('Volume non renseigné')}/>,
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('Volume')} variant="measure" measure={'massTon'} />;
				},
				accessor: 'tonnage',
				editable: true,
				sortDescFirst: true,
				component: 'SoldItemsField',
				type: 'number',
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('Approvisionnements')}
							variant="measure"
							measure={'nbrReference'}
						/>
					);
				},
				accessor: 'approvisionnements',
				editable: true,
				sortDescFirst: true,
				component: 'SimpleCell',
				type: 'number',
			},
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// decimalNumberSort,
		indicator,
		selectedYear,
		t,
	]);

	// -------------------------------------------------------------------------------------- //
	// --------------------------------- State definition ----------------------------------- //
	// -------------------------------------------------------------------------------------- //

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState<GetParams>({
		...(searchQueryParams?.orderBy && {
			sortingBy: {
				id: searchQueryParams?.orderBy,
				desc: searchQueryParams?.direction === 'desc',
			},
		}),
		...searchQueryParams,
	} as any);

	const {
		result: { data: tableResultData, isLoading: isTableLoading },
	} = useFindSuppliersTable({
		paramsTable,
	});

	// state to force to first page
	const [resetPage, setResetPage] = useState(0);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);

	useEffect(() => {
		setTableParams((prev: any) => {
			return { ...prev, ...searchQueryParams };
		});
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQueryParams]);



	const {
		result: { data: authData },
	} = useGetClientAuthQuery();
	const languageCode = getIsoCountryCode(i18n.language);

	const handleExport = useCallback(async (format: string, selectedRows: any) => {
			trackEvent('Export supplier', { count: selectedRows?.length });
			await exportSupplierAction(
				{
					ids: selectedRows.map((e: any) => {
						return e.original.objectId;
					}),
					facetFilters: searchQueryParams.facetFilters,
					supplierIds: searchQueryParams?.supplierIds ?? [],
					input: searchQueryParams.input ?? '',
					format: format,
					selectedYear,
					companyName: authData?.session?.company?.name || '___',

				},
				t,
				languageCode,
			);
		}, [authData?.session?.company?.name, trackEvent, languageCode, searchQueryParams.facetFilters, searchQueryParams.input, searchQueryParams?.supplierIds, selectedYear, t]);

	let paramsUrlString = '';
	if (searchParams) paramsUrlString = `${searchParams}`;

	return (
		<>
		<TableComponent
			skeleton={isTableLoading}
			loading={false}
			fetchData={updateTableParams}
			pageCount={tableResultData?.meta?.last_page || 0}
			noDataMessage={t('suppliers-table-empty-data')}
			pageSize={paramsTable.size}
			columns={tableColumns}
			resetPage={resetPage}
			data={formatData(tableResultData?.suppliers || [], indicator, paramsUrlString)}
			setSelectedRow={() => {
				return;
			}}
			onSortingColumn={handleSortingTable}
			addStyles={stylesTable}
			manualSortBy={manualSortBy}
			stickyHeader
			stickyHeaderStyles={stickyHeaderStyles}
			transFunc={t}
			onNextClick={() => {
				return trackEvent('Explore Suppliers Next Page');
			}}
		/>
			<CompareFloatingMenu element={CompareSupplier} onExport={handleExport} tag="fournisseur" />
		</>
	);
};

const stickyHeaderStyles = getStickyHeaderStyle(1583);

export default SuppliersTable;
