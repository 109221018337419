import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MUILink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { ChevronRight, X } from 'lucide-react';
import { Link } from 'react-router-dom';

import Logo from '@carbonmaps/media/icons/logo-blue.svg?react';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
type HeaderFullScreenProps = {
	onResize: () => void;
};

const HeaderFullScreen = ({ onResize }: HeaderFullScreenProps) => {
	const theme = useTheme();
	const { breadcrumbs } = useApp();
	const { t } = useTranslation();

	return (
		<Box
			className=" flexRow spaceBetween alignCenter"
			sx={{ padding: '16px 22px 16px 24px', background: theme.palette.primary.main }}
		>
			<Box className=" flexRow alignCenter" sx={{ gap: '16px' }}>
				<Logo />
				<Breadcrumbs separator={<ChevronRight color="#FFF" />} aria-label="breadcrumb">
					{breadcrumbs.map(({ text, link }) => {
						if (link) {
							return (
								<MUILink
									component={Link}
									key={text}
									to={link}
									sx={{
										textDecoration: 'none',
										cursor: 'pointer',
										color: siteColors.common.white,
										fontSize: '16px',
										fontWeight: 500,
									}}
								>
									{text}
								</MUILink>
							);
						}

						return (
							<Typography
								key={text}
								sx={{
									textDecoration: 'none',
									cursor: 'pointer',
									color: siteColors.common.white,
									fontSize: '16px',
									fontWeight: 500,
								}}
							>
								{text}
							</Typography>
						);
					})}
				</Breadcrumbs>
			</Box>
			<BButton
				label={t('close-button')}
				variant="secondary"
				iconLeft={<X color={theme.palette.primary.main} />}
				sx={{ color: theme.palette.primary.main, fontWeight: '600' }}
				onClick={onResize}
			/>
		</Box>
	);
};

export default HeaderFullScreen;
