import { useEffect } from 'react';

import CompareContent from '../../../../../components/comparison/layout/CompareContent';
import CompareLayout from '../../../../../components/comparison/layout/CompareLayout';
import CompareLeftMenu from '../../../../../components/comparison/layout/CompareLeftMenu';
import CompareTypeMenu from '../../../../../components/comparison/layout/CompareTypeMenu';
import MenuTitle from '../../../../../components/comparison/layout/MenuTitle';
import SupplierModalSelect from '../../../../../components/comparison/modal/SupplierModalSelect';
import { useCompareProduct } from '../../../../../hooks/useCompareProduct';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../lib/colors';
import { supplierCompareContentMap, suppliersTypeOptions } from '../../../../../utils/compareProduct';
import { useSuppliersToCompare } from '../../../hooks';


type CompareProductProps = {
	onClose: () => void;
}

const CompareSupplier = ({ onClose }: CompareProductProps ) => {
	const { t } = useTranslation();

	const { products, compareType } = useCompareProduct();

	const ComponentContent = supplierCompareContentMap.get(compareType)?.element;

	const { data, isLoading } = useSuppliersToCompare();
	const { setProducts, setProductToCompare } = useCompareProduct();

	 // --------------------------------------- //
	 // --------- set compare to products ----- //
	 // --------------------------------------- //
	 useEffect(() => {
		if (!data?.length) return;

		setProductToCompare(data[0]);
		setProducts(data.map((p: any, index: number) => {
			return {
				...p,
				color: (siteColors.compareProductColors as any)[`color${index%5}`],
			};
		}));
	 }, [data, setProducts, setProductToCompare]);

	return (
		<CompareLayout onClose={onClose}
			leftMenu={<CompareLeftMenu title={<MenuTitle title={t('Comparaison de fournisseurs')} subtile={t('supplier-selected-count', { count: products.length } )} componentModal={SupplierModalSelect} />} isLoading={isLoading}/>}
		>
			<CompareContent compareTypeMenu={<CompareTypeMenu options={suppliersTypeOptions} />} >
				<ComponentContent />
			</CompareContent>
		</CompareLayout>
	);

};

export default CompareSupplier;
