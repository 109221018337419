import React, { ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import { Box } from '@mui/material';
import { type PopperPlacementType } from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { BoxIcon, ChevronDown } from 'lucide-react';

import BRadioGroup from '@carbonmaps/ui/components/saas/BRadioGroup';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import { useTranslation } from '../hooks/useTranslation';
import { cn, siteColors } from '../lib/colors';

import Popper from './Popper';

const styles = {
	circle: css({
		width: 8,
		height: 8,
		borderRadius: '50%',
		border: '1px solid transparent',
	}),

	content: css({
		width: 110,
		textDecoration: 'none',
	}),

	primary: css({
		background: siteColors.primary,
	}),
	primaryText: css({
		color: cn(siteColors.primary, '#!important'),
	}),
	secondary: css({
		borderColor: siteColors.grey800,
	}),
	popover: css({
		textTransform: 'none !important',
		padding: '0!important',
	} as any),

	popoverContent: css({
		padding: 24,
		minWidth: 150,

		'& p': {
			color: siteColors.text,
			fontSize: 14,
			fontFamily: 'Inter',
			lineHeight: '25px',
			margin: 0,
		},
	}),
} as any;

interface TradeOffHeaderProps {
	value?: string;
}


const TypeModelization = ({
	value,
}: TradeOffHeaderProps) => {
	const { t } = useTranslation();
	const isCustomType = _.toLower(value)?.includes('custom') ? true : false;
	const variant = isCustomType ? 'primary' : 'secondary';
	return (
		<div className='flexRow gap8 alignCenter'>
			<div className={cx(styles.circle, styles[variant])} />
			<Typography className={cx('lineHeight1', styles[`${variant}Text`])}>{isCustomType ? t('Personnalisée') : t('Référence')}</Typography>
		</div>
	);
};

export default TypeModelization;
