import LockPopover from '../../../containers/dashboard/LockPopover';
import Typography from '../../Typography';

type CardTitleProps = {
	title: string;
	icon?: React.ReactNode;

	subtitle?: string;
	isLock?: boolean;
};

const CardTitle = ({ title, subtitle, icon, isLock = false }: CardTitleProps) => {
	return (
		<div className="flexRow alignCenter gap8">
			{icon}
			<div className="flexColumn gap8">
				<div className="flexRow alignCenter gap4">
					<Typography variant="h3" className="lineHeight1">
						{title}
					</Typography>
					{isLock && <LockPopover />}
				</div>
				{subtitle && !isLock && <Typography className="lineHeight1">{subtitle}</Typography>}
			</div>
		</div>
	);
};

export default CardTitle;
