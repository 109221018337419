import { useMemo } from 'react';

import { css, cx } from '@emotion/css';

import BlockTitle from '../../../../components/comparison/BlockTitle';
import CompareProductBarChart from '../../../../components/comparison/CompareProductBarChart';
import ImpactSelected from '../../../../components/comparison/ImpactSelected';
import LifeCycleCheck from '../../../../components/comparison/LifeCycleCheck';
import Skeleton from '../../../../components/Skeleton';
import { useCompareProduct } from '../../../../hooks/useCompareProduct';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import { orderBy } from '../../../../utils/array.utils';
import { compareProductConfig } from '../../../../utils/compareProduct';
import { useLifeChartCompare, useProductsToCompare } from '../hooks';


const styles = {
	container: css({
			padding: '16px 8px',
	}),

	skeleton: css({
		height: 'calc(50vh) !important',
		width: '100%',
	}),
};

const options = [
	{
		label: 'Tri du moins impactant au plus impactant',
		value: 'asc',
	},
	{
		label: 'Tri du plus impactant au mois impactant',
		value: 'desc',
	},
];

const Agriculture = () => {

	const { t } = useTranslation();


	// search params: don't show loading if no products to compare
		const [searchQueryParams] = useSearchQueryParams();

	const { sortDirection, setSortDirection, products, compareType, indicator, setProductToCompare } = useCompareProduct();

	// config for compare value
	const config = compareProductConfig.get(compareType)[indicator] as any;

	// fetch data life chart
	const { data, isLoading } = useLifeChartCompare();

	const dataGraph = useMemo(() => {
		if (!data?.length) return [];
		const lifeChartDataMap = new Map();
		data.forEach((product: any) => {
			lifeChartDataMap.set(product.uid, product);
		});

		const  dataLifeChart = products.map((product: any) => {
			const data = lifeChartDataMap.get(product.uid);
			return {
				...data,
				...product,

			};
		});
		setProductToCompare(dataLifeChart[0]);
		return dataLifeChart;
	}, [data, products, setProductToCompare]);

	return (
		<div className={cx('flexColumn flex1 gap24', styles.container)}>
			<BlockTitle title={t('Comparaison de l’impact de l’agriculture')} subtitle={t('Compare l’impact de l’agriculture sur plusieurs éléments pour déterminer lequel est le moins émissif par kilo')}/>
			<ImpactSelected options={options} value={sortDirection} onChange={(v: any) => {setSortDirection(v);}} >
				<LifeCycleCheck />
			</ImpactSelected>
			{ isLoading
? <Skeleton width="100%!important" className={styles.skeleton} /> :
			<CompareProductBarChart products={orderBy(dataGraph || [], config.fieldStep, sortDirection as any) as any} isLifeChart /> }
		</div>
	);


};

export default Agriculture;
