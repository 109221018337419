import { useQuery } from '@tanstack/react-query';

import { getProductCountByPeriod } from '@carbonmaps/ui/actions/dashboard.actions';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTeam } from '../../../hooks/useTeam';
import { useTranslation } from '../../../hooks/useTranslation';
import Typography from '../../Typography';

const ProductCount = () => {
	// period seleded
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	// team
		const { team } = useTeam();

	// ---- fetch count product by period ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getProductCountByPeriod',
			{ period: selectedYear,
				facetFilters: searchQueryParams?.facetFilters || [],
				team,
			},
		],
		queryFn: getProductCountByPeriod,
	});

	const { t } = useTranslation();
	return isLoading
? null
: (
		<Typography className="number grey900">
			{formatNumber(data, undefined, 0)} {toLowerCase(t('products'))}
		</Typography>
	);
};

export default ProductCount;
