import { useCallback } from 'react';

import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import { Lock, User, Users } from 'lucide-react';

const config  = {
	administrateur: <Users className="grey7" size={20}/>,
	éditeur: <User className="grey7" size={20}/>,
	utilisateur: <User className="grey7" size={20} />,
	contributeur: <Lock className="grey7" size={20} />,
	super_admin:  <Users className="grey7" size={20} />,
} as any;

const styles = {

	label: css({
		textTransform: 'capitalize',
	}),
};

const UserRole = (props: any) => {
	const {
		value,
		row,
		column,
	} = props;

	const translate = (v: string) => {return v;};

	const transFunc = column?.props?.transFunc || translate;
	const callback = column?.props?.callback;

	// add a callback to the row
	const handleClick = useCallback(() => {
		if (callback) {
			callback(row.original);
		}

	}, [callback, row]);


	return (
		<div className="flexRow gap8 pointer" onClick={handleClick}>
			{config[value]}
			<Typography className={cx('grey9', styles.label)}>
				{transFunc(value)}
			</Typography>
		</div>
	);
};

export default UserRole;
