import { ReactNode, useMemo, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';

type DonutChartProps = {
	width: number;
	height: number;
	items: any[];
	innerRadius: number;
	outerRadius: number;
	legend?: ReactNode;
	withTooltip?: boolean;
	renderTooltip?: (item: any, indicator: any) => ReactNode;
	indicator?: any;
	data?: any[];
	className?: string;
};

const getArcPath = (start: number, end: number, innerRadius: number, outerRadius: number) => {
	const startAngle = start * Math.PI * 2;
	const endAngle = end * Math.PI * 2;
	const x1 = innerRadius * Math.sin(startAngle);
	const y1 = innerRadius * -Math.cos(startAngle);
	const x2 = outerRadius * Math.sin(startAngle);
	const y2 = outerRadius * -Math.cos(startAngle);
	const x3 = outerRadius * Math.sin(endAngle);
	const y3 = outerRadius * -Math.cos(endAngle);
	const x4 = innerRadius * Math.sin(endAngle);
	const y4 = innerRadius * -Math.cos(endAngle);
	const bigArc = end - start >= 0.5;
	const outerFlags = bigArc ? '1 1 1' : '0 0 1';
	const innerFlags = bigArc ? '1 1 0' : '1 0 0';
	return `M ${x1},${y1} L ${x2},${y2} A ${outerRadius} ${outerRadius} ${outerFlags} ${x3},${y3}
        L ${x4},${y4} A ${innerRadius} ${innerRadius} ${innerFlags} ${x1},${y1} Z`;
};

const DonutChart = ({
	width,
	height,
	items,
	innerRadius,
	outerRadius,
	legend,
	withTooltip,
	renderTooltip,
	indicator,
	data = [],
	className,
}: DonutChartProps) => {
	const segments = useMemo(() => {
		let start = 0;
		return items.map((item: any) => {
			const delta = item.value / 100;
			const path = getArcPath(start, start + delta, innerRadius, outerRadius);
			start += delta;
			return { ...item, path };
		});
	}, [items, innerRadius, outerRadius]);

	const tooltipRef = useRef<any>();
	const svgRef = useRef<any>();
	const [selectedItem, setSelectedItem] = useState(null);

	const onMouseEnter = (evt: any, data: any) => {
		const CTM = svgRef.current.getScreenCTM();

		const mouseX = (evt.clientX - CTM.e) / CTM.a;
		const mouseY = (evt.clientY - CTM.f) / CTM.d;
		const top = /* tooltipPosition ? mouseY - tooltipPosition.y : */ mouseY - /* topPosition */ 100;

		tooltipRef.current.setAttributeNS(null, 'visibility', 'visible');

		tooltipRef.current.setAttributeNS(
			null,
			'style',
			`left: ${mouseX + 6 / CTM.a}px; top: ${top}px; position: absolute; zIndex: 100000; display:none`,
		);
		setSelectedItem(data);
	};

	return (
		<div className={cx('flexRow alignCenter gap8', className)}>
			<div
				css={{
					position: 'relative',
					'&:hover .custom-tooltip': { display: 'block !important', zIndex: 100000 },
				}}
			>
				{withTooltip && (
					<Box
						className="custom-tooltip"
						ref={tooltipRef}
						style={{ display: 'none', position: 'absolute', left: 100 }}
					>
						{
							renderTooltip ? renderTooltip(selectedItem, indicator) : null
						}
					</Box>
				)}
				<svg width={width} height={'auto'} viewBox={`0 0 ${width} ${height}`} ref={svgRef}>
					<g transform={`translate(${width / 2},${height / 2})`}>
						{segments.map((segment: any, index: number) => {return (
							<path
								key={segment.color}
								stroke={'#fff'}
								fill={segment.color}
								d={segment.path}
								strokeWidth={2}
								onMouseEnter={(evt: any) => {
									onMouseEnter(evt, data?.[index]);
								}}
							/>
						);})}
					</g>
				</svg>
			</div>
			{legend}
		</div>
	);
};

export default DonutChart;
