import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { HelpCircle } from 'lucide-react';

import { TYPE_MODELIZATION_INGREDIENT } from '@carbonmaps/shared/utils/constants';
import { displayValue, formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { useTranslation } from '../../../../../apps/front/src/hooks/useTranslation';
import { siteColors } from '../../../../../apps/front/src/lib/colors';
import IntensityChart from '../../charts/IntensityChart';
import BPopover from '../BPopover';

type Props = {
	value: any;
	row: any;
	column: any;
	updateMyData: (...params: any[]) => void;
};

const styles = {
	width60: css({
		width: 60,
	}),
	popoverContent: css({
		padding: 24,
		gap: 12,
	}),

	popoverContainer: css({
		padding: 24,
	}),
	innerPopoverContainer: css({ marginBottom: 12 }),
	bodyText: css({
		fontSize: '14px !important',
		fontWeight: '400 !important',
	}),
};

const IntensityField = ({ value, row, column: { id, props } }: Props) => {
	const theme = useTheme();

	const { t } = useTranslation();

	const renderChart = (
		<div
			className="flexRow alignCenter nowrap justifyCenter"
			style={{ gap: 16, color: props?.color || siteColors.primary }}
		>
			<>
				<span className={cx(styles.width60, styles.bodyText, 'number')}>{displayValue(value)}</span>
				<IntensityChart
					// percentage={row?.original[`${id}Percent`] || 0}
					percentage={row?.original.percentage}
					color={props?.color || siteColors.primary}
				/>
			</>
		</div>
	);

	if (value >= 0) {
		return row.original.itemType === 'product' ?

			(
			<BPopover
				anchorOriginHorizontal={'right'}
				transformOriginVertical={'top'}
				transformOriginHorizontal={'right'}
				anchorOriginVertical={'bottom'}
				addStyles={{ padding: '0px' }}
				modeHover
				content={(onClose) => {
					return (
						<div className={styles.popoverContainer}>
							<div className={styles.innerPopoverContainer}>
								{!value || value < 0
									? (
										<Typography className="fontWeight700">Valeur incorrect</Typography>
									)
									: (
										<>
											<Typography className="fontWeight700">{t('modeling_method')}</Typography>
											<Typography>
												{t((TYPE_MODELIZATION_INGREDIENT as any)[_.toLower(row.original?.['typeModelizationIngredient'] ??
row.original?.['typeModelizationCompoIngredient'] )], {
													recipe: t('recipe'),
													recipe_lowercase: t('recipe'),
												})}
											</Typography>
										</>
									)}
							</div>
						</div>
					);
				}}
			>
				{/* AAA */}
				{renderChart}
			</BPopover>
			) : (
				<>{renderChart}</>
			);
	}

	return (
		<div className="flexRow justifyCenter width100">
			<BPopover
				anchorOriginHorizontal={'right'}
				transformOriginVertical={'top'}
				transformOriginHorizontal={'right'}
				anchorOriginVertical={'bottom'}
				modeHover
				content={(onClose) => {
					return (
						<div className={cx('flexColumn', styles.popoverContent)}>
							<Typography className="fontWeight600">{value && value < 0 ? 'Valeur incorrect' : ''}</Typography>
						</div>
					);
				}}
			>
				<>
					<span className={styles.width60}>
						<HelpCircle color={props?.color || theme.palette.primary.main} />
					</span>
					<IntensityChart percentage={row?.original[`${id}Percent`] || 0} color={props?.color || siteColors.primary} />
				</>
			</BPopover>
		</div>
	);
};

export default IntensityField;
