import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { HeartPulse } from 'lucide-react';

import { getGlobalAuditPercent } from '@carbonmaps/ui/actions/audit.actions';
import { displayAuditValue, formatNumber, truncNumberFormat } from '@carbonmaps/ui/utils/numberFormat';

import { useYearSelection } from '../../hooks/useImpactYear';
import { useTranslation } from '../../hooks/useTranslation';
import { getGlobalConfigValue } from '../../utils/audit.utils';
import BlockTitle from '../BlockTitle';
import Skeleton from '../Skeleton';
import CircularProgress from '../card/CircularProgress';

const styles = {
	container: css({
		borderRadius: ' 4px 16px 16px 4px',
		padding: 4,
	}),
	boxValueContainer: css({
		padding: '15px 15px 15px 6px',
		borderRadius: '7px 48px 48px 5px',
		background: 'var(--box-color)',
		minWidth: 100,
		minHeight: 30,
	}),

	// ! ==============
	contain1: css({
		borderRadius: '16px',
		padding: '24px',
		// alignItems: 'center',
		// justifyContent: 'center',
	}),
	sub1: css({
		lineHeight: '39.552px!important',
	}),
	span1: css({
		fontSize: '24px!important',
		display: 'inline-block',
		marginLeft: '4px',
	}),
	// contain2: css({
	// 	// borderRadius: '16px',
	// 	// padding: '24px',
	// 	// alignItems: 'center',
	// 	// justifyContent: 'center',
	// }),

	value: css({
		fontSize: '16px !important',
	}),

	boxContent: css({
		padding: '19px 18px 19px 21px',
		gap: '4px',
	}),

	title: css({
		fontSize: '16px !important',
		lineHeight: 'normal !important',
	}),
	text: css({
		lineHeight: 'normal !important',
	}),
	blockTitle: css({
		marginBottom: '0px !important',
		lineHeight: '1 !important',
		h3: {
			lineHeight: '1 !important',
		},
	}),
	skeleton: css({
		width: '100% !important',
		flex: 1,
	}),
};

const GlobalCard = () => {
	const { selectedYear } = useYearSelection();

	// ---- fetch global percent value ---- //
	const { data: percent, isLoading } = useQuery({
		queryKey: ['getGlobalAuditPercent', { selectedYear }],
		queryFn: getGlobalAuditPercent,
	});

	const theme = useTheme();

	// translation
	const { t } = useTranslation();
	const elm = useMemo(() => {
		const globalConfig = getGlobalConfigValue((percent as any) || 0);

		return (
			<>
				{/* <>
					<HeartPulse />
					Audit global
				</> */}
				{/* <div> */}

				<div
					className={cx(
						'flexRow width100 borderGrey500 bgWhite alignCenter',
						styles.contain1,
					)} /* className={cx('flexRow borderGrey500 bgWhite nowrap', styles.container)} */
				>
					<div css={{ marginRight: '20px' }}>
						<CircularProgress
							width={1}
							innerRadius={41}
							containerWidth="96px"
							fillColor={globalConfig?.color}
							percent={percent as any}
						/>
					</div>
					<div /* className="flexColumn justifyCenter" */>
						{/* <div> */}
						<Typography variant="subtitle1" className={cx(styles.sub1, 'number')}>
							{displayAuditValue(percent as any)}
							<span className={cx(styles.span1)}> %</span>
						</Typography>
						<Typography className={styles.text}>{t(globalConfig?.text)}</Typography>
						{/* </div> */}
					</div>
				</div>
			</>
		);
	}, [percent, t]);

	return (
		<div className="flexColumn flex1 width100 gap16 ">
			<BlockTitle className={styles.blockTitle}>{t('dashboard-audit-card-title')}</BlockTitle>
			{isLoading
? (
				<Skeleton className={styles.skeleton} width="100% !important">
					{elm}
				</Skeleton>
			)
: (
				elm
			)}
		</div>
	);
};

export default GlobalCard;
