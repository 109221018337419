import { ReactNode } from 'react';

import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import { useAutoCloseSidebar } from '../../hooks/useAutoCloseSidebar';
import BaseLayout from '../_common/BaseLayout';
import TopBar from '../_common/TopBar';

import BoSideBar from './BoSideBar';

type Props = {
	children?: ReactNode;
};

const BoLayout = ({ children }: Props) => {
	// Définir les routes où la sidebar doit être fermée automatiquement
	const autoCloseRoutes: (string | RegExp)[] = [
		// Ajoutez ici les routes BO qui doivent fermer la sidebar automatiquement
		// si elles existent
	];

	// Utiliser le hook personnalisé
	const { isOpen, toggleSidebar } = useAutoCloseSidebar({
		autoCloseRoutes,
	});

	return (
		<BaseLayout sidebar={<BoSideBar open={isOpen} />}>
			<TopBar open={isOpen} onToggle={toggleSidebar} disableIndicatorSwitch disableYearSelection />

			{/* The actual content */}
			<Box component="main" flexGrow={1}>
				{children ? children : <Outlet />}
			</Box>
		</BaseLayout>
	);
};

export default BoLayout;
