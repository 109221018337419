import { SyntheticEvent, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Tab, Tabs } from '@mui/material';

import { ALL_OPTION_VALUE } from '@carbonmaps/shared/utils/constants';

import Typography from '../../components/Typography';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

import TeamAddMember from './TeamAddMember';
import TeamForm from './TeamForm';


const styles = {
	tabs: css({
		'.MuiTabs-flexContainer': {
			borderBottom: cn('1px solid', siteColors.grey500),
			display: 'flex',
			gap: 16,
		},

		'.MuiButtonBase-root': {
			textTransform: 'none',
			fontSize: '14px !important',
			fontWeight: '400',
			color: cn(siteColors.grey8, '!important'),
			paddingTop: '16px',
			paddingBottom: '16px',
			paddingLeft: '0px !important',
			paddingRight: '0px !important',
			minWidth: '0px !important',
		},
		'.Mui-selected': {
			color: cn(siteColors.primary, '!important'),
		},
		'.MuiTab-textColorPrimary': {},
		'.MuiTabs-indicator': {
			backgroundColor: siteColors.primary,
		},
	}),
	content: css({
		paddingTop: 24,
	}),
	teamLabel: css({
		color: `${siteColors.cmText} !important`,
		fontSize: '24px !important',
		fontWeight: '600 !important',
	}),
};


export type Team = {
	name: string;
	filters: Array<{
		path: string,
		values: Array<string>,
	}>
	objectId: string;
}

type TeamCreateTabProps = {
	onClose: () => void;
	team?: Team;

}

type TeamTabType = {
	title: string;
	element: React.FC<any>;
	tabName: string;
}

const tabs: Record<string, TeamTabType> = {
	members: {
		title: 'members',
		element: TeamAddMember,
		tabName: 'members',
	},
	permission: {
		title: 'permission',
		element: TeamForm,
		tabName: 'permission',
	},
};



const TeamCreateTab = ({ team, onClose }: TeamCreateTabProps) => {
	const { t } = useTranslation();
	const [currentTab, setCurrentTab] = useState('members');

	// -------------------------------------------- //
	// --------------- user connected info -------- //
	// -------------------------------------------- //
		const {
			result: { data: authData },
		} = useGetClientAuthQuery();


	const handleTabChange = (_: SyntheticEvent, value: any) => {
		setCurrentTab(value);
	};

	const renderContent = () => {

		const Component = tabs[currentTab].element;
		return <Component team={team} onClose={onClose} companyId={authData?.session?.company?.objectId} isEdit={true} />;
	};

	return (
		<form className="width100">

			<div>
				<Typography variant="caption" className="grey7">{t('team')}</Typography>
				<Typography className={styles.teamLabel}>{team?.name}</Typography>
			</div>

			<Tabs
					onChange={handleTabChange}
					aria-label="lab API tabs example"
					value={currentTab}
					className={cx('width100', styles.tabs)}
				>
				{ team?.name === t(ALL_OPTION_VALUE)
			? <Tab key={'members'} value={'members'} label={t('members')} />
			: Object.entries(tabs).map(([tabKey, { title }]) => {
					return <Tab key={tabKey} value={tabKey} label={t(title)} />;
				})}
				</Tabs>
				<div className={cx('width100', styles.content)}>
					 {renderContent()}
				</div>
		</form>
	);

};

export default TeamCreateTab;
