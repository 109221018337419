import React from 'react';

import { css, cx } from '@emotion/css';
import { Typography as AntTypography } from '@mui/material';

const styles = {
	h1: css({
		fontSize: '32px !important',
		fontWeight: '600 !important',
	}),
	h2: css({
		fontSize: '24px !important',
		fontWeight: '600 !important',
	}),
	h3: css({
		fontSize: '16px !important',
		fontWeight: '500 !important',
	}),
	h4: css({
		fontSize: '14px !important',
		fontWeight: '600 !important',
		textTransform: 'uppercase',
	}),
	h5: css({
		fontSize: '14px !important',
		fontWeight: '400 !important',
		color: '#000 !important',
	}),

	subtitle1: css({
		fontSize: '48px !important',
		fontStyle: 'normal',
		fontWeight: '400 !important',
	}),
	subtitle2: css({
		fontSize: '24px !important',
		fontStyle: 'normal',
		fontWeight: '400 !important',
	}),
	body1: css({
		fontSize: '14px !important',
		fontStyle: 'normal',
		fontWeight: '400 ',
	}),
	body2: css({
		fontSize: '16px !important',
		fontStyle: 'normal',
		fontWeight: '600 !important ',

	}),
	caption: css({
		fontSize: '12px !important',
		fontStyle: 'normal',
		fontWeight: '400 !important ',
	}),
	overline: css({
		fontSize: '12px !important',
		fontStyle: 'normal',
		fontWeight: '600 !important ',
		textTransform: 'uppercase',
	}),
} as any;

type TypographyProps = {
	text?: React.ReactNode;
	variant?:
		| 'body1'
		| 'body2'
		| 'button'
		| 'caption'
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6'
		| 'inherit'
		| 'overline'
		| 'subtitle1'
		| 'subtitle2'
		| string;
	gutterBottom?: boolean;
	children?: React.ReactNode;
	className?: string;
	[x: string]: any;
};

const Typography = ({
	text,
	children,
	variant = 'body1',
	className,
	gutterBottom = false,
	...rest
}: TypographyProps) => {
	return (
		<AntTypography
			variant={variant as any}
			gutterBottom={gutterBottom}
			className={cx(styles[variant], className)}
			{...rest}
		>
			{text}
			{children}
		</AntTypography>
	);
};

export default Typography;
