import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';

import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { Box as BoxIcon } from 'lucide-react';
import { Link, Navigate, createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';

import {
	FRONT_PATH_NAMES,
	ITEM_NATURE_FOOD,
	ITEM_NATURE_NON_FOOD,
	roleLabels,
} from '@carbonmaps/shared/utils/constants';
import { MOD_ECO_CONCEPTION } from '@carbonmaps/shared/utils/module';
import { getConfigFacets } from '@carbonmaps/shared/utils/utils';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useGetProductSheetTimePeriod, useProductInfo } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { getPluginCompany } from '@carbonmaps/ui/utils/utils';

import SEO from '../../components/SEO';
import ScrollTop from '../../components/ScrollTop';
import SheetPageLayout, { ITab } from '../../components/layout/page/SheetPageLayout';
import useHasRoles from '../../hooks/useHasRoles';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useQueryParams } from '../../hooks/useSearchParams';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import ProductModel from '../../models/Product.model';
import { moveItemToFront } from '../../utils/utils';
import Page404 from '../404/Page404';

import CompareButton from './CompareButton';
import SimulationButton from './SimulationButton';
import ProductDataDetails from './datas/ProductDataDetails';
import Ingredients from './ingredients/ProductIngredients';
import ProductPackaging from './packaging/ProductPackaging';
import ProductSynthesis from './synthesis/ProductSynthesis';

const styles = {
	subTitle: css({
		color: siteColors.grey700,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '140%',

		gap: '8px',
		textDecoration: 'underline',
		textUnderlineOffset: '6px',

		'& a': {
			color: siteColors.grey700,
		},
	}),
};

const ProductSheet = () => {
	const { setBreadcrumbs, indicator } = useApp();
	const params = useParams();

	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();
	const queryParams = useQueryParams();

	const searchParams = createSearchParams(queryParams as any)?.toString();
	const { t } = useTranslation();

	const analytics = useJuneTrack();

	const { data: productInfo, isLoading } = useProductInfo({
		objectId: _.toString(params.productId),
		viewMode: indicator,
	});

	const itemNature = (productInfo as any)?.['itemNature'] ?? ITEM_NATURE_FOOD;

	const trackEvent = useCallback(
		(options: any) => {
			if (!analytics) return;
			analytics.track('Explore Product Switch Tab', options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		if (!productInfo) {
			setBreadcrumbs([{ text: t('products') }]);
			return;
		}

		const productSheetBreadcrumbs: any =
			itemNature === ITEM_NATURE_NON_FOOD
				? [
						{
							text: t('non-food-products'),
							link: `${FRONT_PATH_NAMES.products.general}/non-food-products?${searchParams}`,
						},
				  ]
				: [{ text: t('products'), link: `${FRONT_PATH_NAMES.products.general}/products?${searchParams}` }];
		productSheetBreadcrumbs.push({ text: _.capitalize(productInfo?.label) });
		setBreadcrumbs(productSheetBreadcrumbs);
	}, [productInfo, setBreadcrumbs, searchParams, isLoading, t, itemNature]);

	const tabs = useMemo(() => {
		return {
			synthesis: {
				title: t('synthesis-tab'),
				element: <ProductSynthesis />,
				tabName: 'Summary',
			},
			...(itemNature === ITEM_NATURE_NON_FOOD
				? {}
				: {
						ingredients: {
							title: t('Composition'),
							element: <Ingredients />,
							tabName: 'Agriculture & upstream transformation',
						},
				  }),
			packaging: {
				title: t('product-packaging-tab'),
				element: <ProductPackaging />,
				tabName: 'Packaging',
			},
			datas: {
				title: t('datas'),
				element: <ProductDataDetails />,
			},
		} satisfies Record<string, ITab>;
	}, [t, itemNature]);

	const handleTabChange = (_e: SyntheticEvent, value: string) => {
		trackEvent({
			ProductTabTarget: _.get(tabs, value)?.tabName,
		});

		navigate(`${FRONT_PATH_NAMES.products.details}/${params.productId}/${value}?${searchParams}`);
	};

	// get user data
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// -------------------------------------- //
	// --------- facets to display ---------- //
	// -------------------------------------- //
	const items = getConfigFacets(authData?.session.company, false, 'Product').slice(0, 10);
	const sortedItems = moveItemToFront(
		items,
		_.get(authData, 'session.company.category', _.get(authData, '0.field')) as never,
	);
	const preferredFacet = _.first(sortedItems);

	const hasRoles = useHasRoles();

	const { data: productSheetData, isLoading: isLoadingProductSheet } = useGetProductSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.productId!,
	});

	const productToCompare = useMemo(() => {
		if (!productSheetData) return null;

		const product = _.pick(_.get(productSheetData, 'product'), [
			'carbonImpact',
			'carbonIntensity',
			'label',
			'objectId',
			'uid',
			'waterImpact',
			'waterIntensity',
			'color',
			'id',
			'intensity',
			'impact',
			'percent',
			'percentDiff',
		]);

		return product;
	}, [productSheetData]);

	if (!params.tab) {
		return (
			<Navigate
				replace
				state={{ from: location }}
				to={`${FRONT_PATH_NAMES.products.details}/${params.productId}/${Object.keys(tabs)[0]}?${searchParams}`}
			/>
		);
	}

	if ((productInfo as never) === 'PRODUCT_NOT_FOUND') {
		return <Page404 message={<h2>Produit ({params.productId}) introuvable</h2>} />;
	}

	const plugins = authData?.session.company?.plugins || [];
	const companyCode = authData?.session.company?.code;

	return (
		<>
			<SEO title={productInfo?.label ? `${_.capitalize(productInfo?.label)} - CarbonMaps` : 'CarbonMaps'} />
			<ScrollTop key={params.tab} behavior="auto" />
			<SheetPageLayout
				loading={isLoading}
				currentTab={params.tab}
				headerProps={{
					variant: 'fixed',
					icon: <BoxIcon size={40} color={theme.palette.common.black} />,
					isBlueCheck: new ProductModel(productInfo)?.isN2ModelizationType() ?? false,
					title: productInfo?.label || '',
					overTitle: productInfo?.uid || '',
					subtitle: (
						<div className={cx(styles.subTitle, 'flexRow alignCenter')}>
							<Link
								css={{ '&:hover': { color: siteColors.grey900 } }}
								to={`${FRONT_PATH_NAMES.analyse}/${_.get(preferredFacet, 'field')}/${_.get(
									productInfo,
									`${companyCode}Categories.${_.get(preferredFacet, 'field')}`,
								)}`}
							>
								{_.get(productInfo, `${companyCode}Categories.${_.get(preferredFacet, 'field')}`)}
							</Link>
							<Link css={{ '&:hover': { color: siteColors.grey900 } }} to={'datas'}>
								{t('Voir +')}
							</Link>
						</div>
					),
					rightElement: (
						<div>
							<div className="flexRow gap16" css={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>
								{hasRoles([roleLabels.EDITOR, roleLabels.ADMIN]) &&
								getPluginCompany(plugins, MOD_ECO_CONCEPTION).active === true
? (
									<SimulationButton />
								)
: null}
								<CompareButton product={productToCompare} isLoading={isLoadingProductSheet} title={t('Comparer')} />
							</div>
						</div>
					),
				}}
				onTabChange={handleTabChange}
				tabs={tabs}
				productSheet
			/>
		</>
	);
};

export default ProductSheet;
