import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, FormControlLabel } from '@mui/material';

import { useCompareProduct } from '../../hooks/useCompareProduct';
import { useTranslation } from '../../hooks/useTranslation';

const LifeCycleCheck = () => {
	const { t } = useTranslation();

	const { isLCACompare, setIsLCACompare } = useCompareProduct();

	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;

	const handleChange = () => {
		setIsLCACompare(!isLCACompare);
	};

	return (
		<div className="flexRow alignCenter gap8">
			<FormControlLabel control={<Checkbox icon={icon} checkedIcon={checkedIcon} checked={isLCACompare} onChange={handleChange}/>} label={t("Comparer au total l'impact de l'ACV")} />
		</div>
	);
};


export default LifeCycleCheck;
