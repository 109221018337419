import { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { Button } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { Plus } from 'lucide-react';
import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { SimulationFormInput } from '@carbonmaps/shared/validations/simulation.validations';
import { getProductOptionsAutocompleteDataAction } from '@carbonmaps/ui/actions/product.actions';
import { createSimulationAction } from '@carbonmaps/ui/actions/simulation.actions';
import { useGetProductSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetProductSheet';

import CreateScenarioModal from '../../components/simulation/CreateScenarioModal';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';

const styles = {
	button: css({
		'&.MuiButtonBase-root': {
			display: 'flex',
			height: '42px',
			padding: '8px 16px',
			justifyContent: 'center',
			alignItems: 'center',
			// gap: '8px',
			borderRadius: '8px',
			border: '1px solid var(--Grey-5, #D1D5DB)',
			background: 'var(--Grey-0, #FFF)',
			'&:hover': {
				background: 'var(--Grey-0, #FFF)',
				borderColor: siteColors.grey900,
			},
			textTransform: 'unset',
			color: siteColors.blackButton,
		},
	}),
};

const SimulationButton = () => {
	const { t } = useTranslation();
	const [openModal, setOpenModal] = useState(false);
	const [message, setMessage] = useState('');
	const params = useParams();
	const { selectedYear } = useYearSelection();
	const [options, setOptions] = useState([]);

	const {
		data,
		isSuccess: isSuccessProductSheet,
		// isLoading,
	} = useGetProductSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.productId!,
	});

	const [selectedOption, setSelectedOption] = useState(data?.product) as any;

	useEffect(() => {
		if (!selectedOption) {
			setSelectedOption(data?.product);
		}
	}, [data?.product, selectedOption]);

	const queryClient = useQueryClient();

	const {
		mutate: createSimulation,
		isError,
		isLoading: isLoadingCreateSimulation,
	} = useMutation({
		mutationKey: ['createSimulation'],
		mutationFn: createSimulationAction,
		onSuccess: async (value: any) => {
			queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
			setOpenModal(false);
			window.open(`${FRONT_PATH_NAMES.simulation}/${value.objectId}`, '_blank');
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const onSubmit: SubmitHandler<SimulationFormInput> = async (values: any) => {
		createSimulation({
			...values,
			productId: selectedOption?.objectId,
			period: selectedYear,
		} as any);
	};

	const [searchQueryParams] = useSearchQueryParams();
	const [paramsTable, setTableParams] = useState<any>(searchQueryParams);
	const [search, setSearch] = useState<any>();

	//----- fetch data for autocomplete ----//
	const { data: dataOptions, isSuccess: isSuccessOptions } = useQuery({
		queryKey: [
			'getOptionsAutocompleteData',
			{
				...paramsTable,
				input: search?.input ?? '',
			},
		] as any,
		queryFn: getProductOptionsAutocompleteDataAction,
	});

	useEffect(() => {
		const options: any = [];

		if (isSuccessOptions && dataOptions?.length) {
			options.push(...dataOptions);
		}

		if (isSuccessProductSheet) {
			options.push(data?.product);
		}

		const optionsUniq = _.uniqBy(options, 'uid');
		setOptions(optionsUniq as any);
	}, [isSuccessOptions, dataOptions, search, selectedOption, isSuccessProductSheet, data?.product]);

	const [isNew, setIsNew] = useState(true);

	return (
		<>
			<Button
				className={styles.button}
				disableRipple
				onClick={() => {
					setSearch({ input: '', page: 1 });
					setIsNew(true);
					setSelectedOption(null);
					return setOpenModal(true);
				}}
				startIcon={
					<Plus
						color={siteColors.grey700}
					/>
				}
			>
				{t('Éco-concevoir')}
			</Button>
			<CreateScenarioModal
				title={t('create-scenario-form-title')}
				open={openModal}
				onClose={() => {
					return setOpenModal(false);
				}}
				isLoading={isLoadingCreateSimulation}
				onSubmit={onSubmit}
				options={options}
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				message={message}
				isError={isError}
				setSearch={setSearch}
			/>
		</>
	);
};

export default SimulationButton;
