import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { CheckSquare, HelpCircle, Square } from 'lucide-react';

import { jobQueueStatus } from '@carbonmaps/shared/utils/constants';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import { siteColors } from '../../../../../apps/front/src/lib/colors';
import BPopover from '../BPopover';

import ChipsStatus from './ChipsStatus';
import ListCell from './ListCell';

type Props = {
	value: any;
	row: any;
	column: any;
	updateMyData: (...params: any[]) => void;
	noValue?: string;
};

const styles = {
	popoverContainer: css({
		padding: '24px',
		marginBottom: 12,
	}),
	content: css({
		padding: '8px',
		fontSize: '14px !important',
	}),
	emptyContent: css({
		padding: '8px',
		fontSize: '16px !important',
		fontWeight: 500,
		fontStyle: 'italic',
	}),
	chipsContent: css({
		backgroundColor: siteColors.grey500,
		color: siteColors.grey900,
		padding: '4px 6px',
		borderRadius: '16px',
		margin: '4px',
		textAlign: 'center',
		fontSize: '12px !important',
		width: '150px',
	}),
	red: css({
		color: '#C70000',
	}),

	text0: css({
		// whiteSpace: 'nowrap',
		marginBottom: '6px',
	}),
	text1: css({
		whiteSpace: 'nowrap',
		fontSize: '14px !important',
	}),

	text3: css({
		fontSize: '14px!important',
	}),
};

const SimpleCell = ({
	value,
	row,
	column,
	updateMyData, // This is a custom function that we supplied to our table instance
}: Props) => {
	const theme = useTheme();

	const t = column?.props?.trans;

	if (row.original.showAlert && row.original.showAlert === 1 && column.showAlert === 1) {
		return (
			<div>
				<BPopover
					anchorOriginHorizontal={'right'}
					transformOriginVertical={'top'}
					transformOriginHorizontal={'right'}
					anchorOriginVertical={'bottom'}
					modeHover
					content={(onClose) => {
						return (
							<div className={cx('flexColumn', styles.popoverContainer)}>
								<Typography className="fontWeight700">UVC non renseignées</Typography>
								<Typography>Calcul de l'impact impossible</Typography>
							</div>
						);
					}}
					addStyles={{
						minWidth: '30px',
					}}
				>
					<div className="flexRow nowrap">
						<HelpCircle />
					</div>
				</BPopover>
			</div>
		);
	}

	if (column.type === 'booleanStatus') {
		return <div style={{ color: value === true ? 'red' : 'auto' }}>{value === true ? 'Oui' : 'Non'}</div>;
	}

	if (column.type === 'fieldStatus') {
		if (value)
			return (
				<div className={cx('flexRow alignCenter justifyCenter')}>
					<div className={cx(styles.chipsContent)}>{value}</div>
				</div>
			);
		return;
	}

	if (column.type === 'ListCell') {
		if (value) {
			return (
				<div>
					<ListCell
						value={value}
						borderColor={row.original?.borderColor || theme.palette.grey[100]}
						color={row.original.color || theme.palette.grey[100]}
						backgroundColor={row.original.backgroundColor || theme.palette.grey[100]}
						column={column}
						row={row}
						keyGlossary={row.original?.keyGlossary}
					/>
				</div>
			);
		}

		return;
	}

	if (column.type === 'boolean') {
		if (value === true) {
			return (
				<>
					<CheckSquare color={theme.palette.grey[700]} width={24} height={24} />
				</>
			);
		}

		return (
			<>
				<Square color={theme.palette.grey[700]} width={24} height={24} />
			</>
		);
	}

	if (column.type === 'status') {
		if ((column.props?.page === 'simulation' && row.original?.level === 2) || column.props?.page === 'devis-table') {
			return (
				<div>
					<ChipsStatus
						isLoading={column?.isLoading || false}
						row={row?.original}
						callback={column?.callbackChangeStatus}
						t={t}
						statusDictionary={column?.props?.statusDictionary}
					/>
				</div>
			);
		}

		return <div className={cx(styles.text3)}>{`${row.original?.numberSimulation} ${t('scénario(s) créé(s)')}`}</div>;
	}

	return (
		<>
			{column.noValueMessage && value === '-'
			?
			(
				<div className={cx('grey700', styles.emptyContent)}>{column.noValueMessage}</div>
			)
			:
			(
				<div
					className={cx(
						' number grey900--',
						styles.content,
						css(column?.props?.style),
						column?.props?.className,
						value === jobQueueStatus.RUNNING && styles.red,
					)}
					style={column?.props?.nativeStyle}
				>
					{/* {typeof value === 'number' ? formatNumber(value, '', value < 1 && value > 0 ? 1 : 0) : value} */}
					{/* {_.isNumber(value) ? displayValue(value, undefined, value < 1 && value > 0 ? 1 : 0) : value} */}
					{_.isNumber(value)
						? displayValue(
								value,
								undefined,
								column.props?.page === 'presencePercent' ? 2 : value < 1 && value > 0 ? 1 : 0,
						  )
						: value}
				</div>
			)}
		</>
	);
};

export default SimpleCell;
