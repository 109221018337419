import { css, cx } from '@emotion/css';
import { HeartPulse } from 'lucide-react';

import IconContainer from '../../components/layout/list/IconContainer';
import ListHeader from '../../components/layout/list/ListHeader';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';


const CONTENT_MAX_WIDTH = '1200px';

const styles = {
	mb3: css({
		marginBottom: '2.5rem!important',
		maxWidth: CONTENT_MAX_WIDTH,
	}),
	fullWidth: css({
		maxWidth: 'unset!important',
		marginBottom: '.5rem!important',
	}),
	};

	type Props = {
		title?: string;
		subtitle?: string;
		fullWidth?: boolean;
	};

const DataDiagHeader = ({ subtitle, title, fullWidth }: Props) => {
	const { t } = useTranslation();
	return (
<ListHeader
				title={title ?? t('audit-breadcrumbs')}
				subtitle={subtitle}
				icon={
					<IconContainer element={<HeartPulse  color={siteColors.white} size={32} />} color={siteColors.grey800} />
				}
				className={cx(styles.mb3, fullWidth ? styles.fullWidth : '')}
			/>
	);
};

export default DataDiagHeader;
