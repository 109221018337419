import { css, cx } from '@emotion/css';
import { HelpCircle } from 'lucide-react';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useTranslation } from '../hooks/useTranslation';
import { siteColors } from '../lib/colors';

import LightTooltipGlossary from './LightTooltipGlossary';


type EmptyValueProps = {
	description?: string;
};

const EmptyValueHelpCircle = ({ description }: EmptyValueProps) => {
	const { t } = useTranslation();
	const { indicator } = useApp();

	return <div style={{ cursor: 'pointer' }} >
		<LightTooltipGlossary title={description ? t(description) : t(`no-data-${indicator}-alert`)}>
			<HelpCircle color={siteColors[`${indicator}500`]} />
		</LightTooltipGlossary>
	</div>;
};

export default EmptyValueHelpCircle;
