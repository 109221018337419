import { useCallback, useMemo } from 'react';

import { css } from '@emotion/css';
import Box from '@mui/material/Box';
import MUILink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Users } from 'lucide-react';
import { Link as RouterLink, createSearchParams, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import ScoringCard from '../../../../components/card/ScoringCard';
import Skeleton from '../../../../components/Skeleton';
import Card from '../../../../components/widget/Card';
import LineDisplay from '../../../../components/widget/LineDisplay';
import SupplierLineDisplay from '../../../../components/widget/SupplierLineDisplay';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import SupplierModel from '../../../../models/Supplier.model';
import { useSupplierFirstElement } from '../../analyse.hooks';

const styles = {
	skeleton: css({
		width: '100% !important',
		height: '150px !important',
	}),
};

const SuppliersCard = () => {
	const { t } = useTranslation();
	// fetch data
	const { data, isLoading } = useSupplierFirstElement({});

	const params = useParams();

	const suppliers = useMemo(() => {
		if (!data) return [];

		return data;
	}, [data]);

	const getLinkMore = useCallback(() => {
		const searchParams = createSearchParams({ category: params?.segment, value: params?.value } as any);
		return `${FRONT_PATH_NAMES.suppliers.general}?${searchParams.toString()}`;
	}, [suppliers]);

	return (
		<Card
			title={t('suppliers')}
			icon={<Users color={siteColors.grey700} size={20} />}
			center={false}
			marginBottom="32px"
			sx={{ width: '100%' }}
		>
			{isLoading
? (
				<Skeleton className={styles.skeleton} />
			)
: (
				<Box className="flexColumn" sx={{ width: '100%' }}>
					{suppliers.length
? (
						<Box className="flexColumn" sx={{ gap: '10px' }}>
							<Box>
								{suppliers.map((item: any, index: number) => {
									const supplier = new SupplierModel(item);
									return (
										<LineDisplay
											key={index}
											path={`${FRONT_PATH_NAMES.suppliers.details}/${supplier.getId()}/synthesis`}
											content={<SupplierLineDisplay supplier={supplier} />}
											afterContent={
												<ScoringCard
													value={supplier.getScoreText()}
													fontSize={12}
													sx={{ width: 36, height: 26, justifySelf: 'flex-end' }}
												/>
											}
										/>
									);
								})}
							</Box>
							<MUILink
								component={RouterLink}
								to={getLinkMore()}
								sx={{ color: siteColors.grey800, textDecorationColor: siteColors.grey800 }}
							>
								{t('see-more-supplier')}
							</MUILink>
						</Box>
					)
: (
						<Typography>{t('product-suppliers-card-empty-data')}</Typography>
					)}
				</Box>
			)}
		</Card>
	);
};

export default SuppliersCard;
