

import { css, cx } from '@emotion/css';
import { t } from 'i18next';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { product, useCompareProduct } from '../../hooks/useCompareProduct';
import { siteColors } from '../../lib/colors';
import { compareContentMap, compareProductConfig } from '../../utils/compareProduct';
import Typography from '../Typography';

import CompareItemValue from './CompareItemValue';
import ProductLink from './ProductLink';



const styles = {
	bar: css({
		height: 45,
		width: 'var(--width)',
		backgroundColor: 'var(--bgColor)',
		borderRadius: 2,
		minWidth: 3,
	}),
	value: css({
		minWidth: 100,
	}),
	link: css({
		textDecoration: 'none',
		':hover': {
			textDecoration: 'underline',
			textDecorationColor: 'var(--underlineColor)',
		},
	}),
	lifeChartBar: css({
		minWidth: 'var(--width)',
		backgroundColor: 'var(--bgColor)',
		height: '100%',
		borderRadius: 2,
		transform: 'rotate(-0.013deg)',
		padding: 4,
		svg: {
			color: `${siteColors.common.white} !important`,
		},
	}),
	lifeChartOtherContent: css({
		borderRadius: 2,
		border: '1px dashed rgba(0, 0, 0, 0.20)',
		background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), var(--bgColor)',
		height: '100%',
		transform: 'rotate(-0.013deg)',
	}),
};

type BarItemProps = {
	product: product;
	color: string;
	isLifeChart?:boolean;

}

const BarItem = ({ product, color, isLifeChart }: BarItemProps) => {

	const { compareType, indicator } = useCompareProduct();
	const config = compareProductConfig.get(compareType)[indicator] as any;
	const iconItem = compareContentMap.get(compareType)?.icon;
	const { productToCompare , setProductToCompare, isLCACompare } = useCompareProduct();

	return (
		<div className="flexRow gap16 width100 alignCenter">
			<div className="flexColumn gap8 flex1">
					<ProductLink product={product} color={color}>
						<Typography className="grey8 numeric">
							{product.label}
						</Typography>
					</ProductLink>

				<div className='flexRow gap6 alignCenter width100 nowrap gap4'>
					<div className={cx('flexRow alignCenter gap2 nowrap', styles.bar)} style={{ ['--width' as any]: `calc(${Math.abs(product.percent)}% - ${isLifeChart  ? 170 : 100}px)`, ['--bgColor' as any]: isLifeChart ? 'transparent' : color }}>
							{ isLifeChart
								? <>

											<div className={cx('flexCenter', styles.lifeChartBar)} style={{ ['--width' as any]: `calc(${Math.abs(product?.stepPercent || 0)}%)`, ['--bgColor' as any]:  color }}>
												{iconItem}
											</div>

										{ isLCACompare && <div className={cx('flex1', styles.lifeChartOtherContent)} style={{ ['--bgColor' as any]: color }} /> }
								</>
								: null }
							{ !isLCACompare && isLifeChart && <ValueBarItem product={product} config={config} isLifeCycle={isLifeChart} isLCACompare={isLCACompare} className={css({ paddingLeft: 2 })} /> }
					</div>
					{((isLCACompare && isLifeChart)|| !isLifeChart) && <ValueBarItem product={product} config={config} isLifeCycle={isLifeChart} isLCACompare={isLCACompare} /> }
				</div>
			</div>
			<CompareItemValue value={product.percentDiff as never} onSelected={()=> {return setProductToCompare(product);}} isCompared={(productToCompare as any)?.objectId === product.objectId }/>
		</div>
	);
};

export default BarItem;
type ValueBarItemProps = {
	product: product;
	config: {
		unit: string;
		precision: number;
	}
	isLCACompare?: boolean;
	isLifeCycle?: boolean;
	className?: string
}

export const ValueBarItem = ({ product, config, isLCACompare, isLifeCycle, className }: ValueBarItemProps) => {
	return (
		<div className={cx('flexColumn', styles.value, className)}>
		<div className="flexRow alignCenter nowrap gap6">
			<Typography className="grey9 numeric">
				{  formatNumber(product.value, undefined , config.precision ) }
			</Typography>
			{ isLCACompare && isLifeCycle && <Typography className="grey7 numeric">({formatNumber(product?.stepPercent, undefined , 0 )}% {t("de l'ACV")}) </Typography> }
		</div>
		{config.unit}
	</div>
	);
};
