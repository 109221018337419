import { ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import Box, { type BoxProps } from '@mui/material/Box';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { InfoIcon } from 'lucide-react';

import { pxToRem } from '@carbonmaps/ui/utils/styles';

import { siteColors } from '../lib/colors';

type Props = TypographyProps & {
	icon?: ReactNode;
	className?: string;
};


const styles = {
	container: css({
		padding: '12px 24px',
		backgroundColor: siteColors.grey2,
		border: `solid 1px ${siteColors.grey500}`,
		borderTop: 'none',
		borderRadius: ' 0 0 16px 16px',

	}),
};

const BottomCard = ({ children, icon, className, ...props }: Props) => {

	return (
		<div className={cx('flexRow alignCenter gap8', styles.container)} >
			{icon ?? <InfoIcon size={12} color={siteColors.grey8} />
			}
			<Typography
				color={siteColors.grey8}
				fontSize={'12px'}
				fontWeight={400}
				ml={icon ? '8px' : 0}
				{...props}
			>
				{children}
			</Typography>
		</div>
	);
};

export default BottomCard;
