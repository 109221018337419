import { type QueryFunctionContext } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';

type TeamFormInput = {
	name: string;
	filters: Array<{
		path: string;
		type: string;
		values: Array<string>;
	}>;
	id?:string;
	description?: string;
};

/**
 *  save a team
 * @param param0
 * @returns
 */
export const saveTeamAction = async ({ name, filters, id , description }: TeamFormInput): Promise<void> => {
	try {
		const res = await Parse.Cloud.run(functionName.team.saveTeam, { name, filters, description, id });

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

type setTeamUserInput = {
	userId: string;
	teamId: string;
}

export const addUserToTeamAction = async ({ userId, teamId }: setTeamUserInput): Promise<void> => {
	try {
		const res = await Parse.Cloud.run(functionName.team.addUserToTeam, { userId, teamId });

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const RemoveUserTeamAction = async ({ userId, teamId }: setTeamUserInput): Promise<void> => {
	try {
		const res = await Parse.Cloud.run(functionName.team.RemoveUserTeam, { userId, teamId });

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};



type TeamUserInput = {
	team: string | number;
}

export const saveTeamUserAction = async ({ team }: TeamUserInput): Promise<void> => {
	try {
		const res = await Parse.Cloud.run(functionName.team.saveTeamUser, { team });
		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};




type DeleteTeamInput = {
	id: string;
}

/**
 * delete a team
 * @param param0
 * @returns
 */
export const deleteTeamAction = async ({ id }: DeleteTeamInput): Promise<void> => {
	try {
		const res = await Parse.Cloud.run(functionName.team.deleteTeam, { id });

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 * get teams
 * @param context
 * @returns
 */
export const getTeamsAction = async (
	context: QueryFunctionContext<readonly ['getTeamsTable', Record<string, any>]>,
) => {
	try {
		const {
			queryKey: {
				1: { size, page, ...rest },
			},
		} = context;

		const result: {
			teams: any[];
			meta: {
				last_page: number;
				count: number;
			};
		} = await Parse.Cloud.run(
			functionName.team.getTeams,
			{
				limit: size,
				skip: (page - 1) * size,
				pageIndex: page,
				...rest,
			},
		);

		return {
			data: result.teams,
			meta: {
				last_page: result.meta.last_page,
				count: result.meta.count,
			},
		};
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getTeamOptionsAction = async (
	context: QueryFunctionContext<readonly ['getTeamOptions', {companyId: string}]>,
) => {
	try {
		const {
			queryKey: {
				1: { companyId },
			},
		} = context;
		const result = await Parse.Cloud.run(functionName.team.getTeams, { companyId });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};
