
import { useState } from 'react';

import { css, cx } from '@emotion/css';
import Tooltip from '@mui/material/Tooltip';
import { X } from 'lucide-react';

import { product } from '../../hooks/useCompareProduct';
import { siteColors } from '../../lib/colors';
import LightTooltip from '../LightTooltip';
import Typography from '../Typography';



const styles = {
	container: css({
		cursor: 'pointer',
		background: 'transparent',
	}),
	line: css({
		width:4,
		background: 'var(--background)',
		borderRadius:8,
	}),
	title: css({
		color: `${siteColors.cmText} !important`,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		maxWidth: '220px',
		textWrapMode: 'nowrap',
	}),


};


type ProductItemProps = {
	product: product;
	onClose: (p: product) => void;
}

const ProductItem = ({ product, onClose }: ProductItemProps) => {

	const [isHovered, setIsHovered] = useState(false);

	return (
		<>
		<div className={cx('flexRow gap8 spaceBetween width100 alignCenter', styles.container)} onMouseEnter={() => {return setIsHovered(true);}}
		onMouseLeave={() => {return setIsHovered(false);}}>
			<div className="flexRow gap8 stretch">
				<div className={styles.line} style={{ ['--background' as any]: product.color }}/>
				<div className="flexColumn gap4">

				<LightTooltip title={product.label} >
					<div>
						<Typography className={cx('number', styles.title)}>
							{product.label}
						</Typography>
						</div>
					</LightTooltip>
					<Typography variant="caption" className="number grey7">
						{product.uid}
					</Typography>
				</div>
			</div>

			{ isHovered && <div onClick={() => {return onClose(product);}}><X size={20} color={siteColors.grey7}/></div> }
		</div>
		</>
	);
};

export default ProductItem;
