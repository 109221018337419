import Typography from '@mui/material/Typography';

const TeamsEdit = (props: any) => {
	const {
		value,
		row,
		column,
	} = props;

	const translate = (v: string) => {return v;};

	const transFunc = column?.props?.transFunc || translate;
	console.log('rowrow', row);

	const teams = (value || [])?.map((team: any) => {return transFunc(team.get('name'));});

	return (
		<>
			<Typography className="grey9">{'tetete'}</Typography>
		</>
	);
};

export default TeamsEdit;
