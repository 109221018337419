import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { HelpCircle } from 'lucide-react';
import { useParams, useSearchParams } from 'react-router-dom';

import { ALL_YEAR_OPTION_VALUE, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { getProductTableDataAction } from '@carbonmaps/ui/actions/product.actions';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import LightTooltipGlossary from '../../../components/LightTooltipGlossary';
import ProductFloatingMenu from '../../../components/comparison/product/ProductFloatingMenu';
import TableHeaderCell from '../../../components/table/TableHeaderCell';
import ImpactCarbonePopover from '../../../components/table/popover/ImpactCarbonePopover';
import WaterImpactPopover from '../../../components/table/popover/WaterImpactPopover';
import { useGlossary } from '../../../hooks/useGlossary';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import ProductModel from '../../../models/Product.model';
import { CARBON_INDICATOR } from '../../../utils/constants';

const styles = {
	popoverImpactStyle: css({ paddingRight: 0 }),
	percent: css({
		justifyContent: 'flex-end',
	}),
	check: css({
		p: {
			maxWidth: '250px!important',
		},
	}),
};

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},
		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered tr td:nth-of-type(4) div,.bordered tr td:nth-of-type(6) div': {
			justifyContent: 'flex-end',
		},
		'.bordered tr th:not(:nth-of-type(2),:nth-of-type(2),:nth-of-type(7)) div': {
			justifyContent: 'right',
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		'.bordered tr th:not(:nth-of-type(1),:nth-of-type(2)) > div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) div ,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) button':
			{
				justifyContent: 'flex-end!important',
				paddingRight: '0!important',
			},
	};
};

const formatThenSetData = (res: any, indicator: any) => {
	if (!res?.length) return [];
	const maxValue: any = _.maxBy(res, 'carbonIntensity');
	return res.map((item: any) => {
		const productModel = new ProductModel(item);
		const VSReference = productModel.getVsReference(indicator);

		return {
			...item,
			reference: item.uid,
			percentage: (item.carbonIntensity * 100) / maxValue?.carbonIntensity /* ?? 0 */,
			VSReference,
			// percentageWaterIntensity:
		};
	});
};

type IngredientTableProps = {
	filterOptions: any;
};

const IngredientUsesTable = ({ filterOptions }: IngredientTableProps) => {
	// query params
	const params = useParams();
	const { selectedYear } = useYearSelection();
	// translation
	const { t } = useTranslation();
	// glossary
	const { g } = useGlossary();
	// theme
	const theme = useTheme();
	// switch value ( carbon or water )
	const { indicator } = useApp();
	// custom style tab
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// --- ref for fetch data table
	const fetchIdRef = useRef(0);

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState(
		/* <GetProductTableQueryParams> */ {
			input: '',
			page: 1,
			size: 100,
			facetFilters: [],
			supplierIds: [], // supplier filters
			direction: 0,
			sortingBy: null,
			ingredientId: params.ingredientId,
			...filterOptions,
		},
	);

	// state to force to first page
	const [resetPage, setResetPage] = useState(0);

	// ---- table columns
	const tableColumns = useMemo(() => {
		return [
			{
				// Header: 'PRODUIT FINI',
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('products')} />;
				},
				accessor: 'label',
				editable: true,
				sortDescFirst: true,
				component: 'FinalProduct',
				type: 'string',
				props: {
					className: styles.check,
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={
								selectedYear === ALL_YEAR_OPTION_VALUE
									? t(WORDING_TERMS.IMPACT_ALL_YEAR)
									: t(WORDING_TERMS.IMPACT, { period: selectedYear || t('period') })
							}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonImpactPercent' : 'waterImpactPercent',
				editable: true,
				sortDescFirst: true,
				component: 'PercentField',
				type: 'percent',
				props: {
					...(indicator === CARBON_INDICATOR
						? {
								popoverComponent: ImpactCarbonePopover,

								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,

								emptyValue: <HelpCircle color={siteColors.carbon500} />,
						  }
						: {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
								popoverComponent: WaterImpactPopover,
								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,
								emptyValue: <HelpCircle color={siteColors.water500} />,
						  }),
					decimal: 2,
				},
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.INTENSITY)}
							//label={indicator === CARBON_INDICATOR ? WORDING_TERMS.INTENSITY : 'Intensité eau'}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonIntensity' : 'waterIntensity',
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				type: 'number',
				props: {
					...(indicator !== CARBON_INDICATOR
						? {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
						  }
						: null),
					t: t,
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={
								<LightTooltipGlossary title={g('vs-gamme')}>
									<span className={'underline'}>{t('vs_gamme')}</span>
								</LightTooltipGlossary>
							}
						/>
					);
				},
				accessor: 'VSReference',
				editable: true,
				sortDescFirst: true,
				component: 'VSReference',
				type: 'number',
			},
			{
				// Header: 'PRESENCE',
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('presence')} /* measure="carbonIntensity" */ />;
				},
				accessor: 'composition',
				component: 'Percentage',
				editable: true,
				sortDescFirst: true,
				type: 'number',
				disableSortBy: true,
			},
		];
	}, [indicator, t, selectedYear]);

	const [searchParams] = useSearchParams();
	const paramType = searchParams.get('paramType');

	// ---- fetch data --- //
	const {
		data: dataResult,
		isLoading,
		isSuccess: isSearchSuccess,
	} = useQuery({
		queryKey: [
			'getProductTableData',
			{
				...paramsTable,
				viewMode: indicator,
				selectedYear,
				paramType,
			},
		],
		queryFn: getProductTableDataAction,
	});

	const { skeleton } = useSkeleton({ condition: isLoading });

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);

	useEffect(() => {
		setTableParams((prev: any) => {
			return { ...prev, ...filterOptions };
		});
		// redirect to first page
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterOptions]);

	return (
		<>
			<TableComponent
				skeleton={skeleton}
				loading={false}
				fetchData={updateTableParams}
				pageCount={dataResult?.meta?.last_page || 0}
				noDataMessage={t('ingredient-family-use-product-empty-data', {
					products: toLowerCase(t('products')),

				})}
				pageSize={paramsTable.size}
				columns={tableColumns}
				resetPage={resetPage}
				data={formatThenSetData(dataResult?.data, indicator) || []}
				onSortingColumn={handleSortingTable}
				addStyles={stylesTable}
				manualSortBy
				setSelectedRow={() => {
					return 1;
				}}
			/>
			<ProductFloatingMenu />
		</>
	);
};

export default IngredientUsesTable;
